import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useLocalStorage from '@hooks/useLocalStorage'

import { getUserParam } from '../../auth/state'
import type { RootState } from '../../store'

import {
  fetchAssignedBijlagen,
  getAssignedBijlagen,
  initialState,
} from './state'

export const useBijlagenSearch = () => {
  const dispatch = useDispatch()
  const {
    assignedBijlagen: { data, isLoading, query },
    userParam,
  } = useSelector((state: RootState) => ({
    assignedBijlagen: getAssignedBijlagen(state),
    userParam: getUserParam(state),
  }))
  const { sort, page } = query

  const { item: storedFilters } = useLocalStorage(
    'hoofdleidingAssigned_bijlagen',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localSort, setLocalSort] = useState(sort)
  const [localPage, setLocalPage] = useState(page)

  useEffect(() => {
    dispatch(
      fetchAssignedBijlagen({
        filters: localFilters,
        page: localPage,
        sort: localSort,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort, userParam])

  const onChangeFilters = (newFilters) => {
    setLocalSort(initialState.query.sort)
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort) => setLocalSort(sort)
  const onChangePage = (page) => setLocalPage(page)

  return {
    data,
    initialFilters: initialState.query.filters,
    isLoading,
    localFilters,
    onChangeFilters,
    onChangePage,
    onChangeSort,
    sort,
  }
}
