import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'

import { formatDate } from '../../core/util/date-util'
import {
  fetchBeheerAanvraag,
  getAanvraag,
  importBeheerAanvraag,
  isImportAanvraagLoading,
  resetBeheerImportAanvraag,
} from '../state'

export const AanvraagImporteren: FC = () => {
  const dispatch = useDispatch()
  const { data: aanvraag, isLoading } = useSelector(getAanvraag)
  const isImporting = useSelector(isImportAanvraagLoading)
  const [aanvraagID, setAanvraagID] = useState('')

  const onClick = () => {
    dispatch(fetchBeheerAanvraag({ aanvraagID }))
  }

  const onClickImporteren = () => {
    dispatch(importBeheerAanvraag({ aanvraagID }))
  }

  const onClickOpnieuw = () => {
    dispatch(resetBeheerImportAanvraag())
    setAanvraagID('')
  }

  return (
    <Box sx={{ p: 1, width: '95%', mx: 'auto' }}>
      {!aanvraag.aanvraagID && (
        <Box>
          <FormControl>
            <TextField
              label="AanvraagID"
              onChange={(ev) => setAanvraagID(ev.target.value)}
            />
          </FormControl>

          <IconButton onClick={onClick}>
            <SearchIcon />
          </IconButton>
        </Box>
      )}

      {aanvraag.aanvraagID && (
        <>
          <Box sx={{ width: '95%' }}>
            <Grid sx={{ p: 4, width: '95%', mx: 'auto' }} container>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1">
                  <b>AanvraagID</b>
                </Typography>
                <Typography variant="body2">{aanvraag.aanvraagID}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1">
                  <b>Aanvraagdatum</b>
                </Typography>
                <Typography variant="body2">
                  {formatDate(aanvraag.aanvraagDatum)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1">
                  <b>Gebied</b>
                </Typography>
                <Typography variant="body2">
                  {aanvraag.coordinerende.gebiedscodeLabel}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {!isImporting && (
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" onClick={onClickImporteren}>
                Importeren
              </Button>

              <Button
                sx={{ ml: 2 }}
                variant="outlined"
                onClick={onClickOpnieuw}
              >
                Opnieuw zoeken
              </Button>
            </Box>
          )}
        </>
      )}
      {(isLoading || isImporting) && (
        <LinearProgress
          sx={{
            mt: 2,
            height: '3px',
            backgroundColor: 'rgb(238, 238, 238)',
            background:
              'linear-gradient(to right, rgba(61, 108, 180, 1) 0%, rgba(79, 186, 219, 1) 100%)',
          }}
        />
      )}
    </Box>
  )
}
