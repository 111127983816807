import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'

import { getValuelists } from '@slices/valuelistsSlice'

import { getUser } from '../../auth/state'
import {
  clearOrganisaties,
  fetchOrganisaties,
  getOrganisaties,
} from '../../contracten/state'
import { createProjectdossier } from '../state'

export default function CreateProjectdossierDialog({
  data = {} as any,
  open,
  onClose,
}) {
  const dispatch = useDispatch()
  const { gebieden } = useSelector(getValuelists)
  const { combi } = useSelector(getUser)
  const { data: organisaties, isLoading: isLoadingOrganisaties } =
    useSelector(getOrganisaties)
  const projectmelding = data.projectmelding

  const [name, setName] = useState('')
  const [referenceId, setReferenceId] = useState('')
  const [projectmeldingId, setProjectmeldingId] = useState('')
  const [indienerNaam, setIndienerNaam] = useState('')
  const [indienerOrg, setIndienerOrg] = useState('')
  const [location, setLocation] = useState('')
  const [contractgebied, setContractgebied] = useState<any>(null)
  const [errors, setErrors] = useState({})
  const [involvedParties, setInvolvedParties] = useState<any[]>([])

  const validate = () => {
    const nameError = !name
    const indienerNaamError = !indienerNaam
    const indienerOrgError = !indienerOrg
    const locationError = !location
    const contractgebiedError = !contractgebied

    setErrors({
      nameError,
      indienerNaamError,
      indienerOrgError,
      locationError,
      contractgebiedError,
    })

    return !(
      nameError ||
      indienerNaamError ||
      indienerOrgError ||
      contractgebiedError ||
      locationError
    )
  }

  useEffect(() => {
    if (open && projectmelding) {
      setName(projectmelding.name)
      setLocation(projectmelding.location)
      setIndienerNaam(projectmelding.indienerNaam)
      setIndienerOrg(projectmelding.indienerOrg)
      setContractgebied(
        gebieden.find((g) => g.code === projectmelding.gebiedscode),
      )
      setProjectmeldingId(projectmelding.projectmeldingId)

      setErrors({})
      setReferenceId('')
      setInvolvedParties(organisaties.filter((x) => x.label === combi))
    }
  }, [open, projectmelding, gebieden, combi, organisaties])

  useEffect(() => {
    if (projectmelding?.gebiedscode) {
      dispatch(fetchOrganisaties(projectmelding.gebiedscode))
    }
  }, [dispatch, projectmelding])

  useEffect(() => {
    setInvolvedParties(organisaties.filter((x) => x.label === combi))
  }, [organisaties, combi])

  const handleSubmitClick = () => {
    if (validate()) {
      const body: any = {
        naam: name,
        externeReferentie: referenceId,
        locatieOmschrijving: location,
        organisaties: involvedParties.map((x: any) => x.label),
        gebiedscode: contractgebied.code,
        indiener: {
          naam: indienerNaam,
          organisatie: indienerOrg,
        },
      }

      if (projectmelding?.projectmeldingId) {
        body.projectmeldingId = projectmelding.projectmeldingId
      }

      dispatch(createProjectdossier(body))
      // empty the stored organisaties so that its not filled the next time they open it
      dispatch(clearOrganisaties())
    }
  }

  const handleInvoledPartiesChange = (value) => {
    // If the combi is not in the array (aka if someone tried to unselect the combi)
    // Do not allow them to continue with the operation
    if (value.length === 0) {
      setInvolvedParties(involvedParties.filter((x: any) => x.label === combi))
    } else if (!value.find((x: any) => x.label === combi)) {
      return
    } else {
      setInvolvedParties(value)
    }
  }

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={'md'}>
        <DialogTitle>Aanmaken projectdossier</DialogTitle>
        <DialogContent>
          <Box
            sx={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', p: 1 }}
          >
            <FormControl sx={{ width: '100%', p: 1 }}>
              <TextField
                variant="standard"
                data-testid="inputField"
                value={name}
                label={'Dossiernaam'}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setName(e.target.value)}
                error={errors['nameError']}
              />
            </FormControl>

            <FormControl sx={{ width: '100%', p: 1 }}>
              <TextField
                variant="standard"
                data-testid="inputField"
                value={referenceId}
                label={'Externe referentie'}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setReferenceId(e.target.value)}
                error={errors['referenceIdError']}
              />
            </FormControl>

            {projectmelding?.projectmeldingId && (
              <FormControl sx={{ width: '100%', p: 1 }}>
                <TextField
                  variant="standard"
                  value={projectmeldingId}
                  label={'Projectmelding id'}
                  disabled
                />
              </FormControl>
            )}

            <FormControl sx={{ width: '100%', p: 1 }}>
              <Autocomplete
                disabled={!!projectmelding?.projectmeldingId}
                options={gebieden}
                value={contractgebied}
                onChange={(_, e) => {
                  dispatch(fetchOrganisaties(e.code))
                  setContractgebied(e)
                }}
                getOptionLabel={(gebied) => gebied.label}
                isOptionEqualToValue={(x, y) => x.code === y.code}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Contractgebied"
                    error={errors['contractgebiedError']}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ width: '100%', p: 1 }}>
              <TextField
                variant="standard"
                data-testid="inputField"
                value={location}
                multiline
                rows={3}
                label={`Beschrijving (${location.length}/500)`}
                inputProps={{ maxLength: 500 }}
                onChange={(e) => setLocation(e.target.value)}
                error={errors['locationError']}
              />
            </FormControl>

            <FormControl sx={{ width: '100%', p: 1 }}>
              <Autocomplete
                disabled={!contractgebied || isLoadingOrganisaties}
                options={organisaties}
                value={involvedParties}
                groupBy={(item: any) => item.group}
                isOptionEqualToValue={(x: any, y: any) =>
                  x.label === y.label && x.group === y.group
                }
                onChange={(_, e) => {
                  handleInvoledPartiesChange(e)
                }}
                getOptionLabel={(item: any) => item.label}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Betrokken partijen"
                    error={errors['']}
                    // TODO make this spinner work
                    // inputProps makes the autocomplete not work
                    // inputProps={{
                    //   endAdornment:
                    //   isLoadingOrganisaties &&
                    //     <InputAdornment position='end'>
                    //       <CircularProgress />
                    //     </InputAdornment>,
                    // }}
                  />
                )}
                multiple
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option: any, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={combi === option.label}
                    />
                  ))
                }
              />
            </FormControl>

            <FormControl sx={{ width: '100%', p: 1 }}>
              <TextField
                variant="standard"
                data-testid="inputField"
                value={indienerNaam}
                label={'Indiener'}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setIndienerNaam(e.target.value)}
                error={errors['indienerNaamError']}
              />
            </FormControl>

            <FormControl sx={{ width: '100%', p: 1 }}>
              <TextField
                variant="standard"
                data-testid="inputField"
                value={indienerOrg}
                label={'Indienende organisatie'}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setIndienerOrg(e.target.value)}
                error={errors['indienerOrgError']}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Annuleren
          </Button>
          <Button color="primary" onClick={handleSubmitClick}>
            Aanmaken
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
