import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useLocalStorage from '@hooks/useLocalStorage'

import { getUserParam } from '../../auth/state'

import { fetchActiviteiten, getActiviteiten, initialState } from './state'

export const useActiviteitenSearch = () => {
  const dispatch = useDispatch()
  const { data, isLoading, query } = useSelector(getActiviteiten)
  const { sort, page } = query

  const { item: storedFilters } = useLocalStorage(
    'hoofdleidingActiviteiten',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localSort, setLocalSort] = useState(sort)
  const [localPage, setLocalPage] = useState(page)
  const userParam = useSelector(getUserParam)

  useEffect(() => {
    dispatch(
      fetchActiviteiten({
        filters: localFilters,
        page: localPage,
        sort: localSort,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort, userParam])

  const onChangeFilters = (newFilters) => {
    setLocalSort(initialState.query.sort)
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort) => setLocalSort(sort)
  const onChangePage = (page) => setLocalPage(page)

  return {
    onChangeFilters,
    onChangeSort,
    onChangePage,
    initialFilters: initialState.query.filters,
    filters: localFilters,
    sort: localSort,
    data,
    isLoading,
  }
}
