import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'

import { apiError, success } from '../alerts/state'
import { request } from '../gateways/api'
import type { RootState } from '../store'

import type { State } from './types'

export const initialState: State = {
  gebiedswissels: {
    data: { count: 0, skip: 0, limit: 0, items: [] },
    query: {
      filters: { gebied: [], status: null },
      page: { skip: 0, limit: 25 },
    },
    status: 'idle',
    createDialog: {
      open: false,
      data: {},
    },
  },
  aanvraag: {
    data: {},
    status: 'idle',
    importStatus: 'idle',
    copyStatus: 'idle',
  },
}

export const createFilters = ({ gebied, status }) => {
  const filters: IFilter[] = []

  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (status)
    filters.push({ name: 'status', value: status.code, operator: 'eq' })

  return filters
}

export const fetchBeheerGebiedswissels = createAsyncThunk(
  'fetch/beheer/gebiedswissels',
  async (payload: any, { dispatch }) => {
    const { filters, page } = payload

    try {
      const result = await request({
        url: '/rest/beheer/gebiedswissel/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen gebiedswissels', error))

      throw error
    }
  },
)

export const fetchBeheerAanvraag = createAsyncThunk(
  'fetch/beheer/aanvraag',
  async (payload: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/beheer/aanvraag/${payload.aanvraagID}`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen aanvraag', error))

      throw error
    }
  },
)

export const importBeheerAanvraag = createAsyncThunk(
  'import/beheer/aanvraag',
  async (payload: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/beheer/aanvraag/${payload.aanvraagID}/import`,
        method: 'POST',
      })

      dispatch(success('Aanvraag succesvol geïmporteerd'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij importeren aanvraag', error))

      throw error
    }
  },
)

export const copyBeheerAanvraag = createAsyncThunk(
  'copy/beheer/aanvraag',
  async (payload: any, { dispatch }) => {
    const { aanvraagID, data } = payload
    try {
      const result = await request({
        url: `/rest/beheer/aanvraag/${aanvraagID}/copy`,
        method: 'POST',
        data,
      })

      dispatch(success('Aanvraag succesvol gekopiëerd'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij kopiëren aanvraag', error))

      throw error
    }
  },
)

interface CreateGebiedsWisselPayload {
  gebiedscode: string
  lines: string[]
  omschrijving: string
}

export const createBeheerGebiedswissel = createAsyncThunk<
  any,
  CreateGebiedsWisselPayload
>('create/beheer/gebiedswissel', async (payload, { dispatch, getState }) => {
  try {
    const result = await request({
      url: '/rest/beheer/gebiedswissel',
      method: 'POST',
      data: payload,
    })

    const {
      beheer: {
        gebiedswissels: { query },
      },
    } = getState() as any

    dispatch(success('Gebiedswissel succesvol aangemaakt'))
    dispatch(fetchBeheerGebiedswissels(query))

    return result
  } catch (error: any) {
    dispatch(apiError('Fout bij aanmaken gebiedswissel', error))

    throw error
  }
})

const slice = createSlice({
  name: 'beheer',
  initialState,
  reducers: {
    openCreateDialog: (state) => {
      state.gebiedswissels.createDialog.open = true
    },
    closeCreateDialog: (state) => {
      state.gebiedswissels.createDialog.open = false
    },
    resetBeheerImportAanvraag: (state) => {
      state.aanvraag.data = initialState.aanvraag
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBeheerGebiedswissels.pending, (state, action) => {
        state.gebiedswissels.data = initialState.gebiedswissels.data
        state.gebiedswissels.status = 'loading'
        state.gebiedswissels.query = action.meta.arg
      })
      .addCase(fetchBeheerGebiedswissels.fulfilled, (state, action) => {
        state.gebiedswissels.data = action.payload
        state.gebiedswissels.status = 'idle'
      })
      .addCase(fetchBeheerGebiedswissels.rejected, (state) => {
        state.gebiedswissels.status = 'error'
        state.gebiedswissels.data = initialState.gebiedswissels.data
      })
      .addCase(createBeheerGebiedswissel.fulfilled, (state) => {
        state.gebiedswissels.createDialog.open = false
      })
      .addCase(fetchBeheerAanvraag.pending, (state) => {
        state.aanvraag.data = initialState.aanvraag.data
        state.aanvraag.status = 'loading'
      })
      .addCase(fetchBeheerAanvraag.fulfilled, (state, action) => {
        state.aanvraag.data = action.payload
        state.aanvraag.status = 'idle'
      })
      .addCase(fetchBeheerAanvraag.rejected, (state) => {
        state.aanvraag.status = 'error'
        state.aanvraag.data = initialState.aanvraag.data
      })
      .addCase(importBeheerAanvraag.pending, (state) => {
        state.aanvraag.importStatus = 'loading'
      })
      .addCase(importBeheerAanvraag.fulfilled, (state) => {
        state.aanvraag.importStatus = 'idle'
        state.aanvraag.data = initialState.aanvraag.data
      })
      .addCase(importBeheerAanvraag.rejected, (state) => {
        state.aanvraag.importStatus = 'error'
      })
      .addCase(copyBeheerAanvraag.pending, (state) => {
        state.aanvraag.copyStatus = 'loading'
      })
      .addCase(copyBeheerAanvraag.fulfilled, (state) => {
        state.aanvraag.copyStatus = 'idle'
        state.aanvraag.data = initialState.aanvraag.data
      })
      .addCase(copyBeheerAanvraag.rejected, (state) => {
        state.aanvraag.copyStatus = 'error'
      })
  },
})

export const getGebiedswissels = (state: RootState) => ({
  data: state.beheer.gebiedswissels.data,
  isLoading: state.beheer.gebiedswissels.status === 'loading',
  query: state.beheer.gebiedswissels.query,
})
export const getAanvraag = (state: RootState) => ({
  data: state.beheer.aanvraag.data,
  isLoading: state.beheer.aanvraag.status === 'loading',
})
export const isCreateDialogOpen = (state: RootState) =>
  state.beheer.gebiedswissels.createDialog.open
export const isImportAanvraagLoading = (state: RootState) =>
  state.beheer.aanvraag.importStatus === 'loading'
export const isCopyAanvraagLoading = (state: RootState) =>
  state.beheer.aanvraag.copyStatus === 'loading'

export const { reducer } = slice
export const {
  openCreateDialog,
  closeCreateDialog,
  resetBeheerImportAanvraag,
} = slice.actions
