import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Table, TableBody } from '@mui/material'

import type { Project } from '../../project/types'

import { Discipline } from './Discipline'

const findByDisciplineNetbeheerder = (
  produkten,
  disciplineCode,
  netbeheerderCode,
) =>
  produkten &&
  produkten.find(
    (p) =>
      p.disciplineCode === disciplineCode &&
      p.netbeheerderCode === netbeheerderCode,
  )
const disciplineFilter = (disciplineCode, netbeheerderCode) => (object) =>
  findByDisciplineNetbeheerder(
    object.produkten,
    disciplineCode,
    netbeheerderCode,
  ) !== undefined

interface Props {
  project: Project
  product: {
    disciplineCode: string
    netbeheerderCode: string
  }
  onClose?: any
  objectFilter?: any
}

export const Disciplines: FC<Props> = ({
  project,
  product: { disciplineCode, netbeheerderCode },
  onClose,
  objectFilter,
}: Props) => {
  const objecten = objectFilter
    ? project.aansluitObjecten.filter(objectFilter)
    : project.aansluitObjecten

  return (
    <Box
      role="presentation"
      sx={{ width: '100%', minWidth: '700px', p: '20px' }}
    >
      {objecten && (
        <>
          <IconButton
            data-testid="closeSide"
            onClick={onClose}
            sx={{ color: 'black' }}
          >
            <CloseIcon />
          </IconButton>
          <Table>
            <TableBody>
              {objecten
                .filter(disciplineFilter(disciplineCode, netbeheerderCode))
                .map((object, index) => {
                  const product = findByDisciplineNetbeheerder(
                    object.produkten,
                    disciplineCode,
                    netbeheerderCode,
                  )

                  return (
                    <Discipline
                      key={object.objectID}
                      project={project}
                      object={object}
                      product={product}
                      expanded={index === 0}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  )
}
