import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material'

import { getUserParam, storeUserParam } from '../../auth/state'

export const ProfileBeheer: FC = () => {
  const dispatch = useDispatch()
  const userParam = useSelector(getUserParam)

  const handleChangeUserParam = (event) => {
    dispatch(storeUserParam(event.target.value))
  }

  return (
    <FormControl fullWidth sx={{ mt: 1, minWidth: 120 }}>
      <InputLabel id="rol-select-label">User parameter</InputLabel>
      <Select
        labelId="rol-select-label"
        id="rol-select"
        value={userParam}
        onChange={handleChangeUserParam}
      >
        <ListSubheader>Combi</ListSubheader>
        <MenuItem value={'combi:SYNFRA'}>Synfra</MenuItem>
        <MenuItem value={'combi:GRONDG'}>GRONDG</MenuItem>
        <MenuItem value={'combi:SION'}>SION</MenuItem>
        <MenuItem value={'combi:CINH'}>CINH</MenuItem>
        <MenuItem value={'combi:Structin Noord'}>Structin Noord</MenuItem>
        <MenuItem value={'combi:CIAMS'}>CIAMS</MenuItem>
        <MenuItem value={'combi:Infra-SDO'}>Infra-SDO</MenuItem>
        <MenuItem value={'combi:Infra-SV'}>Infra-SV</MenuItem>
      </Select>
    </FormControl>
  )
}
