import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { Button, IconButton, SxProps, Tooltip } from '@mui/material'

import { updateMyList as updateAansluitingList } from '../../aansluiting/projecten/state'
import { updateMyList as updateHoofdleidingList } from '../../hoofdleiding/projecten/state'

import { MyListIcon } from './MyListIcon'

const BASE_SIZE = 20

interface BaseProps {
  type: 'hoofdleiding' | 'aansluiting'
  active?: boolean
  disabled?: boolean
}

interface AsButtonProps extends BaseProps {
  type: 'hoofdleiding' | 'aansluiting'
  aanvraagID: string
  asButton: true
  size?: undefined
  sx?: SxProps
}

interface AsIconProps extends BaseProps {
  type: 'hoofdleiding' | 'aansluiting'
  aanvraagID: string
  asButton?: false
  size?: number
  sx?: undefined
}

type Props = AsButtonProps | AsIconProps

export const MyListButton: FC<Props> = ({
  aanvraagID,
  active = false,
  asButton = false,
  disabled = false,
  size = BASE_SIZE,
  sx = {},
  type,
}) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    if (type === 'hoofdleiding') {
      dispatch(
        updateHoofdleidingList({
          aanvraagID,
          method: active ? 'remove' : 'add',
        }),
      )
    }

    if (type === 'aansluiting') {
      dispatch(
        updateAansluitingList({
          aanvraagID,
          method: active ? 'remove' : 'add',
        }),
      )
    }
  }

  if (asButton) {
    return (
      <Button
        disabled={disabled}
        onClick={handleClick}
        startIcon={<MyListIcon active={active} size={size} />}
        sx={sx}
      >
        {active ? 'Verwijder uit mijn lijst' : 'Voeg toe aan mijn lijst'}
      </Button>
    )
  }

  /** Materal UI does not like tooltips around a disabled button. */
  if (disabled) {
    return (
      <IconButton disabled={disabled}>
        <MyListIcon active={active} />
      </IconButton>
    )
  }

  return (
    <Tooltip
      title={active ? 'Verwijderen van mijn lijst' : 'Toevoegen aan mijn lijst'}
    >
      <IconButton onClick={handleClick}>
        <MyListIcon active={active} />
      </IconButton>
    </Tooltip>
  )
}
