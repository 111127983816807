import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'

import { getUserParam } from '../auth/state'
import { clearOrganisaties } from '../contracten/state'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import CreateProjectdossierDialog from './dialogs/CreateProjectdossierDialog'
import {
  closeDialogProjectdossiers,
  fetchProjectdossiers,
  getProjectdossiers,
  initialState,
  isDialogOpenProjectdossiers,
  openDialogProjectdossiers,
} from './state'

const headerCells = [
  {
    id: 'projectdossierId',
    numeric: true,
    label: 'Projectdossier id',
    sort: false,
  },
  {
    id: 'projectdossier',
    numeric: false,
    label: 'Projectdossier',
    sort: false,
  },
  { id: 'externeReferentie', numeric: false, label: 'Referentie', sort: false },
  { id: 'gebiedsCodeLabel', numeric: false, label: 'Gebied', sort: false },
  { id: 'status', label: 'Status', sort: false },
  { id: 'indiener', numeric: false, label: 'Indiener', sort: false },
  {
    id: 'indienerOrganisatie',
    numeric: false,
    label: 'Organisatie',
    sort: false,
  },
  { id: 'created', label: 'Aanmaakdatum', sort: false, fixed: 75 },
  { id: 'details', label: 'Details', sort: false, fixed: 50 },
] as const

export const Projectdossiers: FC = () => {
  const dispatch = useDispatch()
  const {
    projectdossiers: { data, isLoading, query },
    userParam,
    dialogOpen,
  } = useSelector((state) => ({
    projectdossiers: getProjectdossiers(state),
    userParam: getUserParam(state),
    dialogOpen: isDialogOpenProjectdossiers(state),
  }))

  const { items: projectdossiers, count, skip, limit } = data
  const { page } = query

  const { item: storedFilters } = useLocalStorage(
    'project_dossiers',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localPage, setLocalPage] = useState(page)

  useEffect(() => {
    dispatch(
      fetchProjectdossiers({
        filters: localFilters,
        page: localPage,
      }),
    )
  }, [dispatch, localFilters, localPage, userParam])

  const onChangePage = (page) => {
    setLocalPage(page)
  }

  const onChangeFilters = (newFilters) => {
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onCreate = () => dispatch(openDialogProjectdossiers({}))

  const onCloseDialog = () => {
    dispatch(closeDialogProjectdossiers())
    // empty the stored organisaties so that its not filled the next time they open it
    dispatch(clearOrganisaties())
  }

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">
        Overzicht van alle projectdossiers in uw gebied
      </Typography>
      <Paper sx={{ mb: 2 }}>
        <Filters
          onChange={onChangeFilters}
          filters={localFilters}
          initialFilters={initialState.query.filters}
          onCreate={onCreate}
        />
        <StyledTable
          onChangePage={onChangePage}
          headerCells={headerCells}
          stats={{ count, skip, limit }}
          loading={isLoading}
          {...({} as any)}
        >
          {projectdossiers.map((projectdossier) => (
            <Row key={projectdossier._id} projectdossier={projectdossier} />
          ))}
        </StyledTable>
      </Paper>
      <CreateProjectdossierDialog open={dialogOpen} onClose={onCloseDialog} />
    </Box>
  )
}
