import React, { PropsWithChildren, ReactNode } from 'react'

import { Box, Card, CardContent, Typography } from '@mui/material'

interface Props {
  title: string
  buttonBar?: ReactNode
}

const StyledCard = ({
  children,
  title,
  buttonBar,
}: PropsWithChildren<Props>) => {
  return (
    <Card sx={{ mt: 3, mb: 2, w: '100%', pb: 4 }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Typography variant="h5">{title}</Typography>
          {buttonBar && <>{buttonBar}</>}
        </Box>
        <Box sx={{ width: '95%', mx: 'auto' }}>{children}</Box>
      </CardContent>
    </Card>
  )
}

export default StyledCard
