import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError } from '../alerts/state'
import { request } from '../gateways/api'
import type { RootState } from '../store'

import type { State } from './types'

const initialState: State = {
  data: undefined,
  status: 'idle',
}

export const fetchOpdracht = createAsyncThunk(
  'fetch/opdracht',
  async (id: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/opdracht/deelnemer/${id}`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van opdracht', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'opdracht',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpdracht.pending, (state) => {
        state.status = 'loading'
        state.data = undefined
      })
      .addCase(fetchOpdracht.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchOpdracht.rejected, (state) => {
        state.status = 'error'
        state.data = undefined
      })
  },
})

export const getOpdracht = (state: RootState) => ({
  data: state.opdracht.data,
  isLoading: state.opdracht.status === 'loading',
})

export const reducer = slice.reducer
