import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Avatar, Chip, Grid, Tooltip, Typography } from '@mui/material'

const tooltipBuilder = ({ isCombiUitvoeringText, isCombiUitvoeringReden }) => {
  if (!isCombiUitvoeringText) return ''
  return (
    <>
      <Typography>{isCombiUitvoeringText}</Typography>
      {isCombiUitvoeringReden && (
        <Typography variant="body2">{isCombiUitvoeringReden}</Typography>
      )}
    </>
  )
}

export interface Props {
  netbeheerderLabel?: string
  isCombiUitvoering?: boolean
  isCombiUitvoeringText?: string
  isCombiUitvoeringReden?: string
  isExtraWerkvoorbereiding?: boolean
  isExtraWerkvoorbereidingText?: string
  isHoofdleidingAanleggen?: boolean
}

export const CombiUitvoering: FC<Props> = ({
  netbeheerderLabel,
  isCombiUitvoering,
  isCombiUitvoeringText,
  isCombiUitvoeringReden,
  isExtraWerkvoorbereiding,
  isExtraWerkvoorbereidingText,
  isHoofdleidingAanleggen,
}: Props) => {
  const chipColor = isCombiUitvoering ? 'green' : 'red'

  return (
    <Grid container spacing={1}>
      <Tooltip
        title={tooltipBuilder({
          isCombiUitvoeringText,
          isCombiUitvoeringReden,
        })}
      >
        <Chip
          label={netbeheerderLabel}
          variant="outlined"
          sx={{
            backgroundColor: 'white',
            borderColor: chipColor,
            color: chipColor,
          }}
          avatar={
            <Avatar>
              {isCombiUitvoering ? (
                <DoneIcon
                  sx={{
                    color: 'green',
                    cursor: 'default',
                    backgroundColor: 'white',
                  }}
                />
              ) : (
                <CloseIcon
                  sx={{
                    color: 'red',
                    cursor: 'default',
                    backgroundColor: 'white',
                  }}
                />
              )}
            </Avatar>
          }
        />
      </Tooltip>

      {isExtraWerkvoorbereiding && (
        <Tooltip title={isExtraWerkvoorbereidingText}>
          <Chip
            label=" Extra werkvoorbereiding"
            variant="outlined"
            sx={{ ml: 1, backgroundColor: 'white' }}
            color={
              isExtraWerkvoorbereidingText === 'Open' ? 'primary' : 'success'
            }
          />
        </Tooltip>
      )}

      {isHoofdleidingAanleggen && (
        <Chip
          label=" Hoofdleiding aanleggen"
          variant="outlined"
          sx={{ ml: 1, backgroundColor: 'white' }}
          color="primary"
        />
      )}
    </Grid>
  )
}
