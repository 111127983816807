import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError, success } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { EntityContactpersonenState } from './types'

const initialState: EntityContactpersonenState = {
  contactpersonen: [],
  contactpersoonDialog: {
    open: false,
    data: {},
  },
}

export const fetchContactpersonenForEntity = createAsyncThunk(
  'fetch/entity/contactpersonen',
  async ({ id, type }: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/${type}/${id}/contactpersoon`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen contactpersonen', error))

      throw error
    }
  },
)

export const cancelContactpersoonForEntity = createAsyncThunk(
  'cancel/entity/contactpersoon',
  async ({ persoonId, type, id }: any, { dispatch }) => {
    try {
      const result = await request({
        method: 'PATCH',
        url: `/rest/${type}/${id}/contactpersoon/${persoonId}`,
        data: {
          status: 'cancelled',
        },
      })
      dispatch(fetchContactpersonenForEntity({ type, id }))
      dispatch(success('Contactpersoon successvol verwijderd'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij verwijderen contactpersoon', error))

      throw error
    }
  },
)

export const createContactpersoonForEntity = createAsyncThunk(
  'create/entity/contactpersoon',
  async ({ entity, contactpersoon }: any, { dispatch, rejectWithValue }) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/contactpersoon`

    try {
      const result = await request({
        url,
        method: 'POST',
        data: contactpersoon,
      })

      dispatch(fetchContactpersonenForEntity(entity))
      dispatch(success('Contactpersoon successvol aangemaakt'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij aanmaken contactpersoon', error))

      return rejectWithValue(error)
    }
  },
)

export const updateContactpersoonForEntity = createAsyncThunk(
  'update/entity/contactpersoon',
  async ({ entity, contactpersoon }: any, { dispatch, rejectWithValue }) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/contactpersoon/${contactpersoon._id}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: { ...contactpersoon, _id: undefined, status: undefined },
      })

      dispatch(fetchContactpersonenForEntity(entity))
      dispatch(success('Contactpersoon successvol bijgewerkt'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij bijwerken contactpersoon', error))

      return rejectWithValue(error)
    }
  },
)

const slice = createSlice({
  name: 'entityContactpersonen',
  initialState,
  reducers: {
    openContactpersoonDialog: (state, action) => {
      state.contactpersoonDialog.open = true
      state.contactpersoonDialog.data = action.payload
    },
    closeContactpersoonDialog: (state) => {
      state.contactpersoonDialog.open = false
      state.contactpersoonDialog.data = {}
    },
    clearEntityContactpersonen: (state) => {
      state.contactpersonen = initialState.contactpersonen
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactpersonenForEntity.fulfilled, (state, action) => {
        state.contactpersonen = action.payload
      })
      .addCase(fetchContactpersonenForEntity.rejected, (state) => {
        state.contactpersonen = initialState.contactpersonen
      })
      .addCase(createContactpersoonForEntity.fulfilled, (state) => {
        state.contactpersoonDialog.open = false
        state.contactpersoonDialog.data = {}
      })
      .addCase(cancelContactpersoonForEntity.fulfilled, (state) => {
        state.contactpersoonDialog.open = false
        state.contactpersoonDialog.data = {}
      })
      .addCase(updateContactpersoonForEntity.fulfilled, (state) => {
        state.contactpersoonDialog.open = false
        state.contactpersoonDialog.data = {}
      })
  },
})

export const getContactpersonenForEntity = (state: RootState) =>
  state.entityContactpersonen.contactpersonen
export const getContactpersoonDialogOpen = (state: RootState) =>
  state.entityContactpersonen.contactpersoonDialog.open
export const getContactpersoonDialogData = (state: RootState) =>
  state.entityContactpersonen.contactpersoonDialog.data

export const {
  clearEntityContactpersonen,
  closeContactpersoonDialog,
  openContactpersoonDialog,
} = slice.actions

export const { reducer } = slice
