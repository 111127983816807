import React, { FC } from 'react'

import { FormControl, TextField, Typography } from '@mui/material'

const CHARACTERS_PER_ROW = 200

export interface Props {
  isEdit?: boolean
  value?: string
  onChange?: any
  error?: boolean
  inputProps?: any
}

export const CustomEditInputComponent: FC<Props> = ({
  isEdit = false,
  value,
  onChange,
  error,
  inputProps = { maxLength: 100 },
}) => {
  return isEdit ? (
    <FormControl sx={{ width: '100%' }}>
      <TextField
        data-testid="inputField"
        value={value}
        inputProps={inputProps}
        onChange={(e) => onChange(e.target.value)}
        error={error}
        multiline={inputProps.maxLength > CHARACTERS_PER_ROW}
        rows={inputProps.maxLength > CHARACTERS_PER_ROW ? 5 : 1} //eslint-disable-line
      />
    </FormControl>
  ) : (
    <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body2">
      {value}
    </Typography>
  )
}
