import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'

import { fetchContracten, getContracten } from '../../../../contracten/state'
import { saveEvent } from '../../state'
import type { OptionsDialogData } from '../../types'

const AANNEMER_REDENEN = ['Onvoldoende capaciteit', 'Aannemer bekend']

export interface Props {
  data: OptionsDialogData
  handleClose: () => void
}

export const Aannemer: FC<Props> = ({ data, handleClose }: Props) => {
  const dispatch = useDispatch()
  const { project } = data
  const { contracten } = useSelector((state) => ({
    contracten: getContracten(state),
  }))
  const [reden, setReden] = useState<string | null>(null)
  const [aannemer, setAannemer] = useState<string | null>(
    project.aannemers && project.aannemers.length > 0
      ? project.aannemers[0]
      : null,
  )
  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const aannemers = [
    ...new Set(
      contracten
        .filter(({ combi }) => combi === project.combi)
        .map((contract) => contract.coordAannemer)
        .flat(),
    ),
  ]

  const validate = () => {
    setErrors({
      redenError: !reden,
      aannemerError: !aannemer,
    })

    return !!reden && !!aannemer
  }

  const handleSubmitClick = () => {
    if (validate()) {
      dispatch(
        saveEvent({
          id: project.aanvraagID,
          data: {
            aannemer: aannemer || '',
            reden: reden || '',
          },
          type: 'hoofdleiding_aannemer',
          source: 'manual',
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(fetchContracten())
  }, [dispatch])

  return (
    <>
      <DialogTitle>Wijzigen aannemer</DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            options={aannemers}
            onChange={(_, aannemer) => setAannemer(aannemer)}
            value={aannemer}
            renderInput={(params) => {
              return (
                <TextField
                  variant="standard"
                  {...params}
                  label="Aannemer"
                  error={errors.aannemerError}
                />
              )
            }}
          />
        </FormControl>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            options={AANNEMER_REDENEN}
            onChange={(_, reden) => setReden(reden)}
            value={reden}
            renderInput={(params) => {
              return (
                <TextField
                  variant="standard"
                  {...params}
                  label="Reden"
                  error={errors.redenError}
                />
              )
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={handleSubmitClick}>
          Wijzigen
        </Button>
      </DialogActions>
    </>
  )
}
