import React, { FC } from 'react'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import { useBijlagenSearch } from './hooks'

const headerCells = [
  { id: 'expired', numeric: false, label: '', fixed: 25 },
  { id: 'documentsoort', numeric: false, label: 'Documentsoort', fixed: 200 },
  {
    id: 'dueWeek',
    numeric: false,
    label: 'Oplevering',
    fixed: 100,
    sort: true,
  },
  {
    id: 'created.date',
    numeric: false,
    label: 'Aangemaakt',
    fixed: 50,
    sort: true,
  },
  {
    id: 'created.organisatie',
    numeric: false,
    label: 'Aangemaakt door',
    fixed: 100,
    sort: true,
  },
  {
    id: 'assignedTo',
    numeric: false,
    label: 'Toegewezen aan',
    fixed: 150,
    sort: true,
  },
  { id: 'entity', numeric: false, label: 'Horend bij', fixed: 75, sort: true },
  { id: 'gemeente', numeric: false, label: 'Gemeente', fixed: 125 },
  { id: 'gebiedscode', numeric: false, label: 'Gebied & labels', fixed: 100 },
  { id: 'actions', numeric: false, label: 'Details', fixed: 75 },
] as const

export const AssignedBijlagen: FC = () => {
  const {
    data: { items: assignedBijlagen, count, skip, limit },
    initialFilters,
    isLoading,
    localFilters,
    onChangeFilters,
    onChangePage,
    onChangeSort,
    sort,
  } = useBijlagenSearch()

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">Overzicht van uw bijlagen</Typography>
      <Paper sx={{ mb: 2 }}>
        <Filters
          filters={localFilters}
          initialFilters={initialFilters}
          onChange={onChangeFilters}
        />
        <StyledTable
          headerCells={headerCells}
          loading={isLoading}
          onChangePage={onChangePage}
          onChangeSort={onChangeSort}
          sort={sort}
          stats={{ count, skip, limit }}
        >
          {assignedBijlagen.map((assignedBijlage) => {
            return (
              <Row
                assignedBijlage={assignedBijlage}
                key={assignedBijlage._id}
              />
            )
          })}
        </StyledTable>
      </Paper>
    </Box>
  )
}
