import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'

import Adres from '@components/Adres'
import DisciplineIcon from '@components/DisciplineIcon'
import Realisatie from '@components/Realisatie'
import { formatDateTime } from '@util/date-util'

import type { Opdracht } from '../types'

interface Props {
  opdracht: Opdracht
}

export const Detail: FC<Props> = ({ opdracht }) => {
  const {
    clusterId,
    netbeheerder,
    aannemer,
    changed,
    opdrachtnemer,
    details,
    discipline,
    actiehouder,
    versieNummer,
    disciplineLabel,
  } = opdracht

  const { combi, aansluitObjectId } = details || {}
  const {
    realisatiedatum = '',
    gebiedscodeLabel = '',
    realisatietype = '',
    combi: gebiedsCombi = '',
  } = combi || {}

  const items = [
    { title: 'Cluster ID', text: clusterId },
    {
      title: 'Discipline',
      text: (
        <DisciplineIcon
          disciplineCode={discipline}
          toolTip={disciplineLabel}
          {...({} as any)}
        />
      ),
      jsx: true,
    },
    {
      title: 'Adres',
      text: opdracht.details && <Adres {...opdracht.details} />,
    },
    { title: 'Netbeheerder', text: netbeheerder },
    { title: 'Uitvoerende aannemer', text: aannemer },
    { title: 'Actiehouder', text: actiehouder },
    { title: 'Aansluitobject ID', text: aansluitObjectId },
    { title: 'Mutatiedatum', text: formatDateTime(changed) },
    { title: 'Versienummer', text: versieNummer },
  ]

  if (opdrachtnemer) {
    const opdnemer = {
      title: 'Opdrachtnemer',
      text: opdrachtnemer,
    }

    items.splice(5, 0, opdnemer) // eslint-disable-line no-magic-numbers
  }

  if (realisatiedatum && opdracht.details) {
    const endDate =
      opdracht.details.planning && !opdracht.details.technischGereed
        ? opdracht.details.planning.eindTijdstip
        : undefined

    const plandatum = {
      title: 'Plandatum',
      text: (
        <Realisatie
          date={realisatiedatum}
          endDate={endDate}
          type={realisatietype}
          showTime
        />
      ),
      jsx: true,
    }

    items.splice(2, 0, plandatum) // eslint-disable-line no-magic-numbers
  }

  if (gebiedscodeLabel && gebiedsCombi !== 'GEEN') {
    const gebied = { title: 'Gebied', text: gebiedscodeLabel }

    items.push(gebied)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ m: 'auto', width: '95%', p: 1 }}>
        {generateTextItems(items)}
      </Grid>
    </>
  )
}

/** Generard a `Grid` > `Typography` or `Grid` > `DisciplineIcon` based on an array. Used for less code duplication */
function generateTextItems(items) {
  return (
    <>
      {items.map((item) => {
        return (
          <Grid key={`${item.title}-${item.text}`} item xs={3}>
            <Typography gutterBottom variant="subtitle1">
              <b>{item.title}</b>
            </Typography>
            {item.jsx ? (
              item.text
            ) : (
              <Typography variant="body2">{item.text}</Typography>
            )}
          </Grid>
        )
      })}
    </>
  )
}
