import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material'

import useLocalStorage from '@hooks/useLocalStorage'
import { getValuelists } from '@slices/valuelistsSlice'

import { getUser } from '../../../../auth/state'
import { initialState as initialStateActiviteiten } from '../../../activiteiten/state'
import { initialState as initialStateAssignedBijlagen } from '../../../bijlagen/state'
import { initialState as initialStateProjecten } from '../../../projecten/state'
import {
  fetchWerklijsten,
  getWerklijstFilters,
  getWerklijsten,
  initialState,
} from '../../state'
import type { Werklijst } from '../../types'

import { Filters } from './Filters'
import { Tile } from './Tile'

export interface Props {
  onClickTile: (query: any, url: any) => void
}

export const Werklijsten: FC<Props> = ({ onClickTile }: Props) => {
  const dispatch = useDispatch()
  const {
    user: { organisatie, deelnemer, canViewCombiOpdrachten },
    werklijstenFilters,
    werklijsten: { acties, bijlagen, activiteiten },
    valuelists,
  } = useSelector((state) => ({
    user: getUser(state),
    werklijstenFilters: getWerklijstFilters(state),
    werklijsten: getWerklijsten(state),
    valuelists: getValuelists(state),
  }))

  const { item: storedFilters } = useLocalStorage(
    'werklijsten',
    werklijstenFilters,
    initialState.werklijsten.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)

  useEffect(() => {
    if (canViewCombiOpdrachten) {
      dispatch(fetchWerklijsten({ filters: localFilters }))
    }
  }, [dispatch, localFilters, canViewCombiOpdrachten])

  const onClickTileActies = useCallback(
    (werklijst: Werklijst) => {
      const { code, label } = werklijst
      const actiehouder = deelnemer
        ? { group: '', label: organisatie }
        : localFilters.organisatie

      const query = {
        filters: {
          ...initialStateProjecten.query.filters,
          ...localFilters,
          actie: { code, label },
          actiehouder,
        },
        page: initialStateProjecten.query.page,
        sort: initialStateProjecten.query.sort,
      }

      onClickTile(query, '/aansluiting/projecten')
    },
    [deelnemer, localFilters, onClickTile, organisatie],
  )

  const onClickTileBijlagen = useCallback(
    (werklijst: Werklijst) => {
      const { label } = werklijst

      const query = {
        filters: {
          ...initialStateAssignedBijlagen.query.filters,
          ...localFilters,
          documentsoort: [label],
          assignedTo: localFilters.organisatie
            ? [localFilters.organisatie]
            : [],
        },
        page: initialStateAssignedBijlagen.query.page,
        sort: initialStateAssignedBijlagen.query.sort,
      }

      onClickTile(query, '/aansluiting/bijlagen')
    },
    [localFilters, onClickTile],
  )

  const onClickTileActiviteiten = useCallback(
    (werklijst: Werklijst) => {
      const { code, label } = werklijst

      const query = {
        filters: {
          ...initialStateActiviteiten.query.filters,
          ...localFilters,
          type: [{ code, label }],
          assignedTo: localFilters.organisatie
            ? [localFilters.organisatie]
            : [],
        },
        page: initialStateActiviteiten.query.page,
        sort: initialStateActiviteiten.query.sort,
      }

      onClickTile(query, '/aansluiting/activiteiten')
    },
    [localFilters, onClickTile],
  )

  return canViewCombiOpdrachten ? (
    <>
      <Filters
        onChange={(newFilters) => setLocalFilters(newFilters)}
        filters={localFilters}
        initialFilters={initialState.werklijsten.query.filters}
        valuelists={valuelists}
      />

      <Box sx={{ p: '50px', ml: '20px' }}>
        <Accordion sx={{ width: '100%' }} defaultExpanded key="acties">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontSize: '24px', pl: '24px' }}>
              Acties
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              {acties.map((werklijst) => (
                <Grid item key={werklijst.label}>
                  <Tile werklijst={werklijst} onClickTile={onClickTileActies} />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ width: '100%' }} defaultExpanded key="bijlagen">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontSize: '24px', pl: '24px' }}>
              Bijlagen
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              {bijlagen.map((werklijst) => (
                <Grid item key={werklijst.label}>
                  <Tile
                    werklijst={werklijst}
                    onClickTile={onClickTileBijlagen}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ width: '100%' }} defaultExpanded key="Activiteiten">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontSize: '24px', pl: '24px' }}>
              Activiteiten
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              {activiteiten.map((werklijst) => (
                <Grid item key={werklijst.label}>
                  <Tile
                    werklijst={werklijst}
                    onClickTile={onClickTileActiviteiten}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  ) : null
}
