import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { patchProjectmelding } from '../../state'

export interface Props {
  id: string
  handleClose: () => void
}

export const Afwijzen: FC<Props> = ({ id, handleClose }) => {
  const dispatch = useDispatch()

  const handleClickJa = () => {
    dispatch(patchProjectmelding({ id }))
  }

  return (
    <Dialog open>
      <DialogTitle>Projectmelding afwijzen</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Wilt u deze projectmelding afwijzen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClickJa()} color="primary">
          Ja
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Nee
        </Button>
      </DialogActions>
    </Dialog>
  )
}
