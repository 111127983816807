import { useCallback, useState } from 'react'

const quotaExceededNames = ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED']

export function getLocalStorageItem<T extends object>(
  name: string,
): {
  available: boolean
  item: T | null
} {
  try {
    const item = JSON.parse(
      window.localStorage.getItem(
        name.toLowerCase().trim().split(' ').join('_'),
      ) || '{}',
    )

    if (!item || Array.isArray(item) || typeof item !== 'object') {
      return {
        available: true,
        item: null,
      }
    }

    return {
      available: true,
      item: item as T,
    }
  } catch (e: any) {
    return {
      available: false,
      item: null,
    }
  }
}

export function setLocalStorageItem<T extends object>(
  name: string,
  storable: T,
): {
  available: boolean
  full: boolean
  item: T | null
} {
  try {
    const { item, available } = getLocalStorageItem<T>(name)

    if (!available) {
      return {
        available: false,
        full: false,
        item: null,
      }
    }

    const storableItem = { ...item, ...storable }

    window.localStorage.setItem(
      name.toLowerCase().trim().split(' ').join('_'),
      JSON.stringify(storableItem),
    )

    return {
      available: true,
      full: false,
      item: storableItem,
    }
  } catch (e: any) {
    return {
      available: true,
      full: e instanceof DOMException && quotaExceededNames.includes(e.name),
      item: null,
    }
  }
}

export function transformItem<T extends object>(item: T, matcher: T): T {
  return Object.keys(matcher).reduce(
    (acc, key) => {
      if (
        item[key] &&
        (matcher[key] === null || typeof matcher[key] === typeof item[key])
      ) {
        acc[key] = item[key]
      }

      return acc
    },
    { ...matcher },
  )
}

export function sortObject<T extends object>(obj: T): T {
  const sorted = {} as T

  Object.keys(obj)
    .sort()
    .forEach((key) => {
      sorted[key] = obj[key]
    })

  return sorted
}

/**
 * Hook to store and read data from localStorage.
 * @param name Name of the key/value pair in localStorage.
 * @param storable Object that should be stored.
 * @param matcher We only load data from localStorage if the `matcher` matches
 * the `storable`. This allows for a fallback to localStorage data if there is
 * no application state (yet).
 */
export default function useLocalStorage<T extends object>(
  name: string,
  storable: T,
  matcher?: T,
) {
  const [available, setAvailable] = useState<boolean>(true)

  let sortedStorable = sortObject(storable)
  const sortedMatcher = matcher && sortObject(matcher)

  if (JSON.stringify(sortedStorable) === JSON.stringify(sortedMatcher)) {
    const storage = getLocalStorageItem<T>(name)

    if (!storage.available) {
      setAvailable(storage.available)
    }

    if (storage.item && sortedMatcher) {
      sortedStorable = transformItem(storage.item, sortedMatcher)
    }
  }

  const [item, setItem] = useState(sortedStorable)
  const [full, setFull] = useState<boolean>(false)
  const [storableEqualsItem, setStorableEqualsItem] = useState<boolean>(false)

  const save = useCallback(() => {
    const storage = setLocalStorageItem(name, storable)

    setAvailable(storage.available)
    setFull(storage.full)

    if (storage.item) {
      setItem(storage.item)
    }
  }, [name, storable])

  const checkAvailability = useCallback(() => {
    const storage = getLocalStorageItem<T>(name)

    if (!storage.available) {
      setAvailable(false)
    }
  }, [name])

  const checkEquality = useCallback(() => {
    const storage = getLocalStorageItem<T>(name)

    if (storage.item) {
      const sortedItem = sortObject(storage.item)
      const sortedStorable = sortObject(storable)

      setStorableEqualsItem(
        JSON.stringify(sortedItem) === JSON.stringify(sortedStorable),
      )
    }
  }, [name, storable])

  return {
    available,
    checkAvailability,
    checkEquality,
    full,
    item,
    save,
    storableEqualsItem,
  }
}
