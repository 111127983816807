import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { State } from './types'

const initialState: State = {
  gegevens: {},
  additioneleVragen: {
    data: [],
    status: 'idle',
  },
}

export const fetchKlantgegevens = createAsyncThunk(
  'fetch/klant/gegevens',
  async (id: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/project/${id}/klant`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen klantgegevens', error))

      throw error
    }
  },
)

export const openKlantbijlage = createAsyncThunk(
  'fetch/klant/bijlage',
  async ({ id, bijlageId }: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/project/${id}/klantbijlage/${bijlageId}`,
        method: 'GET',
      })

      const { url } = result.downloadData

      window.open(url, '_blank')

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij openen klantbijlage', error))

      throw error
    }
  },
)

export const fetchKlantAdditioneleVragen = createAsyncThunk(
  'fetch/klant/additionelevragen',
  async (id: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/project/${id}/additionelevragen`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van klant additionele vragen', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'klant',
  initialState,
  reducers: {
    clearKlantgegevens: (state) => {
      state.gegevens = initialState.gegevens
      state.additioneleVragen = initialState.additioneleVragen
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKlantgegevens.pending, (state) => {
        state.gegevens = initialState.gegevens
      })
      .addCase(fetchKlantgegevens.fulfilled, (state, action) => {
        state.gegevens = action.payload
      })
      .addCase(fetchKlantgegevens.rejected, (state) => {
        state.gegevens = initialState.gegevens
      })
      .addCase(fetchKlantAdditioneleVragen.pending, (state) => {
        state.additioneleVragen.status = 'loading'
        state.additioneleVragen.data = []
      })
      .addCase(fetchKlantAdditioneleVragen.fulfilled, (state, action) => {
        state.additioneleVragen.status = 'idle'
        state.additioneleVragen.data = action.payload
      })
      .addCase(fetchKlantAdditioneleVragen.rejected, (state) => {
        state.additioneleVragen.status = 'error'
        state.additioneleVragen.data = []
      })
  },
})

export const getKlantgegevens = (state: RootState) => state.klant.gegevens
export const getKlantAdditioneleVragen = (state: RootState) => ({
  data: state.klant.additioneleVragen.data,
  isLoading: state.klant.additioneleVragen.status === 'loading',
})

export const { reducer } = slice
export const { clearKlantgegevens } = slice.actions
