import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, Typography } from '@mui/material'

import StyledCard from '@components/StyledCard'
import type { Entity } from '@models/Entity'
import { getValuelists } from '@slices/valuelistsSlice'

import { fetchOrganisaties, getOrganisaties } from '../contracten/state'
import { Activiteiten } from '../entity/activiteiten/components/Activiteiten'
import {
  fetchActiviteitenForEntity,
  getActiviteitenForEntity,
} from '../entity/activiteiten/state'
import { Bijlagen } from '../entity/bijlagen/components/Bijlagen'
import {
  ENTITY_PROJECTDOSSIER,
  fetchBijlagenForEntity,
  getBijlagenForEntity,
} from '../entity/bijlagen/state'
import {
  clearProjectmelding,
  fetchProjectmelding,
  getProjectmelding,
} from '../projectmelding/state'

import { DetailGrid } from './components/DetailGrid'
import { LinkedProjectmelding } from './components/LinkedProjectmelding'
import { LinkedProjects } from './components/LinkedProjects'
import {
  clearProjectdossier,
  fetchProjectdossier,
  getProjectdossier,
} from './state'

export const Projectdossier = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    activiteiten,
    bijlagen,
    dossier,
    projectmelding,
    valuelists: {
      projectdossier: { status: statussen },
    },
    organisaties: { data: organisaties },
  } = useSelector((state) => ({
    activiteiten: getActiviteitenForEntity(state),
    bijlagen: getBijlagenForEntity(state),
    dossier: getProjectdossier(state),
    projectmelding: getProjectmelding(state),
    valuelists: getValuelists(state),
    organisaties: getOrganisaties(state),
  }))

  const { id } = useParams()
  const entity = {
    id,
    type: ENTITY_PROJECTDOSSIER,
  } as Entity

  const handleBackClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    dispatch(fetchProjectdossier(id))
    dispatch(fetchBijlagenForEntity({ id, type: ENTITY_PROJECTDOSSIER }))
    dispatch(fetchActiviteitenForEntity({ id, type: ENTITY_PROJECTDOSSIER }))
  }, [dispatch, id])

  useEffect(() => {
    if (dossier?.gebiedscode) {
      dispatch(fetchOrganisaties(dossier.gebiedscode))
    }

    if (dossier?.projectmeldingId) {
      dispatch(
        fetchProjectmelding({
          id: dossier.projectmeldingId,
          projectdossierId: dossier.projectdossierId,
        }),
      )
    }
  }, [dispatch, dossier])

  useEffect(() => {
    return () => {
      dispatch(clearProjectmelding())
      dispatch(clearProjectdossier())
    }
  }, [dispatch])

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">
        {!dossier?.projectdossierId
          ? 'Projectdossier niet gevonden'
          : `Projectdossier ${dossier.projectdossierId}`}
      </Typography>
      <Button
        sx={{ ml: 3, color: 'rgb(188, 53, 141)' }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon sx={{ fontSize: '16px' }} />
        Terug
      </Button>
      {dossier?.projectdossierId && (
        <>
          <DetailGrid
            dossier={dossier}
            organisaties={organisaties}
            statussen={statussen}
          />
          {projectmelding && (
            <LinkedProjectmelding projectmelding={projectmelding} />
          )}
          <StyledCard title="Activiteiten">
            <Activiteiten
              activiteiten={activiteiten}
              entity={entity}
              assignees={organisaties}
            />
          </StyledCard>
          <Bijlagen bijlagen={bijlagen} entity={entity} />
          <LinkedProjects
            projecten={dossier.projects}
            dossierId={dossier.projectdossierId}
            gebiedscode={dossier.gebiedscode}
          />
        </>
      )}
    </Box>
  )
}
