import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import { getProject } from '../../../../aansluiting/project/state'
import type { Project } from '../../../../aansluiting/project/types'

import {
  finishActiviteitForEntity,
  isFinishActiviteitLoading,
} from './../../state'

/**
 * @param Project The project to get the netbeheerders from
 * @returns An array of unique objects with codes and labels of the netbeheerders
 */
export const getUniqueNetbeheerdersFromProject = (project?: Project) => {
  if (!project) return []

  const mapVerzameling = ({
    netbeheerderLabel: label,
    netbeheerderCode: code,
    relevantForProgress: selected,
  }) => ({ code, label, selected })

  const netbeheerders = Object.values(
    project.disciplineVerzamelingen.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.netbeheerderCode]: mapVerzameling(curr),
      }),
      {},
    ),
  )

  return netbeheerders
}

interface Props {
  open: any
  data: any
  handleClose: any
}

export const KtoActiviteitDialog: FC<Props> = ({ open, data, handleClose }) => {
  const dispatch = useDispatch()
  const { project } = useSelector(getProject)
  const { activiteit } = data
  const [comment, setComment] = useState('')
  const [checkedNetbeheerders, setCheckedNetbeheerders] = useState<any[]>([])
  const [errors, setErrors] = useState({})
  const [netbeheerders] = useState(getUniqueNetbeheerdersFromProject(project))
  const isLoading = useSelector(isFinishActiviteitLoading)

  useEffect(() => {
    setCheckedNetbeheerders(netbeheerders.filter((x: any) => x.selected))
  }, [netbeheerders])

  useEffect(() => {
    setComment('')
  }, [open])

  const validate = () => {
    const checkedNetbeheerdersError = checkedNetbeheerders.length === 0

    setErrors({
      checkedNetbeheerdersError,
    })

    return !checkedNetbeheerdersError
  }

  const handleClickJa = () => {
    if (validate()) {
      const { _id, entity } = activiteit

      dispatch(
        finishActiviteitForEntity({
          entity,
          activiteitInfo: {
            _id,
            entity,
            text: comment,
            status: 'done',
            netbeheerderCodes: checkedNetbeheerders.map((nb) => nb.code),
          },
        }),
      )
    }
  }

  const handleClickNeeKto = () => {
    const { _id, entity } = activiteit

    dispatch(
      finishActiviteitForEntity({
        entity,
        activiteitInfo: { _id, entity, text: comment, status: 'cancelled' },
      }),
    )
  }

  return (
    <>
      <DialogTitle>Verzenden Klanttevredenheidsonderzoek</DialogTitle>
      <DialogContent>
        <Typography>
          Wilt u een klanttevredenheidsonderzoek (KTO) verzenden?
        </Typography>

        <Typography>
          Zo ja, selecteer de netbeheerders voor wie u een KTO wilt verzenden:
        </Typography>

        <Autocomplete
          data-testid="netbeheerders-select"
          multiple
          options={netbeheerders}
          onChange={(_, checkedNbs) => setCheckedNetbeheerders(checkedNbs)}
          getOptionLabel={(netbeheerder) => netbeheerder.label}
          value={checkedNetbeheerders}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              label="Netbeheerders:"
              error={errors['checkedNetbeheerdersError']}
            />
          )}
          sx={{ width: '100%', mt: 2 }}
        />

        <TextField
          id="standard-textarea"
          label="Eventuele extra opmerking"
          placeholder="Opmerking"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          sx={{ width: '100%', mt: 2 }}
          rows={5}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>
        {isLoading ? (
          <CircularProgress style={{ width: '20px', height: '20px' }} />
        ) : (
          <>
            <Button color="secondary" onClick={handleClickNeeKto}>
              Nee
            </Button>
            <Button onClick={handleClickJa} color="primary">
              Ja
            </Button>
          </>
        )}
      </DialogActions>
    </>
  )
}
