import React from 'react'

import FilterAutocomplete from './FilterAutocomplete'

interface FilterGebiedProps {
  value: any
  onFilterChange: any
  items: any[]
  label?: string
}

export default function FilterGebied({
  value,
  onFilterChange,
  items,
  label,
}: FilterGebiedProps) {
  return (
    <FilterAutocomplete
      items={items}
      label={label || 'Gebied'}
      filterName="gebied"
      value={value}
      onFilterChange={onFilterChange}
      multiple
      groupBy={(entry) => (entry.buitenGebied ? 'Anders' : 'Uw Gebied')}
    />
  )
}
