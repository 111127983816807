import React from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { disciplines, getProducts } from '@util/discipline'

import type { Project } from '../../project/types'

import { DisciplineVerzameling } from './DisciplineVerzameling'

const headerCells = [
  {
    id: 'disciplineCode',
    numeric: false,
    label: 'Aangevraagde disciplines',
    sort: true,
  },
  { id: 'netbeheerderCode', numeric: false, label: 'Netbeheerder', sort: true },
  { id: 'aantalObjecten', numeric: true, label: 'Objecten', sort: true },
  {
    id: 'processtap',
    numeric: false,
    label: 'Processtap',
    sort: false,
    fixed: 150,
  },
  { id: 'opties', numeric: false, label: '', sort: true, fixed: 50 },
]

export interface Props {
  row: Project
}

export const DisciplineVerzamelingen = ({ row }: Props) => {
  const { aanvraagID, disciplineVerzamelingen = [] } = row
  const getDisciplineVerzameling = getProducts(disciplineVerzamelingen)

  return (
    <TableRow>
      <TableCell colSpan={11} sx={{ p: 0, backgroundColor: '#f7f7f7' }}>
        <Table sx={{ p: 0, backgroundColor: '#f7f7f7' }}>
          <TableHead>
            <TableRow>
              {headerCells.map((headerCell) => (
                <TableCell
                  key={headerCell.id}
                  sx={{
                    width: headerCell.fixed,
                  }}
                >
                  {headerCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {disciplines
              .reduce(
                (curr, disciplineCode) =>
                  curr.concat(getDisciplineVerzameling(disciplineCode)),
                [],
              )
              .map((product: any) => {
                return (
                  <DisciplineVerzameling
                    key={`${aanvraagID}${product.disciplineCode}${product.netbeheerderCode}`}
                    project={row}
                    product={product}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  )
}
