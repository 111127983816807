import React, { FC } from 'react'

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Box, Button, Card, Grid, Typography } from '@mui/material'

interface Props {
  handleClickLogin: () => void
}

export const GeneralInfoCard: FC<Props> = ({ handleClickLogin }) => {
  return (
    <Card
      sx={{
        width: '80%',
        m: 'auto',
        mt: '2em',
        mb: '2em',
        p: '1.5em',
        // Gotten from mijnaansluiting.nl via chrome inspector
        boxShadow:
          '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
      }}
    >
      <Grid container spacing={8} sx={{ pr: '1em', pl: '1em' }}>
        <Grid item xs={6}>
          <Typography
            variant="h2"
            sx={{
              m: 0,
              pb: 2,
              pt: 1,
              fontSize: '1.8rem',
            }}
          >
            Wat is CAPO?
          </Typography>
          <Typography sx={{ lineHeight: 1.6 }}>
            Het doel van CAPO is om alle betrokkenen bij het
            combi-aansluitproces een eenduidig en gedeeld beeld te geven van de
            status van lopende aanvragen en projecten. Het geeft de mogelijkheid
            om regie te voeren op basis van actuele en gedeelde informatie over
            de hele aansluitketen, gebaseerd op data van DSP en
            mijnaansluiting.nl. Zo kan worden samengewerkt op basis van één
            waarheid. Knelpunten kunnen worden geïdentificeerd en alle
            aansluitingen kunnen op tijd worden gerealiseerd om de klant zo goed
            mogelijk te helpen. Elke partij ziet de aanvragen en projecten
            waarbij zij betrokken zijn.
          </Typography>
          <Box
            sx={{
              mt: '20px',
              position: 'relative',
              width: '100%',
              paddingBottom: '56.25%',
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }}
              src="https://www.youtube-nocookie.com/embed/dfJ5oNA0F8A"
              title="CAPO Uitlegvideo"
              allowFullScreen
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h4" sx={{ pb: 2, pt: 2 }}>
            Inloggen of registreren?
          </Typography>
          <Typography>
            Om gebruik te kunnen maken van CAPO zal u uzelf moeten registreren
            via de MijnAansluiting servicedesk.
          </Typography>
          <Button
            href="https://mijnaansluiting.atlassian.net/wiki/spaces/Wiki/pages/40075267/Registreren+als+gebruiker+van+CAPO"
            color="secondary"
            sx={{
              m: '1em',
              ml: '0',
            }}
            startIcon={<AssignmentIndIcon />}
          >
            Registeren voor CAPO
          </Button>
          <Typography>Heeft u al een account?</Typography>
          <Button
            onClick={handleClickLogin}
            color="secondary"
            sx={{ m: '1em', ml: 0 }}
            startIcon={<LockOpenIcon />}
          >
            Inloggen
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}
