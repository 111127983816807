import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'

import {
  fetchKlantAdditioneleVragen,
  getKlantAdditioneleVragen,
} from '../../klant/state'
import { AansluitObject } from '../../projecten/components/AansluitObject'
import type { Project } from '../types'

import VraagAntwoord from './VraagAntwoord'

const headerCells = [
  { id: 'disciplines', numeric: false, label: 'Aangevraagde disciplines' },
  { id: 'objectID', numeric: true, label: 'Object id' },
  { id: 'type', numeric: false, label: 'Object type' },
  { id: 'adres', numeric: false, label: 'Adres' },
  { id: 'statusText', numeric: false, label: 'Processtap', fixed: 150 },
  { id: 'opties', numeric: false, label: '', fixed: 50 },
]

export interface Props {
  project: Project
  showVraagAntwoord: boolean
}

export const Objecten: FC<Props> = ({ project, showVraagAntwoord }: Props) => {
  const dispatch = useDispatch()
  const {
    additioneleVragen: { data: beantwoordeVragen },
  } = useSelector((state) => ({
    additioneleVragen: getKlantAdditioneleVragen(state),
  }))
  const [showBeantwoordeVragen, setShowBeantwoordeVragen] = useState(false)

  useEffect(() => {
    if (showVraagAntwoord) {
      dispatch(fetchKlantAdditioneleVragen(project.aanvraagID))
    }
  }, [dispatch, project.aanvraagID, showVraagAntwoord])

  const aansluitObjecten = project.aansluitObjecten || []
  const additioneleVragenExists =
    beantwoordeVragen.length > 0 &&
    beantwoordeVragen[0].additioneleVragen.length > 0

  return (
    <StyledCard
      buttonBar={
        <>
          {showVraagAntwoord && additioneleVragenExists && (
            <StyledIconButton
              title="Toon beantwoorde vragen"
              onClick={() => setShowBeantwoordeVragen(!showBeantwoordeVragen)}
              icon={<QuestionAnswerIcon />}
            />
          )}
        </>
      }
      title="Objecten"
    >
      <Table sx={{ padding: 0, backgroundColor: '#f7f7f7' }} size="medium">
        <TableHead>
          <TableRow>
            {headerCells.map((headerCell) => (
              <TableCell
                key={headerCell.id}
                sx={{
                  width: headerCell.fixed,
                }}
              >
                {headerCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {aansluitObjecten.map((row) => (
            <React.Fragment key={`${project.aanvraagID}${row.objectID}`}>
              <AansluitObject project={project} row={row} />
              {showVraagAntwoord &&
                showBeantwoordeVragen &&
                additioneleVragenExists &&
                row.produkten.map((product) => (
                  <VraagAntwoord
                    key={product.disciplineID}
                    product={product}
                    beantwoordeVragen={beantwoordeVragen}
                  />
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </StyledCard>
  )
}
