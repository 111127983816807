import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterAutosuggest from '@components/toolbar/FilterAutosuggest'
import FilterFromToDate from '@components/toolbar/FilterFromToDate'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import FilterKpi from '@components/toolbar/FilterKpi'
import { FilterMyList } from '@components/toolbar/FilterMyList'

import { getValuelists } from '../../../core/slices/valuelistsSlice'
import type { RootState } from '../../../store'
import { getCombiWerkbakken } from '../state'
import type { Filters as StateFilters } from '../types'

interface Props {
  onChange: (filters: StateFilters, removeSort?: boolean) => void
  filters: StateFilters
  initialFilters: StateFilters
  onSearchChange: any
  options: any
}

export const Filters: FC<Props> = ({
  onChange,
  filters,
  initialFilters,
  onSearchChange,
  options,
}) => {
  const [localFilters, setLocalFilters] = useState(filters)
  const {
    werkbak,
    gebied,
    netbeheerder,
    aannemer,
    search,
    fromDate,
    toDate,
    kpi,
    labels,
    open,
    myList,
  } = localFilters

  const {
    valuelists: { aannemers, netbeheerders, gebieden, labels: valuelistLabels },
    werkbakken,
  } = useSelector((state: RootState) => ({
    valuelists: getValuelists(state),
    werkbakken: getCombiWerkbakken(state),
  }))

  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

  const handleClearFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  const handleFilterChange = (
    filter,
    apply?: boolean,
    removeSort?: boolean,
  ) => {
    const newFilters = { ...localFilters, ...filter }

    setLocalFilters(newFilters)
    if (apply)
      removeSort ? onChange(newFilters, removeSort) : onChange(newFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterAutocomplete
          items={werkbakken}
          label="KPI"
          filterName="werkbak"
          value={werkbak}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={gebied}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={netbeheerders}
          label="Netbeheerder"
          filterName="netbeheerder"
          value={netbeheerder}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={aannemers}
          label="Uitvoerende"
          filterName="aannemer"
          value={aannemer}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem minColumns="double">
        <FilterFromToDate
          fromDate={fromDate}
          label={'Plandatum'}
          toDate={toDate}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      {valuelistLabels.length > 0 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={valuelistLabels}
            label="Labels"
            filterName="labels"
            value={labels}
            onFilterChange={handleFilterChange}
            multiple
          />
        </FilterGridItem>
      )}

      <FilterGridItem>
        <FilterAutosuggest
          search={search}
          options={options}
          onFilterChange={handleFilterChange}
          onSearchChange={onSearchChange}
        />
      </FilterGridItem>

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FilterKpi
          kpi={kpi}
          onFilterChange={(filter) => handleFilterChange(filter, true)}
        />
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={handleClearFilters}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <FilterMyList myList={myList} onFilterChange={handleFilterChange} />

        <Tooltip
          title={
            open
              ? 'Klik om ook afgesloten opdrachten te tonen'
              : 'Klik om alleen open opdrachten te tonen'
          }
        >
          <IconButton
            data-testid="openbutton"
            onClick={() => handleFilterChange({ open: !open }, true)}
          >
            {open ? <AlarmRoundedIcon /> : <AlarmOffRoundedIcon />}
          </IconButton>
        </Tooltip>

        <LocalStorageButtons
          name="combi_opdrachten"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
