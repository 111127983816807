import type { FC } from 'react'
import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Avatar, Chip, Tooltip } from '@mui/material'

import type { AssignedBijlage, BijlageLog } from '../types'

const statusLabelDict = {
  assigned: 'Toegewezen',
  uploaded: 'Bijlagen toegevoegd',
  review: 'Ter beoordeling aangeboden',
  done: 'Afgerond',
  cancelled: 'Geannuleerd',
}

const logStatusLabel = (
  logEntry: BijlageLog,
  assignedBijlage: AssignedBijlage,
) => {
  const { status } = logEntry

  if (status === 'waiting') {
    return `Wacht op ${assignedBijlage.waitFor}`
  }

  return statusLabelDict[status]
}

interface Props {
  logEntry: BijlageLog
  assignedBijlage: AssignedBijlage
}

export const AssignedBijlageLogStatus: FC<Props> = ({
  logEntry,
  assignedBijlage,
}) => {
  const { requiresReview } = assignedBijlage
  const { status, reden } = logEntry
  const statusText = logStatusLabel(logEntry, assignedBijlage)
  const isAfgekeurd = requiresReview && status === 'uploaded' && reden
  const isGoedgekeurd = requiresReview && status === 'done'

  return (
    <>
      {!isAfgekeurd && !isGoedgekeurd && statusText}
      {isAfgekeurd && (
        <Tooltip title={`Reden afkeur: ${reden}`}>
          <Chip
            label="Afgekeurd"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              borderColor: 'red',
              color: 'red',
            }}
            avatar={
              <Avatar>
                <CloseIcon
                  sx={{
                    color: 'red',
                    cursor: 'default',
                    backgroundColor: 'white',
                  }}
                />
              </Avatar>
            }
          />
        </Tooltip>
      )}

      {isGoedgekeurd && (
        <Chip
          label="Goedgekeurd"
          variant="outlined"
          sx={{
            backgroundColor: 'white',
            borderColor: 'green',
            color: 'green',
          }}
          avatar={
            <Avatar>
              <DoneIcon
                sx={{
                  color: 'green',
                  cursor: 'default',
                  backgroundColor: 'white',
                }}
              />
            </Avatar>
          }
        />
      )}
    </>
  )
}
