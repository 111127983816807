import React, { useState } from 'react'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'

import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'

import type { GroupedFilterOption } from '../../../../core/models/Filter'
import { opleverenAssignedBijlageForEntity } from '../../state'

export interface Props {
  data: any
  onClose: () => void
}

export const Opleveren: FC<Props> = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const [assignedTo, setAssignedTo] = useState<GroupedFilterOption | null>(null)
  const [errors, setErrors] = useState({})
  const {
    bijlage: { documentsoort, _id, entity, requiresReview },
    assignees = [],
  } = data

  const onClick = () => {
    if (requiresReview && !assignedTo) {
      setErrors({ assignedToError: true })
      return
    }

    setErrors({})

    dispatch(
      opleverenAssignedBijlageForEntity({
        entity,
        assignedTo: assignedTo?.label,
        id: _id,
      }),
    )
  }

  return (
    <>
      <DialogTitle>Opleveren bijlage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {requiresReview
            ? `Als u deze bijlage ${documentsoort} wilt laten beoordelen, kies dan de beoordelende organisatie`
            : `Wilt u deze bijlage ${documentsoort} opleveren?`}
        </DialogContentText>
        {requiresReview && (
          <Autocomplete
            sx={{ width: '50%', pr: 1, pt: 2 }}
            options={assignees}
            groupBy={(assignedTo) => assignedTo.group}
            onChange={(_, assignee) => setAssignedTo(assignee)}
            getOptionLabel={(assignedTo) => assignedTo.label}
            value={assignedTo}
            size={'medium'}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Beoordelen door"
                error={errors['assignedToError']}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuleren</Button>
        <Button
          onClick={onClick}
          startIcon={<PlaylistPlayIcon />}
          sx={{ color: 'green', border: '1px solid green' }}
        >
          {requiresReview ? 'Ter beoordeling aanbieden' : 'Opleveren'}
        </Button>
      </DialogActions>
    </>
  )
}
