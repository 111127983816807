import React from 'react'

import { Dialog } from '@mui/material'

import { CancelActiviteitDialog } from './dialogs/CancelActiviteitDialog'
import { CreateEditActiviteitDialog } from './dialogs/CreateEditActiviteitDialog'
import { FinishActiviteitDialog } from './dialogs/FinishActiviteitDialog'
import { KtoActiviteitDialog } from './dialogs/KtoActiviteitDialog'

export const ActiviteitenDialog = ({ open, data, onClose }) => {
  const { type } = data

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'}>
      {type === 'resolve' && (
        <FinishActiviteitDialog open={open} data={data} handleClose={onClose} />
      )}
      {(type === 'create' || type === 'edit') && (
        <CreateEditActiviteitDialog data={data} handleClose={onClose} />
      )}
      {type === 'kto' && (
        <KtoActiviteitDialog open={open} data={data} handleClose={onClose} />
      )}
      {type === 'cancel' && (
        <CancelActiviteitDialog open={open} data={data} handleClose={onClose} />
      )}
    </Dialog>
  )
}
