import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { State } from './types'

const AANSLUITING_PROJECT_TYPES = ['Aanvraag', 'VOG', 'SAN']

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: {
      gebied: [],
      type: [],
      projecttype: [],
      assignedTo: [],
      fromChangedDate: null,
      toChangedDate: null,
      fromDate: null,
      toDate: null,
      fromGeplandeUitvoeringsdatum: null,
      toGeplandeUitvoeringsdatum: null,
      labels: [],
      myList: false,
    },
    sort: { sortBy: 'created.date', sortDirection: 'desc' },
    page: { skip: 0, limit: 25 },
  },
}

export const createFilters = ({
  gebied,
  type,
  projecttype,
  assignedTo,
  toDate,
  fromDate,
  toChangedDate,
  fromChangedDate,
  fromGeplandeUitvoeringsdatum,
  toGeplandeUitvoeringsdatum,
  labels,
  myList,
}) => {
  const filters: IFilter[] = [
    { name: 'status', value: 'open', operator: 'eq' },
    { name: 'isActive', value: true, operator: 'eq' },
  ]

  if (type && type.length > 0)
    filters.push({
      name: 'type',
      value: type.map(({ code }) => code),
      operator: 'in',
    })

  filters.push({
    name: 'entity.projecttype',
    value:
      projecttype && projecttype.length > 0
        ? projecttype.map(({ code }) => code)
        : AANSLUITING_PROJECT_TYPES,
    operator: 'in',
  })
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'entity.gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (assignedTo && assignedTo.length > 0)
    filters.push({
      name: 'assignedTo',
      value: assignedTo.map(({ label }) => label),
      operator: 'in',
    })
  if (fromDate)
    filters.push({
      name: 'dueDate',
      value: startOfDay(fromDate),
      operator: 'gte',
    })
  if (toDate)
    filters.push({ name: 'dueDate', value: endOfDay(toDate), operator: 'lte' })
  if (fromChangedDate)
    filters.push({
      name: 'changed',
      value: startOfDay(fromChangedDate),
      operator: 'gte',
    })
  if (toChangedDate)
    filters.push({
      name: 'changed',
      value: endOfDay(toChangedDate),
      operator: 'lte',
    })
  if (fromGeplandeUitvoeringsdatum) {
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: startOfDay(fromGeplandeUitvoeringsdatum),
      operator: 'gte',
    })
  }
  if (toGeplandeUitvoeringsdatum) {
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: endOfDay(toGeplandeUitvoeringsdatum),
      operator: 'lte',
    })
  }
  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchActiviteiten = createAsyncThunk(
  'fetch/activiteiten',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/activiteit/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van activiteiten', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'activiteiten',
  initialState,
  reducers: {
    storeQuery: (state, action) => {
      state.query = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiviteiten.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchActiviteiten.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchActiviteiten.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
  },
})

export const getActiviteiten = (state: RootState) => ({
  data: state.activiteiten.data,
  isLoading: state.activiteiten.status === 'loading',
  query: state.activiteiten.query,
})

export const { reducer } = slice
export const { storeQuery } = slice.actions
