import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'

import CreateProjectdossierDialog from '../projectdossiers/dialogs/CreateProjectdossierDialog'

import { Gegevens } from './components/Gegevens'
import { Afwijzen } from './components/dialogs/Afwijzen'
import {
  clearProjectmelding,
  closeAfwijzenDialog,
  closeCreateDialog,
  fetchProjectmelding,
  getCreateDialogData,
  getProjectmelding,
  isAfwijzenDialogOpen,
  isCreateDialogOpen,
  openAfwijzenDialog,
  openCreateDialog,
} from './state'
import type { Projectmelding as ProjectMeldingType } from './types'

export const Projectmelding = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectmelding, open, data, isOpenAfwijzen } = useSelector(
    (state) => ({
      projectmelding: getProjectmelding(state),
      open: isCreateDialogOpen(state),
      data: getCreateDialogData(state),
      isOpenAfwijzen: isAfwijzenDialogOpen(state),
    }),
  )

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(fetchProjectmelding({ id }))
    }

    return () => {
      dispatch(clearProjectmelding())
    }
  }, [dispatch, id])

  const onClickCreate = (projectmelding: ProjectMeldingType) => {
    const {
      projectName,
      description,
      gender,
      initials,
      middleName,
      lastName,
      companyName,
      contractGebiedCode,
    } = projectmelding

    dispatch(
      openCreateDialog({
        projectmelding: {
          projectmeldingId: id,
          name: projectName,
          location: description,
          indienerOrg: companyName,
          indienerNaam: `${gender} ${initials} ${middleName} ${lastName}`,
          gebiedscode: contractGebiedCode,
        },
      }),
    )
  }

  const onClickAfwijzen = () => dispatch(openAfwijzenDialog())
  const onCloseAfwijzen = () => dispatch(closeAfwijzenDialog())
  const handleBackClick = () => navigate(-1)

  const onClose = () => dispatch(closeCreateDialog())

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">
        {!projectmelding
          ? 'Vooraanmelding niet gevonden'
          : `Vooraanmelding ${projectmelding.projectName}`}
      </Typography>

      <Button
        sx={{ ml: 3, color: 'rgb(188, 53, 141)' }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon sx={{ fontSize: '16px' }} />
        Terug
      </Button>
      {projectmelding && (
        <>
          <Card sx={{ mt: 3, mb: 2, w: '100%', pb: 4 }}>
            <CardContent>
              <Gegevens
                projectmelding={projectmelding}
                onClickCreate={onClickCreate}
                onClickAfwijzen={onClickAfwijzen}
              />
            </CardContent>
          </Card>

          <CreateProjectdossierDialog
            data={data}
            open={open}
            onClose={onClose}
          />
          {isOpenAfwijzen && (
            <Afwijzen id={projectmelding.id} handleClose={onCloseAfwijzen} />
          )}
        </>
      )}
    </Box>
  )
}
