import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import {
  IconButton,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
} from '@mui/material'

import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledTable from '@components/StyledTable'
import type { Entity } from '@models/Entity'
import { formatDateTime } from '@util/date-util'

import {
  closeBijlagenDialog,
  getBijlagenDialogData,
  isBijlagenDialogOpen,
  openBijlageForEntity,
  openBijlagenDialog,
} from '../state'
import type { Bijlage } from '../types'

import { BijlagenDialog } from './BijlagenDialog'

const headerCells = [
  { id: 'naam', numeric: true, label: 'Naam', sort: false },
  { id: 'created.date', label: 'Aangemaakt', fixed: 50 },
  { id: 'created.organisatie', label: 'Aangemaakt door', fixed: 150 },
  { id: 'opties', label: 'Opties', sort: false, fixed: 50 },
]

const createNaamByStatus = ({ naam, status, entity }) => {
  if (entity.type !== 'opdracht') return naam
  if (status === 'cancelled') return `${naam} - geannuleerd`
  if (status === 'rejected') return `${naam} - afgekeurd`
  if (status === 'accepted') return `${naam} - goedgekeurd`

  return naam
}

interface Props {
  entity: Entity
  bijlagen?: Bijlage[]
  showUploadButton?: boolean
}

export const Bijlagen: FC<Props> = ({
  entity,
  bijlagen,
  showUploadButton = true,
}: Props) => {
  const dispatch = useDispatch()
  const open = useSelector(isBijlagenDialogOpen)
  const data = useSelector(getBijlagenDialogData)

  const handleUpload = () => {
    dispatch(openBijlagenDialog({ entity, type: 'upload' }))
  }

  const handleOpen = (bijlage: Bijlage) => {
    dispatch(openBijlageForEntity({ entity, bijlage }))
  }

  const onClose = () => {
    dispatch(closeBijlagenDialog())
  }

  return (
    <StyledCard title="Bijlagen">
      <Toolbar
        sx={{
          backgroundColor: '#F7F7F7',
          borderBottom: '1px solid #E0E0E0',
          justifyContent: 'flex-end',
        }}
      >
        {showUploadButton && (
          <StyledIconButton
            title="Bijlage toevoegen"
            onClick={handleUpload}
            icon={<AddIcon />}
          />
        )}
      </Toolbar>
      <StyledTable variant="details" headerCells={headerCells} {...({} as any)}>
        {bijlagen?.map((row) => (
          <TableRow key={row._id}>
            <TableCell>{createNaamByStatus(row)}</TableCell>
            <TableCell>{formatDateTime(row.created.date)}</TableCell>
            <TableCell>{row.created.organisatie}</TableCell>
            <TableCell>
              <Tooltip title="Bijlage openen">
                <IconButton onClick={() => handleOpen(row)}>
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </StyledTable>
      {open && <BijlagenDialog open={open} data={data} onClose={onClose} />}
    </StyledCard>
  )
}
