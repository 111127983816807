import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AccountCirleIcon from '@mui/icons-material/AccountCircle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import MenuIcon from '@mui/icons-material/Menu'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import TodayIcon from '@mui/icons-material/Today'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material'

import { fetchStatusconfig } from '@slices/statusconfigSlice'
import {
  fetchDeelnemerValuelists,
  fetchHoofdleidingValuelists,
  fetchValuelists,
} from '@slices/valuelistsSlice'

import packageInfo from '../../package.json'
import { getUser, getUserParam, isAuthenticated } from '../auth/state'
import { getVersion } from '../config'
import { fetchContracten } from '../contracten/state'
import { logout } from '../gateways/auth0'

import { AansluitingMenu } from './components/AansluitingMenu'
import { ContextChip } from './components/ContextChip'
import { HoofdleidingMenu } from './components/HoofdleidingMenu'
import { KetensamenwerkingMenu } from './components/KetensamenwerkingMenu'
import { Profile } from './components/Profile'

const logo = '/images/mijnaansluiting-logo.svg'

export const Navigation: FC = () => {
  const dispatch = useDispatch()
  const authenticated = useSelector(isAuthenticated)
  const [anchorEl, setAnchorEl] = useState(null)
  const [drawer, setDrawer] = useState(false)
  const open = Boolean(anchorEl)
  const trigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })
  const userParam = useSelector(getUserParam)
  const sha = getVersion()
  const {
    canViewBeheer,
    canViewCombiOpdrachten,
    canViewContracten,
    canViewOpdrachten,
  } = useSelector(getUser)

  useEffect(() => {
    if (authenticated) {
      if (canViewCombiOpdrachten) {
        dispatch(fetchValuelists())
      }
      // deelnemer opdrachten are only relevant for deelnemer opdrachten
      // so only send that request when required
      if (canViewOpdrachten) {
        dispatch(fetchDeelnemerValuelists())
      }
      dispatch(fetchStatusconfig())
      dispatch(fetchHoofdleidingValuelists())
      dispatch(fetchContracten())
    }
  }, [
    dispatch,
    userParam,
    authenticated,
    canViewOpdrachten,
    canViewCombiOpdrachten,
  ])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleSideList = (open: boolean) => () => {
    setDrawer(open)
  }

  return (
    <AppBar
      position="sticky"
      sx={
        trigger
          ? { background: 'white', elevation: 4 }
          : { background: 'transparent', boxShadow: 'none' }
      }
      id={trigger ? 'elevated' : 'flat'}
    >
      <Toolbar sx={{ color: 'black' }}>
        <IconButton
          data-testid="menuButton"
          edge="start"
          sx={{ mr: 2 }}
          color="inherit"
          onClick={toggleSideList(true)}
        >
          <MenuIcon />
        </IconButton>

        <img src={logo} style={{ width: '190px' }} alt="logo" />

        <Box sx={{ flex: 1 }}>
          <ContextChip />
        </Box>

        <IconButton
          color="inherit"
          onClick={handleMenu}
          data-testid="accountButton"
        >
          <AccountCirleIcon />
        </IconButton>

        <Popover
          data-testid="accountPopover"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Profile />
        </Popover>
      </Toolbar>

      <Drawer
        open={drawer}
        onClose={toggleSideList(false)}
        data-testid="drawer"
        sx={{
          '& .MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        }}
      >
        <Box sx={{ width: '250px' }} role="presentation">
          <img
            src={logo}
            style={{ width: '200px', margin: '20px 25px' }}
            alt="logo"
          />

          <AansluitingMenu onItemClick={toggleSideList(false)} />

          <HoofdleidingMenu onItemClick={toggleSideList(false)} />

          <KetensamenwerkingMenu onItemClick={toggleSideList(false)} />

          <List>
            {canViewBeheer && (
              <ListItemButton
                component={Link}
                to="/beheer"
                onClick={toggleSideList(false)}
              >
                <ListItemIcon sx={{ color: 'black' }}>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText primary="Beheer" />
              </ListItemButton>
            )}

            {canViewContracten && (
              <ListItemButton
                component={Link}
                to="/contracten"
                onClick={toggleSideList(false)}
              >
                <ListItemIcon sx={{ color: 'black' }}>
                  <FindInPageIcon />
                </ListItemIcon>
                <ListItemText primary="Combi contracten" />
              </ListItemButton>
            )}

            <ListItemButton
              component={'a'}
              rel="noopener noreferrer"
              href="https://mijnaansluiting.atlassian.net/wiki/spaces/Wiki/pages/39911528/CAPO+UI+Handleiding"
              target="_blank"
              data-testid="handleidingLink"
            >
              <ListItemIcon sx={{ color: 'black' }}>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Handleiding" />
            </ListItemButton>

            <ListItemButton onClick={logout}>
              <ListItemIcon sx={{ color: 'black' }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Uitloggen" />
            </ListItemButton>
          </List>
        </Box>

        <Typography
          variant="caption"
          sx={{
            my: '10px',
          }}
        >{`v${packageInfo.version}${sha}`}</Typography>
      </Drawer>
    </AppBar>
  )
}
