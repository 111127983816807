import React, { FC } from 'react'

import { Dialog } from '@mui/material'

import type { OptionsDialogData } from '../../types'
import { Actief } from '../dialogs/Actief'
import { CombiUitvoering } from '../dialogs/CombiUitvoering'
import { Gebiedswissel } from '../dialogs/Gebiedswissel'
import { Herplan } from '../dialogs/Herplan'
import { Labels } from '../dialogs/Labels'
import { Quickscan } from '../dialogs/Quickscan'

export interface Props {
  open: boolean
  data: OptionsDialogData
  onClose: () => void
}

export const ProjectOptionsDialog: FC<Props> = ({
  open,
  data,
  onClose,
}: Props) => {
  const { type, project, selectableLabels } = data

  if (!type || !project) return null

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {type === 'combiuitvoering' && (
        <CombiUitvoering project={project} handleClose={onClose} />
      )}

      {type === 'quickscan' && (
        <Quickscan project={project} handleClose={onClose} />
      )}

      {type === 'actief' && <Actief project={project} handleClose={onClose} />}

      {type === 'labels' && (
        <Labels
          project={project}
          selectableLabels={selectableLabels}
          handleClose={onClose}
        />
      )}

      {type === 'herplanning_klant' && (
        <Herplan project={project} handleClose={onClose} />
      )}

      {type === 'gebiedswissel' && (
        <Gebiedswissel project={project} handleClose={onClose} />
      )}
    </Dialog>
  )
}
