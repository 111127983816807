import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

import type { AuthenticatedUser } from '../../../auth/types'
import {
  closeBijlagenDialog,
  getAssignedBijlagenForEntity,
  getBijlageTemplatesForEntity,
  getBijlagenDialogData,
  getBijlagenForEntity,
  isBijlagenDialogOpen,
  openBijlageForEntity,
  openBijlagenDialog,
} from '../state'
import type { AssignedBijlage, Bijlage } from '../types'

import { AssignedBijlagen } from './AssignedBijlagen'
import { BijlagenDialog } from './BijlagenDialog'

interface Props {
  entity: any
  user: AuthenticatedUser | Record<string, any>
  assignees: any[]
  showCreateAssignedBijlage: boolean
  projectStatus: string
  isHoofdleidingProject?: boolean
  isVerantwoordelijke: boolean
}

export const AssignedBijlagenByProcesstap: FC<Props> = ({
  entity,
  user,
  assignees,
  showCreateAssignedBijlage,
  projectStatus,
  isHoofdleidingProject = false,
  isVerantwoordelijke,
}) => {
  const dispatch = useDispatch()
  const assignedBijlagen = useSelector(getAssignedBijlagenForEntity)
  const bijlageTemplates = useSelector(getBijlageTemplatesForEntity)
  const bijlagen = useSelector(getBijlagenForEntity)
  const open = useSelector(isBijlagenDialogOpen)
  const data = useSelector(getBijlagenDialogData)

  const processtatussen = new Set(bijlageTemplates.map((b) => b.processtatus))

  const defaultExpanded = !isHoofdleidingProject

  const [expanded, setExpanded] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setExpanded({
      [projectStatus]: true,
    })
  }, [projectStatus])

  const onOpen = (bijlage: Bijlage) =>
    dispatch(openBijlageForEntity({ entity, bijlage }))

  const onUpload = (bijlage: AssignedBijlage) =>
    dispatch(
      openBijlagenDialog({
        entity,
        bijlage,
        type: 'upload',
        assignees,
      }),
    )

  const onCreate = (processtatus: string) => {
    dispatch(
      openBijlagenDialog({
        entity,
        isHoofdleidingProject,
        assignees,
        processtatus,
        bijlageTemplates,
        type: 'create',
      }),
    )
  }

  const onOpleveren = (bijlage: AssignedBijlage) => {
    dispatch(openBijlagenDialog({ bijlage, assignees, type: 'submit' }))
  }

  const onReview = (bijlage: AssignedBijlage) => {
    dispatch(openBijlagenDialog({ bijlage, assignees, type: 'review' }))
  }

  const onCancel = (bijlage: Bijlage | AssignedBijlage) => {
    dispatch(openBijlagenDialog({ bijlage, type: 'cancel' }))
  }

  const onClose = () => dispatch(closeBijlagenDialog())

  return (
    <>
      {[...processtatussen].map((status) => {
        return (
          <Accordion
            key={status}
            sx={{ mt: 3, mb: 2 }}
            expanded={
              expanded[status] !== undefined
                ? expanded[status]
                : defaultExpanded
            }
            onChange={(_, isExpanded) => {
              setExpanded({
                ...expanded,
                [status]: isExpanded,
              })
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{status}</Typography>
            </AccordionSummary>

            {/* padding hier is valsspelen om het er hetzelfde uit te laten zien als de normale styled card */}
            <AccordionDetails sx={{ width: '95%', mx: 'auto', pb: 7 }}>
              <AssignedBijlagen
                assignedBijlagen={assignedBijlagen.filter(
                  (assignedBijlage) => assignedBijlage.processtatus === status,
                )}
                bijlagen={bijlagen}
                user={user}
                onCreate={() => onCreate(status)}
                onOpen={onOpen}
                onUpload={onUpload}
                onCancel={onCancel}
                onOpleveren={onOpleveren}
                onReview={onReview}
                showCreateAssignedBijlage={showCreateAssignedBijlage}
                showCancelAssignedBijlage={
                  !isHoofdleidingProject || isVerantwoordelijke
                }
                isVerantwoordelijke={isVerantwoordelijke}
              />
            </AccordionDetails>
          </Accordion>
        )
      })}

      {open && <BijlagenDialog open={open} data={data} onClose={onClose} />}
    </>
  )
}
