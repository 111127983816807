import React, { type FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SaveIcon from '@mui/icons-material/Save'
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

import { getProcesstappenHoofdleiding } from '@slices/statusconfigSlice'

import { saveEvent } from '../../state'
import type { OptionsDialogData } from '../../types'

type Props = {
  data: OptionsDialogData
  handleClose: () => void
}

export const Processtap: FC<Props> = ({ data, handleClose }: Props) => {
  const dispatch = useDispatch()
  const { processtappen } = useSelector((state) => ({
    processtappen: getProcesstappenHoofdleiding(state),
  }))

  const [processtap, setProcesstap] = useState<string | null>(
    data.project.statusText,
  )

  const handleSave = () => {
    if (processtap) {
      dispatch(
        saveEvent({
          type: 'hoofdleiding_processtap',
          id: data.project.aanvraagID,
          source: 'manual',
          data: { statusText: processtap },
        }),
      )
    }
  }

  return (
    <>
      <DialogTitle>Processtap wijzigen</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={processtappen}
          sx={{ width: '50%', pr: 2 }}
          onChange={(_, processtap) => {
            setProcesstap(processtap)
          }}
          value={processtap}
          renderInput={(params) => (
            <TextField variant="standard" {...params} label="Processtap" />
          )}
        />

        <Collapse in={processtap === 'Geannuleerd'} unmountOnExit>
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            Een project annuleren is onomkeerbaar, de onderliggende bijlagen en
            activiteiten worden ook geannuleerd.
          </Alert>
        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="text">
          Annuleren
        </Button>
        <Button
          disabled={data.project.statusText === processtap}
          onClick={handleSave}
          variant="contained"
          endIcon={<SaveIcon />}
        >
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
