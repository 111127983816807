import React, { FC, useState } from 'react'

import {
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material'

import { createBadge, createSummaryBadge } from '@util/kpi-util'

import type { KpiSummary } from '../types'

const colors = {
  red: 'red',
  amber: 'darkorange',
}

const background = {
  redAndAmber: 'linear-gradient( -45deg, darkorange, darkorange 50%, red 50% )',
}

interface Props {
  kpi: KpiSummary
}

export const Kpi: FC<Props> = ({ kpi }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const badge = createSummaryBadge(kpi)
  const badgeColor = badge && colors[badge.color as any]
  const badgeBackground = badge && background[badge.color as any]

  const badgeCount = (badge && badge.count) || 0
  const kpis = kpi.kpis || []

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        sx={{ pl: '25px' }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: badgeColor,
              color: 'white',
              background: badgeBackground,
            },
          }}
          badgeContent={badgeCount}
          overlap="circular"
        >
          <div></div>
        </Badge>
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          '& .MuiPopover-paper': {
            p: 1,
            backgroundColor: 'rgba(97,97,97,.9)',
            color: 'white',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List>
          {kpis.map((kpi) => {
            const badge = createBadge(kpi)
            const badgeColor =
              badge && badge.color === 'red' ? badge.color : 'darkorange'
            const badgeCount = (badge && badge.count) || 0

            return (
              <ListItem key={kpi.code}>
                <ListItemIcon>
                  <Badge
                    badgeContent={badgeCount}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: badgeColor,
                        color: 'white',
                      },
                    }}
                    overlap="circular"
                  >
                    <div></div>
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={`${kpi.actiehouder}: ${kpi.msg}`} />
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </>
  )
}
