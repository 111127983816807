import axios from 'axios'
import Status from 'http-status-codes'

import type { Alert } from '../alerts/types'

const extractErrorsFromAxios = (
  error: any,
): Pick<Alert, 'message' | 'messageDetail'> => {
  if (!error)
    return {
      message: 'Fout bij het maken van het verzoek.',
      messageDetail: 'Onbekende error',
    }

  if (!error.response) {
    return error.request
      ? { message: 'Geen reactie ontvangen.', messageDetail: error.code }
      : {
          message: 'Fout bij het maken van het verzoek.',
          messageDetail: error.message,
        }
  }

  switch (error.response.status) {
    case Status.FORBIDDEN: // intentional fall through
    case Status.UNAUTHORIZED:
      return { message: 'Onvoldoende rechten' }

    case Status.NOT_FOUND:
      return { message: 'Niets gevonden' }

    default:
      // eslint-disable-next-line no-case-declarations
      const { data } = error.response

      if (data && data.message && data.message.length > 0) {
        return {
          message: 'Fout reactie ontvangen',
          messageDetail: data.message,
        }
      }

      if (typeof data === 'string' && data.length > 0) {
        return { message: 'Fout reactie ontvangen', messageDetail: data }
      }

      return {
        message: 'Fout reactie ontvangen',
        messageDetail: `${error.response.statusText} (${error.response.status})`,
      }
  }
}

const apiClient = axios.create()

apiClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject({ ...extractErrorsFromAxios(error) }),
)

export const setAccessToken = (accessToken) => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

export const setUserParam = (userParam) => {
  const param =
    userParam !== ''
      ? { [userParam.split(':')[0]]: userParam.split(':')[1] }
      : {}

  apiClient.defaults.params = { ...param }
}

export const request = async <T = any>(req): Promise<T> => {
  const { data } = await apiClient({ ...req })

  return data
}

const s3Client = axios.create()

s3Client.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject({ ...extractErrorsFromAxios(error) }),
)

export const requestS3 = async (req) => {
  const { data } = await s3Client({ ...req })

  return data
}
