import React from 'react'

import { Box } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function FilterFromToDate({
  fromDate,
  toDate,
  onFilterChange,
  label,
  prop = 'Date',
  // eslint-disable-next-line no-magic-numbers
  spacing = 2,
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <DatePicker
        label={`${label} van`}
        onChange={(fromDate) => onFilterChange({ [`from${prop}`]: fromDate })}
        value={fromDate}
        displayWeekNumber
        slotProps={{
          field: { clearable: true },
          textField: { variant: 'standard', fullWidth: true },
        }}
      />
      <DatePicker
        label={`${label} tot`}
        onChange={(toDate) => onFilterChange({ [`to${prop}`]: toDate })}
        value={toDate}
        displayWeekNumber
        slotProps={{
          field: { clearable: true },
          textField: {
            variant: 'standard',
            sx: { ml: spacing },
            fullWidth: true,
          },
        }}
      />
    </Box>
  )
}
