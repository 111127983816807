import type { FC } from 'react'
import React from 'react'

import { IconButton, Tooltip } from '@mui/material'

import { MyListIcon } from '../MyListIcon'

interface Props {
  myList: boolean
  onFilterChange: (filter: any, submit?: boolean) => void
}

export const FilterMyList: FC<Props> = ({ myList, onFilterChange }) => {
  return (
    <Tooltip title={`Mijn lijst ${myList ? 'uit' : 'aan'}zetten`}>
      <IconButton
        data-testid="MyListButton"
        onClick={() => onFilterChange({ myList: !myList }, true)}
      >
        <MyListIcon active={myList} />
      </IconButton>
    </Tooltip>
  )
}
