import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { BooleanToggleGroup } from '@components/BooleanToggleGroup'
import { EditCombiUitvoeringPerDiscipline } from '@components/EditCombiUitvoeringPerDiscipline'
import PlanningConflict from '@components/PlanningConflict'
import Realisatie from '@components/Realisatie'
import { addWeeks, formatDate, subtractWeeks } from '@util/date-util'
import { getWeeksBeforeStartUitvoering } from '@util/planningsconflict-util'

import { getUser } from '../../../../auth/state'
import { saveEvent } from '../../state'
import type { Project, QuickscanQuestion } from '../../types'
import { createQuickscanCombiDisciplines } from '../../util'

const dateFormat = 'dd-MM-yyyy'
const HOOFDLEIDING_ONLY_WHEN_EXTRA_WVB = ['Vitens']

export interface Props {
  project: Project
  handleClose: () => void
}

export const Quickscan = ({ project, handleClose }: Props) => {
  const dispatch = useDispatch()
  const {
    aanvraagID,
    geplandeUitvoeringsdatum,
    geplandeUitvoeringsdatumType,
    combi,
  } = project

  const WEEKS_BEFORE_START_UITVOERING = getWeeksBeforeStartUitvoering(combi)
  const { deelnemer, organisatie } = useSelector(getUser)

  const [extraWVB, setExtraWVB] = useState('Nee')

  const [hoofdleidingText, setHoofdleidingText] = useState('')
  const [extraWVBText, setExtraWVBText] = useState('')
  const [extraWVBDueDate, setExtraWVBDueDate] = useState(
    geplandeUitvoeringsdatum ? new Date(geplandeUitvoeringsdatum) : null,
  )

  const disciplineVerzamelingenFormRef = useRef<HTMLFormElement>(null)

  const [errors, setErrors] = useState({})

  const isConditionalHoofdleiding =
    !!deelnemer && HOOFDLEIDING_ONLY_WHEN_EXTRA_WVB.includes(deelnemer)

  const showHoofdleiding = !isConditionalHoofdleiding || extraWVB === 'Ja'

  const [hoofdleiding, setHoofdleiding] = useState(
    showHoofdleiding ? 'Nee' : '',
  )

  const disciplineVerzamelingen = project.disciplineVerzamelingen.filter(
    (dv) => dv.netbeheerderLabel === organisatie,
  )

  const [discVer, setDiscVer] = useState(disciplineVerzamelingen)

  const handleChangeHoofdleiding = (newValue) => {
    if (newValue !== null) {
      setHoofdleiding(newValue)
    }
  }

  const handleChangeExtraWVB = (newValue) => {
    if (newValue !== null) {
      setExtraWVB(newValue)

      if (isConditionalHoofdleiding && newValue === 'Ja') {
        setHoofdleiding('Nee')
        setErrors({ ...errors, hoofdleidingError: false })
      }

      if (isConditionalHoofdleiding && newValue === 'Nee') {
        setHoofdleiding('')
        setErrors({ ...errors, hoofdleidingError: false })
      }
    }
  }

  const validate = () => {
    const extraWVBTextError =
      (!extraWVBText || extraWVBText === '') && extraWVB === 'Ja'
    const extraWVBDueDateError = !extraWVBDueDate && extraWVB === 'Ja'
    const hoofdleidingTextError =
      (!hoofdleidingText || hoofdleidingText === '') && hoofdleiding === 'Ja'
    const hoofdleidingError =
      showHoofdleiding && !['Ja', 'Nee'].includes(hoofdleiding)

    setErrors({
      extraWVBTextError,
      extraWVBDueDateError,
      hoofdleidingTextError,
      hoofdleidingError,
    })

    return (
      !extraWVBTextError &&
      !extraWVBDueDateError &&
      !hoofdleidingTextError &&
      !hoofdleidingError
    )
  }

  const handleClickOpslaan = () => {
    const isValid = validate()
    const isDisciplineVerzamelingenValid =
      disciplineVerzamelingenFormRef.current?.checkValidity()

    if (isValid && isDisciplineVerzamelingenValid) {
      const extraWerkvoorbereiding: QuickscanQuestion = {
        id: 'Extra werkvoorbereiding',
        answer: false,
      }
      const hoofdleidingAanleggen: QuickscanQuestion = {
        id: 'hoofdleiding_aanleggen',
        answer: false,
      }

      if (extraWVB === 'Ja') {
        extraWerkvoorbereiding.text = extraWVBText
        extraWerkvoorbereiding.dueDate =
          subtractWeeks(extraWVBDueDate, WEEKS_BEFORE_START_UITVOERING) ||
          undefined
        extraWerkvoorbereiding.answer = true
      }

      if (hoofdleiding === 'Ja') {
        hoofdleidingAanleggen.text = hoofdleidingText
        hoofdleidingAanleggen.dueDate = addWeeks(new Date(), 1)
        hoofdleidingAanleggen.answer = true
      }

      if (extraWVB === 'Nee' && isConditionalHoofdleiding) {
        hoofdleidingAanleggen.answer = 'unanswered'
      }

      dispatch(
        saveEvent({
          id: aanvraagID,
          type: 'quickscan',
          source: 'manual',
          data: {
            questions: [
              {
                id: 'combiuitvoering',
                disciplines: createQuickscanCombiDisciplines(discVer),
              },
              extraWerkvoorbereiding,
              hoofdleidingAanleggen,
            ],
          },
        }),
      )
    }
  }

  return (
    <>
      <DialogTitle>Quickscan</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              component="form"
              ref={disciplineVerzamelingenFormRef}
            >
              <EditCombiUitvoeringPerDiscipline
                disciplineVerzamelingen={discVer}
                onChange={setDiscVer}
              />

              <Accordion
                expanded={extraWVB === 'Ja'}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary>
                  <Typography style={{ flex: 1 }}>
                    Extra werkvoorbereiding
                  </Typography>

                  <BooleanToggleGroup
                    value={extraWVB === 'Ja' ? true : false}
                    onChange={(value) =>
                      handleChangeExtraWVB(value === true ? 'Ja' : 'Nee')
                    }
                  />
                </AccordionSummary>

                <AccordionDetails>
                  {extraWVBDueDate && (
                    <PlanningConflict
                      geplandeUitvoeringsdatum={
                        geplandeUitvoeringsdatum as string
                      }
                      geplandeUitvoeringsdatumType={
                        geplandeUitvoeringsdatumType as string
                      }
                      opleverdatum={subtractWeeks(
                        extraWVBDueDate,
                        WEEKS_BEFORE_START_UITVOERING,
                      )}
                      combi={combi as string}
                    />
                  )}

                  <Typography>
                    Voor deze aanvraag doet u zelf de werkvoorbereiding. Geef
                    aub de start uitvoering datum en de reden aan.
                  </Typography>

                  <Grid
                    sx={{ pt: 2, pb: 2 }}
                    container
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>
                        De huidige geplande start uitvoering is:{' '}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Realisatie
                        date={geplandeUitvoeringsdatum as string}
                        type={geplandeUitvoeringsdatumType}
                      />
                    </Grid>
                  </Grid>

                  <Grid container sx={{ m: 1 }}>
                    <Grid item xs={6} sx={{ pl: 1 }}>
                      {!isNaN(new Date(extraWVBDueDate as any) as any) &&
                        extraWVBDueDate !== null && (
                          <TextField
                            label="Berekende opleverdatum"
                            variant="standard"
                            value={formatDate(
                              subtractWeeks(
                                extraWVBDueDate,
                                WEEKS_BEFORE_START_UITVOERING,
                              ),
                            )}
                            disabled
                          />
                        )}
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl>
                        <DatePicker
                          format={dateFormat}
                          label="Start uitvoering"
                          disablePast
                          value={extraWVBDueDate}
                          onChange={setExtraWVBDueDate}
                          displayWeekNumber
                          slotProps={{
                            field: { clearable: true },
                            textField: {
                              error: errors['extraWVBDueDateError'],
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                      value={extraWVBText}
                      multiline
                      rows={6}
                      label={`Tekst (${extraWVBText.length}/500)`}
                      onChange={(e) => setExtraWVBText(e.target.value)}
                      required
                      error={errors['extraWVBTextError']}
                      inputProps={{ maxLength: 500 }}
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              {showHoofdleiding && (
                <Accordion
                  expanded={hoofdleiding === 'Ja'}
                  TransitionProps={{
                    unmountOnExit: true,
                  }}
                >
                  <AccordionSummary>
                    <Typography style={{ flex: 1 }}>
                      Hoofdleiding aanleggen
                    </Typography>

                    <BooleanToggleGroup
                      value={hoofdleiding === 'Ja' ? true : false}
                      onChange={(value) =>
                        handleChangeHoofdleiding(value === true ? 'Ja' : 'Nee')
                      }
                    />
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography>
                      Voor deze aanvraag moet hoofdleiding aangelegd worden.
                      Geef aub de reden aan voor deze hoofdleiding
                    </Typography>

                    <FormControl style={{ width: '100%', marginTop: 10 }}>
                      <TextField
                        value={hoofdleidingText}
                        multiline
                        rows={6}
                        label={`Tekst (${hoofdleidingText.length}/500)`}
                        onChange={(e) => setHoofdleidingText(e.target.value)}
                        required
                        error={errors['hoofdleidingTextError']}
                        inputProps={{ maxLength: 500 }}
                      />
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Annuleren
        </Button>
        <Button onClick={handleClickOpslaan}>Opslaan</Button>
      </DialogActions>
    </>
  )
}
