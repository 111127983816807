import React from 'react'

import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material'

export default function VraagAntwoord({ product, beantwoordeVragen }) {
  return (
    <TableRow sx={{ borderColor: 'white', border: '2px solid white' }}>
      <TableCell colSpan={6}>
        {beantwoordeVragen
          .filter((vraag) => vraag.disciplineID === product.disciplineID)
          .map((vraag) => {
            return (
              <Box key={`vraag-${vraag.disciplineID}-${product.netbeheerder}`}>
                <Typography
                  sx={{
                    pl: 5,
                    pb: 2,
                    color: 'rgb(61, 108, 180)',
                    fontFamily: '"Aller", "Helvetica", "sans-serif"',
                    fontSize: 24,
                    fontWeight: 200,
                    letterSpacing: '.02em',
                    lineHeight: '120%',
                    display: 'block',
                  }}
                >{`Beantwoorde vragen ${product.netbeheerder}`}</Typography>
                {vraag.additioneleVragen.map((v) => {
                  return (
                    <Grid
                      key={`vraag-add-${vraag.disciplineID}-${v.vraagCode}`}
                      sx={{ p: 1, pl: 5, width: '100%' }}
                      container
                      spacing={7}
                    >
                      <Grid item xs={8}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {v.vraag}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{v.antwoord}</Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </Box>
            )
          })}
      </TableCell>
    </TableRow>
  )
}
