import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, Typography } from '@mui/material'

import StyledCard from '@components/StyledCard'

import { AanvraagDetail } from '../components/AanvraagDetail'
import { Detail } from '../components/Detail'
import { History } from '../components/History'
import { Status } from '../components/Status'
import { Werkzaamheden } from '../components/Werkzaamheden'

import { fetchCombiOpdracht, getCombiOpdracht } from './state'

export const CombiOpdracht: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: opdracht } = useSelector(getCombiOpdracht)

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(fetchCombiOpdracht(id))
    }
  }, [dispatch, id])

  const handleBackClick = () => {
    navigate(-1)
  }

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">
        {!opdracht.opdrachtId
          ? 'Combi opdracht niet gevonden'
          : `Combi opdracht ${id}`}
      </Typography>

      <Button
        sx={{ ml: 3, color: 'rgb(188, 53, 141)' }}
        onClick={() => handleBackClick()}
      >
        <ArrowBackIcon sx={{ fontSize: '16px' }} />
        Terug
      </Button>
      {opdracht.opdrachtId && (
        <>
          <StyledCard title="Gegevens">
            <Detail opdracht={opdracht} />
          </StyledCard>
          <StyledCard title="Status">
            <Status opdracht={opdracht} />
          </StyledCard>
          <StyledCard title="Werkzaamheden">
            <Werkzaamheden opdracht={opdracht} />
          </StyledCard>
          <StyledCard title="Aangevraagd">
            <AanvraagDetail opdracht={opdracht} />
          </StyledCard>
          <StyledCard title="Historie">
            <History history={opdracht.history} />
          </StyledCard>
        </>
      )}
    </Box>
  )
}
