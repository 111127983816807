import React from 'react'

import { Button } from '@mui/material'

export interface Props {
  onClick: (event: any, color: string) => void
  color: string
  count: number
}

export const Kpi = ({ onClick, color, count }: Props) => {
  const backgroundColor = color === 'amber' ? 'darkorange' : color

  return (
    <Button
      onClick={(event) => onClick(event, color)}
      size="small"
      sx={{
        color: 'white',
        borderRadius: '100%',
        backgroundColor,
        '&:hover': {
          backgroundColor,
          opacity: 0.5,
        },
      }}
    >
      {count}
    </Button>
  )
}
