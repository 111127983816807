import React, { FC } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import LaunchIcon from '@mui/icons-material/Launch'
import { TableCell, TableRow } from '@mui/material'

import { ProjectAdres } from '@components/Adres'
import StyledIconButton from '@components/StyledIconButton'
import StyledIconLink from '@components/StyledIconLink'
import { formatDate } from '@util/date-util'

export interface Props {
  project: any
  canCreate: boolean
  handleRemove: (projectId: string) => void
}

export const LinkedProject: FC<Props> = ({
  project,
  canCreate,
  handleRemove,
}) => {
  const { aanvraagID, type, aanvraagDatum, gebiedscodeLabel, statusText } =
    project

  return (
    <TableRow key={aanvraagID}>
      <TableCell>{aanvraagID}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{formatDate(aanvraagDatum)}</TableCell>
      <TableCell>{project && <ProjectAdres {...project} />}</TableCell>
      <TableCell>{gebiedscodeLabel}</TableCell>
      <TableCell>{statusText}</TableCell>
      <TableCell>
        <>
          <StyledIconLink
            icon={<LaunchIcon />}
            title={'Navigeer naar project'}
            href={`/aansluiting/project/${aanvraagID}`}
          />
          {canCreate && (
            <StyledIconButton
              title="Verwijder koppeling met project"
              onClick={() => handleRemove(aanvraagID)}
              icon={<DeleteIcon />}
            />
          )}
        </>
      </TableCell>
    </TableRow>
  )
}
