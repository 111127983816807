import React from 'react'

import LaunchIcon from '@mui/icons-material/Launch'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material'

import DisciplineIcon from '@components/DisciplineIcon'
import Label from '@components/Label'
import { MyListButton } from '@components/MyListButton'
import StyledIconLink from '@components/StyledIconLink'
import { formatDate } from '@util/date-util'
import { disciplines as disciplineMap } from '@util/discipline'

import Status from '../../../aansluiting/project/components/Status'
import type { HoofdleidingProject } from '../types'

import { Mijlpalen } from './Mijlpalen'

interface Props {
  project: HoofdleidingProject
  onClickMenu: (anchorEl: HTMLElement, project: HoofdleidingProject) => void
}

export const Row = ({ project, onClickMenu }: Props) => {
  const {
    aanvraagID,
    adres,
    disciplineVerzamelingen,
    gebiedscodeLabel,
    startUitvoeringWeek,
    externeReferentie,
    naam,
    typeWerk,
    gemeente,
    bijlagen,
    statusText,
    labels,
    planningChanged,
  } = project

  let opleverweekWerkmap = ''

  if (bijlagen?.length) {
    const werkmap = bijlagen.find(
      (x) => x.documentsoort === 'Werkmap' && x.status !== 'cancelled',
    )

    if (werkmap && werkmap.dueWeek) {
      opleverweekWerkmap = werkmap.dueWeek
    }
  }

  const handleMenu = (event) => {
    onClickMenu(event.currentTarget, project)
  }

  return (
    <TableRow>
      <TableCell>
        <Box>
          <Status project={project} />
        </Box>
      </TableCell>
      <TableCell>
        {naam}
        <br />
        <Typography variant="caption">
          <span>#</span>
          {aanvraagID}
        </Typography>
      </TableCell>
      <TableCell>
        {externeReferentie}
        <br />
        {labels && labels.length > 0 && (
          <div>
            {labels.map((l) => (
              <Label text={l} key={l} small />
            ))}
          </div>
        )}
      </TableCell>
      <TableCell>
        {adres}
        <br />
        <Typography variant="caption">gem. {gemeente}</Typography>
      </TableCell>
      <TableCell>
        {typeWerk}
        <br />
        <Typography variant="caption">{gebiedscodeLabel}</Typography>
      </TableCell>
      <TableCell>
        {disciplineMap.map((disc) => {
          return (
            <DisciplineIcon
              key={disc}
              disciplineCode={disc}
              active={disciplineVerzamelingen.some(
                (x) => x.disciplineCode === disc,
              )}
              toolTip={disciplineVerzamelingen
                .filter((x) => x.disciplineCode === disc)
                .map((x) => x.netbeheerderLabel)
                .join(', ')}
            ></DisciplineIcon>
          )
        })}
      </TableCell>
      <TableCell>
        {planningChanged ? formatDate(planningChanged) : ''}
      </TableCell>
      <TableCell>
        {startUitvoeringWeek}
        {startUitvoeringWeek && (
          <>
            <br />
            <Typography variant="caption">{`werkmap: ${opleverweekWerkmap}`}</Typography>
          </>
        )}
      </TableCell>
      <TableCell>{statusText}</TableCell>
      <TableCell>
        <Mijlpalen
          bijlagen={project.bijlagen}
          startUitvoeringWeek={startUitvoeringWeek}
        />
      </TableCell>
      <TableCell>
        <MyListButton
          type="hoofdleiding"
          active={project.myList}
          aanvraagID={project.aanvraagID}
        />

        <StyledIconLink
          title={`Navigeer naar project ${aanvraagID}`}
          icon={<LaunchIcon />}
          href={`/hoofdleiding/project/${aanvraagID}`}
        />

        <IconButton data-testid="buttonMenu" onClick={handleMenu}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
