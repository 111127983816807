import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import { getValuelists } from '@slices/valuelistsSlice'

import type { RootState } from '../../store'
import type { Filters as StateFilters } from '../types'

export interface Props {
  onChange: (filters: StateFilters) => void
  filters: StateFilters
  initialFilters: StateFilters
}

export const Filters: FC<Props> = ({
  onChange,
  filters,
  initialFilters,
}: Props) => {
  const {
    valuelists: {
      projectmelding: { status: statusOptions },
      gebieden,
    },
  } = useSelector((state: RootState) => ({
    valuelists: getValuelists(state),
  }))

  const [localFilters, setLocalFilters] = useState<StateFilters>(filters)
  const { statuscode, gebied } = localFilters

  const handleFilterChange = (filter) => {
    const newFilters = {
      ...localFilters,
      ...filter,
    }

    setLocalFilters(newFilters)
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={gebied}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={statusOptions}
          label="Status"
          filterName="statuscode"
          isOptionEqualToValue={(option, value) => option.label === value.label}
          value={statuscode}
          onFilterChange={handleFilterChange}
          multiple
        />
      </FilterGridItem>

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={() => resetFilters()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </FilterGridItem>
    </FilterGridContainer>
  )
}
