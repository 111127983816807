import React from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import LaunchIcon from '@mui/icons-material/Launch'
import { TableCell, TableRow, Tooltip } from '@mui/material'

import { MyListButton } from '@components/MyListButton'
import StyledIconLink from '@components/StyledIconLink'
import type { Activiteit as ActiviteitType } from '@models/Activiteit'
import { formatDate, formatDateTime, pastDay } from '@util/date-util'

const getEntityLabel = (type: string, projecttype?: string) => {
  return (
    projecttype ||
    {
      project: 'Project',
      projectdossier: 'Projectdossier',
    }[type]
  )
}

export interface Props {
  activiteit: ActiviteitType
}

export const Row = ({ activiteit }: Props) => {
  const {
    _id,
    type,
    text,
    changed,
    assignedTo,
    dueDate,
    entity: { id: entityId, type: entityType, projecttype, gebiedscodeLabel },
    completed,
    searchValues,
    myList,
  } = activiteit
  const { organisatie, date: created } = activiteit.created

  // An activiteit should only get the icon if it is not completed post end date
  // The ? : statement is to catch dueDate not being filled, which would throw an exception which would make it false
  const shouldMarkActiviteitAsExpired =
    pastDay(activiteit.dueDate) && !completed ? true : false

  return (
    <TableRow key={_id}>
      <TableCell>
        {shouldMarkActiviteitAsExpired && (
          <Tooltip title="De opleverdatum van deze activiteit is verstreken">
            <ErrorIcon sx={{ color: 'red' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{type}</TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{text} </TableCell>
      <TableCell>{formatDate(dueDate)}</TableCell>
      <TableCell>{formatDateTime(created)}</TableCell>
      <TableCell>{organisatie}</TableCell>
      <TableCell>{assignedTo && assignedTo.join(', ')}</TableCell>
      <TableCell>{formatDateTime(changed)}</TableCell>
      <TableCell>
        {getEntityLabel(entityType, projecttype)} <span>{entityId}</span>
      </TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>
        {searchValues?.adres}
      </TableCell>
      <TableCell>{gebiedscodeLabel}</TableCell>
      <TableCell>
        <MyListButton
          type="aansluiting"
          disabled={true}
          active={myList}
          aanvraagID={''}
        />

        <StyledIconLink
          icon={<LaunchIcon />}
          title={`Navigeer naar ${entityType} ${entityId}`}
          href={`/aansluiting/${entityType}/${entityId}`}
        />
      </TableCell>
    </TableRow>
  )
}
