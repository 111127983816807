import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { StatusconfigState } from './statusconfig.types'

const initialState: StatusconfigState = {
  processtappen: [],
  processtappenHoofdleiding: [],
}

export const fetchStatusconfig = createAsyncThunk(
  'fetch/statusconfig',
  async (_, { dispatch }) => {
    try {
      const result = await request({ url: '/rest/statusconfig', method: 'GET' })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen statusconfig', error))

      throw error
    }
  },
)

const statusconfigSlice = createSlice({
  name: 'statusconfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatusconfig.fulfilled, (state, action) => {
        state.processtappen = action.payload.processtappen
        state.processtappenHoofdleiding =
          action.payload.processtappenHoofdleiding
      })
      .addCase(fetchStatusconfig.rejected, (state) => {
        state.processtappen = []
        state.processtappenHoofdleiding = []
      })
  },
})

export const getProcesstappen = (state: RootState) =>
  state.statusconfig.processtappen

export const getProcesstappenHoofdleiding = (state: RootState) =>
  state.statusconfig.processtappenHoofdleiding

export default statusconfigSlice.reducer
