import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BusinessIcon from '@mui/icons-material/Business'
import InfoIcon from '@mui/icons-material/Info'
import LaunchIcon from '@mui/icons-material/Launch'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Tooltip, Typography } from '@mui/material'

import { MyListButton } from '@components/MyListButton'
import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledIconLink from '@components/StyledIconLink'
import {
  canCreateAssignedBijlageForProject,
  getPossibleAssigneesForProject,
  isUserVerantwoordelijkForProject,
} from '@util/project-util'

import { getUser } from '../../auth/state'
import { Activiteiten } from '../../entity/activiteiten/components/Activiteiten'
import {
  ENTITY_PROJECT,
  clearEntityActiviteiten,
  fetchActiviteitenForEntity,
  getActiviteitenForEntity,
} from '../../entity/activiteiten/state'
import { AssignedBijlagenByProcesstap } from '../../entity/bijlagen/components/AssignedBijlagenByProcesstap'
import {
  clearEntityBijlagen,
  fetchAssignedBijlagenForEntity,
  fetchBijlageTemplatesForEntity,
  fetchBijlagenForEntity,
} from '../../entity/bijlagen/state'
import { LinkedProjects } from '../../entity/projecten/components/LinkedProjects'
import { clearEntityProjecten } from '../../entity/projecten/state'
import {
  fetchCombiOpdrachtenForProject,
  getCombiOpdrachtenForProject,
} from '../../opdrachten/combi/state'
import { Klant } from '../klant'
import { clearKlantgegevens, fetchKlantgegevens } from '../klant/state'

import { DisciplineVerzamelingen } from './components/DisciplineVerzamelingen'
import { Gegevens } from './components/Gegevens'
import { Log } from './components/Log'
import { Objecten } from './components/Objecten'
import { Opdrachten } from './components/Opdrachten'
import Status from './components/Status'
import { ProjectOptions } from './components/options/ProjectOptions'
import { fetchLog, fetchProject, getProject } from './state'

export const Project: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    project: { isError, project },
    user,
    opdrachten,
    activiteiten,
  } = useSelector((state) => ({
    project: getProject(state),
    user: getUser(state),
    opdrachten: getCombiOpdrachtenForProject(state),
    activiteiten: getActiviteitenForEntity(state),
  }))

  const [showProjectLog, setShowProjectLog] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const {
    canViewActiviteiten,
    canViewKlantgegevens,
    canViewKlantVraagAntwoord,
  } = user

  useEffect(() => {
    if (id) {
      dispatch(fetchProject(id))
    }

    return () => {
      dispatch(clearKlantgegevens())
      dispatch(clearEntityBijlagen())
      dispatch(clearEntityActiviteiten())
      dispatch(clearEntityProjecten())
    }
  }, [dispatch, id])

  useEffect(() => {
    if (!project) return

    const { aanvraagID } = project
    if (aanvraagID === id) {
      const entity = { id: aanvraagID, type: ENTITY_PROJECT }

      dispatch(fetchCombiOpdrachtenForProject(aanvraagID))
      dispatch(fetchActiviteitenForEntity(entity))
      dispatch(fetchAssignedBijlagenForEntity(entity))
      dispatch(fetchBijlagenForEntity(entity))
      dispatch(fetchBijlageTemplatesForEntity(entity))
      dispatch(fetchLog(aanvraagID))

      if (canViewKlantgegevens) {
        dispatch(fetchKlantgegevens(aanvraagID))
      }
    }
  }, [dispatch, project, id, canViewKlantgegevens])

  const goBack = () => navigate(-1)
  const incorrectProjectType = project?.type === 'Hoofdleiding'

  if (!project || isError || incorrectProjectType) {
    return (
      <Box sx={{ width: '95%', m: 'auto' }}>
        <Typography variant="h2">
          {isError || incorrectProjectType
            ? 'Project niet gevonden'
            : 'Project'}
        </Typography>

        <Button sx={{ ml: 3, color: 'rgb(188, 53, 141)' }} onClick={goBack}>
          <ArrowBackIcon sx={{ fontSize: '16px' }} />
          Terug
        </Button>
      </Box>
    )
  }

  const {
    aanvraagID,
    type,
    linkedProjectdossier,
    lipurl,
    gebiedscode,
    gebiedscodeLabel,
    isActive,
    myList,
  } = project
  const entity = { id: aanvraagID, type: ENTITY_PROJECT }

  const assignees = getPossibleAssigneesForProject({ project })
  const canCreateAssignedBijlage = canCreateAssignedBijlageForProject({
    project,
    user,
    assignees,
  })
  const isVerantwoordelijke = isUserVerantwoordelijkForProject({
    user,
    project,
  })

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">
        {`Project ${aanvraagID}`}

        {lipurl && (
          <Tooltip title="Open in mijnaansluiting.nl">
            <LaunchIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(lipurl, '_blank')}
            />
          </Tooltip>
        )}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button sx={{ ml: 3, color: 'rgb(188, 53, 141)' }} onClick={goBack}>
          <ArrowBackIcon sx={{ fontSize: '16px' }} />
          Terug
        </Button>

        <MyListButton
          type={'aansluiting'}
          active={myList}
          aanvraagID={aanvraagID}
          asButton
        />
      </Box>

      <StyledCard
        title="Gegevens"
        buttonBar={
          <>
            {linkedProjectdossier && (
              <StyledIconLink
                title={`Open projectdossier: ${linkedProjectdossier}`}
                href={`/projectdossier/${linkedProjectdossier}`}
                icon={<BusinessIcon />}
              />
            )}

            <Status project={project} placement={'right'} />

            <StyledIconButton
              title="Opties"
              onClick={(ev) => setAnchorEl(ev.currentTarget)}
              icon={<MoreVertIcon />}
            />

            <StyledIconButton
              title={'Project log'}
              onClick={() => setShowProjectLog(!showProjectLog)}
              icon={<InfoIcon />}
            />
          </>
        }
      >
        <Gegevens project={project} />
      </StyledCard>

      {canViewActiviteiten && (
        <StyledCard title="Activiteiten">
          <Activiteiten
            activiteiten={activiteiten}
            entity={{
              id: aanvraagID,
              type: ENTITY_PROJECT,
              gebiedscode,
              gebiedscodeLabel,
              isActive,
              projecttype: type,
            }}
            entityProps={project}
            assignees={assignees}
          />
        </StyledCard>
      )}

      <AssignedBijlagenByProcesstap
        user={user}
        entity={entity}
        assignees={assignees}
        showCreateAssignedBijlage={canCreateAssignedBijlage}
        projectStatus={project.statusText}
        isVerantwoordelijke={isVerantwoordelijke}
      />

      {canViewKlantgegevens && <Klant aanvraagID={aanvraagID} />}

      <DisciplineVerzamelingen project={project} />

      <Objecten
        project={project}
        showVraagAntwoord={!!canViewKlantVraagAntwoord}
      />

      <Opdrachten opdrachten={opdrachten} />

      {project.type === 'Aanvraag' && (
        <LinkedProjects
          entity={entity}
          isVerantwoordelijke={isVerantwoordelijke}
          linkedProjectIds={project.linkedProjects}
        />
      )}

      <Log open={showProjectLog} onClose={() => setShowProjectLog(false)} />
      <ProjectOptions
        project={project}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </Box>
  )
}
