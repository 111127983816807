import React from 'react'

import { initialState as initialStateCombiOpdrachten } from '../../../../opdrachten/combi/state'
import { initialState as initialStateProjecten } from '../../../projecten/state'
import type { FiltersKpis, Kpi as KpiType } from '../../types'
import { DashboardCard } from '../DashboardCard'

import { Kpi } from './Kpi'

export interface Props {
  kpi: KpiType
  showKpiActions: boolean
  filters: FiltersKpis
  onClickTile: (query: any, url: string) => void
}

export const Tile = ({ kpi, showKpiActions = false, filters, onClickTile }) => {
  const { statusText, total, red, amber, green } = kpi
  const { gebied, netbeheerder, labels, myList } = filters

  const onClick = () => {
    if (kpi.code) {
      onClickTile(
        {
          filters: {
            ...initialStateCombiOpdrachten.query.filters,
            gebied,
            netbeheerder,
            labels,
            werkbak: { code: kpi.code, label: kpi.statusText },
            myList,
          },
          page: initialStateCombiOpdrachten.query.page,
          sort: initialStateCombiOpdrachten.query.sort,
        },
        '/combiopdrachten',
      )
    } else {
      onClickTile(
        {
          filters: {
            ...initialStateProjecten.query.filters,
            netbeheerder,
            gebied,
            labels,
            myList,
            processtap: kpi.statusText,
          },
          page: initialStateProjecten.query.page,
          sort: initialStateProjecten.query.sort,
        },
        '/aansluiting/projecten',
      )
    }
  }

  const onClickKpi = (event: any, color: string) => {
    event.stopPropagation()

    if (kpi.code) {
      onClickTile(
        {
          filters: {
            ...initialStateCombiOpdrachten.query.filters,
            gebied,
            netbeheerder,
            labels,
            werkbak: { code: kpi.code, label: kpi.statusText },
            kpi: color,
            myList,
          },
          page: initialStateCombiOpdrachten.query.page,
          sort: initialStateCombiOpdrachten.query.sort,
        },
        '/combiopdrachten',
      )
    } else {
      onClickTile(
        {
          filters: {
            ...initialStateProjecten.query.filters,
            netbeheerder,
            gebied,
            labels,
            myList,
            processtap: kpi.statusText,
            kpi: color,
          },
          page: initialStateProjecten.query.page,
          sort: initialStateProjecten.query.sort,
        },
        '/aansluiting/projecten',
      )
    }
  }

  return (
    <DashboardCard
      onClick={onClick}
      label={statusText}
      count={total}
      actions={
        showKpiActions && (
          <>
            {red > 0 && <Kpi color={'red'} count={red} onClick={onClickKpi} />}
            {amber > 0 && (
              <Kpi color={'amber'} count={amber} onClick={onClickKpi} />
            )}
            {green > 0 && (
              <Kpi color={'green'} count={green} onClick={onClickKpi} />
            )}
          </>
        )
      }
    />
  )
}
