import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import {
  Autocomplete,
  Chip,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledIconLink from '@components/StyledIconLink'
import { formatDateTime } from '@util/date-util'

import { getUser } from '../../auth/state'
import type { Type } from '../../core/slices/valuelists.types'
import { updateProjectdossier } from '../state'
import type { GroupedOption, Projectdossier } from '../types'

import { CustomEditInputComponent } from './CustomEditInputComponent'

const createOptionsForEdit = (
  organisaties: GroupedOption[],
  items: any[] = [],
) => {
  const toReturn: GroupedOption[] = []

  organisaties.forEach((x: GroupedOption) => {
    if (items.includes(x.label)) toReturn.push(x)
  })

  return toReturn
}

export interface Props {
  dossier: Projectdossier
  organisaties: GroupedOption[]
  statussen: Type[]
}

export const DetailGrid: FC<Props> = ({ dossier, organisaties, statussen }) => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const { combi } = useSelector(getUser)

  // Only editable by combi users
  const canUserEdit = combi && combi === dossier.aanlegger.organisatie

  const [name, setName] = useState('')
  const [referenceId, setReferenceId] = useState('')
  const [indienerNaam, setIndienerNaam] = useState('')
  const [indienerOrg, setIndienerOrg] = useState('')
  const [location, setLocation] = useState('')
  const [contractgebied, setContractgebied] = useState<any>(null)
  const [errors, setErrors] = useState({})
  const [createdAt, setCreatedAt] = useState('')
  const [createdBy, setCreatedBy] = useState('')
  const [involvedParties, setInvolvedParties] = useState<any[]>([])
  const [status, setStatus] = useState<any>({})
  const originalDossier = dossier

  const onIsEditClick = () => {
    setIsEdit(!isEdit)
  }

  const setOriginalValues = useCallback(
    (dossier: Projectdossier) => {
      setErrors({})
      const {
        naam,
        externeReferentie,
        gebiedscode,
        gebiedscodeLabel,
        indiener,
        locatieOmschrijving,
        aanlegger,
        status,
        statusText,
        organisaties: organisatiesFromApi,
      } = dossier

      setName(naam)
      setReferenceId(externeReferentie)
      setContractgebied({ code: gebiedscode, label: gebiedscodeLabel })
      setIndienerNaam(indiener.naam)
      setIndienerOrg(indiener.organisatie)
      setLocation(locatieOmschrijving)
      setCreatedBy(aanlegger.organisatie)
      setCreatedAt(aanlegger.date)
      setStatus({ code: status, label: statusText })
      setInvolvedParties(
        createOptionsForEdit(organisaties, organisatiesFromApi),
      )
    },
    [organisaties],
  )

  useEffect(() => {
    // If the user has exited edit mode, set the original values
    // If the user submitted to the API, this will automatically update because originalDossier will be updated too
    if (!isEdit) {
      setOriginalValues(originalDossier)
    }
  }, [isEdit, originalDossier, setOriginalValues])

  // If the dossier is updated, turn off edit mode and overwrite the original values
  useEffect(() => {
    setIsEdit(false)
    setOriginalValues(dossier)
  }, [dossier, setOriginalValues])

  const validate = () => {
    const nameError = !name
    const indienerNaamError = !indienerNaam
    const indienerOrgError = !indienerOrg
    const locationError = !location
    const contractgebiedError = !contractgebied
    const statusError = !status

    setErrors({
      nameError,
      indienerNaamError,
      indienerOrgError,
      locationError,
      contractgebiedError,
      statusError,
    })

    return !(
      nameError ||
      indienerNaamError ||
      indienerOrgError ||
      contractgebiedError ||
      locationError ||
      statusError
    )
  }

  const handleSubmitClick = () => {
    if (validate()) {
      const projectdossierInfo = {
        naam: name,
        externeReferentie: referenceId,
        locatieOmschrijving: location,
        organisaties: involvedParties.map((x) => x.label),
        indiener: {
          naam: indienerNaam,
          organisatie: indienerOrg,
        },
        status: status.code,
      }

      const { projectdossierId } = dossier

      dispatch(
        updateProjectdossier({ id: projectdossierId, projectdossierInfo }),
      )
    }
  }

  const handleInvoledPartiesChange = (value) => {
    // If the combi is not in the array (aka if someone tried to unselect the combi)
    // Do not allow them to continue with the operation
    if (value.length === 0) {
      setInvolvedParties(involvedParties.filter((x) => x.label === combi))
    } else if (!value.find((x) => x.label === combi)) {
      return
    } else {
      setInvolvedParties(value)
    }
  }

  return (
    <StyledCard
      title="Gegevens"
      buttonBar={
        <>
          {canUserEdit ? (
            isEdit ? (
              <>
                <StyledIconButton
                  title="Stop met bewerken"
                  onClick={onIsEditClick}
                  icon={<CancelIcon />}
                />
                <StyledIconButton
                  title="Sla uw bewerkingen op"
                  onClick={handleSubmitClick}
                  icon={<SaveIcon />}
                />
              </>
            ) : (
              <StyledIconButton
                title="Projectdossier bewerken"
                onClick={onIsEditClick}
                icon={<EditIcon />}
              />
            )
          ) : (
            <></>
          )}
          {dossier.projectmeldingId && combi && (
            <StyledIconLink
              title="Navigeer naar de gekoppelde projectmelding"
              href={`/vooraanmelding/${dossier.projectmeldingId}`}
              icon={<SwapHorizontalCircleIcon />}
            />
          )}
        </>
      }
    >
      <Grid container spacing={3} sx={{ m: 'auto', width: '95%', p: 1 }}>
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Dossiernaam</b>
          </Typography>
          <CustomEditInputComponent
            isEdit={isEdit}
            value={name}
            onChange={setName}
            error={errors['nameError']}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Contractgebied</b>
          </Typography>
          <Typography variant="body2">{contractgebied?.label}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Externe referentie</b>
          </Typography>
          <CustomEditInputComponent
            isEdit={isEdit}
            value={referenceId}
            onChange={setReferenceId}
            error={errors['referenceIdError']}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Betrokken partijen</b>
          </Typography>
          {isEdit ? (
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                data-testid="autocomplete-betrokkenpartijen"
                options={organisaties}
                value={involvedParties}
                isOptionEqualToValue={(x, y) =>
                  x.label === y.label && x.group === y.group
                }
                getOptionLabel={(item) => item.label}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    aria-label={'Betrokken partijen'}
                    error={errors['']}
                  />
                )}
                multiple
                onChange={(_, e) => {
                  handleInvoledPartiesChange(e)
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={combi === option.label}
                    />
                  ))
                }
                groupBy={(item) => item.group}
              />
            </FormControl>
          ) : (
            // TODO this field shortly flickers when the involvedParties are updated
            // this is due to it re-retrieving the organisations because the dossier is updated with the new value
            // meaning that in the index.js the dossier changes, meaning that it sends the fetchOrganisations request
            <Typography variant="body2">
              {involvedParties.map((x) => x.label).join(', ')}
            </Typography>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Aangemaakt door </b>
          </Typography>
          <Typography>{createdBy}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Naam indiener </b>
          </Typography>
          <CustomEditInputComponent
            isEdit={isEdit}
            value={indienerNaam}
            onChange={setIndienerNaam}
            error={errors['indienerNaamError']}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Aangemaakt op </b>
          </Typography>
          <Typography>{formatDateTime(createdAt)}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Organisatie indiener </b>
          </Typography>
          <CustomEditInputComponent
            isEdit={isEdit}
            value={indienerOrg}
            onChange={setIndienerOrg}
            error={errors['indienerOrgError']}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            <b>Status</b>
          </Typography>
          {isEdit ? (
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                options={statussen}
                value={status}
                onChange={(_, e) => {
                  setStatus(e)
                }}
                isOptionEqualToValue={(x, y) => x.code === y.code}
                getOptionLabel={(status) => status.label}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    aria-label="Status"
                    error={errors['statusError']}
                  />
                )}
              />
            </FormControl>
          ) : (
            <Typography>{status.label}</Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1">
            <b>Beschrijving {isEdit && `(${location.length}/500)`} </b>
          </Typography>
          <CustomEditInputComponent
            isEdit={isEdit}
            value={location}
            onChange={setLocation}
            error={errors['locationError']}
            inputProps={{ maxLength: 500 }}
          />
        </Grid>
      </Grid>
    </StyledCard>
  )
}
