const disciplines = ['E', 'G', 'W', 'M', 'R']
const filterByDisciplineCode = (arr, disciplineCode) =>
  arr && arr.filter((o) => o.disciplineCode === disciplineCode)
const getProducts = (arr) => (disciplineCode) =>
  filterByDisciplineCode(arr, disciplineCode)

const getProductsOrDefault = (arr) => (disciplineCode) => {
  const products = filterByDisciplineCode(arr, disciplineCode)

  return products && products.length > 0
    ? products
    : [{ disciplineCode, progress: 0 }]
}

export { disciplines, getProductsOrDefault, getProducts }
