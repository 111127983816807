import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { FiltersKpis, FiltersWerklijsten, State } from './types'

export const initialState: State = {
  selectedTab: 0,
  kpis: {
    items: [],
    query: {
      filters: {
        gebied: [],
        netbeheerder: null,
        coordinerende: null,
        labels: [],
        myList: false,
      },
    },
  },
  werklijsten: {
    data: {
      acties: [],
      activiteiten: [],
      bijlagen: [],
    },
    query: {
      filters: {
        organisatie: null,
        gebied: [],
        fromGeplandeUitvoeringsdatum: null,
        toGeplandeUitvoeringsdatum: null,
        labels: [],
        myList: false,
      },
    },
    status: 'idle',
  },
}

export const createFiltersKpis = ({
  gebied,
  netbeheerder,
  labels,
  myList,
}: FiltersKpis) => {
  const filters: IFilter[] = []

  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (netbeheerder)
    filters.push({ name: 'netbeheerders', value: netbeheerder, operator: 'eq' })
  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchKpi = createAsyncThunk(
  'fetch/dashboard/aansluiting/kpi',
  async (payload: any, { dispatch }) => {
    try {
      const result = await request({
        url: '/rest/dashboard/aansluiting/kpi',
        method: 'POST',
        data: { filters: createFiltersKpis(payload.filters) },
      })

      return result
    } catch (error: any) {
      dispatch(apiError("Fout bij ophalen kpi's", error))

      throw error
    }
  },
)

export const createFiltersWerklijsten = ({
  organisatie,
  gebied,
  fromGeplandeUitvoeringsdatum,
  toGeplandeUitvoeringsdatum,
  labels,
  myList,
}: FiltersWerklijsten) => {
  const filters: IFilter[] = []

  if (organisatie)
    filters.push({
      name: 'organisatie',
      value: organisatie.label,
      operator: 'eq',
    })

  if (gebied && gebied.length > 0) {
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  }

  if (fromGeplandeUitvoeringsdatum) {
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: startOfDay(fromGeplandeUitvoeringsdatum),
      operator: 'gte',
    })
  }

  if (toGeplandeUitvoeringsdatum) {
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: endOfDay(toGeplandeUitvoeringsdatum),
      operator: 'lte',
    })
  }

  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  filters.push({
    name: 'isActive',
    value: true,
    operator: 'eq',
  })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchWerklijsten = createAsyncThunk(
  'fetch/dashboard/aansluiting/werklijst',
  async (payload: any, { dispatch }) => {
    const { filters } = payload

    try {
      const result = await request({
        url: '/rest/dashboard/aansluiting/werklijst',
        method: 'POST',
        data: { filters: createFiltersWerklijsten(filters) },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen werklijsten', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    storeSelectedTabDashboard: (state, action) => {
      state.selectedTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKpi.fulfilled, (state, action) => {
        state.kpis.items = action.payload
      })
      .addCase(fetchKpi.pending, (state, action) => {
        state.kpis.query = action.meta.arg
      })
      .addCase(fetchKpi.rejected, (state) => {
        state.kpis.items = []
      })
      .addCase(fetchWerklijsten.pending, (state, action) => {
        state.werklijsten.status = 'loading'
        state.werklijsten.data = initialState.werklijsten.data
        state.werklijsten.query.filters = action.meta.arg.filters
      })
      .addCase(fetchWerklijsten.fulfilled, (state, action) => {
        state.werklijsten.status = 'idle'
        state.werklijsten.data.acties = action.payload.acties
        state.werklijsten.data.bijlagen = action.payload.bijlagen
        state.werklijsten.data.activiteiten = action.payload.activiteiten
      })
      .addCase(fetchWerklijsten.rejected, (state) => {
        state.werklijsten.status = 'error'
        state.werklijsten.data = initialState.werklijsten.data
      })
  },
})

export const getKpi = (state: RootState) => ({
  data: state.dashboard.kpis.items,
  query: state.dashboard.kpis.query,
})
export const getWerklijstFilters = (state: RootState) =>
  state.dashboard.werklijsten.query.filters

export const getWerklijsten = (state: RootState) =>
  state.dashboard.werklijsten.data
export const getSelectedTabDashboard = (state: RootState) =>
  state.dashboard.selectedTab

export const { reducer } = slice
export const { storeSelectedTabDashboard } = slice.actions
