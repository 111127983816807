import React, { FC, PropsWithChildren } from 'react'

import { Grid } from '@mui/material'
import type { GridTypeMap } from '@mui/system'

type FilterGridItemProps = GridTypeMap['props'] &
  PropsWithChildren<{ minColumns?: 'double' | 'full' }>

/** This component is just a small abstraction layer over the actual MUI
 * component. It is designed to be overridable by everything that the MUI
 * `<Grid item />` normally takes as props, but has some defaults baked into it.
 * The only non-overridable prop is that this is an `item`. That can not be
 * changed. */
const FilterGridItem: FC<FilterGridItemProps> = ({
  minColumns,
  children,
  ...rest
}) => {
  if (minColumns === 'full') {
    return (
      <Grid xs={12} {...rest} item>
        {children}
      </Grid>
    )
  }

  if (minColumns === 'double') {
    return (
      <Grid xs={12} md={8} lg={6} xl={4} {...rest} item>
        {children}
      </Grid>
    )
  }

  return (
    <Grid xs={6} md={4} lg={3} xl={2} {...rest} item>
      {children}
    </Grid>
  )
}

export default FilterGridItem
