import React from 'react'

import LaunchIcon from '@mui/icons-material/Launch'
import { TableCell, TableRow } from '@mui/material'

import StyledIconLink from '@components/StyledIconLink'
import { formatDate } from '@util/date-util'

import type { ProjectmeldingSearchResult } from '../types'

export interface Props {
  projectmelding: ProjectmeldingSearchResult
}

export const Row = ({ projectmelding }: Props) => {
  const {
    id,
    numberOfObjects,
    companyName,
    projectName,
    projectCity,
    created,
    status,
  } = projectmelding

  return (
    <TableRow key={id}>
      <TableCell>{projectName}</TableCell>
      <TableCell>{status?.name && status.name}</TableCell>
      <TableCell>{companyName}</TableCell>
      <TableCell>{numberOfObjects}</TableCell>
      <TableCell>{formatDate(created)}</TableCell>
      <TableCell>{projectCity}</TableCell>
      <TableCell>
        <StyledIconLink
          title={`Navigeer naar vooraanmelding: ${projectName}`}
          icon={<LaunchIcon />}
          href={`/vooraanmelding/${projectmelding.id}`}
        />
      </TableCell>
    </TableRow>
  )
}
