import React, { useState } from 'react'

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import { Grid, IconButton, Popover, Tooltip } from '@mui/material'

export default function KPIPopover({
  onFilterChange,
  kpi,
  iconClass = '',
  isActiviteiten = false,
  tooltips = {} as any,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const openPopover = Boolean(anchorEl)
  const colorKpi =
    kpi && kpi !== '' ? (kpi === 'amber' ? 'darkorange' : kpi) : ''

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleClickKpi = (kpi) => {
    onFilterChange({ kpi })
    handlePopoverClose()
  }

  return (
    <>
      <Tooltip title="">
        <IconButton
          data-testid="kpibutton"
          sx={{
            color: iconClass === 'amber' ? 'darkorange' : iconClass,
          }}
          aria-owns={openPopover ? 'click-popover' : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
        >
          <ErrorRoundedIcon sx={{ color: colorKpi }} />
        </IconButton>
      </Tooltip>
      <Popover
        id="click-popover"
        sx={{
          '& .MuiPopover-paper': {
            elevation: 3,
          },
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container direction="row">
          <Tooltip title={tooltips.red || ''}>
            <IconButton
              data-testid="kpi-red-button"
              onClick={() => handleClickKpi('red')}
            >
              <ErrorRoundedIcon sx={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={tooltips.amber || ''}>
            <IconButton
              data-testid="kpi-amber-button"
              onClick={() => handleClickKpi('amber')}
            >
              <ErrorRoundedIcon sx={{ color: 'darkorange' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={tooltips.green || ''}>
            <IconButton
              data-testid="kpi-green-button"
              onClick={() => handleClickKpi('green')}
            >
              <ErrorRoundedIcon sx={{ color: 'green' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={tooltips.grey || ''}>
            <IconButton
              data-testid="kpi-grey-button"
              onClick={() => handleClickKpi('')}
            >
              <ErrorRoundedIcon />
            </IconButton>
          </Tooltip>
          {isActiviteiten && (
            <Tooltip title={tooltips.noduedate || ''}>
              <IconButton
                data-testid="kpi-noduedate-button"
                onClick={() => handleClickKpi('noduedate')}
              >
                <EventBusyIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Popover>
    </>
  )
}
