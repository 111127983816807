import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'

import { getUser } from '../../auth/state'
import { getValuelists } from '../../core/slices/valuelistsSlice'
import type { RootState } from '../../store'
import type { Filters as StateFilters } from '../types'

export interface Props {
  onChange: (filters: StateFilters) => void
  filters: StateFilters
  initialFilters: StateFilters
  onCreate: () => void
}

export const Filters: FC<Props> = ({
  onChange,
  filters,
  initialFilters,
  onCreate,
}) => {
  const {
    valuelists: {
      gebieden,
      projectdossier: { status: statussen },
    },
    user: { canCreateProjectdossier },
  } = useSelector((state: RootState) => ({
    valuelists: getValuelists(state),
    user: getUser(state),
  }))

  const [localFilters, setLocalFilters] = useState(filters)
  const { gebied, status, open } = localFilters

  const handleFilterChange = (filter, apply) => {
    const newFilters = { ...localFilters, ...filter }

    setLocalFilters(newFilters)
    if (apply) onChange(newFilters)
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={gebied}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={statussen}
          label="Status"
          filterName="status"
          value={status}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={() => resetFilters()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={
            open
              ? 'Klik om ook afgesloten projectdossiers te tonen'
              : 'Klik om alleen open projectdossiers te tonen'
          }
        >
          <IconButton
            data-testid="openbutton"
            onClick={() => handleFilterChange({ open: !open }, true)}
          >
            {open ? <AlarmRoundedIcon /> : <AlarmOffRoundedIcon />}
          </IconButton>
        </Tooltip>

        {canCreateProjectdossier && (
          <Tooltip title="Nieuw projectdossier">
            <IconButton aria-label="Nieuw projectdossier" onClick={onCreate}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}

        <LocalStorageButtons
          name="project_dossiers"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
