import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { Toolbar } from '@mui/material'

import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledTable from '@components/StyledTable'
import type { Contactpersoon as ContactpersoonType } from '@models/Contactpersoon'
import type { Entity } from '@models/Entity'

import {
  getContactpersoonDialogData,
  getContactpersoonDialogOpen,
  openContactpersoonDialog,
} from '../state'

import { Contactpersoon } from './Contactpersoon'
import { ContactpersoonDialog } from './ContactpersoonDialog'

const headerCells = [
  { id: 'naam', label: 'Naam' },
  { id: 'rol', label: 'Rol' },
  { id: 'telefoonnummer', label: 'Telefoonnummer' },
  { id: 'email', label: 'Email' },
  { id: 'actions', label: 'Acties', fixed: 120 },
]

const cancelledFilter = (x) => x.status !== 'cancelled'

interface Props {
  contactpersonen: ContactpersoonType[]
  entity: Entity
  isVerantwoordelijke: boolean
}

export const Contactpersonen: FC<Props> = ({
  contactpersonen,
  entity,
  isVerantwoordelijke,
}) => {
  const dispatch = useDispatch()

  const [showCancelled, setShowCancelled] = useState(false)

  const { contactpersoonDialogOpen, contactpersoonDialogData } = useSelector(
    (state) => ({
      contactpersoonDialogOpen: getContactpersoonDialogOpen(state),
      contactpersoonDialogData: getContactpersoonDialogData(state),
    }),
  )

  const handleClick = (
    type: 'edit' | 'create' | 'delete',
    contactpersoon?: ContactpersoonType,
  ) => {
    dispatch(
      openContactpersoonDialog({
        entity,
        type,
        contactpersoon,
      }),
    )
  }

  return (
    <>
      <StyledCard title="Contactpersonen">
        <Toolbar
          sx={{
            backgroundColor: '#F7F7F7',
            borderBottom: '1px solid #E0E0E0',
            justifyContent: 'flex-end',
          }}
        >
          {isVerantwoordelijke && (
            <StyledIconButton
              icon={<PersonAddAlt1Icon />}
              title="Aanmaken contactpersoon"
              onClick={() => handleClick('create')}
            />
          )}
          <StyledIconButton
            title={
              showCancelled
                ? 'Laat alleen actieve contactpersonen zien'
                : 'Laat ook verwijderde contactpersonen zien'
            }
            icon={
              showCancelled ? <LibraryBooksIcon /> : <LibraryAddCheckIcon />
            }
            onClick={() => setShowCancelled(!showCancelled)}
          />
        </Toolbar>
        <StyledTable
          variant="details"
          headerCells={headerCells}
          {...({} as any)}
        >
          {contactpersonen &&
            contactpersonen
              .filter(showCancelled ? () => true : cancelledFilter)
              .map((contactpersoon) => {
                return (
                  <Contactpersoon
                    key={contactpersoon._id}
                    contactpersoon={contactpersoon}
                    onClick={handleClick}
                    isVerantwoordelijke={isVerantwoordelijke}
                  />
                )
              })}
        </StyledTable>
      </StyledCard>
      <ContactpersoonDialog
        open={contactpersoonDialogOpen}
        data={contactpersoonDialogData}
      />
    </>
  )
}
