import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'

import type { Gebied } from '@models/Gebied'
import { getValuelists } from '@slices/valuelistsSlice'

import { isSaveEventPending, saveEvent } from '../../state'
import type { Project } from '../../types'

interface Props {
  project: Project
  handleClose: () => void
}

export const Gebiedswissel: FC<Props> = ({ handleClose, project }: Props) => {
  const dispatch = useDispatch()
  const { gebieden } = useSelector(getValuelists)
  const [gebied, setGebied] = React.useState<Gebied | null>(null)
  const [errors, setErrors] = useState({ gebiedError: false })
  const isLoading = useSelector(isSaveEventPending)

  const handleClick = () => {
    if (!gebied) {
      setErrors({ gebiedError: true })
      return
    }

    dispatch(
      saveEvent({
        id: project.aanvraagID,
        type: 'gebiedswissel',
        source: 'manual',
        data: {
          gebiedscode: gebied.code,
          sourceGebiedscode: project.gebiedscode,
        },
      }),
    )
  }

  return (
    <>
      <DialogTitle>Wisselen van gebied</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Kies het gebied waar het project heen gewisseld moet worden. Na het
          opslaan zal het max 5 minuten duren voordat het project volledig
          gewisseld is
        </DialogContentText>
        <FormControl sx={{ width: '100%', p: 1 }}>
          <Autocomplete
            options={gebieden}
            value={gebied}
            onChange={(_, e) => setGebied(e)}
            getOptionLabel={(gebied) => `${gebied.code} - ${gebied.label}`}
            isOptionEqualToValue={(x, y) => x.code === y.code}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Doel gebied"
                error={errors['gebiedError']}
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>{' '}
        <Button
          disabled={isLoading}
          onClick={() => handleClick()}
          color="primary"
        >
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
