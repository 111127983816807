import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { State } from './types'

const AANSLUITING_PROJECT_TYPES = ['Aanvraag', 'VOG', 'SAN']

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: {
      gebied: [],
      projecttype: [],
      documentsoort: [],
      assignedTo: [],
      fromWeek: '',
      toWeek: '',
      fromGeplandeUitvoeringsdatum: null,
      toGeplandeUitvoeringsdatum: null,
      labels: [],
      review: false,
      myList: false,
    },
    sort: { sortBy: 'created.date', sortDirection: 'desc' },
    page: { skip: 0, limit: 25 },
  },
}

export const createFilters = ({
  gebied,
  projecttype,
  documentsoort,
  assignedTo,
  toWeek,
  fromWeek,
  fromGeplandeUitvoeringsdatum,
  toGeplandeUitvoeringsdatum,
  labels,
  review,
  myList,
}) => {
  const filters: IFilter[] = []

  if (documentsoort && documentsoort.length > 0)
    filters.push({
      name: 'documentsoort',
      value: documentsoort,
      operator: 'in',
    })
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'entity.gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  filters.push({
    name: 'entity.projecttype',
    value:
      projecttype && projecttype.length > 0
        ? projecttype.map(({ code }) => code)
        : AANSLUITING_PROJECT_TYPES,
    operator: 'in',
  })
  if (assignedTo && assignedTo.length > 0)
    filters.push({
      name: 'assignedTo',
      value: assignedTo.map(({ label }) => label),
      operator: 'in',
    })
  if (fromWeek)
    filters.push({ name: 'dueWeek', value: fromWeek, operator: 'gte' })
  if (toWeek) filters.push({ name: 'dueWeek', value: toWeek, operator: 'lte' })
  if (fromGeplandeUitvoeringsdatum) {
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: startOfDay(fromGeplandeUitvoeringsdatum),
      operator: 'gte',
    })
  }
  if (toGeplandeUitvoeringsdatum) {
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: endOfDay(toGeplandeUitvoeringsdatum),
      operator: 'lte',
    })
  }

  const status = review ? ['review'] : ['assigned', 'uploaded', 'review']
  filters.push({
    name: 'status',
    value: status,
    operator: 'in',
  })

  filters.push({
    name: 'isActive',
    value: true,
    operator: 'eq',
  })

  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchAssignedBijlagen = createAsyncThunk(
  'fetch/assigned/bijlagen',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/bijlageassigned/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van bijlagen', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'assignedBijlagen',
  initialState,
  reducers: {
    storeQuery: (state, action) => {
      state.query = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedBijlagen.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchAssignedBijlagen.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchAssignedBijlagen.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
  },
})

export const getAssignedBijlagen = (state: RootState) => ({
  data: state.assignedBijlagen.data,
  isLoading: state.assignedBijlagen.status === 'loading',
  query: state.assignedBijlagen.query,
})

export const { reducer } = slice
export const { storeQuery } = slice.actions
