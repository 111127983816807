import React from 'react'

import { Typography } from '@mui/material'

import AlertBlock from '@components/StyledAlert'
import { formatDate, isBefore, subtractWeeks } from '@util/date-util'
import { getWeeksBeforeStartUitvoering } from '@util/planningsconflict-util'

interface Props {
  geplandeUitvoeringsdatum: string
  geplandeUitvoeringsdatumType: string
  opleverdatum: any
  combi?: string
}

export default function PlanningConflict({
  geplandeUitvoeringsdatum,
  geplandeUitvoeringsdatumType,
  opleverdatum,
  combi,
}: Props) {
  const amountOfWeeks = getWeeksBeforeStartUitvoering(combi)
  const uitvoering = new Date(geplandeUitvoeringsdatum)
  const oplever = new Date(opleverdatum)
  const show =
    isBefore(subtractWeeks(uitvoering, amountOfWeeks) as any, oplever) &&
    geplandeUitvoeringsdatumType === 'Planning door uitvoerende aannemer'

  return (
    <>
      {show && (
        <AlertBlock
          severity={'warning'}
          title={'Deze keuzes van data leiden tot een planning conflict' as any}
        >
          <Typography>
            {`De werkvoorbereiding moet ${amountOfWeeks} weken (${formatDate(
              subtractWeeks(uitvoering, amountOfWeeks),
            )}) voor de geplande start uitvoering (${formatDate(
              geplandeUitvoeringsdatum,
            )}) afgerond zijn.`}
          </Typography>
        </AlertBlock>
      )}
    </>
  )
}
