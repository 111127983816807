import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, Button, Typography } from '@mui/material'

import { checkSession, login } from '../../gateways/auth0'
import { isSessionChecked, storeSessionChecked, storeToken } from '../state'

import { GeneralInfoCard } from './GeneralInfoCard'
import { LinkGrid } from './LinkGrid'
import { Loading } from './Loading'

export const Login: FC = () => {
  const dispatch = useDispatch()
  const sessionChecked = useSelector(isSessionChecked)

  const validateSession = useCallback(
    (_, result) => {
      dispatch(storeSessionChecked(true))

      if (result && result.accessToken && result.idToken)
        dispatch(storeToken(result))
    },
    [dispatch],
  )

  useEffect(() => {
    if (!sessionChecked) checkSession(validateSession)
  }, [validateSession, sessionChecked])

  const handleClickLogin = () => login()

  return !sessionChecked ? (
    <Loading />
  ) : (
    <Box data-testid="login">
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          top: 125,
          zIndex: 99,
          transition: 'all .3s cubic-bezier(.25,.8,.25,1)',
          overflow: 'hidden',
          backgroundColor: 'rgb(95, 132, 190, .8)',
          width: '2.4em',
          borderRadius: '25px 0 0 25px',
          WebkitBorderTopRightRadius: 0,
          WebkitBorderBottomRightRadius: 0,
          WebkitBorderBottomLeftRadius: '25px',
          WebkitBorderTopLeftRadius: '25px',
          '&:hover': {
            transition: 'all .3s cubic-bezier(.25,.8,.25,1)',
            width: '10em',
            cursor: 'pointer',
          },
        }}
      >
        <Button
          sx={{ color: 'white', whiteSpace: 'nowrap', padding: '1em' }}
          href="https://mijnaansluiting.atlassian.net/wiki/spaces/Wiki/pages/40271873/Release+notes+CAPO"
          target="_blank"
          rel="noreferrer"
          startIcon={<NotificationsIcon />}
        >
          Release notes
        </Button>
      </Box>

      <Box sx={{ p: '2em' }}>
        <Typography variant="h1" sx={{ textAlign: 'center', m: 5 }}>
          CAPO geeft inzicht
        </Typography>
        <Typography
          variant="h4"
          sx={{
            width: '80%',
            textAlign: 'center',
            mr: 'auto',
            mb: 5,
            ml: 'auto',
          }}
        >
          CAPO geeft alle betrokkenen bij het combi-aansluitproces een eenduidig
          en gedeeld beeld van de status van lopende aanvragen en projecten
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#d8d8d8', p: '2em ' }}>
        <GeneralInfoCard handleClickLogin={handleClickLogin} />
      </Box>

      <Box sx={{ p: '2em' }}>
        <LinkGrid />
      </Box>
    </Box>
  )
}
