import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'

import { getValuelists } from '@slices/valuelistsSlice'

import { createBeheerGebiedswissel } from '../../state'

interface AddGebiedenJobProps {
  open: boolean
  onClose: () => void
}

export default function AddGebiedenJob({ open, onClose }: AddGebiedenJobProps) {
  const dispatch = useDispatch()
  const { gebieden } = useSelector(getValuelists)
  const [gebied, setGebied] = useState<any>(null)
  const [omschrijving, setOmschrijving] = useState('')
  const [text, setText] = useState('')
  const [errors, setErrors] = useState({ gebiedError: false, textError: false })

  useEffect(() => {
    setOmschrijving('')
    setText('')
    setGebied(null)
    setErrors({ gebiedError: false, textError: false })
  }, [open])

  const handleClickCreate = () => {
    if (!gebied || !text) {
      setErrors({
        gebiedError: !gebied,
        textError: !text,
      })
    } else {
      dispatch(
        createBeheerGebiedswissel({
          gebiedscode: gebied.code,
          lines: text.split('\n'),
          omschrijving,
        }),
      )
    }
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Wijzigen gebied voor projecten</DialogTitle>
      <DialogContent>
        <Box
          sx={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', p: 1 }}
        >
          <FormControl sx={{ width: '100%', p: 1 }}>
            <TextField
              variant="standard"
              id="omschrijving"
              label="Omschrijving"
              value={omschrijving}
              onChange={(e) => setOmschrijving(e.target.value)}
              error={errors['omschrijvingError']}
            />
          </FormControl>
          <FormControl sx={{ width: '100%', p: 1 }}>
            <Autocomplete
              options={gebieden}
              value={gebied}
              onChange={(_, e) => setGebied(e)}
              getOptionLabel={(gebied) => `${gebied.code} - ${gebied.label}`}
              isOptionEqualToValue={(x, y) => x.code === y.code}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Doel gebied"
                  error={errors['gebiedError']}
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ mt: 3, width: '100%' }}>
            <TextField
              value={text}
              multiline
              rows={6}
              label="Lijst van project ids (met optioneel datum)"
              onChange={(e) => setText(e.target.value)}
              required
              error={errors['textError']}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button onClick={handleClickCreate} color="primary">
          Aanmaken
        </Button>
      </DialogActions>
    </Dialog>
  )
}
