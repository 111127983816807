import {
  addWeeks,
  differenceInCalendarISOWeeks,
  endOfDay as endDay,
  format,
  getISOWeekYear,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  isWeekend,
  nextMonday,
  parseISO,
  setISOWeek,
  startOfDay as startDay,
  subWeeks,
} from 'date-fns'

const DATE_FORMAT = 'dd-LL-yyyy'
const DATETIME_FORMAT = 'dd-LL-yyyy HH:mm:ss'

const formatDate = (date?: any) => date && format(new Date(date), DATE_FORMAT)
const formatDateTime = (date?: any) =>
  date && format(new Date(date), DATETIME_FORMAT)
const startOfDay = (date?: any) => date && startDay(new Date(date))
const endOfDay = (date?: any) => date && endDay(new Date(date))
const pastDay = (date?: any) => date && isPast(new Date(date))
const futureDay = (date?: any) => date && isFuture(new Date(date))
const isToday = (date?: any) => date && isSameDay(new Date(date), new Date())
const skipWeekend = (date?: any) => {
  if (!date) return

  const d = new Date(date)

  return isWeekend(d) ? nextMonday(d) : d
}

const formatWeekYearToISOWeek = (year, week) =>
  year && week && format(setISOWeek(year.setMonth(2), week), "RRRR-'W'II") // eslint-disable-line
const formatDateToISOWeek = (date) =>
  date && format(new Date(date), "RRRR-'W'II") // eslint-disable-line quotes

const beginningOfYearDateForISOWeek = (dateString?: string) =>
  dateString
    ? new Date(getISOWeekYear(parseISO(dateString)).toString())
    : new Date()

const subtractWeeks = (date: any, weeks: any) =>
  date instanceof Date && !Number.isNaN(date) && subWeeks(date, weeks)

/**
 * @param date1 the date to compare to
 * @param date2 the input date
 * @returns the nr of weeks the 2nd date is ahead of or in past of date1
 * @description 2023W10 as first date, and 2023W12 as second date, will return 2, because it is 2 weeks ahead
 */
const compareISOWeeks = (date1: string, date2: string) => {
  return differenceInCalendarISOWeeks(parseISO(date2), parseISO(date1))
}

export {
  addWeeks,
  endOfDay,
  formatDate,
  formatDateTime,
  formatDateToISOWeek,
  formatWeekYearToISOWeek,
  futureDay,
  isBefore,
  isToday,
  parseISO,
  pastDay,
  skipWeekend,
  startOfDay,
  subtractWeeks,
  compareISOWeeks,
  beginningOfYearDateForISOWeek,
}
