import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { getISOWeek, getISOWeeksInYear, parseISO } from 'date-fns'

import {
  beginningOfYearDateForISOWeek,
  formatWeekYearToISOWeek,
} from '@util/date-util'

import { saveEvent } from '../../state'
import type { OptionsDialogData } from '../../types'
import {
  isHoofdleidingHerplanDoorlooptijdStatus,
  isHoofdleidingHerplanStatus,
} from '../../util'

const MAX_WEEKS_IN_YEAR = 53
const HERPLAN_REDENEN = [
  'Leveringsproblemen materiaal',
  'Capaciteitstekort aannemer',
  'Bodemvervuiling - sanering vereist',
  'Vertraging in vergunningstraject',
  'Planning klant / gemeente',
]

const WEEK_NUMBER_53 = 53

export interface Props {
  data: OptionsDialogData
  handleClose: () => void
}

export const Herplan: FC<Props> = ({ data, handleClose }: Props) => {
  const dispatch = useDispatch()
  const { project } = data
  const {
    aanvraagID,
    startUitvoeringWeek,
    doorlooptijdWeken: doorlooptijd,
    netbeheerders = [],
    statusText,
  } = project

  const canChangeStartuitvoering = isHoofdleidingHerplanStatus(statusText)
  const canChangeDoorlooptijd =
    isHoofdleidingHerplanDoorlooptijdStatus(statusText)

  const [jaar, setJaar] = useState<Date | null>(
    startUitvoeringWeek
      ? beginningOfYearDateForISOWeek(startUitvoeringWeek)
      : null,
  )
  const [week, setWeek] = useState<number | undefined>(
    startUitvoeringWeek ? getISOWeek(parseISO(startUitvoeringWeek)) : undefined,
  )
  const [doorlooptijdWeken, setDoorlooptijdWeken] = useState(doorlooptijd)
  const [herplannenBijlagen, setHerplannenBijlagen] = useState(
    canChangeStartuitvoering,
  )
  const [reden, setReden] = useState('')
  const [errors, setErrors] = useState({})
  const redenen = [...HERPLAN_REDENEN].concat(
    (netbeheerders as string[]).map(
      (nb) => `Werkvoorbereiding ${nb} vertraagd`,
    ),
  )

  const validate = () => {
    const doorlooptijdWekenError = !doorlooptijdWeken || doorlooptijdWeken < 1
    let startUitvoeringWeekError = !week || week > MAX_WEEKS_IN_YEAR || week < 1
    const startUitvoeringJaarError =
      !jaar || jaar.getFullYear() < new Date().getFullYear()
    const redenError = startUitvoeringWeek && !reden

    // Validate week 53
    if (
      !startUitvoeringWeekError &&
      !startUitvoeringJaarError &&
      week === WEEK_NUMBER_53
    ) {
      startUitvoeringWeekError = getISOWeeksInYear(jaar) !== MAX_WEEKS_IN_YEAR
    }

    setErrors({
      redenError,
      doorlooptijdWekenError,
      startUitvoeringWeekError,
      startUitvoeringJaarError,
    })

    return !(
      doorlooptijdWekenError ||
      startUitvoeringWeekError ||
      startUitvoeringJaarError ||
      redenError
    )
  }

  const handleSubmitClick = () => {
    if (validate()) {
      dispatch(
        saveEvent({
          id: aanvraagID,
          data: {
            startUitvoeringWeek: formatWeekYearToISOWeek(jaar, week),
            herplannenBijlagen,
            reden: startUitvoeringWeek ? reden : 'Uitvoeringsweek bekend',
            doorlooptijdWeken,
          },
          type: 'herplanning',
          source: 'manual',
        }),
      )
    }
  }

  const handleVerwijderPlanningClick = () => {
    dispatch(
      saveEvent({
        id: aanvraagID,
        data: {
          herplannenBijlagen,
          reden: 'Correctie',
        },
        type: 'herplanning',
        source: 'manual',
      }),
    )
  }

  return (
    <>
      <DialogTitle>Herplannen project {aanvraagID}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', pb: 1 }}>
          <DatePicker
            views={['year']}
            disablePast
            label="Jaar start uitvoering"
            value={jaar}
            onChange={setJaar as any}
            openTo="year"
            slotProps={{
              field: { clearable: true },
              textField: {
                error: Boolean(errors['startUitvoeringJaarError']),
                variant: 'standard',
                sx: { width: '33%', pr: 1 },
              },
            }}
            disabled={!canChangeStartuitvoering}
          />
          <TextField
            InputProps={{
              inputProps: {
                min: 1,
                max: 52,
                'data-testid': 'startUitvoeringWeek-field',
              },
            }}
            sx={{ width: '33%', pr: 1 }}
            variant="standard"
            type="number"
            value={week || ''}
            label={'Week start uitvoering'}
            onChange={(e) => setWeek(Number(e.target.value))}
            error={errors['startUitvoeringWeekError']}
            disabled={!canChangeStartuitvoering}
          />
          <TextField
            InputProps={{
              inputProps: { min: 1, 'data-testid': 'doorlooptijdWeken-field' },
            }}
            value={doorlooptijdWeken || ''}
            error={errors['doorlooptijdWekenError']}
            variant="standard"
            label={'Doorlooptijd'}
            onChange={(e) => setDoorlooptijdWeken(parseInt(e.target.value))}
            type="number"
            sx={{ width: '33%' }}
            disabled={!canChangeDoorlooptijd}
          />
        </Box>
        {startUitvoeringWeek && (
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="reden-label">Reden</InputLabel>
            <Select
              labelId="reden-label"
              label="Reden"
              variant="standard"
              value={reden}
              onChange={(e) => setReden(e.target.value)}
              error={errors['redenError']}
            >
              {redenen.map((reden) => (
                <MenuItem value={reden} key={reden}>
                  {reden}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box sx={{ width: '90%', display: 'flex', mt: 2 }}>
          <Switch
            sx={{ mr: 1 }}
            checked={herplannenBijlagen}
            onChange={() => setHerplannenBijlagen(!herplannenBijlagen)}
            data-testid="bijlagen-switch"
            disabled={!canChangeStartuitvoering}
          />
          <Typography sx={{ alignSelf: 'center' }}>
            Nog op te leveren bijlagen en af te handelen activiteiten worden{' '}
            <strong>{herplannenBijlagen ? 'wel' : 'niet'}</strong> herpland.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {project.statusText === 'Intake' && (
          <Button onClick={handleVerwijderPlanningClick} color="error">
            Verwijder planning
          </Button>
        )}
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={handleSubmitClick}>
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
