export const activiteittypes = [
  'Afspraak',
  'Bodemonderzoek aanvragen',
  'Klantcontact',
  'Schouw inplannen',
  'Vergunning aanvragen',
  'Wacht op klant',
  'Inplannen overdrachtsoverleg',
]

export const activiteittypesOptions = activiteittypes.map((s) => ({
  code: s,
  label: s,
}))

export const documentsoorten = [
  'Ontwerp initiatiefnemer',
  'Verslaglegging',
  'Intakeformulier',
  'Voorlopig ontwerp',
  'Tracétekening',
  'Bodemonderzoek',
  'CROW 500',
  'Definitief ontwerp',
  'Concept plantekeningen',
  'Schouwrapport',
  'Definitieve plantekening',
  'Voorcalculatie',
  'Werkmap',
  'Vergunning',
  'Uitvoeringsplanning',
  'Overig',
  'Piekvermogensformulier',
  'Bodeminformatie klant',
  'Verslag SWO',
  'Definitief ontwerp klant',
  'Garantieovereenkomst',
  'Bodem en veiligsheidsadvies',
]
