import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import LinkIcon from '@mui/icons-material/Link'
import { Toolbar } from '@mui/material'

import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledTable from '@components/StyledTable'

import { getUser } from '../../auth/state'
import CreateEditProjectDialog from '../../hoofdleiding/project/components/dialogs/CreateEditProjectDialog'
import { openProjectDialog } from '../../hoofdleiding/project/state'
import {
  closeLinkDialog,
  deleteLinkProjectToProjectdossier,
  getLinkDialogData,
  isLinkDialogOpen,
  openLinkDialog,
} from '../state'
import type { Projectdossier } from '../types'

import { LinkedProject } from './LinkedProject'
import { LinkProjectDialog } from './dialogs/LinkProjectDialog'

const headerCells = [
  { id: 'aanvraagId', numeric: false, label: 'Project id' },
  { id: 'type', numeric: false, label: 'Type' },
  { id: 'aanvraagDatum', numeric: false, label: 'Aangevraagd' },
  { id: 'adres', numeric: false, label: 'Adres' },
  { id: 'gebiedscodeLabel', numeric: false, label: 'Gebied' },
  { id: 'statusText', numeric: false, label: 'Processtap' },
  { id: 'options', numeric: false, fixed: 100, label: 'Opties' },
]

export interface Props {
  projecten?: any[]
  dossierId: Projectdossier['projectdossierId']
  gebiedscode: Projectdossier['gebiedscode']
}

export const LinkedProjects: FC<Props> = ({
  projecten = [],
  dossierId,
  gebiedscode,
}) => {
  const dispatch = useDispatch()
  const { canCreateProjectdossier } = useSelector(getUser)
  const isLinkOpen = useSelector(isLinkDialogOpen)
  const linkDialogData = useSelector(getLinkDialogData)

  const onClickLinkProject = () => {
    dispatch(openLinkDialog({ dossierId }))
  }

  const onCloseLinkProject = () => {
    dispatch(closeLinkDialog())
  }

  const onClickRemoveLinkProject = (projectId: string) => {
    dispatch(deleteLinkProjectToProjectdossier({ id: dossierId, projectId }))
  }

  const onClickCreateProject = () => {
    dispatch(
      openProjectDialog({
        data: { linkedProjectdossier: dossierId, gebiedscode },
        type: 'create',
      }),
    )
  }

  return (
    <>
      <StyledCard title="Gekoppelde projecten" {...({} as any)}>
        <Toolbar
          sx={{
            backgroundColor: '#F7F7F7',
            borderBottom: '1px solid #E0E0E0',
            justifyContent: 'flex-end',
          }}
        >
          {canCreateProjectdossier && (
            <>
              <StyledIconButton
                title="Koppel een nieuw project"
                onClick={onClickLinkProject}
                icon={<LinkIcon />}
              />
              <StyledIconButton
                title="Aanmaken hoofdleiding project"
                onClick={onClickCreateProject}
                icon={<AddIcon />}
              />
            </>
          )}
        </Toolbar>
        <StyledTable
          headerCells={headerCells}
          variant={'details'}
          {...({} as any)}
        >
          {projecten.map((project) => {
            return (
              <LinkedProject
                key={project.aanvraagID}
                project={project}
                canCreate={!!canCreateProjectdossier}
                handleRemove={onClickRemoveLinkProject}
              />
            )
          })}
        </StyledTable>
      </StyledCard>
      <LinkProjectDialog
        open={isLinkOpen}
        data={linkDialogData}
        onClose={onCloseLinkProject}
      />

      <CreateEditProjectDialog />
    </>
  )
}
