import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import StyledTable from '@components/StyledTable'
import { formatDate, formatDateTime } from '@util/date-util'

const logStatusLabel = (activiteit, index) => {
  const { completed, log, status } = activiteit

  if (index === 0) return 'Aangemaakt'
  if (completed && log.length > 1 && index === log.length - 1) {
    if (status === 'cancelled') return 'Geannuleerd'
    return 'Afgerond'
  }

  return 'Aangepast'
}

const logText = (activiteit, index, entry) => {
  const { completed, log } = activiteit

  if (completed && log.length > 1 && index === log.length - 1)
    return completed.text

  return entry.text
}

const headerCells = [
  { id: 'date', numeric: false, label: 'Datum', fixed: 80 },
  { id: 'text', numeric: false, label: 'Opmerking' },
  { id: 'dueDate', numeric: false, label: 'Opleverdatum' },
  { id: 'status', numeric: false, label: 'Mutatiesoort' },
  { id: 'email', numeric: false, label: 'Door' },
]

export const ActiviteitLog = ({ activiteit, onClose, open }) => {
  const { log = [] } = activiteit || {}

  return activiteit ? (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box role="presentation" sx={{ p: '20px' }}>
        <IconButton
          data-testid="close-button"
          onClick={onClose}
          sx={{ color: 'black' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            pt: '1em',
            fontWeight: 550,
            pb: '1em',
            fontSize: '1.5rem',
            color: 'black',
          }}
          variant="h4"
        >{`Log van activiteit ${activiteit.type}`}</Typography>
        <Grid
          sx={{ p: 1, width: '95%', mx: 'auto', pt: 4, pb: 4 }}
          container
          spacing={2}
        >
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle1">
              <b>Aangemaakt door</b>
            </Typography>
            <Typography variant="body2">
              {activiteit.created.organisatie}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle1">
              <b>Toegewezen aan</b>
            </Typography>
            <Typography variant="body2">
              {activiteit.assignedTo.join(',')}
            </Typography>
          </Grid>
        </Grid>
        <StyledTable headerCells={headerCells} {...({} as any)}>
          {log.map((logEntry, index) => {
            const statusLabel = logStatusLabel(activiteit, index)
            const text = logText(activiteit, index, logEntry)

            return (
              <TableRow key={`${logEntry.date}-${logEntry.organisatie}`}>
                <TableCell>{formatDateTime(logEntry.date)}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>{text}</TableCell>
                <TableCell>
                  {logEntry.dueDate ? formatDate(logEntry.dueDate) : 'Onbekend'}
                </TableCell>
                <TableCell>{statusLabel}</TableCell>
                <TableCell>
                  {logEntry.organisatie}

                  {logEntry.email ? (
                    <Typography variant="caption">
                      <br />
                      {logEntry.email}
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </StyledTable>
      </Box>
    </Drawer>
  ) : (
    <></>
  )
}
