import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { AutosuggestState } from './autosuggest.types'

const MIN_QUERY_SIZE = 3

export const createParts = (value, q) => {
  const parts: any[] = []
  let text
  const query = (q || '').trim()

  if (value && query) {
    const idx = value.toLowerCase().indexOf(query.toLowerCase())

    if (idx === 0) {
      text = value.slice(0, query.length)
      parts.push({ text, highlight: true })
      text = value.slice(query.length)
      parts.push({ text, highlight: false })
    } else if (idx > 0) {
      text = value.slice(0, idx)
      parts.push({ text, highlight: false })
      text = value.slice(idx, idx + query.length)
      parts.push({ text, highlight: true })
      if (idx < value.length - query.length) {
        text = value.slice(idx + query.length)
        parts.push({ text, highlight: false })
      }
    }
  }

  if (parts.length === 0) parts.push({ text: value, highlight: false })

  return parts
}

export const makeAddParts = (createParts) => (text, items) =>
  items.map((as) => ({ ...as, parts: createParts(as.v, text) }))

const initialState: AutosuggestState = {
  options: [],
}

export const fetchAutosuggest = createAsyncThunk(
  'fetch/autosuggest',
  async (payload: any, { dispatch }) => {
    try {
      const { text, entity, isHoofdleiding } = payload

      if (text && text.trim().length > MIN_QUERY_SIZE) {
        let url = '/rest/autosuggest'

        if (entity) url = `/rest/${entity}/autosuggest`
        if (isHoofdleiding) url = `/rest/hoofdleiding/autosuggest`

        const addParts = makeAddParts(createParts)
        const result = await request({
          url,
          method: 'POST',
          data: { text: text.trim() },
        })

        return addParts(text, result)
      }

      return []
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen suggesties', error))

      throw error
    }
  },
)

const autosuggestSlice = createSlice({
  name: 'autosuggest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutosuggest.fulfilled, (state, action) => {
        state.options = action.payload
      })
      .addCase(fetchAutosuggest.rejected, (state) => {
        state.options = []
      })
  },
})

export const getAutosuggest = (state: RootState) => state.autosuggest.options

export default autosuggestSlice.reducer
