import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import StyledDetailsTable from '@components/StyledDetailsTable'
import type { Activiteit } from '@models/Activiteit'
import { formatDate, formatDateTime } from '@util/date-util'

const generateJsxFromItems = ({ items }) => {
  return items.map((item) => (
    <TableRow key={`${item.title}-${item.text}`}>
      <TableCell>
        <b>{item.title}</b>
      </TableCell>
      <TableCell>{item.text}</TableCell>
    </TableRow>
  ))
}

const generateStatussen = ({ activiteit, statuses }) => {
  const items = [
    { title: 'Open', text: formatDateTime(activiteit.created.date) },
  ]

  if (statuses) {
    const sortedStatuses: any[] = [...statuses].sort(
      (a, b) =>
        Number(new Date(a.statusChangeDateTime)) -
        Number(new Date(b.statusChangeDateTime)),
    )

    for (const status of sortedStatuses) {
      const title = status.statusTypeText || status.statusType

      items.push({ title, text: formatDateTime(status.statusChangeDateTime) })
      if (status.statusChangeComment) {
        items.push({ title: '', text: status.statusChangeComment })
      }
    }
  }
  if (activiteit.completed) {
    items.push({
      title: 'Afgerond',
      text: formatDateTime(activiteit.completed.date),
    })
  }

  return generateJsxFromItems({ items })
}

const generateVestigingen = ({ vestigingen }) => {
  const items: any[] = []

  vestigingen.forEach((vestiging, index) =>
    items.push({ title: `Vestiging ${index + 1}`, text: vestiging.name }),
  )

  return generateJsxFromItems({ items })
}

const generateGeneralDetails = ({ data }) => {
  if (!data) return

  const {
    address,
    plannedstartdate,
    plannedcompleteddate,
    requestedstartdate,
    requestedenddate,
    psmorganisationname,
  } = data

  const items = [
    { title: 'Adres', text: address },
    { title: 'Wegbeheerder', text: psmorganisationname },
  ]

  if (plannedstartdate) {
    items.push({
      title: 'Datum start werk',
      text: formatDateTime(plannedstartdate),
    })
  }
  if (plannedcompleteddate) {
    items.push({
      title: 'Datum afgerond',
      text: formatDateTime(plannedcompleteddate),
    })
  }
  if (requestedstartdate) {
    items.push({
      title: 'Verzochte startdatum',
      text: formatDate(requestedstartdate),
    })
  }
  if (requestedenddate) {
    items.push({
      title: 'Verzochte einddatum',
      text: formatDate(requestedenddate),
    })
  }

  return generateJsxFromItems({ items })
}

interface Props {
  activiteit?: Activiteit
  onClose: () => void
  isExpanded: boolean
}

export const WowDrawer: FC<Props> = ({ activiteit, onClose, isExpanded }) => {
  const isGraafmelding = activiteit?.type.toLowerCase() === 'graafmelding'

  const data = activiteit?.data
  const typeObject =
    (isGraafmelding ? data?.graafmelding : data?.vergunning) || {}
  const id = isGraafmelding
    ? typeObject.roadwork?.id
    : typeObject.permit?.permitid
  const generalDetails = isGraafmelding
    ? typeObject.roadwork
    : typeObject.permit
  const statuses = typeObject.status

  // Single line assignment didn't work, because if the data.request.vestiging is undefined,
  // it will create an array with [undefined] (if you do it like [data?.request?.vestiging])
  const vestigingen: any[] = []

  if (isGraafmelding && data?.request?.vestigingen) {
    vestigingen.push(...data.request.vestigingen)
  } else if (!isGraafmelding && data?.request?.vestiging) {
    vestigingen.push(data.request.vestiging)
  }

  return activiteit ? (
    <Drawer anchor="right" open={isExpanded} onClose={onClose}>
      <Box role="presentation" sx={{ width: '80%', minWidth: 700, p: '20px' }}>
        <IconButton
          data-testid="close-button"
          onClick={onClose}
          sx={{ color: 'black' }}
        >
          <CloseIcon />
        </IconButton>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography>
                  {activiteit.type} {id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <StyledDetailsTable>
                  {generateGeneralDetails({ data: generalDetails })}
                </StyledDetailsTable>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>
                <Typography>Vestigingen</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <StyledDetailsTable>
                  {generateVestigingen({ vestigingen })}
                </StyledDetailsTable>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>
                <Typography>Statusverloop</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <StyledDetailsTable>
                  {generateStatussen({ activiteit, statuses })}
                </StyledDetailsTable>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Drawer>
  ) : (
    <></>
  )
}
