import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import LaunchIcon from '@mui/icons-material/Launch'
import { Avatar, Chip, Grid, TableCell, TableRow, Tooltip } from '@mui/material'

import StyledIconLink from '@components/StyledIconLink'
import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'
import { getValuelists } from '@slices/valuelistsSlice'
import { formatDate, formatDateTime } from '@util/date-util'

import {
  closeCreateDialog,
  fetchBeheerGebiedswissels,
  getGebiedswissels,
  initialState,
  isCreateDialogOpen,
  openCreateDialog,
} from '../state'
import type { Filters as StateFilters } from '../types'

import { Filters } from './Filters'
import CreateGebiedswissel from './dialogs/CreateGebiedswissel'

const headerCells = [
  { id: 'projectid', numeric: false, label: 'Project id' },
  { id: 'sourceGebied', numeric: false, label: 'Bron gebied' },
  { id: 'gebied', numeric: false, label: 'Doel gebied' },
  { id: 'description', numeric: false, label: 'Omschrijving' },
  { id: 'status', numeric: false, label: 'Status', fixed: 200 },
  { id: 'created.date', numeric: false, label: 'Aangemaakt', fixed: 200 },
  { id: 'changed', numeric: false, label: 'Bijgewerkt', fixed: 200 },
  {
    id: 'planned',
    numeric: false,
    label: 'Gepland',
    fixed: 200,
  },
  { id: 'filler' },
] as const

export const WISSEL_STATUSES = {
  open: 'Open',
  failed: 'Gefaald',
  done: 'Successvol afgerond',
}

export const Gebiedswissel: FC = () => {
  const dispatch = useDispatch()
  const isDialogOpen = useSelector(isCreateDialogOpen)
  const valuelists = useSelector(getValuelists)
  const { data, isLoading, query } = useSelector(getGebiedswissels)
  const { items: gebiedswissels, count, skip, limit } = data
  const { page } = query

  const { item: storedFilters } = useLocalStorage(
    'beheer',
    query.filters,
    initialState.gebiedswissels.query.filters,
  )

  const [localFilters, setLocalFilters] = useState<StateFilters>(storedFilters)
  const [localPage, setLocalPage] = useState(page)

  useEffect(() => {
    dispatch(
      fetchBeheerGebiedswissels({
        filters: localFilters,
        page: localPage,
      }),
    )
  }, [dispatch, localFilters, localPage])

  const onChangeFilters = (newFilters: StateFilters) => {
    setLocalPage(initialState.gebiedswissels.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangePage = (page) => setLocalPage(page)

  const onCreate = () => dispatch(openCreateDialog())
  const onClose = () => dispatch(closeCreateDialog())

  return (
    <>
      <Filters
        onChange={onChangeFilters}
        filters={localFilters}
        initialFilters={initialState.gebiedswissels.query.filters}
        valuelists={valuelists}
        onCreate={onCreate}
      />
      <StyledTable
        headerCells={headerCells}
        variant="details"
        loading={isLoading}
        stats={{ count, skip, limit }}
        onChangePage={onChangePage}
        {...({} as any)}
      >
        {gebiedswissels.map((gebiedswissel) => {
          const {
            _id,
            projectId,
            omschrijving,
            created,
            changed,
            sourceGebiedscodeLabel,
            gebiedscodeLabel,
            geplandeUitvoeringsdatum,
          } = gebiedswissel

          return (
            <TableRow key={_id}>
              <TableCell>{projectId}</TableCell>
              <TableCell>{sourceGebiedscodeLabel}</TableCell>
              <TableCell>{gebiedscodeLabel}</TableCell>
              <TableCell>{omschrijving}</TableCell>
              <TableCell>
                <GebiedswisselStatus gebiedswissel={gebiedswissel} />
              </TableCell>
              <TableCell>{formatDateTime(created.date)}</TableCell>
              <TableCell>{formatDateTime(changed)}</TableCell>
              <TableCell>{formatDate(geplandeUitvoeringsdatum)}</TableCell>
              <TableCell>
                <StyledIconLink
                  title={`Navigeer naar project ${projectId}`}
                  icon={<LaunchIcon />}
                  href={`/aansluiting/project/${projectId}`}
                />
              </TableCell>
            </TableRow>
          )
        })}
      </StyledTable>
      <CreateGebiedswissel open={isDialogOpen} onClose={onClose} />
    </>
  )
}

function GebiedswisselStatus({ gebiedswissel }) {
  const { status, error } = gebiedswissel
  const chipColor =
    status === 'done'
      ? 'green'
      : status === 'open'
      ? 'rgb(61, 108, 180)'
      : 'red'

  const iconStyle = {
    color: chipColor,
    backgroundColor: 'white',
    cursor: 'default',
  }
  const chipIcon =
    status === 'done' ? (
      <DoneIcon sx={iconStyle} /> // if done
    ) : status === 'open' ? (
      <FormatListNumberedIcon sx={iconStyle} /> // if open
    ) : (
      <CloseIcon sx={iconStyle} />
    ) // if failed

  return (
    <Grid container spacing={1}>
      <Tooltip title={error || ''}>
        <Chip
          label={WISSEL_STATUSES[status]}
          variant="outlined"
          sx={{
            backgroundColor: 'white',
            borderColor: chipColor,
            color: chipColor,
          }}
          avatar={<Avatar>{chipIcon}</Avatar>}
        />
      </Tooltip>
    </Grid>
  )
}
