import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import StyledCard from '@components/StyledCard'

import { getUserParam } from '../auth/state'

import { Row } from './components/Row'
import { fetchContracten, getContracten } from './state'

export const Contracten: FC = () => {
  const dispatch = useDispatch()
  const contracten = useSelector(getContracten)
  const userParam = useSelector(getUserParam)

  useEffect(() => {
    dispatch(fetchContracten())
  }, [dispatch, userParam])

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">Overzicht van uw combi contracten</Typography>
      {contracten.map((contract) => (
        <StyledCard
          key={contract.gebiedscode}
          title={`Gebied ${contract.gebiedscodeLabel}`}
          {...({} as any)}
        >
          <Row contract={contract} />
        </StyledCard>
      ))}
    </Box>
  )
}
