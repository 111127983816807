import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import SaveIcon from '@mui/icons-material/Save'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  Switch,
  TextField,
} from '@mui/material'

import { useProgress } from '@hooks/useProgress'

import type { GroupedFilterOption } from '../../../../core/models/Filter'
import { uploadBijlagenForEntity } from '../../state'

import { UploadDropzone } from './UploadDropzone'
import { UploadIndicatorItem } from './UploadIndicatorItem'

interface Props {
  data: any
  onClose?: any
}

export const Upload: FC<Props> = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const progress = useProgress()
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const { bijlage, entity, multiple = true, assignees = [] } = data
  const uploading =
    selectedFiles.length > 0 && progress.current > 0 && !progress.error
  const errorWhileUploading =
    selectedFiles.length > 0 && progress.current >= 0 && progress.error
  const requiresReview = !!bijlage?.requiresReview
  const [assignedTo, setAssignedTo] = useState<GroupedFilterOption | null>(null)
  const [errors, setErrors] = useState({})
  const [requestReview, setRequestReview] = useState(false)
  const [opleveren, setOpleveren] = useState(false)

  const title = `Toevoegen bijlage${multiple ? 'n' : ''}${
    bijlage?.documentsoort ? ` aan ${bijlage.documentsoort}` : ''
  }${bijlage?.discipline ? ` ${bijlage.discipline}` : ''}`

  const handleSubmitClick = () => {
    if (requestReview && !assignedTo?.label) {
      setErrors({ assignedToError: true })
      return
    }

    setErrors({})

    dispatch(
      uploadBijlagenForEntity({
        entity,
        selectedFiles,
        progress: {
          ...progress,
          current: 0,
          error: false,
          total: 0,
        },
        id: bijlage?._id,
        opleveren: opleveren || requestReview,
        assignedTo: assignedTo?.label,
      }),
    )
  }

  const handleClose = () => {
    progress.clear()

    if (onClose) {
      onClose()
    }
  }

  const onFilesChange = (files) => {
    setSelectedFiles(files)
    progress.set({ current: -1, error: false, total: files.length })
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '95%', m: 'auto', padding: 1 }}>
          <FormControl sx={{ width: '100%', p: 1 }}>
            <UploadDropzone
              onFilesChange={onFilesChange}
              multiple={!!multiple}
            />
          </FormControl>
          <List sx={{ width: '90%', margin: 'auto' }}>
            {selectedFiles.length > 0 &&
              selectedFiles.map((selectedFile, index) => (
                <UploadIndicatorItem
                  key={selectedFile.name}
                  text={selectedFile.name}
                  state={
                    progress.current > 0 && progress.current > index
                      ? 'done'
                      : progress.current === index && !progress.error
                      ? 'loading'
                      : progress.current === index && progress.error
                      ? 'error'
                      : undefined
                  }
                />
              ))}
          </List>

          {selectedFiles.length > 0 && requiresReview && (
            <Box sx={{ pt: 3, pl: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormControlLabel
                      control={<Switch />}
                      label={`${bijlage.documentsoort} ${
                        bijlage.discipline || ''
                      } direct laten beoordelen`}
                      onChange={() => {
                        setAssignedTo(null)
                        setRequestReview(!requestReview)
                        setErrors((errors) => ({
                          ...errors,
                          assignedToError: false,
                        }))
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {requestReview && (
                    <Autocomplete
                      options={assignees}
                      groupBy={(assignedTo) => assignedTo.group}
                      onChange={(_, assignee) => {
                        setErrors((errors) => ({
                          ...errors,
                          assignedToError: false,
                        }))
                        setAssignedTo(assignee)
                      }}
                      getOptionLabel={(assignedTo) => assignedTo.label}
                      value={assignedTo}
                      size={'medium'}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label="Beoordelen door"
                          error={errors['assignedToError']}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Als geen review nodig, geef mogelijkheid om direct op te leveren. */}
          {selectedFiles.length > 0 && !requiresReview && bijlage && (
            <Box sx={{ pt: 3, pl: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormControlLabel
                      control={<Switch />}
                      label={`${bijlage.documentsoort} ${
                        bijlage.discipline || ''
                      } direct opleveren`}
                      onChange={() => {
                        setOpleveren(!opleveren)
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleClose} disabled={uploading}>
          {errorWhileUploading ? 'Sluiten' : 'Annuleren'}
        </Button>

        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSubmitClick}
          disabled={!selectedFiles.length || uploading || errorWhileUploading}
          endIcon={uploading && <CircularProgress size={20} />}
        >
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
