import React, { FC } from 'react'

import { Box, Button, Typography } from '@mui/material'

interface Props {
  onFilesChange: (files: any[]) => void
  multiple: boolean
}

export const UploadDropzone: FC<Props> = ({ onFilesChange, multiple }) => {
  /* istanbul ignore next */
  const handleDrag = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  const onDropHandler = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    let files: any[] = []

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      files = [...ev.dataTransfer.items]
        .filter((f) => f.kind === 'file')
        .map((f) => f.getAsFile())
    } else {
      // Use DataTransfer interface to access the file(s)
      files = [...ev.dataTransfer.files]
    }

    onFilesChange(!multiple ? files.splice(0, 1) : files)
  }

  const handleSelectFiles = (ev) => {
    const selectedFiles: any[] = []

    for (const file of ev.target.files) {
      selectedFiles.push(file)
    }

    onFilesChange(selectedFiles)
  }

  return (
    <Box sx={{ height: '200px' }}>
      <Box
        sx={{
          height: '100%',
          borderStyle: 'dashed',
          borderWidth: '2px',
          borderColor: '#cbd5e1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f8fafc',
        }}
        onDrop={onDropHandler}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
      >
        <Box>
          <Typography>{`Sleep ${
            multiple ? 'de bestanden' : 'het bestand'
          } naar deze ruimte om te uploaden`}</Typography>
          <Button sx={{ mt: 1, ml: 1 }} component="label">
            {`Of klik hier om ${
              multiple ? 'de bestanden' : 'het bestand'
            } te selecteren`}
            <input
              onChange={handleSelectFiles}
              data-testid="uploadinput"
              type="file"
              hidden
              multiple={multiple}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
