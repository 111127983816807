import React, { FC } from 'react'

import { Chip, Grid, Typography } from '@mui/material'

import DisciplineIcon from '@components/DisciplineIcon'
import StyledCard from '@components/StyledCard'
import { formatDate } from '@util/date-util'

import type { Projectmelding } from '../../projectmelding/types'

const SubHeader = ({ children }) => {
  return (
    <Typography
      sx={{
        color: 'rgb(61, 108, 180)',
        letterSpacing: '.02em',
        lineHeight: '120%',
        padding: '25px',
        flex: 1,
        width: 'fit-content',
        ml: -5,
      }}
      variant="h4"
    >
      {children}
    </Typography>
  )
}

export interface Props {
  projectmelding: Projectmelding
}

export const LinkedProjectmelding: FC<Props> = ({ projectmelding }) => {
  const {
    // Description, comment, and tags
    description,
    comment,
    tags,
    // Contact info
    gender,
    initials,
    middleName,
    lastName,
    companyName,
    email,
    postalCode,
    city,
    street,
    houseNumber,
    houseSuffix,
    primaryPhone,
    secondaryPhone,
    // Project info
    created,
    constructionStartDate,
    numberOfObjects,
    disciplines,
    // Location info
    latitude,
    longitude,
    contractGebiedCode,
    projectCity,
    status,
  } = projectmelding

  const contactgegevens = [
    { title: 'Bedrijf/organisatie', content: companyName },
    {
      title: 'Naam',
      content: `${gender} ${initials} ${middleName} ${lastName}`,
    },
    {
      title: 'Adres',
      content: `${street} ${houseNumber}${houseSuffix}, ${postalCode} ${city}`,
    }, // TODO add line break?
    { title: 'Telefoonnummer', content: primaryPhone || secondaryPhone },
    { title: 'E-mailadres', content: email },
  ]

  const locatiegegevens = [
    {
      title: 'GPS-Coördinaten',
      content: `${latitude},  ${longitude}`,
      link: true,
      linkUrl: `https://www.google.nl/maps/place/${latitude},%20${longitude}`,
    },
    { title: 'Verzorgingsgebied', content: contractGebiedCode },
    ...[projectCity && { title: 'Plaatsnaam', content: projectCity }],
  ].filter(Boolean)

  return (
    <StyledCard title={`Vooraanmelding ${projectmelding.projectName}`}>
      <Grid container spacing={3} sx={{ m: 'auto', width: '95%', p: 1 }}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="subtitle1">
                <b>Beschrijving</b>
              </Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
                {description}
              </Typography>
            </Grid>
            {/* These grid items go from left to right, not left column first then right column, so:
                  the 1st Grid item is the first item in the left column,
                  the 2nd grid item is the fight item in the right column,
                  the 3rd grid item is the second item in the left column, etc */}
            <Grid item xs={6}>
              <Typography gutterBottom variant="subtitle1">
                <b>Datum Ingediend</b>
              </Typography>
              <Typography variant="body2">{formatDate(created)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="subtitle1">
                <b>Verwachte datum start bouw</b>
              </Typography>
              <Typography variant="body2">
                {formatDate(constructionStartDate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="subtitle1">
                <b>Planvorming onderwerpen</b>
              </Typography>
              {tags ? (
                tags.split(',').map((tag) => {
                  return (
                    <Chip
                      key={tag}
                      size="medium"
                      label={tag}
                      sx={{ ml: 0.5, mb: 0.5 }}
                    />
                  )
                })
              ) : (
                <Typography variant="body2">
                  Geen onderwerpen beschikbaar
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="subtitle1">
                <b>Benodigde netwerken</b>
              </Typography>
              {generateIconsForDisciplines(disciplines)}
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="subtitle1">
                <b>Grootte van het project (aantal objecten)</b>
              </Typography>
              <Typography variant="body2">{numberOfObjects}</Typography>
            </Grid>
            {status?.name && (
              <Grid item xs={6}>
                <Typography gutterBottom variant="subtitle1">
                  <b>Status</b>
                </Typography>
                <Typography variant="body2">{status.name}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs>
          <SubHeader>Contactgegevens</SubHeader>
          {createSubGridList(contactgegevens)}
        </Grid>
        <Grid item xs>
          <SubHeader>Locatiegegevens</SubHeader>
          {createSubGridList(locatiegegevens)}
        </Grid>
        <Grid item xs={12}>
          <SubHeader>Opmerking</SubHeader>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{comment}</Typography>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

// The input will always be filled (seeing how the arrays are made above)
// So this doesn't need a default
function createSubGridList(input) {
  return (
    <Grid container spacing={3}>
      <React.Fragment>
        {input.map((item) => {
          return (
            <Grid item xs={12} key={item.title}>
              <Typography gutterBottom variant="subtitle1">
                <b>{item.title}</b>
              </Typography>
              <Typography variant="body2">
                {item.link ? (
                  <a href={item.linkUrl} target="_blank" rel="noreferrer">
                    {item.content}
                  </a>
                ) : (
                  <>{item.content}</>
                )}
              </Typography>
            </Grid>
          )
        })}
      </React.Fragment>
    </Grid>
  )
}

function generateIconsForDisciplines(input = '') {
  const split = input.split(',')

  return split.map((discipline) => {
    let disciplineCode = discipline.charAt(0)

    if (discipline.toLowerCase() === 'warmte') {
      disciplineCode = 'H'
    }

    return (
      <DisciplineIcon
        key={discipline}
        toolTip={discipline}
        disciplineCode={disciplineCode}
      />
    )
  })
}
