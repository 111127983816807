import React, { FC } from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import StyledCard from '@components/StyledCard'
import { disciplines, getProducts } from '@util/discipline'

import { DisciplineVerzameling } from '../../projecten/components/DisciplineVerzameling'
import type { Project } from '../types'

const headerCells = [
  { id: 'disciplineCode', numeric: false, label: 'Aangevraagde disciplines' },
  { id: 'netbeheerderCode', numeric: false, label: 'Netbeheerder' },
  { id: 'aantalObjecten', numeric: true, label: 'Objecten' },
  { id: 'processtap', numeric: false, label: 'Processtap', fixed: 150 },
  { id: 'opties', numeric: false, label: '', fixed: 50 },
]

export interface Props {
  project: Project
}

export const DisciplineVerzamelingen: FC<Props> = ({ project }: Props) => {
  const disciplineVerzamelingen = project.disciplineVerzamelingen || []
  const getDisciplineVerzameling = getProducts(disciplineVerzamelingen)

  return (
    <StyledCard title="Disciplines">
      <Table sx={{ padding: 0, backgroundColor: '#f7f7f7' }}>
        <TableHead>
          <TableRow>
            {headerCells.map((headerCell) => (
              <TableCell
                key={headerCell.id}
                sx={{
                  width: headerCell.fixed,
                }}
              >
                {headerCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {disciplines
            .reduce(
              (curr, disciplineCode) =>
                curr.concat(getDisciplineVerzameling(disciplineCode)),
              [],
            )
            .map((product: any) => {
              return (
                <DisciplineVerzameling
                  key={`${project.aanvraagID}${product.disciplineCode}${product.netbeheerderCode}`}
                  project={project}
                  product={product}
                />
              )
            })}
        </TableBody>
      </Table>
    </StyledCard>
  )
}
