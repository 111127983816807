import React, { FC, useEffect } from 'react'

import { Card, CardContent, Container, Typography } from '@mui/material'

import { logout } from '../../gateways/auth0'

export const Logout: FC = () => {
  useEffect(() => logout())

  return (
    <Container sx={{ mt: 5 }}>
      <Card sx={{ p: 3 }}>
        <CardContent>
          <Typography variant="h2" sx={{ ml: 2 }}>
            Uitloggen
          </Typography>
          <Typography paragraph sx={{ padding: 2 }}>
            U wordt nu uitgelogd
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}
