import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { State } from './types'

const initialState: State = {
  data: {},
  status: 'idle',
}

export const fetchCombiOpdracht = createAsyncThunk(
  'fetch/opdracht/combi',
  async (id: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/opdracht/${id}`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van opdracht', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'combiOpdracht',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCombiOpdracht.pending, (state) => {
        state.status = 'loading'
        state.data = {}
      })
      .addCase(fetchCombiOpdracht.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchCombiOpdracht.rejected, (state) => {
        state.status = 'error'
        state.data = {}
      })
  },
})

export const getCombiOpdracht = (state: RootState) => ({
  data: state.combiOpdracht.data,
  isLoading: state.combiOpdracht.status === 'loading',
})

export const reducer = slice.reducer
