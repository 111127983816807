import React, { useState } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, Popover, Typography } from '@mui/material'

/** Create an icon and tooltip for the additional status information */
const statusTooltip = (row) => {
  const { statusTextAGP, statusTextAGA, statusTextTG } = row

  const textItems: string[] = []

  if (statusTextTG) textItems.push(statusTextTG)
  if (statusTextAGA) textItems.push(statusTextAGA)
  if (statusTextAGP) textItems.push(statusTextAGP)

  return textItems.map((x) => (
    <span key={x}>
      {x}
      <br />
    </span>
  ))
}

interface Props {
  row: any
  text?: string
}

export default function Status({ row, text }: Props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const elements = statusTooltip(row)

  return (
    <React.Fragment>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        aria-label="status-popover"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          '&:hover': {
            cursor: 'help !important',
          },
        }}
      >
        {text}
        <InfoIcon
          fontSize="small"
          sx={{ pl: 0.5, width: '18px', height: '18px' }}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          '& .MuiPopover-paper': {
            p: 1,
            backgroundColor: 'rgba(97,97,97,.9)',
            color: 'white',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          <Typography sx={{ fontSize: '14px' }}>{elements}</Typography>
        </div>
      </Popover>
    </React.Fragment>
  )
}
