import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import type { Alert, State } from './types'

const DEFAULT_DURATION: Alert['duration'] = 60000
const DEFAULT_SEVERITY: Alert['severity'] = 'error'

const initialState: State = {
  items: {},
}

const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      state.items = {
        alert: {
          ...action.payload,
          duration: action.payload.duration || DEFAULT_DURATION,
          severity: action.payload.severity || DEFAULT_SEVERITY,
        },
      }
    },
    removeAlert: (state) => {
      state.items = {}
    },
  },
})

export const apiError = (
  title: string,
  {
    message,
    messageDetail,
  }: Required<Pick<Alert, 'message' | 'messageDetail'>>,
) =>
  addAlert({
    title,
    message,
    messageDetail,
  })

export const error = (message: string) =>
  addAlert({
    message,
  })

export const success = (message: string) =>
  addAlert({
    severity: 'success',
    duration: 3000,
    message,
  })

export const getAlert = (state: RootState) => state.alerts.items.alert

export const { reducer } = slice
export const { removeAlert, addAlert } = slice.actions
