import React, { FC, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'

import type { Filters as StateFilters } from '../types'

import { WISSEL_STATUSES } from './Gebiedswissel'

interface Props {
  onChange: (newFilters: StateFilters) => void
  filters: StateFilters
  initialFilters: StateFilters
  valuelists: any
  onCreate: () => void
}

export const Filters: FC<Props> = ({
  onChange,
  filters,
  initialFilters,
  valuelists,
  onCreate,
}) => {
  const [localFilters, setLocalFilters] = useState(filters)
  const { gebied, status } = localFilters
  const { gebieden } = valuelists

  const handleFilterChange = (filter) => {
    const newFilters = { ...localFilters, ...filter }

    setLocalFilters(newFilters)
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)

    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={gebied}
          onFilterChange={handleFilterChange}
          label="Doel gebied"
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={Object.entries(WISSEL_STATUSES).map(([key, val]) => ({
            code: key,
            label: val,
          }))}
          label="Status"
          filterName="status"
          value={status}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={resetFilters}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Projectids toevoegen">
          <IconButton onClick={onCreate}>
            <AddIcon />
          </IconButton>
        </Tooltip>

        <LocalStorageButtons
          name="beheer"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
