import React from 'react'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import NearbyErrorIcon from '@mui/icons-material/NearbyError'
import SocialDistanceIcon from '@mui/icons-material/SocialDistance'
import { Badge, Grid, IconButton, Tooltip } from '@mui/material'

import type { Acties } from '../../aansluiting/project/types'

const StyledBadgeIconButton = ({
  title,
  icon,
  count,
}: {
  title: string
  icon: JSX.Element
  count: number
}) => {
  return (
    <Tooltip title={title}>
      <Badge
        badgeContent={count}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'rgb(61, 108, 180)',
            color: 'white',
          },
        }}
        overlap="circular"
      >
        <IconButton sx={{ cursor: 'default' }}>{icon}</IconButton>
      </Badge>
    </Tooltip>
  )
}

const filterBySelected = ({
  actie,
  actiehouders,
  selectedActie,
  selectedActiehouder,
}: {
  actie: keyof Acties
  actiehouders?: string[]
  selectedActie?: { code: string; label: string } | null
  selectedActiehouder?: { group: string; label: string } | null
}) => {
  if (!actiehouders) return []

  return actiehouders.filter(
    (actiehouder) =>
      (!selectedActiehouder || actiehouder === selectedActiehouder.label) &&
      (!selectedActie || actie === selectedActie.code),
  )
}

export const Actions = ({
  acties = {},
  selectedActie,
  selectedActiehouder,
}: {
  acties?: Partial<Acties>
  selectedActie?: { code: string; label: string } | null
  selectedActiehouder?: { group: string; label: string } | null
}) => {
  const quickscan = filterBySelected({
    actie: 'quickscan',
    actiehouders: acties.quickscan,
    selectedActie,
    selectedActiehouder,
  })

  const verstuurOpdrachten = filterBySelected({
    actie: 'verstuurOpdrachten',
    actiehouders: acties.verstuurOpdrachten,
    selectedActie,
    selectedActiehouder,
  })

  const opleverenBijlagen = filterBySelected({
    actie: 'opleverenBijlagen',
    actiehouders: acties.opleverenBijlagen,
    selectedActie,
    selectedActiehouder,
  })

  const planningConflict = filterBySelected({
    actie: 'planningConflict',
    actiehouders: acties.planningConflict,
    selectedActie,
    selectedActiehouder,
  })

  const planningConflictHoofdleiding = filterBySelected({
    actie: 'planningConflictHoofdleiding',
    actiehouders: acties.planningConflictHoofdleiding,
    selectedActie,
    selectedActiehouder,
  })

  const gesplitsteWerkvoorbereiding = filterBySelected({
    actie: 'gesplitsteWerkvoorbereiding',
    actiehouders: acties.gesplitsteWerkvoorbereiding,
    selectedActie,
    selectedActiehouder,
  })

  return (
    <Grid container spacing={1} direction="row">
      <Grid item>
        <StyledBadgeIconButton
          count={quickscan.length}
          title={`Quickscan: ${quickscan.join(', ')}`}
          icon={<AccountTreeIcon />}
        />
      </Grid>

      <Grid item>
        <StyledBadgeIconButton
          count={verstuurOpdrachten.length}
          title={`Opdrachten versturen: ${verstuurOpdrachten.join(', ')}`}
          icon={<ForwardToInboxIcon />}
        />
      </Grid>

      <Grid item>
        <StyledBadgeIconButton
          count={opleverenBijlagen.length}
          title={`Bijlagen opleveren: ${opleverenBijlagen.join(', ')}`}
          icon={<CloudUploadIcon />}
        />
      </Grid>

      <Grid item>
        <StyledBadgeIconButton
          count={planningConflict.length}
          title={`Planning conflict: ${planningConflict.join(', ')}`}
          icon={<EventBusyIcon />}
        />
      </Grid>

      <Grid item>
        <StyledBadgeIconButton
          count={planningConflictHoofdleiding.length}
          title={`Planning conflict hoofdleiding: ${planningConflictHoofdleiding.join(
            ', ',
          )}`}
          icon={<NearbyErrorIcon />}
        />
      </Grid>

      <Grid item>
        <StyledBadgeIconButton
          count={gesplitsteWerkvoorbereiding.length}
          title={`Gesplitste werkvoorbereiding: ${gesplitsteWerkvoorbereiding.join(
            ', ',
          )}`}
          icon={<SocialDistanceIcon />}
        />
      </Grid>
    </Grid>
  )
}
