import React from 'react'
import { useSelector } from 'react-redux'

import { Card, CardContent, Grid, Typography } from '@mui/material'

import { getKlantgegevens } from '../state'
import type { Aanvrager } from '../types'

const createAanvragerNaam = (aanvrager: Aanvrager) => {
  const { aanhef, achternaam, tussenvoegsels, voorletters } = aanvrager

  return `${aanhef} ${voorletters}${
    tussenvoegsels ? ` ${tussenvoegsels}` : ''
  } ${achternaam}`
}

export const Gegevens = () => {
  const gegevens = useSelector(getKlantgegevens)

  const {
    aanvrager,
    contactPersoon,
    bedrijfsnaam,
    isProfessioneel,
    omschrijving,
  } = gegevens
  const { emailadres, telefoonnummer, telefoonnummerMobiel } = aanvrager || {}
  const {
    naam,
    emailadres: contactEmailadres,
    telefoonnummerMobiel: contactTelefoonnummerMobiel,
  } = contactPersoon || {}

  return aanvrager ? (
    <Card sx={{ mt: 3, mb: 2, width: '100%', pb: 4 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5">Aanvrager</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Contactgegevens uitvoering</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ m: 'auto', width: '95%' }} container spacing={2}>
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle1">
              <b>Naam</b>
            </Typography>
            <Typography variant="body2">
              {createAanvragerNaam(aanvrager)}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 40 }}>
            <Typography gutterBottom variant="subtitle1">
              <b>Naam</b>
            </Typography>
            <Typography variant="body2">{naam}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle1">
              <b>E-mailadres</b>
            </Typography>
            <Typography variant="body2">{emailadres}</Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 40 }}>
            <Typography gutterBottom variant="subtitle1">
              <b>E-mailadres</b>
            </Typography>
            <Typography variant="body2">{contactEmailadres}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle1">
              <b>Telefoonnummer (mobiel)</b>
            </Typography>
            <Typography variant="body2">{telefoonnummerMobiel}</Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 40 }}>
            <Typography gutterBottom variant="subtitle1">
              <b>Telefoonnummer (mobiel)</b>
            </Typography>
            <Typography variant="body2">
              {contactTelefoonnummerMobiel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              <b>Telefoonnummer</b>
            </Typography>
            <Typography variant="body2">{telefoonnummer}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              <b>Bedrijfsnaam</b>
            </Typography>
            <Typography variant="body2">{bedrijfsnaam}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              <b>Professionele klant</b>
            </Typography>
            <Typography variant="body2">
              {isProfessioneel ? 'Ja' : 'Nee'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              <b>Opmerking</b>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body2">
              {omschrijving || ''}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : null
}
