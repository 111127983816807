import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

import { linkProjectToProjectdossier } from '../../state'

export interface Props {
  open: boolean
  data: any
  onClose: () => void
}

export const LinkProjectDialog: FC<Props> = ({ open, data = {}, onClose }) => {
  const dispatch = useDispatch()
  const [projectId, setProjectId] = useState('')
  const { dossierId } = data
  const [errors, setErrors] = useState({})

  const validate = () => {
    const idError = !projectId

    setErrors({
      idError,
    })

    return !idError
  }

  const handleClickJa = () => {
    if (validate()) {
      dispatch(linkProjectToProjectdossier({ id: dossierId, projectId }))
    }
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Koppelen project</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          value={projectId}
          onChange={(e) => setProjectId(e.target.value.trim())}
          label="ID van het project"
          fullWidth
          error={errors['idError']}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button onClick={handleClickJa} color="primary">
          Koppelen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
