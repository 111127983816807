import React, { FC } from 'react'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import StyledCard from '@components/StyledCard'

import { Row } from '../../../opdrachten/combi/components/Row'

const headerCells = [
  { id: 'kpi', numeric: false, label: '', sort: false },
  { id: 'opdrachtId', numeric: true, label: 'Opdracht id', sort: false },
  { id: 'clusterId', numeric: false, label: 'Cluster id', sort: false },
  { id: 'realisatiedatum', label: 'Plandatum', sort: false },
  { id: 'discipline', label: 'Discipline', sort: false, fixed: 50 },
  { id: 'adres', numeric: false, label: 'Adres', sort: false },
  { id: 'netbeheerder', numeric: true, label: 'Netbeheerder', sort: false },
  { id: 'aannemer', numeric: true, label: 'Uitvoerende', sort: false },
  { id: 'gebiedsCodeLabel', numeric: false, label: 'Gebied', sort: false },
  {
    id: 'statusText',
    numeric: false,
    label: 'Status',
    sort: false,
    fixed: 150,
  },
  { id: 'changed', label: 'Mutatiedatum', sort: false, fixed: 150 },
  { id: 'details', label: 'Details', sort: false, fixed: 100 },
]

export interface Props {
  opdrachten: any[]
}

export const Opdrachten: FC<Props> = ({ opdrachten }: Props) => {
  return (
    <StyledCard title="Opdrachten">
      <Box sx={{ overflowX: 'auto' }}>
        <Table
          sx={{ minWidth: 750, backgroundColor: '#f7f7f7' }}
          size={'medium'}
        >
          <TableHead>
            <TableRow>
              {headerCells.map((headerCell) => (
                <TableCell
                  key={headerCell.id}
                  sx={{
                    width: headerCell.fixed,
                  }}
                >
                  {headerCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {opdrachten.map((row) => (
              <Row key={row.opdrachtId} row={row} {...({} as any)} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </StyledCard>
  )
}
