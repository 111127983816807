import React from 'react'

import { Autocomplete, TextField } from '@mui/material'

interface Props {
  filterName: string
  items: any[]
  label: string
  onFilterChange: (arg1: any, arg2?: any) => void
  value: any
  groupBy?: (option: any) => string
  isOptionEqualToValue?: (option: any, value: any) => boolean
  multiple?: boolean
  textfieldWidth?: number
}

export default function FilterAutocomplete({
  items,
  label,
  onFilterChange,
  value,
  filterName,
  multiple = false,
  isOptionEqualToValue,
  groupBy,
}: Props) {
  const onChange = (e, value) => {
    onFilterChange({ [filterName]: value })
  }

  return (
    <Autocomplete
      limitTags={2}
      options={items}
      getOptionLabel={(input) => (input.label ? input.label : input)}
      groupBy={groupBy}
      onChange={onChange}
      value={value}
      multiple={multiple}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField variant="standard" {...params} label={label} />
      )}
      isOptionEqualToValue={isOptionEqualToValue || undefined}
    />
  )
}
