import React from 'react'
import { Provider } from 'react-redux'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { nl } from 'date-fns/locale/nl'

import { AlertProvider } from '../../alerts'
import StyledThemeProvider from '../theme/StyledThemeProvider'

export default function Providers({ children, store }) {
  return (
    <StyledThemeProvider>
      {/* The LocalizationProvider provides a context for the MUI DatePicker
      components. To prevent re-mounting this component for every DatePicker
      that is rendered, we just keep it top level here. */}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
        <Provider store={store}>
          <AlertProvider />
          {children}
        </Provider>
      </LocalizationProvider>
    </StyledThemeProvider>
  )
}
