import React from 'react'

import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import HistoryIcon from '@mui/icons-material/History'
import type { PopoverOrigin } from '@mui/material'

import StyledIconButton from '@components/StyledIconButton'
import { createBadge } from '@util/kpi-util'

import type { Project } from '../types'

import Kpi from './Kpi'

interface Props {
  project: Project
  placement?: PopoverOrigin['horizontal']
}

export default function Status({ project, placement }: Props) {
  const badge = project.kpi && createBadge(project.kpi)
  const { isActive, isDirty } = project

  if (!isActive)
    return (
      <StyledIconButton title="Niet actief" icon={<AlarmOffRoundedIcon />} />
    )
  if (isActive && isDirty)
    return (
      <StyledIconButton
        title="Door wijzigingen worden KPI's herberekend"
        icon={<HistoryIcon />}
      />
    )

  return badge ? <Kpi project={project} placement={placement} /> : null
}
