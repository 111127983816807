import React, { useState } from 'react'

import { Card, CardActions, CardContent, Typography } from '@mui/material'

const defaultElevation = 3
const hoverElevation = 24

interface Props {
  actions?: any
  onClick: any
  label: string
  count: number
}

export const DashboardCard = ({ actions, onClick, label, count }: Props) => {
  const [elevation, setElevation] = useState(defaultElevation)

  const handleMouseOver = () => setElevation(hoverElevation)
  const handleMouseOut = () => setElevation(defaultElevation)

  return (
    <Card
      sx={{
        width: '300px',
        minHeight: '228px',
        cursor: 'pointer',
        background:
          'radial-gradient(ellipse at center, #ffffff 0%, #f4f4f4 48%, #eaeaea 100%)',
      }}
      elevation={elevation}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
    >
      <CardContent>
        <Typography
          color="textSecondary"
          gutterBottom
          sx={{ fontSize: '24px' }}
        >
          {label}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{
            fontSize: '56px',
            textAlign: 'right',
            mr: '20px',
            mt: '20px',
            mb: '10px',
          }}
        >
          {count}
        </Typography>
      </CardContent>
      {actions && (
        <CardActions style={{ justifyContent: 'flex-end' }}>
          {actions}
        </CardActions>
      )}
    </Card>
  )
}
