import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { useProgress } from '@hooks/useProgress'

import { apiError, success } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { EntityActiviteitenState } from './types'

const initialState: EntityActiviteitenState = {
  activiteiten: [],
  activiteitenDialog: {
    open: false,
    data: {},
  },
  finishActiviteit: {
    status: 'idle',
  },
}

export const ENTITY_PROJECT = 'project'

export const fetchActiviteitenForEntity = createAsyncThunk(
  'fetch/entity/activiteiten',
  async ({ id, type }: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/${type}/${id}/activiteit/search`,
        method: 'POST',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen activiteiten', error))

      throw error
    }
  },
)

export const createActiviteitForEntity = createAsyncThunk(
  'create/entity/activiteit',
  async ({ entity, activiteitInfo }: any, { dispatch, rejectWithValue }) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/activiteit`

    try {
      const result = await request({
        url,
        method: 'POST',
        data: activiteitInfo,
      })

      dispatch(fetchActiviteitenForEntity(entity))
      dispatch(success('Activiteit successvol aangemaakt'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij aanmaken activiteit', error))

      return rejectWithValue({ error })
    }
  },
)

export const patchActiviteitForEntity = createAsyncThunk(
  'patch/entity/activiteit',
  async (
    { entity, activiteitInfo: { dueDate, text, activiteitId } }: any,
    { dispatch, rejectWithValue },
  ) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/activiteit/${activiteitId}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: { dueDate, text },
      })

      dispatch(fetchActiviteitenForEntity(entity))
      dispatch(success('Activiteit successvol bijgewerkt'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij bijwerken activiteit', error))

      return rejectWithValue({ error })
    }
  },
)

export const cancelActiviteitForEntity = createAsyncThunk(
  'cancel/entity/activiteit',
  async (
    { entity, activiteitInfo: { status, text, activiteitId } }: any,
    { dispatch, rejectWithValue },
  ) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/activiteit/${activiteitId}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: { status, text },
      })

      dispatch(fetchActiviteitenForEntity(entity))
      dispatch(success('Activiteit successvol geannuleerd'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij annuleren activiteit', error))

      return rejectWithValue({ error })
    }
  },
)

export const finishActiviteitForEntity = createAsyncThunk(
  'finish/entity/activiteit',
  async (
    {
      entity,
      activiteitInfo: { status, text, netbeheerderCodes, activiteitId },
    }: any,
    { dispatch, rejectWithValue },
  ) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/activiteit/${activiteitId}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: { status, text, netbeheerderCodes },
      })

      dispatch(fetchActiviteitenForEntity(entity))
      dispatch(success('Activiteit successvol afgehandeld'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij afronden activiteit', error))

      return rejectWithValue({ error })
    }
  },
)

export const createActiviteitenForEntity = createAsyncThunk<
  any,
  {
    entity: any
    activiteiten: any[]
    progress: ReturnType<typeof useProgress>
  }
>(
  'create/entity/activiteiten',
  async ({ entity, activiteiten, progress }, { dispatch, rejectWithValue }) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/activiteit`

    try {
      progress.set({
        current: progress.current,
        total: progress.total,
        error: progress.error,
      })

      for (const activiteitInfo of activiteiten) {
        await request({ url, method: 'POST', data: activiteitInfo })
        progress.bump()
        dispatch(fetchActiviteitenForEntity(entity))
      }

      dispatch(fetchActiviteitenForEntity(entity))
      dispatch(success('Activiteiten successvol aangemaakt'))

      return
    } catch (error: any) {
      progress.fail()
      dispatch(apiError('Fout bij aanmaken activiteit', error))

      return rejectWithValue({ error })
    }
  },
)

const slice = createSlice({
  name: 'entityActiviteit',
  initialState,
  reducers: {
    openActiviteitenDialog: (state, action) => {
      state.activiteitenDialog.open = true
      state.activiteitenDialog.data = action.payload
    },
    closeActiviteitenDialog: (state) => {
      state.activiteitenDialog.open = false
      state.activiteitenDialog.data = {}
    },
    clearEntityActiviteiten: (state) => {
      state.activiteiten = initialState.activiteiten
      state.finishActiviteit = initialState.finishActiviteit
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiviteitenForEntity.fulfilled, (state, action) => {
        state.activiteiten = action.payload
      })
      .addCase(fetchActiviteitenForEntity.rejected, (state) => {
        state.activiteiten = initialState.activiteiten
      })
      .addCase(createActiviteitForEntity.fulfilled, (state) => {
        state.activiteitenDialog.open = false
        state.activiteitenDialog.data = {}
      })
      .addCase(patchActiviteitForEntity.fulfilled, (state) => {
        state.activiteitenDialog.open = false
        state.activiteitenDialog.data = {}
      })
      .addCase(finishActiviteitForEntity.pending, (state) => {
        state.finishActiviteit.status = 'loading'
      })
      .addCase(finishActiviteitForEntity.fulfilled, (state) => {
        state.activiteitenDialog.open = false
        state.activiteitenDialog.data = {}
        state.finishActiviteit.status = 'idle'
      })
      .addCase(finishActiviteitForEntity.rejected, (state) => {
        state.finishActiviteit.status = 'error'
      })
      .addCase(cancelActiviteitForEntity.fulfilled, (state) => {
        state.activiteitenDialog.open = false
        state.activiteitenDialog.data = {}
      })
      .addCase(createActiviteitenForEntity.fulfilled, (state) => {
        state.activiteitenDialog.open = false
        state.activiteitenDialog.data = {}
      })
  },
})

export const getActiviteitenForEntity = (state: RootState) =>
  state.entityActiviteiten.activiteiten
export const isActiviteitenDialogOpen = (state: RootState) =>
  state.entityActiviteiten.activiteitenDialog.open
export const getActiviteitenDialogData = (state: RootState) =>
  state.entityActiviteiten.activiteitenDialog.data
export const isFinishActiviteitLoading = (state: RootState) =>
  state.entityActiviteiten.finishActiviteit.status === 'loading'

export const {
  clearEntityActiviteiten,
  openActiviteitenDialog,
  closeActiviteitenDialog,
} = slice.actions
export const { reducer } = slice
