import React, { useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'

import { getSorting, stableSort } from '@util/sort'

import { AansluitObject } from './AansluitObject'

const headerCells = [
  {
    id: 'disciplines',
    numeric: false,
    label: 'Aangevraagde disciplines',
    sort: false,
  },
  { id: 'objectID', numeric: true, label: 'Object id', sort: true },
  { id: 'type', numeric: false, label: 'Object type', sort: true },
  { id: 'adres', numeric: false, label: 'Adres', sort: true },
  {
    id: 'statusText',
    numeric: false,
    label: 'Processtap',
    sort: true,
    fixed: 150,
  },
  { id: 'opties', numeric: false, label: '', sort: false, fixed: 50 },
]

export default function AansluitObjecten({ project }) {
  const [orderBy, setOrderBy] = useState('objectID')
  const [order, setOrder] = useState<'desc' | 'asc'>('desc')

  const aansluitObjecten = project.aansluitObjecten || []

  const handleSort = (property) => {
    const isDesc = orderBy === property && order === 'desc'

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  return (
    <TableRow>
      <TableCell colSpan={11} sx={{ p: 0, backgroundColor: '#f7f7f7' }}>
        <Table sx={{ p: 0, backgroundColor: '#f7f7f7' }} size={'medium'}>
          <TableHead>
            <TableRow>
              {headerCells.map((headerCell) => (
                <TableCell
                  key={headerCell.id}
                  style={{ width: headerCell.fixed }}
                >
                  {headerCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headerCell.id}
                      direction={order}
                      onClick={() => handleSort(headerCell.id)}
                    >
                      {headerCell.label}
                    </TableSortLabel>
                  ) : (
                    headerCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(aansluitObjecten, getSorting(order, orderBy)).map(
              (row) => (
                <AansluitObject
                  key={`${project.aanvraagID}${row.objectID}`}
                  project={project}
                  row={row}
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  )
}
