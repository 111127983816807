import React from 'react'

import { Table, TableBody } from '@mui/material'

export default function StyledDetailsTable({ children }) {
  return (
    <Table
      sx={{
        m: '40px',
        verticalAlign: 'top',
        maxWidth: 650,
        '& td': {
          p: 0.5,
          fontSize: 18,
          borderBottom: '0px solid',
          fontWeight: 200,
        },
        '& td:first-of-type': {
          width: 219,
          fontWeight: 'bold',
        },
      }}
    >
      <TableBody>{children}</TableBody>
    </Table>
  )
}
