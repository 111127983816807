import React, { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import { isAuthenticated } from '../state'

import { Login } from './Login'
import { Unauthorized } from './Unauthorized'

interface Props {
  allowed?: boolean
}

export const ProtectedComponent: FC<PropsWithChildren<Props>> = ({
  children,
  allowed = false,
}) => {
  const authenticated = useSelector(isAuthenticated)

  if (!authenticated) return <Login />
  if (!allowed) return <Unauthorized />

  return <>{children}</>
}
