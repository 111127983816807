import React from 'react'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import type { Contactpersoon } from '@models/Contactpersoon'
import type { Entity } from '@models/Entity'

import { cancelContactpersoonForEntity } from '../../state'

interface Props {
  data: {
    contactpersoon: Contactpersoon
    entity: Entity
  }
  onClose: () => void
}

export const Delete: FC<Props> = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const { contactpersoon, entity } = data

  const onClick = () => {
    dispatch(
      cancelContactpersoonForEntity({
        persoonId: contactpersoon._id,
        ...entity,
      }),
    )
  }

  return (
    <>
      <DialogTitle>Verwijderen contactpersoon</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Weet u zeker dat u ${contactpersoon.naam} wilt verwijderen als contactpersoon? Dit kunt u niet terugdraaien.`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Nee</Button>
        <Button onClick={onClick}>Ja</Button>
      </DialogActions>
    </>
  )
}
