import React, { useState } from 'react'
import type { FC } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { TableCell, TableRow, Typography } from '@mui/material'

import StyledIconButton from '@components/StyledIconButton'
import type { Contactpersoon as ContactpersoonType } from '@models/Contactpersoon'

import { ContactpersoonLog } from './ContactpersoonLog'

interface Props {
  contactpersoon: ContactpersoonType
  isVerantwoordelijke: boolean
  onClick: (type: 'edit' | 'delete', contactpersoon: ContactpersoonType) => void
}

export const Contactpersoon: FC<Props> = ({
  contactpersoon,
  isVerantwoordelijke,
  onClick,
}) => {
  const { status, rol, naam, telefoonnummer, emailadres } = contactpersoon
  const isDeleted = status === 'cancelled'

  const [logDrawer, setLogDrawer] = useState(false)

  const toggleLogDrawer = () => setLogDrawer(!logDrawer)

  return (
    <TableRow
      sx={{
        ...(isDeleted && {
          backgroundColor: '#E0E0E0 !important',
        }),
      }}
    >
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>
        <div>{naam}</div>
        {isDeleted && <Typography variant="caption">Verwijderd</Typography>}
      </TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{rol}</TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{telefoonnummer}</TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{emailadres}</TableCell>
      <TableCell>
        {isVerantwoordelijke && !isDeleted && (
          <StyledIconButton
            icon={<EditIcon />}
            title="Bewerk dit contactpersoon"
            onClick={() => onClick('edit', contactpersoon)}
          />
        )}
        <StyledIconButton
          title="Bekijk de log voor dit contactpersoon"
          onClick={toggleLogDrawer}
          icon={<InfoIcon />}
        />
        {isVerantwoordelijke && !isDeleted && (
          <StyledIconButton
            icon={<DeleteIcon />}
            title="Verwijder dit contactpersoon"
            onClick={() => onClick('delete', contactpersoon)}
          />
        )}
        {logDrawer && (
          <ContactpersoonLog
            open={logDrawer}
            onClose={toggleLogDrawer}
            contactpersoon={contactpersoon}
          />
        )}
      </TableCell>
    </TableRow>
  )
}
