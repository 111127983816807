import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'

import type { Opdracht } from '../types'

/**
 * Add the `AGA`, `AGP`, and `TG` status texts to the `statusses` array, but only if they are filled
 * @param {Object} input
 * @param {String} input.statusTextAGA AGA status text
 * @param {String} input.statusTextAGP AGP status text
 * @param {String} input.statusTextTG TG status text
 * @param {Array<{title: string, text: string}>} input.statusses the array to add the values to
 */
const addStatusTexts = ({
  statusTextAGA,
  statusTextAGP,
  statusTextTG,
  statusses,
}) => {
  if (statusTextTG) statusses.push({ title: 'Status TG', text: statusTextTG })

  if (statusTextAGA)
    statusses.push({ title: 'Status AGA', text: statusTextAGA })

  if (statusTextAGP)
    statusses.push({ title: 'Status AGP', text: statusTextAGP })
}

const STATUS_OPLEVERING = 500

interface Props {
  opdracht: Opdracht
}

export const Status: FC<Props> = ({ opdracht }) => {
  const {
    status,
    statusText,
    statusTextTG,
    statusTextAGA,
    statusTextAGP,
    beschrijving,
    stadium,
  } = opdracht

  const items = [{ title: 'Status', text: statusText }]

  if (status === STATUS_OPLEVERING) {
    addStatusTexts({
      statusTextAGA,
      statusTextAGP,
      statusTextTG,
      statusses: items,
    })
  }

  // These are later because if the statusAGA, statusAGP and statusTG can be filled, then they're neatly aligned
  items.push(
    { title: 'Beschrijving', text: beschrijving },
    { title: 'Stadium', text: stadium },
  )

  return (
    <>
      <Grid container spacing={2} sx={{ m: 'auto', width: '95%', p: 1 }}>
        {generateTextItems(items)}
      </Grid>
    </>
  )
}

/** Generard a `Grid` > `Typography` or `Grid` > `DisciplineIcon` based on an array. Used for less code duplication */
function generateTextItems(items) {
  return (
    <>
      {items.map((item) => {
        return (
          <Grid key={`${item.title}-${item.text}`} item xs={3}>
            <Typography gutterBottom variant="subtitle1">
              <b>{item.title}</b>
            </Typography>
            <Typography variant="body2">{item.text}</Typography>
          </Grid>
        )
      })}
    </>
  )
}
