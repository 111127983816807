import React from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles'

const maPrimaryHex = '#3d6cb4'
const maSecondaryHex = '#bc358d'

const capoTheme = createTheme({
  palette: {
    secondary: {
      main: maSecondaryHex,
    },
    mijnaansluiting: {
      main: maPrimaryHex,
    },
  },

  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),

    h1: {
      color: maPrimaryHex,
      fontFamily: '"Aller", "Helvetica", "sans-serif"',
      fontSize: '3em',
      fontWeight: 600,
    },

    h2: {
      color: maPrimaryHex,
      fontFamily: '"Aller", "Helvetica", "sans-serif"',
      fontSize: '2.125rem',
      fontWeight: 600,
      letterSpacing: '.02em',
      lineHeight: '120%',
      margin: '40px 0 40px 24px',
    },

    h4: {
      color: maPrimaryHex,
      fontFamily: '"Aller", "Helvetica", "sans-serif"',
      fontSize: 20,
      fontWeight: 200,
      lineHeight: '24px',
    },

    h5: {
      color: maPrimaryHex,
      fontFamily: '"Aller", "Helvetica", "sans-serif"',
      fontSize: '24px',
      fontWeight: 200,
      letterSpacing: '.02em',
      lineHeight: '120%',
      marginLeft: '8px',
      padding: '24px',
      width: '100%',
    },

    caption: {
      opacity: 0.75,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            'radial-gradient(ellipse at center,#ffffff 0%,#f4f4f4 48%,#eaeaea 100%)',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
        },

        head: {
          color: 'rgba(45, 63, 88, 0.55)',
        },
      },
    },

    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
  },
})

export default function StyledThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={capoTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
