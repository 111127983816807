import React from 'react'

import { TableCell, TableRow } from '@mui/material'
import { LinearProgress } from '@mui/material'

export default function TableLoadingBar({ colSpan }) {
  return (
    <TableRow data-testid="loadingbar">
      <TableCell colSpan={colSpan} sx={{ p: 0 }}>
        <LinearProgress
          sx={{
            height: '3px',
            backgroundColor: 'rgb(238, 238, 238)',
            background:
              'linear-gradient(to right, rgba(61, 108, 180, 1) 0%, rgba(79, 186, 219, 1) 100%)',
          }}
        />
      </TableCell>
    </TableRow>
  )
}
