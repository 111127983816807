import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SaveIcon from '@mui/icons-material/Save'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  addWeeks,
  getISOWeek,
  getISOWeeksInYear,
  parseISO,
  startOfDay,
  startOfWeek,
  startOfYear,
} from 'date-fns'

import StyledAlert from '@components/StyledAlert'
import type { DisciplineVerzameling } from '@models/DisciplineVerzameling'
import type {
  NetbeheerderDiscipline,
  NetbeheerderDisciplinesByGebied,
} from '@slices/valuelists.types'
import {
  getGemeenten,
  getNetbeheerderDisciplinesByGebied,
  getTypeWerkTypes,
  getValuelists,
} from '@slices/valuelistsSlice'
import {
  beginningOfYearDateForISOWeek,
  formatWeekYearToISOWeek,
} from '@util/date-util'
import { buildProjectDetailsUpdates } from '@util/project-util'

import { getUser } from '../../../../auth/state'
import { fetchContracten, getContracten } from '../../../../contracten/state'
import type { HoofdleidingProject } from '../../../../hoofdleiding/projecten/types'
import {
  closeProjectDialog,
  createHoofdleidingProject,
  getHoofdleidingProjectDialogData,
  getHoofdleidingProjectDialogOpen,
  getHoofdleidingProjectDialogType,
  updateHoofdleidingEvent,
} from '../../state'

const MINIMUM_WEEKS_START_UITVOERING_FROM_TODAY = 15
const MAX_WEEKS_IN_YEAR = 53

const initialProject: Partial<HoofdleidingProject> = {
  aannemers: [],
  adres: '',
  coordAannemer: [],
  disciplineVerzamelingen: [],
  doorlooptijdWeken: undefined,
  gemeente: undefined,
  externeReferentie: '',
  naam: '',
  netbeheerders: [],
  startUitvoeringWeek: undefined,
  type: 'Hoofdleiding',
  typeWerk: undefined,
  statusText: 'Intake',
  labels: [],
}

export default function CreateEditProjectDialog() {
  const dispatch = useDispatch()
  const { deelnemer } = useSelector(getUser)
  const {
    data,
    gemeenten,
    type,
    open,
    netbeheerderDisciplinesByGebied,
    contracten,
    valuelists,
    typeWerkTypes,
  } = useSelector((state) => ({
    data: getHoofdleidingProjectDialogData(state),
    gemeenten: getGemeenten(state),
    netbeheerderDisciplinesByGebied: getNetbeheerderDisciplinesByGebied(state),
    type: getHoofdleidingProjectDialogType(state),
    open: getHoofdleidingProjectDialogOpen(state),
    contracten: getContracten(state),
    valuelists: getValuelists(state),
    typeWerkTypes: getTypeWerkTypes(state),
  }))

  const [project, setProject] = useState(initialProject)
  const [startUitvoeringWeek, setStartUitvoeringWeek] = useState<number | null>(
    null,
  )
  const [startUitvoeringJaar, setStartUitvoeringJaar] = useState<Date | null>(
    null,
  )
  const [aanvraagDatum, setAanvraagDatum] = useState<Date | null>(null)
  const [errors, setErrors] = useState<
    Partial<Record<keyof HoofdleidingProject, boolean>>
  >({})
  const [displayWeekWarning, setDisplayWeekWarning] = useState(false)
  const [netbeheerderDisciplines, setNetbeheerderDisciplines] = useState<
    NetbeheerderDiscipline[]
  >([])
  const selectableLabels =
    valuelists.gebieden.find((g) => g.code && g.code === project.gebiedscode)
      ?.labels || []

  const [aannemers, setAannemers] = useState<string[]>([])

  const isUpdateProject = type === 'edit'

  const reset = () => {
    setErrors({})
    setProject(initialProject)
    setStartUitvoeringWeek(null)
    setStartUitvoeringJaar(null)
    setAanvraagDatum(null)
  }

  const validate = () => {
    const doorlooptijdWekenError =
      !!project.doorlooptijdWeken && project.doorlooptijdWeken < 1
    let startUitvoeringWeekError =
      !!startUitvoeringWeek &&
      (startUitvoeringWeek > MAX_WEEKS_IN_YEAR || startUitvoeringWeek < 1)
    const startUitvoeringJaarError =
      !!startUitvoeringJaar &&
      startUitvoeringJaar.getFullYear() < new Date().getFullYear()
    const disciplinesError =
      !!project.gebiedscode && !project.disciplineVerzamelingen?.length

    // Validate week 53
    if (
      !startUitvoeringWeekError &&
      !startUitvoeringJaarError &&
      !!startUitvoeringJaar &&
      startUitvoeringWeek === MAX_WEEKS_IN_YEAR
    ) {
      startUitvoeringWeekError =
        getISOWeeksInYear(startUitvoeringJaar) !== MAX_WEEKS_IN_YEAR
    }

    startUitvoeringWeekError =
      startUitvoeringWeekError || startUitvoeringJaarError
    const tracelengteError = !!project.tracelengte && project.tracelengte < 1
    const woningenError = !!project.woningen && project.woningen < 1
    const appartementenError =
      !!project.appartementen && project.appartementen < 1

    setErrors({
      naam: !project.naam,
      gemeente: !project.gemeente,
      gebiedscode: !project.gebiedscode,
      typeWerk: !project.typeWerk,
      statusText: !project.statusText,
      adres: !project.adres,
      doorlooptijdWeken: doorlooptijdWekenError,
      startUitvoeringWeek: startUitvoeringWeekError,
      disciplineVerzamelingen: disciplinesError,
      tracelengte: tracelengteError,
      woningen: woningenError,
      appartementen: appartementenError,
    })

    return (
      !!project.naam &&
      !!project.gemeente &&
      !!project.gebiedscode &&
      !!project.typeWerk &&
      !!project.statusText &&
      !!project.adres &&
      !doorlooptijdWekenError &&
      !startUitvoeringWeekError &&
      !disciplinesError &&
      !tracelengteError &&
      !woningenError &&
      !appartementenError
    )
  }

  const handleSubmit = () => {
    const isValid = validate()

    if (!isValid) {
      return
    }

    const request: Partial<HoofdleidingProject> = { ...project }

    if (startUitvoeringJaar && startUitvoeringWeek) {
      request.startUitvoeringWeek = formatWeekYearToISOWeek(
        startUitvoeringJaar,
        startUitvoeringWeek,
      )
    }

    if (aanvraagDatum) {
      request.aanvraagDatum = aanvraagDatum.toISOString()
    }

    if (type === 'edit') {
      if (project.aanvraagID) {
        dispatch(
          updateHoofdleidingEvent({
            type: 'hoofdleiding_details',
            id: project.aanvraagID,
            data: buildProjectDetailsUpdates(data, request),
            source: 'manual',
          }),
        )
      }
    } else {
      dispatch(createHoofdleidingProject(request))
    }
  }

  const handleClose = () => {
    dispatch(closeProjectDialog())
  }

  /** Watch year and week, set warning accordingly. */
  useEffect(() => {
    if (!startUitvoeringJaar || !startUitvoeringWeek) {
      setDisplayWeekWarning(false)
      return
    }

    const today = startOfDay(new Date())
    const fifteenWeeksFromNow = startOfWeek(
      addWeeks(today, MINIMUM_WEEKS_START_UITVOERING_FROM_TODAY),
    )

    const year = startOfYear(startUitvoeringJaar)
    const uitvoeringsweek = startOfWeek(addWeeks(year, startUitvoeringWeek))

    setDisplayWeekWarning(uitvoeringsweek < fifteenWeeksFromNow)
  }, [startUitvoeringJaar, startUitvoeringWeek])

  useEffect(() => {
    if (open) {
      reset()
      setProject((project) => ({ ...project, ...data }))

      if (data.startUitvoeringWeek) {
        setStartUitvoeringJaar(
          beginningOfYearDateForISOWeek(data.startUitvoeringWeek),
        )
        setStartUitvoeringWeek(getISOWeek(parseISO(data.startUitvoeringWeek)))
      }

      if (data.aanvraagDatum) {
        setAanvraagDatum(new Date(data.aanvraagDatum))
      }
    }
  }, [open, data, dispatch])

  useEffect(() => {
    const disciplines = netbeheerderDisciplinesByGebied.find(
      ({ gebiedscode }) => project.gebiedscode === gebiedscode,
    )

    setNetbeheerderDisciplines(
      deelnemer
        ? disciplines?.netbeheerderDisciplines.filter(
            ({ netbeheerderLabel }) => netbeheerderLabel === deelnemer,
          ) || []
        : disciplines?.netbeheerderDisciplines || [],
    )

    const contract = contracten.find(
      ({ gebiedscode }) => project.gebiedscode === gebiedscode,
    )

    setAannemers([
      ...new Set(
        contracten
          .filter(({ combi }) => contract?.combi === combi)
          .map((contract) => contract.coordAannemer)
          .flat(),
      ),
    ])

    if (!isUpdateProject) {
      setProject((project) => ({ ...project, disciplineVerzamelingen: [] }))
    }
  }, [
    project.gebiedscode,
    netbeheerderDisciplinesByGebied,
    isUpdateProject,
    deelnemer,
    contracten,
  ])

  useEffect(() => {
    if (gemeenten.length === 0) {
      dispatch(fetchContracten())
    }
  }, [dispatch, gemeenten])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
      <DialogTitle>
        {isUpdateProject ? 'Wijzigen' : 'Aanmaken'} project
      </DialogTitle>

      <DialogContent>
        {displayWeekWarning && (
          <StyledAlert severity="warning">
            De voorbereidingstijd van een hoofdleidingproject is normaal 15
            weken. De huidig geselecteerde data zorgen voor een kortere
            voorbereidingstijd.
          </StyledAlert>
        )}

        <Box sx={{ m: 'auto', width: '95%', p: 1 }}>
          <FormControl sx={{ width: '100%', p: 1 }}>
            <TextField
              variant="standard"
              value="Hoofdleiding"
              label="Type"
              disabled
            />
          </FormControl>
          <FormControl
            sx={{ width: '100%', p: 1, display: 'flex', flexDirection: 'row' }}
          >
            <TextField
              sx={{ width: '50%', pr: 2 }}
              variant="standard"
              value={project.externeReferentie}
              label="Externe referentie"
              inputProps={{ maxLength: 25 }}
              onChange={(e) =>
                setProject((project) => ({
                  ...project,
                  externeReferentie: e.target.value,
                }))
              }
              error={errors.externeReferentie}
            />
            <TextField
              sx={{ width: '50%' }}
              variant="standard"
              value={project.naam}
              label="Naam"
              inputProps={{ maxLength: 100 }}
              onChange={(e) =>
                setProject((project) => ({ ...project, naam: e.target.value }))
              }
              error={errors.naam}
            />
          </FormControl>
          <FormControl
            sx={{ width: '100%', p: 1, display: 'flex', flexDirection: 'row' }}
          >
            <Autocomplete
              disabled
              options={[]}
              sx={{ width: '50%', pr: 2 }}
              value={project.statusText}
              renderInput={(params) => (
                <TextField variant="standard" {...params} label="Processtap" />
              )}
            />

            <Autocomplete
              options={gemeenten}
              sx={{ width: '50%' }}
              onChange={(_, gemeente) =>
                setProject((project) => ({
                  ...project,
                  gemeente: gemeente as string,
                }))
              }
              value={project.gemeente || null}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Gemeente"
                  error={errors.gemeente}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: '100%', p: 1 }}>
            <TextField
              placeholder="Straatnamen, huisnummers, postcodes en woonplaatsen"
              variant="standard"
              value={project.adres}
              multiline
              rows={3}
              label={`Adres (${project.adres?.length || 0}/100)`}
              inputProps={{ maxLength: 100 }}
              onChange={(e) =>
                setProject((project) => ({ ...project, adres: e.target.value }))
              }
              error={errors.adres}
            />
          </FormControl>
          <FormControl
            sx={{ width: '100%', p: 1, display: 'flex', flexDirection: 'row' }}
          >
            <Autocomplete
              disabled={isUpdateProject || !!data.linkedProjectdossier}
              sx={{ width: '50%', pr: 2 }}
              options={netbeheerderDisciplinesByGebied}
              onChange={(_, netbeheerderDisciplineByGebied) =>
                setProject((project) => ({
                  ...project,
                  labels: [],
                  gebiedscode: (
                    netbeheerderDisciplineByGebied as NetbeheerderDisciplinesByGebied
                  )?.gebiedscode,
                }))
              }
              value={
                netbeheerderDisciplinesByGebied.find(
                  (g) => g.gebiedscode === project.gebiedscode,
                ) || null
              }
              isOptionEqualToValue={(option, value) =>
                option.gebiedscode === value.gebiedscode
              }
              getOptionLabel={(option) => option.gebiedscodeLabel}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Gebied"
                  error={errors.gebiedscode}
                />
              )}
            />

            <Autocomplete
              sx={{ width: '50%' }}
              options={typeWerkTypes}
              onChange={(_, type) =>
                setProject((project) => ({
                  ...project,
                  typeWerk: type as HoofdleidingProject['typeWerk'],
                }))
              }
              value={project.typeWerk || null}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Type werk"
                  error={errors.typeWerk}
                />
              )}
            />
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              p: 1,
            }}
          >
            <DatePicker
              label="Aanvraagdatum"
              value={aanvraagDatum}
              onChange={(date) => setAanvraagDatum(date)}
              displayWeekNumber
              disableFuture
              slotProps={{
                field: { clearable: true },
                textField: {
                  variant: 'standard',
                  sx: { width: '33%', pr: 2 },
                },
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              p: 1,
            }}
          >
            <DatePicker
              disabled={isUpdateProject}
              views={['year']}
              disablePast
              label="Jaar start uitvoering"
              value={startUitvoeringJaar || null}
              onChange={(date) => setStartUitvoeringJaar(date)}
              openTo="year"
              displayWeekNumber
              slotProps={{
                field: { clearable: true },
                textField: {
                  variant: 'standard',
                  sx: { width: '33%', pr: 2 },
                  error: Boolean(errors.startUitvoeringWeek),
                },
              }}
            />

            <TextField
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 53,
                  'data-testid': 'startUitvoeringWeek-field',
                },
              }}
              disabled={isUpdateProject}
              sx={{ width: '33%', pr: 2 }}
              variant="standard"
              type="number"
              value={startUitvoeringWeek || ''}
              label={'Week start uitvoering'}
              onChange={(e) => setStartUitvoeringWeek(Number(e.target.value))}
              error={errors.startUitvoeringWeek}
            />

            <TextField
              sx={{ width: '33%' }}
              disabled={isUpdateProject}
              InputProps={{
                inputProps: { min: 1, 'data-testid': 'doorlooptijd-field' },
              }}
              variant="standard"
              type="number"
              value={
                typeof project.doorlooptijdWeken === 'number'
                  ? project.doorlooptijdWeken
                  : ''
              }
              label="Doorlooptijd (in weken)"
              onChange={(e) =>
                setProject((project) => ({
                  ...project,
                  doorlooptijdWeken: Number(e.target.value),
                }))
              }
              error={errors.doorlooptijdWeken}
            />
          </FormControl>
          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              p: 1,
            }}
          >
            <Autocomplete
              sx={{ width: '50%' }}
              multiple
              disabled={!project.gebiedscode || isUpdateProject}
              options={netbeheerderDisciplines}
              isOptionEqualToValue={(option, value) => {
                return (
                  option.netbeheerderCode === value.netbeheerderCode &&
                  option.disciplineCode === value.disciplineCode
                )
              }}
              getOptionLabel={(option) => {
                return `${option.netbeheerderLabel} - ${option.disciplineLabel}`
              }}
              onChange={(_, disc) =>
                setProject((project) => ({
                  ...project,
                  disciplineVerzamelingen: disc as DisciplineVerzameling[],
                }))
              }
              value={project.disciplineVerzamelingen}
              renderInput={(params) => {
                return (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Disciplines"
                    error={errors.disciplineVerzamelingen}
                  />
                )
              }}
            />
            <Autocomplete
              sx={{ width: '50%', pl: 1 }}
              disabled={isUpdateProject}
              options={aannemers}
              onChange={(_, aannemer) =>
                setProject((project) => ({
                  ...project,
                  aannemers: aannemer ? [aannemer] : [],
                }))
              }
              value={
                project.aannemers && project.aannemers.length > 0
                  ? project.aannemers[0]
                  : null
              }
              renderInput={(params) => {
                return (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Aannemer"
                    error={errors.aannemers}
                  />
                )
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              p: 1,
            }}
          >
            <Autocomplete
              sx={{ width: '50%' }}
              disabled={!selectableLabels.length || isUpdateProject}
              filterSelectedOptions
              options={selectableLabels}
              multiple
              fullWidth
              value={project.labels}
              onChange={(_, labels) => setProject({ ...project, labels })}
              renderInput={(params) => (
                <TextField variant="standard" {...params} label="Labels" />
              )}
            />
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: 1,
            }}
          >
            <TextField
              fullWidth
              variant="standard"
              label="Tracelengte"
              type="number"
              sx={{
                paddingRight: 2,
              }}
              value={
                typeof project.tracelengte === 'number'
                  ? project.tracelengte
                  : ''
              }
              helperText="in meters"
              onChange={(e) =>
                setProject({
                  ...project,
                  tracelengte: parseInt(e.target.value),
                })
              }
              error={errors.tracelengte}
            />
            <TextField
              fullWidth
              variant="standard"
              label="Aantal woningen"
              type="number"
              sx={{
                paddingRight: 2,
              }}
              value={
                typeof project.woningen === 'number' ? project.woningen : ''
              }
              helperText="Grondgebonden"
              onChange={(e) =>
                setProject({
                  ...project,
                  woningen: parseInt(e.target.value),
                })
              }
              error={errors.woningen}
            />
            <TextField
              fullWidth
              variant="standard"
              type="number"
              label="Aantal appartementen"
              value={
                typeof project.appartementen === 'number'
                  ? project.appartementen
                  : ''
              }
              onChange={(e) =>
                setProject({
                  ...project,
                  appartementen: parseInt(e.target.value),
                })
              }
              error={errors.appartementen}
            />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleClose} autoFocus>
          Annuleren
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          startIcon={<SaveIcon />}
        >
          {isUpdateProject ? 'Wijzigen' : 'Aanmaken'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
