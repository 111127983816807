import React from 'react'

import { Alert, AlertTitle } from '@mui/material'

export default function AlertBlock({ children, severity, title = undefined }) {
  return (
    <Alert severity={severity} sx={{ mb: '1em' }}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </Alert>
  )
}
