const WEEKS_BEFORE_START_UITVOERING_PER_COMBI = {
  SION: 3,
  'Infra-SV': 3,
  CINH: 5,
  'Structin Noord': 5,
  CIAMS: 4,
  'Infra-SDO': 5,
  Default: 0,
}

export function getWeeksBeforeStartUitvoering(combi) {
  return (
    WEEKS_BEFORE_START_UITVOERING_PER_COMBI[combi] ||
    WEEKS_BEFORE_START_UITVOERING_PER_COMBI.Default
  )
}
