import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'

import { apiError, success } from '../alerts/state'
import { request } from '../gateways/api'
import { closeCreateDialog, fetchProjectmelding } from '../projectmelding/state'
import type { RootState } from '../store'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: { gebied: [], status: null, open: true },
    page: { skip: 0, limit: 25 },
  },
  dialog: {
    open: false,
    data: {},
  },
}

export const createFilters = ({ gebied, status, open }) => {
  const filters: IFilter[] = []

  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (status)
    filters.push({ name: 'status', value: status.code, operator: 'eq' })
  if (open) filters.push({ name: 'status', value: 'gereed', operator: 'ne' })

  return filters
}

export const fetchProjectdossiers = createAsyncThunk(
  'fetch/projectdossiers',
  async (payload: any, { dispatch }) => {
    const { filters, page } = payload

    try {
      const result = await request({
        url: '/rest/projectdossier/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van projectdossiers', error))

      throw error
    }
  },
)

export const createProjectdossier = createAsyncThunk(
  'create/projectdossier',
  async (projectdossier: any, { dispatch, getState }) => {
    try {
      const result = await request({
        url: '/rest/projectdossier',
        method: 'POST',
        data: projectdossier,
      })

      const {
        projectdossiers: { query },
      } = getState() as any

      // if it was linked to a projectmelding, also refresh the melding itself
      if (result.projectmeldingId) {
        dispatch(fetchProjectmelding({ id: result.projectmeldingId }))
        dispatch(closeCreateDialog())
      }

      dispatch(success('Projectdossier successvol aangemaakt'))
      dispatch(fetchProjectdossiers(query))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij aanmaken projectdossier', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'projectdossiers',
  initialState,
  reducers: {
    openDialogProjectdossiers: (state, action) => {
      state.dialog.open = true
      state.dialog.data = action.payload
    },
    closeDialogProjectdossiers: (state) => {
      state.dialog.open = false
      state.dialog.data = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectdossiers.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchProjectdossiers.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchProjectdossiers.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
      .addCase(createProjectdossier.fulfilled, (state) => {
        state.dialog.open = false
      })
  },
})

export const getProjectdossiers = (state: RootState) => ({
  data: state.projectdossiers.data,
  isLoading: state.projectdossiers.status === 'loading',
  query: state.projectdossiers.query,
})
export const isDialogOpenProjectdossiers = (state: RootState) =>
  state.projectdossiers.dialog.open

export const { reducer } = slice
export const { openDialogProjectdossiers, closeDialogProjectdossiers } =
  slice.actions
