import React, { FC, PropsWithChildren } from 'react'

import { Box, Grid } from '@mui/material'
import type { BoxTypeMap, GridTypeMap } from '@mui/system'

const PADDING_DEFAULT = '48px'

type FilterGridContainerProps = {
  gridProps?: GridTypeMap['props']
  boxProps?: BoxTypeMap['props']
} & PropsWithChildren

/** This component is just a small abstraction layer over the actual MUI
 * components. It is designed to be overridable by everything that the MUI
 * `<Box />` (boxProps) and the MUI `<Grid container />` (gridProps) normally
 * takes as props, but has some defaults baked into it. The only non-overridable
 * prop is that this is a `container`. That can not be changed. */
const FilterGridContainer: FC<FilterGridContainerProps> = ({
  boxProps = {},
  children,
  gridProps = {},
}) => {
  return (
    <Box
      {...boxProps}
      sx={{
        pt: PADDING_DEFAULT,
        px: `calc(${PADDING_DEFAULT} + 2.5vw)`,
        pb: 0,
        ...boxProps.sx,
      }}
    >
      <Grid spacing={2} {...gridProps} container>
        {children}
      </Grid>
    </Box>
  )
}

export default FilterGridContainer
