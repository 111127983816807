import React, { FC, useState } from 'react'

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import LaunchIcon from '@mui/icons-material/Launch'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'

import StyledIconButton from '@components/StyledIconButton'
import { formatDate, formatDateTime, pastDay } from '@util/date-util'

import { ActiviteitLog } from './ActiviteitLog'
import { ActiviteitOpmerking } from './ActiviteitOpmerking'
import { WowDrawer } from './WowDrawer'

const statusLabels = {
  open: 'Open',
  done: 'Afgerond',
  cancelled: 'Geannuleerd',
  retry: 'Opnieuw proberen',
  failed: 'Gefaald',
}

const getStatusLabel = (status) => {
  const label = statusLabels[status]

  return label || status
}

export const Activiteit: FC<any> = ({
  activiteit,
  user,
  handleClickFinish,
  handleClickEdit,
  handleClickCancel,
}) => {
  const {
    _id,
    type,
    assignedTo,
    status,
    created = {},
    completed,
    data,
    dueDate = null,
    text,
  } = activiteit
  const { organisatie, date: createdAt } = created
  const [isExpanded, setIsExpanded] = useState(false)
  const [logDrawer, setLogDrawer] = useState(false)
  const assignedToOrganisatie =
    assignedTo &&
    (assignedTo.includes(user.combi) ||
      assignedTo.includes(user.deelnemer) ||
      assignedTo.includes(user.delegerende))
  const isCompleted = activiteit.completed?.date && true
  const isKto = type.toLowerCase() === 'kto'
  const canSubmitKto = isKto && status === 'open'
  const isGraafmelding = type.toLowerCase() === 'graafmelding'
  const isVergunning = type.toLowerCase() === 'vergunning'
  const isStraatWerk = type.toLowerCase() === 'straatwerk'
  const changeIsExpanded = () => setIsExpanded(!isExpanded)
  const changeLogDrawer = () => setLogDrawer(!logDrawer)
  const editable =
    !isGraafmelding &&
    !isVergunning &&
    [user.combi, user.deelnemer, user.organisatie].includes(
      created.organisatie,
    ) &&
    !isCompleted

  // An activiteit should only get the icon if it is not completed post end date
  // The ? : statement is to catch dueDate not being filled, which would throw an exception which would make it false
  const shouldMarkActiviteitAsExpired =
    pastDay(activiteit.dueDate) && !completed ? true : false

  return (
    <TableRow
      sx={{ ...(isCompleted && { backgroundColor: '#E0E0E0 !important' }) }}
    >
      <TableCell>
        {shouldMarkActiviteitAsExpired && (
          <Tooltip title="De opleverdatum van deze activiteit is verstreken">
            <ErrorIcon sx={{ color: 'red' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{assignedTo && assignedTo.join(', ')}</TableCell>
      <TableCell>
        <ActiviteitOpmerking
          status={status}
          isVergunning={isVergunning}
          text={text}
          completed={completed}
          data={data}
        />
      </TableCell>
      <TableCell>{formatDate(dueDate) || ''}</TableCell>
      <TableCell>{formatDateTime(createdAt)}</TableCell>
      <TableCell>{organisatie}</TableCell>
      <TableCell>{getStatusLabel(status)}</TableCell>
      <TableCell>
        {editable && (
          <StyledIconButton
            title="Bewerken"
            onClick={handleClickEdit}
            icon={<EditIcon />}
          />
        )}
        {!isGraafmelding && !isVergunning && (
          <StyledIconButton
            title="Activiteit log"
            onClick={changeLogDrawer}
            icon={<InfoIcon />}
          />
        )}
        {assignedToOrganisatie && !isKto && !isCompleted && !isStraatWerk && (
          <StyledIconButton
            title="Afhandelen"
            onClick={() =>
              handleClickFinish({ key: _id, type: 'resolve', activiteit })
            }
            icon={<PlaylistPlayIcon />}
          />
        )}
        {Array.isArray(assignedTo) &&
          assignedTo.length === 0 &&
          [user.combi, user.deelnemer, user.organisatie].includes(
            created.organisatie,
          ) &&
          !isKto &&
          !isCompleted &&
          !isStraatWerk && (
            <StyledIconButton
              title="Annuleren"
              onClick={() =>
                handleClickCancel({ key: _id, type: 'cancel', activiteit })
              }
              icon={<CancelIcon />}
            />
          )}

        {assignedToOrganisatie && isKto && canSubmitKto && (
          <StyledIconButton
            title="Verzenden KTO"
            onClick={() =>
              handleClickFinish({ key: _id, type: 'kto', activiteit })
            }
            icon={<AssignmentTurnedInIcon />}
          />
        )}
        {isGraafmelding && (
          <>
            <StyledIconButton
              title="Informatie graafmelding"
              onClick={changeIsExpanded}
              icon={<InfoIcon />}
            />
            {data?.graafmelding?.portalUrl && (
              <Tooltip title="Bekijk deze graafmelding in het WoW-portaal">
                <a
                  href={data.graafmelding.portalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="Navigeer naar het WoW portaal">
                    {' '}
                    <LaunchIcon />{' '}
                  </IconButton>
                </a>
              </Tooltip>
            )}
          </>
        )}
        {isVergunning && (
          <>
            <StyledIconButton
              title="Informatie vergunning"
              onClick={changeIsExpanded}
              icon={<InfoIcon />}
            />
            {data?.vergunning?.portalUrl && (
              <Tooltip title="Bekijk deze vergunning in het WoW-portaal">
                <a
                  href={data.vergunning.portalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="Navigeer naar het WoW portaal">
                    {' '}
                    <LaunchIcon />{' '}
                  </IconButton>
                </a>
              </Tooltip>
            )}
          </>
        )}
        {isStraatWerk && data?.graafmelding?.portalUrl && (
          <Tooltip title="Bekijk deze graafmelding in het WoW-portaal">
            <a
              href={data.graafmelding.portalUrl}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton aria-label="Navigeer naar het WoW portaal">
                {' '}
                <LaunchIcon />{' '}
              </IconButton>
            </a>
          </Tooltip>
        )}
        {isExpanded && (
          <WowDrawer
            isExpanded={isExpanded}
            activiteit={activiteit}
            onClose={changeIsExpanded}
          />
        )}
        {logDrawer && (
          <ActiviteitLog
            open={logDrawer}
            onClose={changeLogDrawer}
            activiteit={activiteit}
          />
        )}
      </TableCell>
    </TableRow>
  )
}
