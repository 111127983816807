import React from 'react'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { cancelBijlageForEntity } from '../../state'
import type { AssignedBijlage, Bijlage } from '../../types'

interface Props {
  data: {
    bijlage: Bijlage | AssignedBijlage
  }
  onClose: () => void
}

const isAssignedBijlage = (bijlage: any): bijlage is AssignedBijlage =>
  bijlage && Object.keys(bijlage).includes('assignedTo')

export const Cancel: FC<Props> = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const { bijlage } = data
  const isAssigned = isAssignedBijlage(bijlage)

  const naam = isAssigned
    ? `${bijlage.documentsoort} ${bijlage.discipline}`
    : bijlage.naam

  const onClick = () => {
    dispatch(
      cancelBijlageForEntity({
        entity: bijlage.entity,
        id: bijlage._id,
        isAssigned,
      }),
    )
  }

  return (
    <>
      <DialogTitle>Annuleren bijlage</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Weet u zeker dat u de bijlage ${naam} wilt annuleren?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick}>Ja</Button>
        <Button onClick={onClose}>Nee</Button>
      </DialogActions>
    </>
  )
}
