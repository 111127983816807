import React, { useState } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, Popover, Typography } from '@mui/material'

interface Props {
  opdrachtnemer?: string
  aannemer: string
  addComma?: boolean
}

export default function Uitvoerende({
  opdrachtnemer,
  aannemer,
  addComma = false,
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const displayOpdrachtnemer = opdrachtnemer ? true : false

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        aria-label="uitvoerende-popover"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          mr: 1,
          '&:hover': {
            cursor: opdrachtnemer ? 'help !important' : '',
          },
        }}
      >
        <Typography variant="body2">
          {displayOpdrachtnemer ? opdrachtnemer : aannemer}
        </Typography>
        {displayOpdrachtnemer && (
          <>
            <InfoIcon
              sx={{ pl: 0.3, width: '18px', height: '18px', mb: '2px' }}
            />
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
                '& .MuiPopover-paper': {
                  p: 0.5,
                  backgroundColor: 'rgba(97,97,97,.9)',
                  color: 'white',
                },
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div>
                <Typography sx={{ fontSize: '14px' }}>
                  Toegewezen aannemer: {aannemer}
                </Typography>
              </div>
            </Popover>
          </>
        )}
        {addComma ? ', ' : ''}
      </Box>
    </React.Fragment>
  )
}
