import React from 'react'
import { useDispatch } from 'react-redux'

import type { Project } from '../project/types'

import { Bijlagen } from './components/Bijlagen'
import { Gegevens } from './components/Gegevens'
import { openKlantbijlage } from './state'

export interface Props {
  aanvraagID: Project['aanvraagID']
}

export const Klant = ({ aanvraagID }: Props) => {
  const dispatch = useDispatch()

  const onClick = (bijlageId) =>
    dispatch(openKlantbijlage({ id: aanvraagID, bijlageId }))

  return (
    <>
      <Gegevens />
      <Bijlagen onClick={onClick} />
    </>
  )
}
