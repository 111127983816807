import React from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import LaunchIcon from '@mui/icons-material/Launch'
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material'

import Label from '@components/Label'
import { MyListButton } from '@components/MyListButton'
import StyledIconLink from '@components/StyledIconLink'
import type { Activiteit as ActiviteitType } from '@models/Activiteit'
import { formatDate, formatDateTime, pastDay } from '@util/date-util'

export interface Props {
  activiteit: ActiviteitType
}

export const Row = ({ activiteit }: Props) => {
  const {
    _id,
    type,
    text,
    changed,
    assignedTo,
    dueDate,
    entity: { id: entityId, type: entityType, gebiedscodeLabel },
    completed,
    searchValues,
    myList,
    labels,
  } = activiteit
  const { organisatie, date: created } = activiteit.created

  // An activiteit should only get the icon if it is not completed post end date
  // The ? : statement is to catch dueDate not being filled, which would throw an exception which would make it false
  const shouldMarkActiviteitAsExpired =
    pastDay(activiteit.dueDate) && !completed ? true : false

  return (
    <TableRow key={_id}>
      <TableCell>
        {shouldMarkActiviteitAsExpired && (
          <Tooltip title="De opleverdatum van deze activiteit is verstreken">
            <ErrorIcon sx={{ color: 'red' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{type}</TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{text} </TableCell>
      <TableCell>{formatDate(dueDate)}</TableCell>
      <TableCell>{formatDateTime(created)}</TableCell>
      <TableCell>{organisatie}</TableCell>
      <TableCell>{assignedTo && assignedTo.join(', ')}</TableCell>
      <TableCell>{formatDateTime(changed)}</TableCell>
      <TableCell>
        {searchValues?.naam}
        <br />
        <Typography variant="caption">
          <span>#</span>
          {entityId}
        </Typography>
      </TableCell>
      <TableCell>{searchValues?.gemeente}</TableCell>
      <TableCell>
        {gebiedscodeLabel}
        <br />
        {labels && labels.map((l) => <Label text={l} key={l} small={true} />)}
      </TableCell>{' '}
      <TableCell>
        <MyListButton
          type="hoofdleiding"
          disabled={true}
          active={myList}
          aanvraagID={''}
        />

        <StyledIconLink
          icon={<LaunchIcon />}
          title={`Navigeer naar ${entityType} ${entityId}`}
          href={`/hoofdleiding/${entityType}/${entityId}`}
        />
      </TableCell>
    </TableRow>
  )
}
