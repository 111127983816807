import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { TableCell, TableRow } from '@mui/material'

import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledTable from '@components/StyledTable'

import { getKlantgegevens } from '../state'

const headerCells = [
  { id: 'bijlageID', numeric: true, label: 'BijlageID', sort: false },
  { id: 'bestandsnaam', numeric: false, label: 'Bestandsnaam', sort: false },
  { id: 'mimeType', numeric: false, label: 'Mimetype', sort: false },
  { id: 'opties', label: 'Opties', sort: false, fixed: 50 },
]

export interface Props {
  onClick: (id: string) => void
}

export const Bijlagen: FC<Props> = ({ onClick }: Props) => {
  const gegevens = useSelector(getKlantgegevens)
  const { bijlagen } = gegevens

  return bijlagen ? (
    <StyledCard title="Bijlagen">
      <StyledTable variant="details" headerCells={headerCells} {...({} as any)}>
        {bijlagen &&
          bijlagen.map((row) => (
            <TableRow key={row.bijlageID}>
              <TableCell>{row.bijlageID}</TableCell>
              <TableCell>{row.bestandsnaam}</TableCell>
              <TableCell>{row.mimeType}</TableCell>
              <TableCell>
                <StyledIconButton
                  title="Bijlage openen"
                  onClick={() => onClick(row.bijlageID)}
                  icon={<CloudDownloadIcon />}
                />
              </TableCell>
            </TableRow>
          ))}
      </StyledTable>
    </StyledCard>
  ) : (
    <></>
  )
}
