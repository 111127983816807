import React from 'react'

interface Props {
  postcode?: string
  plaats?: string
  straatNaam?: string
  huisnummer?: string
  toevoeging?: string
}

export default function Adres({
  postcode,
  plaats,
  straatNaam,
  huisnummer,
  toevoeging,
}: Props) {
  if (straatNaam) {
    const nummer = toevoeging ? `${huisnummer} ${toevoeging}` : huisnummer

    return (
      <>
        <span>
          {straatNaam} {nummer}
        </span>
        <br />
        <span>
          {postcode} {plaats}
        </span>
      </>
    )
  }

  return (
    <>
      {postcode} {plaats}
    </>
  )
}

export function ProjectAdres(project) {
  if (!project) return ''
  if (project.adresAttributen) {
    const { straat: straatNaam, huisnummerToevoeging: toevoeging } =
      project.adresAttributen
    const props = { ...project.adresAttributen, straatNaam, toevoeging }

    return <Adres {...props} />
  }
  if (project.adres) {
    const lines = project.adres.split('\n')

    if (lines.length > 1) {
      return (
        <>
          {lines.map((part, i) => (
            <div key={i}>{part}</div>
          ))}
        </>
      )
    }

    return project.adres
  }

  return ''
}
