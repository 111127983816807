import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'

import type { Contactpersoon } from '@models/Contactpersoon'
import type { Entity } from '@models/Entity'

import {
  createContactpersoonForEntity,
  updateContactpersoonForEntity,
} from '../../state'

interface Props {
  data: any
  onClose: () => void
  isEdit?: boolean
}

export const CreateEdit: FC<Props> = ({
  data,
  onClose,
  isEdit = false,
}: Props) => {
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({})

  const [naam, setNaam] = useState('')
  const [rol, setRol] = useState('')
  const [emailadres, setEmailadres] = useState('')
  const [telefoonnummer, setTelefoonnummer] = useState('')

  const { entity, contactpersoon } = data as {
    entity: Entity
    contactpersoon: Contactpersoon
  }

  const setValues = useCallback((contactpersoon) => {
    const { naam, rol, emailadres, telefoonnummer } = contactpersoon

    setNaam(naam)
    setRol(rol)
    setEmailadres(emailadres)
    setTelefoonnummer(telefoonnummer)
  }, [])

  useEffect(() => {
    if (isEdit) {
      setValues(contactpersoon)
    }
  }, [isEdit, contactpersoon, setValues])

  useEffect(() => {
    if (!isEdit) {
      setNaam('')
      setRol('')
      setEmailadres('')
      setTelefoonnummer('')
      setErrors({})
    }
  }, [isEdit])

  const validate = () => {
    const naamError = !naam
    const rolError = !rol
    const emailadresError = !emailadres
    const telefoonnummerError = !telefoonnummer

    setErrors({
      naamError,
      rolError,
      emailadresError,
      telefoonnummerError,
    })

    return !(naamError || rolError || emailadresError || telefoonnummerError)
  }

  const onSubmitClick = () => {
    if (validate()) {
      const persoon: Partial<Contactpersoon> = {
        emailadres,
        naam,
        rol,
        telefoonnummer,
      }

      const body = {
        entity,
        contactpersoon: persoon,
      }

      if (isEdit) {
        body.contactpersoon._id = contactpersoon._id

        dispatch(updateContactpersoonForEntity(body))
      } else {
        dispatch(createContactpersoonForEntity(body))
      }
    }
  }

  return (
    <>
      <DialogTitle>
        {isEdit
          ? `Bewerken ${contactpersoon.naam}`
          : `Aanmaken contactpersoon voor ${entity.type} ${entity.id}`}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
            <AlertTitle>Let op!</AlertTitle>U bent ervoor verantwoordelijk om te
            melden dat contactgegevens worden verwerkt.
          </Alert>
        </Box>
        <Box>
          <TextField
            sx={{ pr: 1, pb: 1 }}
            onChange={(e) => setNaam(e.target.value)}
            value={naam}
            variant="standard"
            label="Naam"
            fullWidth
            error={errors['naamError']}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            sx={{ pr: 1, pb: 1 }}
            onChange={(e) => setRol(e.target.value)}
            value={rol}
            variant="standard"
            label="Rol"
            fullWidth
            error={errors['rolError']}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            sx={{ pr: 1, pb: 1 }}
            onChange={(e) => setTelefoonnummer(e.target.value)}
            value={telefoonnummer}
            variant="standard"
            label="Telefoonnummer"
            fullWidth
            error={errors['telefoonnummerError']}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            sx={{ pr: 1, pb: 1 }}
            onChange={(e) => setEmailadres(e.target.value)}
            value={emailadres}
            variant="standard"
            label="Emailadres"
            fullWidth
            error={errors['emailadresError']}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={onSubmitClick}>
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
