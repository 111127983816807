const createBadge = (kpi = {} as any) => {
  const { rag } = kpi
  let badge

  if (rag) {
    const colors = ['red', 'amber']

    for (let i = 0; i < colors.length; i += 1) {
      const count = kpi.rag[i]

      if (count > 0) {
        badge = { count, color: colors[i], msg: kpi.msg }
        break
      }
    }
  }

  return badge
}

const createSummaryBadge = (kpi = {} as any) => {
  const { rag } = kpi
  let badge

  if (rag) {
    const count = rag[0] + rag[1]
    let color

    if (rag[1] > 0) color = 'amber'
    if (rag[0] > 0) color = 'red'
    if (rag[0] > 0 && rag[1] > 0) color = 'redAndAmber'

    if (count > 0) {
      badge = { count, color }
    }
  }

  return badge
}

export { createBadge, createSummaryBadge }
