import React, { FC } from 'react'

import { Card, CardContent, Container, Typography } from '@mui/material'

export const Unauthorized: FC = () => {
  return (
    <Container data-testid="unauthorized" maxWidth="sm" sx={{ pt: '50px' }}>
      <Card sx={{ p: '20px' }}>
        <CardContent>
          <Typography variant="h2" sx={{ ml: '10px' }}>
            Niet toegestaan
          </Typography>
          <Typography paragraph sx={{ p: '10px' }}>
            U heeft niet genoeg rechten om deze applicatie te bekijken
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}
