import React from 'react'
import { useSelector } from 'react-redux'

import {
  getWerkbakkenFilters,
  initialState,
} from '../../../../opdrachten/state'
import { DashboardCard } from '../DashboardCard'

export default function WerkbakTile({ werkbak, onClickTile }) {
  const { label, code, count } = werkbak
  const filters = useSelector(getWerkbakkenFilters)

  const onClick = () => {
    onClickTile(
      {
        filters: {
          ...initialState.query.filters,
          ...filters,
          werkbak: { code: code, label: label },
        },
        page: initialState.query.page,
        sort: initialState.query.sort,
      },
      '/opdrachten',
    )
  }

  return <DashboardCard onClick={onClick} label={label} count={count} />
}
