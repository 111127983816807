import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'
import { fetchAutosuggest, getAutosuggest } from '@slices/autosuggestSlice'

import { getUserParam } from '../../auth/state'
import { ENTITY_OPDRACHT } from '../../entity/bijlagen/state'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import {
  fetchCombiOpdrachten,
  fetchCombiWerkbakken,
  getCombiOpdrachten,
  initialState,
} from './state'

const headerCells = [
  { id: 'kpi', numeric: false, label: '', sort: false, fixed: 40 },
  { id: 'opdrachtId', numeric: true, label: 'Opdracht id', sort: false },
  { id: 'clusterId', numeric: false, label: 'Cluster id', sort: false },
  { id: 'realisatiedatum', label: 'Plandatum', sort: true },
  { id: 'discipline', label: 'Discipline', sort: false, fixed: 50 },
  { id: 'adres', numeric: false, label: 'Adres', sort: false },
  { id: 'netbeheerder', numeric: true, label: 'Netbeheerder', sort: false },
  { id: 'aannemer', numeric: true, label: 'Uitvoerende', sort: false },
  { id: 'gebiedsCodeLabel', numeric: false, label: 'Gebied', sort: false },
  {
    id: 'statusText',
    numeric: false,
    label: 'Status',
    sort: false,
    fixed: 130,
  },
  { id: 'changed', label: 'Mutatiedatum', sort: true, fixed: 75 },
  { id: 'details', label: 'Details', sort: false, fixed: 150 },
] as const

export const CombiOpdrachten: FC = () => {
  const dispatch = useDispatch()
  const { data, isLoading, query } = useSelector(getCombiOpdrachten)
  const options = useSelector(getAutosuggest)
  const userParam = useSelector(getUserParam)

  const { items: opdrachten, count, skip, limit } = data
  const { filters, sort, page } = query

  const { item: storedFilters } = useLocalStorage(
    'combi_opdrachten',
    filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localPage, setLocalPage] = useState(page)
  const [localSort, setLocalSort] = useState(sort)

  useEffect(() => {
    dispatch(fetchCombiWerkbakken())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      fetchCombiOpdrachten({
        filters: localFilters,
        sort: localSort,
        page: localPage,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort, userParam])

  const handleChangeFilters = (newFilters) => {
    if (newFilters.search) setLocalSort({ sortBy: '', sortDirection: 'asc' })
    else setLocalSort(initialState.query.sort)

    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort) => setLocalSort(sort)
  const onChangePage = (page) => setLocalPage(page)

  const onSearchChange = useCallback(
    (search) => {
      dispatch(fetchAutosuggest({ text: search, entity: ENTITY_OPDRACHT }))
    },
    [dispatch],
  )

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">
        Overzicht van alle combi opdrachten in uw gebied
      </Typography>
      <Paper sx={{ mb: 2 }}>
        <div>
          <Filters
            onChange={handleChangeFilters}
            filters={localFilters}
            initialFilters={initialState.query.filters}
            onSearchChange={onSearchChange}
            options={options}
          />
          <StyledTable
            onChangeSort={onChangeSort}
            onChangePage={onChangePage}
            headerCells={headerCells}
            sort={sort}
            initialSort={initialState.query.sort}
            stats={{ count, skip, limit }}
            loading={isLoading}
          >
            {opdrachten.map((row) => (
              <Row key={row.opdrachtId} row={row} showProjectOptions />
            ))}
          </StyledTable>
        </div>
      </Paper>
    </Box>
  )
}
