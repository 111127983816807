import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { HoofdleidingProject } from '../../../projecten/types'
import {
  closeProjectOptionsDialog,
  getHoofdleidingProjectOptionsDialogData,
  getHoofdleidingProjectOptionsDialogOpen,
  openProjectOptionsDialog,
} from '../../state'
import type { OptionsDialogData } from '../../types'

import { ProjectOptionsDialog } from './ProjectOptionsDialog'
import { ProjectOptionsMenu } from './ProjectOptionsMenu'

export interface Props {
  project: HoofdleidingProject
  anchorEl?: HTMLElement
  setAnchorEl: (anchor?: HTMLElement) => void
  showHoofdleidingOptions?: boolean
}

export const ProjectOptions: FC<Props> = ({
  project,
  anchorEl,
  setAnchorEl,
}: Props) => {
  const dispatch = useDispatch()
  const dialogData = useSelector(getHoofdleidingProjectOptionsDialogData)
  const isDialogOpen = useSelector(getHoofdleidingProjectOptionsDialogOpen)

  const onOpenDialog = (data: OptionsDialogData) =>
    dispatch(openProjectOptionsDialog(data))
  const onCloseDialog = () => dispatch(closeProjectOptionsDialog())

  return (
    <>
      <ProjectOptionsMenu
        project={project}
        onClose={() => setAnchorEl(undefined)}
        anchorEl={anchorEl}
        onOpenDialog={onOpenDialog}
      />
      <ProjectOptionsDialog
        data={dialogData}
        open={isDialogOpen}
        onClose={onCloseDialog}
      />
    </>
  )
}
