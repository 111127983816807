import React, { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import { FilterMyList } from '@components/toolbar/FilterMyList'

import type { FiltersKpis } from '../../types'

const filterGebiedenByCoordinerende = (gebieden, coordinerende) =>
  gebieden.filter(
    (gebied) => gebied.coordinerende.indexOf(coordinerende) !== -1,
  )

export interface Props {
  onChange: (filters: FiltersKpis) => void
  filters: FiltersKpis
  initialFilters: FiltersKpis
  valuelists: any
}

export const Filters = ({
  onChange,
  filters,
  initialFilters,
  valuelists,
}: Props) => {
  const { gebieden, netbeheerders, coordinerenden } = valuelists
  const [localFilters, setLocalFilters] = useState(filters)
  const { gebied, netbeheerder, coordinerende, labels, myList } = localFilters
  const gebiedenByCoordinerenden = coordinerende
    ? filterGebiedenByCoordinerende(gebieden, coordinerende)
    : gebieden

  const handleFilterChange = (filters, submit?: boolean) => {
    setLocalFilters({ ...localFilters, ...filters })

    if (submit) {
      onChange({ ...localFilters, ...filters })
    }
  }

  const handleCoordinerendeChange = (filter) => {
    setLocalFilters({
      ...localFilters,
      ...filter,
      gebied: filterGebiedenByCoordinerende(gebieden, filter.coordinerende),
    })
  }

  const handleGebiedChange = (filter) => {
    filter.gebied.length === 0
      ? setLocalFilters({ ...localFilters, ...filter, coordinerende: null })
      : setLocalFilters({ ...localFilters, ...filter })
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterAutocomplete
          items={coordinerenden}
          label="Coordinerende"
          filterName="coordinerende"
          value={coordinerende}
          onFilterChange={handleCoordinerendeChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterGebied
          items={gebiedenByCoordinerenden}
          value={gebied}
          onFilterChange={handleGebiedChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={netbeheerders}
          label="Netbeheerder"
          filterName="netbeheerder"
          value={netbeheerder}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      {valuelists.labels.length > 0 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={valuelists.labels}
            label="Labels"
            filterName="labels"
            value={labels}
            onFilterChange={handleFilterChange}
            multiple
          />
        </FilterGridItem>
      )}

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={() => resetFilters()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <FilterMyList myList={myList} onFilterChange={handleFilterChange} />

        <LocalStorageButtons
          name="Dashboard"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
