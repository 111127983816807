import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  initialState as initialStateCombiOpdrachten,
  storeQueryCombiOpdrachten,
} from '../../../../opdrachten/combi/state'
import {
  closeProjectOptionsDialog,
  getProjectOptionsDialogData,
  getProjectOptionsDialogOpen,
  openProjectOptionsDialog,
} from '../../state'
import type { Project } from '../../types'
import type { OptionsDialogData } from '../../types'

import { ProjectOptionsDialog } from './ProjectOptionsDialog'
import { ProjectOptionsMenu } from './ProjectOptionsMenu'

export interface Props {
  project: Project
  anchorEl?: HTMLElement
  setAnchorEl: (anchor?: HTMLElement) => void
}

export const ProjectOptions: FC<Props> = ({
  project,
  anchorEl,
  setAnchorEl,
}: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dialogData = useSelector(getProjectOptionsDialogData)
  const isDialogOpen = useSelector(getProjectOptionsDialogOpen)

  const onOpenDialog = (data: OptionsDialogData) =>
    dispatch(openProjectOptionsDialog(data))
  const onCloseDialog = () => dispatch(closeProjectOptionsDialog())

  const onNavigateToOpdrachten = ({ aanvraagID }) => {
    dispatch(
      storeQueryCombiOpdrachten({
        filters: {
          ...initialStateCombiOpdrachten.query.filters,
          search: aanvraagID,
        },
        sort: initialStateCombiOpdrachten.query.sort,
        page: initialStateCombiOpdrachten.query.page,
      }),
    )
    navigate('/combiopdrachten')
  }

  return (
    <>
      <ProjectOptionsMenu
        project={project}
        onClose={() => setAnchorEl(undefined)}
        anchorEl={anchorEl}
        onOpenDialog={onOpenDialog}
        onNavigateToOpdrachten={onNavigateToOpdrachten}
      />
      <ProjectOptionsDialog
        data={dialogData}
        open={isDialogOpen}
        onClose={onCloseDialog}
      />
    </>
  )
}
