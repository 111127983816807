import type { HoofdleidingProject } from '../projecten/types'

const HERPLAN_STATUS: HoofdleidingProject['statusText'][] = [
  'Intake',
  'Werkvoorbereiding',
]
const HERPLAN_DOORLOOPTIJD_STATUS: HoofdleidingProject['statusText'][] = [
  ...HERPLAN_STATUS,
  'In uitvoering',
]

export const isHoofdleidingHerplanStatus = (statusText: any) =>
  HERPLAN_STATUS.includes(statusText)

export const isHoofdleidingHerplanDoorlooptijdStatus = (statusText: any) =>
  HERPLAN_DOORLOOPTIJD_STATUS.includes(statusText)
