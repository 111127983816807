import React, { FC } from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

interface Props {
  onChange: (bool: boolean) => void
  value?: boolean
}

export const BooleanToggleGroup: FC<Props> = ({ onChange, value = false }) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      style={{ height: 10 }}
      color="primary"
      onChange={(_event, bool) => {
        if (bool !== null) {
          onChange(bool)
        }
      }}
    >
      <ToggleButton
        value={true}
        color="primary"
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'rgb(25 210 31 / 8%)',
            color: 'green',
          },
        }}
      >
        Ja
      </ToggleButton>
      <ToggleButton
        value={false}
        color="primary"
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'rgb(210 25 25 / 8%)',
            color: 'red',
          },
        }}
      >
        Nee
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
