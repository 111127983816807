import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material'

import useLocalStorage from '@hooks/useLocalStorage'
import { getValuelists } from '@slices/valuelistsSlice'

import { getUser, getUserParam } from '../../auth/state'
import { storeQueryCombiOpdrachten } from '../../opdrachten/combi/state'
import { storeQueryOpdrachten } from '../../opdrachten/state'
import { storeQuery as storeQueryActiviteiten } from '../activiteiten/state'
import { storeQuery as storeQueryAssignedBijlagen } from '../bijlagen/state'
import { storeQueryProjecten } from '../projecten/state'

import { Filters } from './components/kpis/Filters'
import { Tile } from './components/kpis/Tile'
import { Werkbakken } from './components/werkbakken/Werkbakken'
import { Werklijsten } from './components/werklijsten/Werklijsten'
import {
  fetchKpi,
  getKpi,
  getSelectedTabDashboard,
  initialState,
  storeSelectedTabDashboard,
} from './state'

const getSelectedTabByAuth = (selectedTab, canViewCombiOpdrachten) => {
  if (selectedTab) return selectedTab

  if (canViewCombiOpdrachten) return 'processtappen'

  return 'werkbakken'
}

export const Dashboard: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: kpis, query } = useSelector(getKpi)
  const selectedTab = useSelector(getSelectedTabDashboard)
  const valuelists = useSelector(getValuelists)
  const userParam = useSelector(getUserParam)
  const { canViewOpdrachten, canViewCombiOpdrachten } = useSelector(getUser)
  const selectedTabByAuth = getSelectedTabByAuth(
    selectedTab,
    canViewCombiOpdrachten,
  )
  const { item: storedFilters } = useLocalStorage(
    'dashboard',
    query.filters,
    initialState.kpis.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)

  useEffect(() => {
    if (canViewCombiOpdrachten) {
      dispatch(fetchKpi({ filters: localFilters }))
    }
  }, [dispatch, localFilters, userParam, canViewCombiOpdrachten])

  const handleTabChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newTab: string,
  ) => {
    dispatch(storeSelectedTabDashboard(newTab))
  }

  const onClickTile = (query, url) => {
    if (url === '/aansluiting/projecten') dispatch(storeQueryProjecten(query))
    if (url === '/combiopdrachten') dispatch(storeQueryCombiOpdrachten(query))
    if (url === '/opdrachten') dispatch(storeQueryOpdrachten(query))
    if (url === '/aansluiting/bijlagen')
      dispatch(storeQueryAssignedBijlagen(query))
    if (url === '/aansluiting/activiteiten')
      dispatch(storeQueryActiviteiten(query))

    navigate(url)
  }

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">Dashboard</Typography>

      <Tabs centered value={selectedTabByAuth} onChange={handleTabChange}>
        {/* KPIs and Processtappen have to be seperate as Tabs wants an array */}
        {canViewCombiOpdrachten && (
          <Tab label="Processtappen" value="processtappen" />
        )}
        {canViewCombiOpdrachten && <Tab label="KPI's" value="kpis" />}

        {canViewOpdrachten && <Tab label="Werkbakken" value="werkbakken" />}

        {canViewCombiOpdrachten && (
          <Tab label="Werklijsten" value="werklijsten" />
        )}
      </Tabs>

      <Paper sx={{ m: 1 }}>
        {['processtappen', 'kpis'].includes(selectedTabByAuth) && (
          <Filters
            onChange={(newFilters) => setLocalFilters(newFilters)}
            filters={localFilters}
            initialFilters={initialState.kpis.query.filters}
            valuelists={valuelists}
          />
        )}

        {canViewCombiOpdrachten && (
          <>
            {selectedTabByAuth === 'processtappen' && (
              <Box sx={{ p: '50px', ml: '20px' }}>
                <Grid sx={{ width: '100%' }} container spacing={2}>
                  {kpis
                    .filter((kpi) => !kpi.code)
                    .map((kpi) => (
                      <Grid item key={kpi.statusText}>
                        <Tile
                          kpi={kpi}
                          filters={localFilters}
                          onClickTile={onClickTile}
                          showKpiActions={false}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}

            {selectedTabByAuth === 'kpis' && (
              <Box sx={{ p: '50px', ml: '20px' }}>
                <Grid sx={{ width: '100%' }} container spacing={2}>
                  {kpis
                    .filter((kpi) => kpi.code || kpi.statusText === 'Intake')
                    .map((kpi) => (
                      <Grid item key={kpi.statusText}>
                        <Tile
                          kpi={kpi}
                          filters={localFilters}
                          onClickTile={onClickTile}
                          showKpiActions
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </>
        )}

        {selectedTabByAuth === 'werkbakken' && (
          <Werkbakken onClickTile={onClickTile} />
        )}

        {selectedTabByAuth === 'werklijsten' && (
          <Werklijsten onClickTile={onClickTile} />
        )}
      </Paper>
    </Box>
  )
}
