import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'

import { getUser } from '../../auth/state'
import { logout } from '../../gateways/auth0'

import { ProfileBeheer } from './ProfileBeheer'

export const Profile: FC = () => {
  const { name, picture, combi, deelnemer, delegerende, switchUserParam } =
    useSelector(getUser)
  const isBeheer = switchUserParam && deelnemer === 'DSP' && !combi

  const handleLogoff = () => logout()

  return (
    <Card sx={{ width: 350 }}>
      <CardHeader
        avatar={<Avatar aria-label="avatar" src={picture}></Avatar>}
        title={combi ? 'Combi' : 'Deelnemer: '}
        subheader={combi ? combi : deelnemer}
      />
      <CardContent>
        {delegerende && (
          <FormControl sx={{ width: '100%', pb: 1 }}>
            <TextField
              variant="standard"
              fullWidth
              label="Gedelegeerd door"
              value={delegerende}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        )}
        <FormControl sx={{ width: '100%', pb: 1 }}>
          <TextField
            variant="standard"
            fullWidth
            label="Email"
            value={name}
            InputProps={{ readOnly: true }}
          />
        </FormControl>
        {isBeheer && (
          <FormControl sx={{ width: '100%', pb: 1 }}>
            <ProfileBeheer />
          </FormControl>
        )}
      </CardContent>
      <CardActions>
        <Tooltip title="Uitloggen">
          <IconButton
            onClick={handleLogoff}
            sx={{ marginLeft: 'auto', color: 'black' }}
          >
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
}
