import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Drawer,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import StyledTable from '@components/StyledTable'
import { formatDateTime } from '@util/date-util'

import type { AssignedBijlage } from '../types'

import { AssignedBijlageLogStatus } from './AssignedBijlageLogStatus'

const headerCells = [
  { id: 'date', numeric: false, label: 'Datum' },
  { id: 'dueDate', numeric: false, label: 'Opleverweek' },
  { id: 'assignedTo', numeric: false, label: 'Toegewezen aan' },
  { id: 'status', numeric: false, label: 'Status' },
  { id: 'organisatie', numeric: false, label: 'Door' },
]

interface Props {
  assignedBijlage: AssignedBijlage
  onClose: () => void
  open: boolean
}

export const AssignedBijlageLog: FC<Props> = ({
  assignedBijlage,
  onClose,
  open,
}) => {
  const { documentsoort, discipline, log = [] } = assignedBijlage || {}

  return assignedBijlage ? (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box role="presentation" sx={{ p: '20px' }}>
        <IconButton
          data-testid="close-button"
          onClick={onClose}
          sx={{ color: 'black' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            pt: '1em',
            fontWeight: 550,
            pb: '1em',
            fontSize: '1.5rem',
            color: 'black',
          }}
          variant="h4"
        >{`Log van bijlage ${documentsoort} ${discipline || ''}`}</Typography>
        <StyledTable headerCells={headerCells} {...({} as any)}>
          {log.map((logEntry) => {
            return (
              <TableRow key={`${logEntry.date}-${logEntry.organisatie}`}>
                <TableCell>{formatDateTime(logEntry.date)}</TableCell>
                <TableCell>
                  {logEntry.dueWeek ? logEntry.dueWeek : 'Onbekend'}
                </TableCell>
                <TableCell>{logEntry.assignedTo || 'Onbekend'}</TableCell>
                <TableCell>
                  <AssignedBijlageLogStatus
                    logEntry={logEntry}
                    assignedBijlage={assignedBijlage}
                  />
                </TableCell>

                <TableCell>
                  {logEntry.organisatie}

                  {logEntry.email ? (
                    <Typography variant="caption">
                      <br />
                      {logEntry.email}
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </StyledTable>
      </Box>
    </Drawer>
  ) : (
    <></>
  )
}
