import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { saveEvent } from '../../state'
import type { Project } from '../../types'

interface Props {
  project: Project
  handleClose: () => void
}

export const Actief: FC<Props> = ({ handleClose, project }: Props) => {
  const dispatch = useDispatch()

  const handleClickJa = () => {
    dispatch(
      saveEvent({
        id: project.aanvraagID,
        type: 'actief',
        source: 'manual',
        data: {
          isActive: !project.isActive,
        },
      }),
    )
  }

  return (
    <>
      <DialogTitle>
        {project.isActive ? 'Deactiveren' : 'Activeren'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Wilt u project ${project.aanvraagID} ${
            project.isActive ? 'inactief' : 'actief'
          } maken?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClickJa()} color="primary">
          Ja
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Nee
        </Button>
      </DialogActions>
    </>
  )
}
