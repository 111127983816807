import React, { FC } from 'react'

import LaunchIcon from '@mui/icons-material/Launch'
import { TableCell, TableRow } from '@mui/material'

import StyledIconLink from '@components/StyledIconLink'
import { formatDate } from '@util/date-util'

import type { Projectdossier as ProjectdossierType } from '../../projectdossier/types'

export interface Props {
  projectdossier: ProjectdossierType
}

export const Row: FC<Props> = ({ projectdossier }) => {
  const {
    _id,
    projectdossierId,
    naam,
    externeReferentie,
    gebiedscodeLabel,
    statusText,
    indiener,
    aanlegger,
  } = projectdossier

  return (
    <TableRow key={_id}>
      <TableCell>{projectdossierId}</TableCell>
      <TableCell>{naam}</TableCell>
      <TableCell>{externeReferentie}</TableCell>
      <TableCell>{gebiedscodeLabel}</TableCell>
      <TableCell>{statusText}</TableCell>
      <TableCell>{indiener.naam}</TableCell>
      <TableCell>{indiener.organisatie}</TableCell>
      <TableCell>{formatDate(aanlegger.date)}</TableCell>
      <TableCell>
        <StyledIconLink
          title={`Navigeer naar projectdossier ${projectdossierId}`}
          icon={<LaunchIcon />}
          href={`/projectdossier/${projectdossierId}`}
        />
      </TableCell>
    </TableRow>
  )
}
