import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError } from '../alerts/state'
import { request } from '../gateways/api'
import type { RootState } from '../store'

import type { State } from './types'

const initialState: State = {
  contracten: [],
  organisaties: {
    data: [],
    status: 'idle',
  },
}

export const fetchContracten = createAsyncThunk(
  'fetch/contracten',
  async (_, { dispatch }) => {
    try {
      const result = await request({ url: '/rest/contract', method: 'GET' })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van contracten', error))

      throw error
    }
  },
)

export const fetchOrganisaties = createAsyncThunk(
  'fetch/contract/organisaties',
  async (gebiedscode: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/contract/${gebiedscode}/organisaties`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen organisaties', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    clearOrganisaties: (state) => {
      state.organisaties.data = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracten.fulfilled, (state, action) => {
        state.contracten = action.payload
      })
      .addCase(fetchOrganisaties.pending, (state) => {
        state.organisaties.status = 'loading'
        state.organisaties.data = []
      })
      .addCase(fetchOrganisaties.fulfilled, (state, action) => {
        state.organisaties.status = 'idle'
        state.organisaties.data = action.payload
      })
      .addCase(fetchOrganisaties.rejected, (state) => {
        state.organisaties.status = 'error'
        state.organisaties.data = []
      })
  },
})

export const getContracten = (state: RootState) => state.contract.contracten
export const getOrganisaties = (state: RootState) => ({
  data: state.contract.organisaties.data,
  isLoading: state.contract.organisaties.status === 'loading',
})

export const { reducer } = slice
export const { clearOrganisaties } = slice.actions
