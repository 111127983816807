import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddLink from '@mui/icons-material/AddLink'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkOff from '@mui/icons-material/LinkOff'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'

import Realisatie from '@components/Realisatie'
import StyledIconButton from '@components/StyledIconButton'
import StyledIconLink from '@components/StyledIconLink'
import StyledTable from '@components/StyledTable'

import { getUser } from '../../../auth/state'
import StyledCard from '../../../core/components/StyledCard'
import {
  openProjectOptionsDialog,
  saveEvent,
} from '../../../hoofdleiding/project/state'
import type { RootState } from '../../../store'
import { useLinkedProjects } from '../hooks/useLinkedProjects'

interface Props {
  entity: any
  linkedProjectIds: string[]
  isVerantwoordelijke: boolean
}

const headerCells = [
  { id: 'aanvraagID', label: 'Project ID' },
  { id: 'uitvoerdatum', label: 'Geplande start uitvoering' },
  { id: 'doorlooptijdWeken', label: 'Doorlooptijd (in weken)' },
  { id: 'netbeheerders', label: 'Netbeheerders' },
  { id: 'aannemers', label: 'Aannemers' },
  { id: 'statusText', label: 'Status' },
  { id: 'acties', label: 'Acties', fixed: 100 },
]

export const LinkedProjects: FC<Props> = ({
  entity,
  linkedProjectIds,
  isVerantwoordelijke,
}) => {
  const dispatch = useDispatch()
  const { linkedProjects } = useLinkedProjects({
    entity,
    linkedProjectIds,
  })
  const [open, setOpen] = useState(false)
  const [unlinkId, setUnlinkId] = useState('')
  const { user } = useSelector((state: RootState) => ({
    user: getUser(state),
  }))

  const isHoofdleiding = entity.projecttype === 'Hoofdleiding'
  const canKoppelProject =
    user.canKoppelProject && isHoofdleiding && isVerantwoordelijke
  const filteredHeaderCells = isHoofdleiding
    ? headerCells.filter((h) => h.id !== 'doorlooptijdWeken')
    : headerCells

  const handleLink = () => {
    dispatch(
      openProjectOptionsDialog({
        type: 'link_project',
        project: { aanvraagID: entity.id },
      }),
    )
  }

  const handleUnlinkClick = (childId: string) => {
    setUnlinkId(childId)
    setOpen(true)
  }

  const handleUnlink = () => {
    dispatch(
      saveEvent({
        id: entity.id,
        data: {
          deleteProject: unlinkId,
        },
        type: 'project_koppelen',
        source: 'manual',
      }),
    )
    setOpen(false)
  }

  return (
    <StyledCard title="Gekoppelde projecten">
      <Toolbar
        sx={{
          backgroundColor: '#F7F7F7',
          borderBottom: '1px solid #E0E0E0',
          justifyContent: 'flex-end',
        }}
      >
        {canKoppelProject && (
          <StyledIconButton
            title="Koppel een project"
            icon={<AddLink />}
            onClick={handleLink}
          />
        )}
      </Toolbar>
      <StyledTable
        headerCells={filteredHeaderCells}
        variant="details"
        {...({} as any)}
      >
        {linkedProjects &&
          linkedProjects.map((p) => {
            if (typeof p === 'object') {
              /**
               * Deze check is om te controleren of het linkedProject (`p`) is gevuld met een veld dat,
               * als de partij geauthorizeerd is, altijd gevuld is.
               * Als deze leeg is komt hij via de `explodeKnownProjects` er niet in (zie project util),
               * en kunnen we zeggen dat hij unauthorized is
               */
              const isAuthorizedOnProject = Object.keys(p).includes('combi')

              return (
                <TableRow key={p.aanvraagID}>
                  <TableCell>{p.aanvraagID}</TableCell>
                  <TableCell>
                    {p.geplandeUitvoeringsdatum ? (
                      <Realisatie
                        date={p.geplandeUitvoeringsdatum as string}
                        type={p.geplandeUitvoeringsdatumType}
                      />
                    ) : (
                      p.startUitvoeringWeek
                    )}
                  </TableCell>
                  {!isHoofdleiding && (
                    <TableCell>{p.doorlooptijdWeken}</TableCell>
                  )}
                  <TableCell>{p.netbeheerders?.join(', ')}</TableCell>
                  <TableCell>{p.aannemers?.join(', ')}</TableCell>
                  <TableCell>{p.statusText}</TableCell>
                  <TableCell>
                    {/* Alleen ontkoppelen als we in het hoofdleiding scherm zitten */}
                    {canKoppelProject && (
                      <StyledIconButton
                        icon={<LinkOff />}
                        title="Ontkoppel dit project"
                        onClick={() =>
                          handleUnlinkClick(p.aanvraagID as string)
                        }
                      />
                    )}
                    {isAuthorizedOnProject && (
                      <StyledIconLink
                        href={`/${
                          isHoofdleiding ? 'aansluiting' : 'hoofdleiding'
                        }/project/${p.aanvraagID}`}
                        icon={<LaunchIcon />}
                        title={`Navigeer naar project ${p.aanvraagID}`}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            }
          })}
      </StyledTable>
      <Dialog open={open}>
        <DialogTitle>Project ontkoppelen</DialogTitle>
        <DialogContent>
          <Typography>Wilt u project {unlinkId} ontkoppelen?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Nee</Button>
          <Button onClick={handleUnlink} variant="contained">
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  )
}
