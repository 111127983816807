import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'
import { fetchAutosuggest, getAutosuggest } from '@slices/autosuggestSlice'

import { getUser, getUserParam } from '../auth/state'
import { ENTITY_DEELNEMER_OPDRACHT } from '../entity/bijlagen/state'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import {
  fetchOpdrachten,
  fetchWerkbakken,
  getOpdrachten,
  getWerkbakkenFilters,
  initialState,
} from './state'

const headerCells = [
  { id: 'opdrachtId', numeric: true, label: 'Opdracht id', sort: false },
  { id: 'clusterId', numeric: false, label: 'Cluster id', sort: false },
  { id: 'versieNummer', label: 'Versienummer', sort: false, fixed: 50 },
  { id: 'discipline', label: 'Discipline', sort: false, fixed: 50 },
  { id: 'adres', numeric: false, label: 'Adres', sort: false },
  { id: 'netbeheerder', numeric: true, label: 'Netbeheerder', sort: false },
  { id: 'aannemer', numeric: true, label: 'Aannemer', sort: false },
  {
    id: 'details.creatieDatum',
    numeric: false,
    label: 'Creatiedatum',
    sort: true,
  },
  { id: 'changed', numeric: false, label: 'Mutatiedatum', sort: true },
  { id: 'status', label: 'Status', sort: false },
  { id: 'details', label: 'Details', sort: false, fixed: 100 },
] as const

export const Opdrachten: FC = () => {
  const dispatch = useDispatch()
  const { data, isLoading, query } = useSelector(getOpdrachten)
  const werkbakkenFilters = useSelector(getWerkbakkenFilters)
  const options = useSelector(getAutosuggest)

  const { items: opdrachten, count, skip, limit } = data
  const { filters, sort, page } = query
  const userParam = useSelector(getUserParam)
  const { canViewProjecten } = useSelector(getUser)

  const { item: storedFilters } = useLocalStorage(
    'opdrachten',
    filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localPage, setLocalPage] = useState(page)
  const [localSort, setLocalSort] = useState(sort)

  useEffect(() => {
    dispatch(fetchWerkbakken({ filters: werkbakkenFilters }))
  }, [dispatch, werkbakkenFilters])

  useEffect(() => {
    dispatch(
      fetchOpdrachten({
        filters: localFilters,
        sort: localSort,
        page: localPage,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort, userParam])

  const handleChangeFilters = (newFilters) => {
    if (newFilters.search) setLocalSort({ sortBy: '', sortDirection: 'asc' })
    else setLocalSort(initialState.query.sort)

    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort) => setLocalSort(sort)
  const onChangePage = (page) => setLocalPage(page)

  const onSearchChange = useCallback(
    (search) => {
      dispatch(
        fetchAutosuggest({ text: search, entity: ENTITY_DEELNEMER_OPDRACHT }),
      )
    },
    [dispatch],
  )

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">
        Overzicht van alle opdrachten als deelnemer
      </Typography>
      <Paper sx={{ mb: 2 }}>
        <div>
          <Filters
            onChange={handleChangeFilters}
            filters={localFilters}
            initialFilters={initialState.query.filters}
            onSearchChange={onSearchChange}
            options={options}
          />

          <StyledTable
            onChangeSort={onChangeSort}
            onChangePage={onChangePage}
            headerCells={headerCells}
            sort={sort}
            initialSort={initialState.query.sort}
            stats={{ count, skip, limit }}
            loading={isLoading}
            {...({} as any)}
          >
            {opdrachten.map((row) => (
              <Row
                key={row.opdrachtId}
                row={row}
                canNavigateToProject={
                  canViewProjecten &&
                  row.details?.combi &&
                  row.details.combi.combi !== 'GEEN'
                }
              />
            ))}
          </StyledTable>
        </div>
      </Paper>
    </Box>
  )
}
