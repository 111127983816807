import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'
import type { Page, Sort } from '@models/Query'
import { fetchAutosuggest, getAutosuggest } from '@slices/autosuggestSlice'
import { getProcesstappen } from '@slices/statusconfigSlice'
import { getValuelists } from '@slices/valuelistsSlice'

import { getUserParam } from '../../auth/state'
import { ProjectOptions } from '../project/components/options/ProjectOptions'
import type { Project as ProjectType } from '../project/types'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import {
  fetchProjecten,
  getProjecten,
  getShowActions,
  initialState,
  storeShowActions,
} from './state'

const locationHeaderCells = [
  { id: 'kpi', numeric: false, label: '', sort: false, fixed: 30 },
  { id: 'aanvraagID', numeric: true, label: 'Project id', sort: true },
  { id: 'type', numeric: true, label: 'Type', sort: false },
  { id: 'aanvraagDatum', numeric: false, label: 'Aangevraagd', sort: true },
  {
    id: 'geplandeUitvoeringsdatum',
    numeric: false,
    label: 'Gepland',
    sort: true,
  },
  { id: 'adres', numeric: false, label: 'Adres', sort: false },
  { id: 'aantalObjecten', numeric: true, label: 'Objecten', sort: false },
  { id: 'uniqDisciplines', numeric: true, label: 'Disciplines', sort: false },
  { id: 'gebiedsCodeLabel', numeric: false, label: 'Gebied', sort: false },
  {
    id: 'statusText',
    numeric: false,
    label: 'Processtap',
    sort: false,
    fixed: 150,
  },
  { id: 'details', label: 'Details', sort: false, fixed: 150 },
] as const

const actionHeaderCells = [
  { id: 'kpi', numeric: false, label: '', sort: false },
  { id: 'aanvraagID', numeric: true, label: 'Project id', sort: true },
  { id: 'type', numeric: true, label: 'Type', sort: false },
  { id: 'aanvraagDatum', numeric: false, label: 'Aangevraagd', sort: true },
  {
    id: 'geplandeUitvoeringsdatum',
    numeric: false,
    label: 'Gepland',
    sort: true,
  },
  { id: 'adres', numeric: false, label: 'Adres', sort: false },
  { id: 'acties', numeric: false, label: 'Acties', sort: false },
  { id: 'acties2', numeric: false, label: '', sort: false },
  { id: 'gebiedsCodeLabel', numeric: false, label: 'Gebied', sort: false },
  {
    id: 'statusText',
    numeric: false,
    label: 'Processtap',
    sort: false,
    fixed: 150,
  },
  { id: 'details', label: 'Details', sort: false, fixed: 100 },
] as const

export const Projecten: FC = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [selectedRow, setSelectedRow] = useState<ProjectType>()
  const { data, isLoading, query } = useSelector(getProjecten)
  const valuelists = useSelector(getValuelists)
  const processtappen = useSelector(getProcesstappen)
  const userParam = useSelector(getUserParam)
  const options = useSelector(getAutosuggest)
  const showActions = useSelector(getShowActions)
  const { items: projecten, count, skip, limit } = data
  const { sort, page } = query

  const { item: storedFilters } = useLocalStorage(
    'projecten',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)

  const [localPage, setLocalPage] = useState(page)
  const [localSort, setLocalSort] = useState(sort)

  const headerCells = showActions ? actionHeaderCells : locationHeaderCells

  useEffect(() => {
    dispatch(
      fetchProjecten({
        filters: localFilters,
        sort: localSort,
        page: localPage,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort, userParam])

  const onSearchChange = useCallback(
    (search) => {
      dispatch(fetchAutosuggest({ text: search }))
    },
    [dispatch],
  )

  const onChangeFilters = (newFilters, removeSort) => {
    setLocalSort(removeSort ? {} : initialState.query.sort)
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort: Sort) => setLocalSort(sort)
  const onChangePage = (page: Page) => setLocalPage(page)

  const onClickMenu = useCallback((anchorEl, row) => {
    setSelectedRow(row)
    setAnchorEl(anchorEl)
  }, [])

  const onToggleShowActions = () => {
    dispatch(storeShowActions(!showActions))
  }

  return (
    <>
      <Box sx={{ width: '95%', m: 'auto' }}>
        <Typography variant="h2">Overzicht van uw projecten</Typography>

        <Paper sx={{ mb: 2 }}>
          <Filters
            onChangeFilters={onChangeFilters}
            filters={localFilters}
            initialFilters={initialState.query.filters}
            onSearchChange={onSearchChange}
            onToggleShowActions={onToggleShowActions}
            showActions={showActions}
            options={options}
            valuelists={{
              ...valuelists,
              processtappen,
            }}
          />
          <StyledTable
            onChangeSort={onChangeSort}
            onChangePage={onChangePage}
            headerCells={headerCells}
            sort={sort}
            stats={{ count, skip, limit }}
            initialSort={initialState.query.sort}
            loading={isLoading}
          >
            {projecten.map((project) => (
              <Row
                key={project.aanvraagID}
                row={project}
                onClickMenu={onClickMenu}
                showActions={showActions}
                filters={localFilters}
              />
            ))}
          </StyledTable>

          {selectedRow && (
            <ProjectOptions
              project={selectedRow}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          )}
        </Paper>
      </Box>
    </>
  )
}
