import type { DisciplineVerzameling } from '@models/DisciplineVerzameling'

import type { QuickscanCombiDiscipline } from './types'

export const createQuickscanCombiDisciplines = (
  data: DisciplineVerzameling[],
): QuickscanCombiDiscipline[] => {
  const disciplines: QuickscanCombiDiscipline[] = []

  data.forEach((dv) => {
    if (dv.isCombiUitvoeringReden && !dv.isCombiUitvoering) {
      disciplines.push({
        discipline: dv.disciplineLabel,
        answer: false,
        text: dv.isCombiUitvoeringReden,
      })
    }

    if (dv.isCombiUitvoering) {
      disciplines.push({
        discipline: dv.disciplineLabel,
        answer: true,
      })
    }
  })

  return disciplines
}
