import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import { getUser } from '../auth/state'
import { StyledExpandCard } from '../core/components/StyledExpandCard'

import { AanvraagImporteren } from './components/AanvraagImporteren'
import { AanvraagKopieren } from './components/AanvraagKopieren'
import { Gebiedswissel } from './components/Gebiedswissel'

export const Beheer: FC = () => {
  const {
    canViewGebiedswissel,
    canViewAanvraagImporteren,
    canViewAanvraagKopieren,
  } = useSelector(getUser)

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">Beheer</Typography>
      {canViewGebiedswissel && (
        <StyledExpandCard title="Gebied wisselen">
          <Gebiedswissel />
        </StyledExpandCard>
      )}

      {canViewAanvraagImporteren && (
        <StyledExpandCard title="Aanvraag importeren">
          <AanvraagImporteren />
        </StyledExpandCard>
      )}

      {canViewAanvraagKopieren && (
        <StyledExpandCard title="Aanvraag kopiëren">
          <AanvraagKopieren />
        </StyledExpandCard>
      )}
    </Box>
  )
}
