import React, { FC, useCallback, useEffect, useState } from 'react'

import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import CloseIcon from '@mui/icons-material/Close'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterAutosuggest from '@components/toolbar/FilterAutosuggest'
import FilterFromToDate from '@components/toolbar/FilterFromToDate'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import FilterKpi from '@components/toolbar/FilterKpi'
import { FilterMyList } from '@components/toolbar/FilterMyList'

import type { Filters as FiltersType } from '../types'

const kpiTooltips = {
  red: 'Laat alleen de verlopen KPIs zien',
  amber: 'Laat bijna verlopen KPIs zien',
  green: 'Laat alle nog niet verlopen KPIs zien',
  grey: 'Laat alle projecten zien',
}

const acties = [
  { code: 'quickscan', label: 'Quickscan' },
  { code: 'verstuurOpdrachten', label: 'Verstuur opdrachten' },
  { code: 'planningConflict', label: 'Planning conflict' },
  {
    code: 'planningConflictHoofdleiding',
    label: 'Planning conflict Hoofdleiding',
  },
  { code: 'opleverenBijlagen', label: 'Opleveren bijlagen' },
  {
    code: 'gesplitsteWerkvoorbereiding',
    label: 'Gesplitste werkvoorbereiding',
  },
]

export interface Props {
  onChangeFilters: (filters: any, removeSort?: boolean) => void
  filters: FiltersType
  initialFilters: any
  onSearchChange: (search: any) => void
  options: any
  valuelists: any
  onToggleShowActions: () => void
  showActions: boolean
}

export const Filters: FC<Props> = ({
  onChangeFilters,
  filters,
  initialFilters,
  onSearchChange,
  options,
  valuelists,
  onToggleShowActions,
  showActions,
}: Props) => {
  const [localFilters, setLocalFilters] = useState(filters)
  const {
    fromDate,
    toDate,
    fromGeplandeUitvoeringsdatum,
    toGeplandeUitvoeringsdatum,
    search,
    gebied,
    processtap,
    active,
    kpi,
    netbeheerder,
    type,
    actiehouder,
    actie,
    uitvoerende,
    labels,
    myList,
  } = localFilters
  const {
    gebieden,
    netbeheerders,
    uitvoerendeAannemers,
    actiehouders,
    types,
    processtappen,
  } = valuelists

  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

  const handleClearFilters = () => {
    setLocalFilters(initialFilters)
    onChangeFilters(initialFilters)
  }

  const handleFilterChange = useCallback(
    (filter, apply?: boolean, removeSort = false) => {
      const newFilters = { ...localFilters, ...filter }

      setLocalFilters(newFilters)
      if (apply)
        removeSort
          ? onChangeFilters(newFilters, removeSort)
          : onChangeFilters(newFilters)
    },
    [localFilters, onChangeFilters],
  )

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterAutocomplete
          items={types}
          label="Type"
          filterName="type"
          value={type}
          onFilterChange={handleFilterChange}
          multiple
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={gebied}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={processtappen}
          label="Processtap"
          filterName="processtap"
          value={processtap}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={netbeheerders}
          label="Netbeheerder"
          filterName="netbeheerder"
          value={netbeheerder}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={uitvoerendeAannemers}
          label="Uitvoerende Aannemer"
          filterName="uitvoerende"
          value={uitvoerende}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={actiehouders}
          label="Actiehouder"
          filterName="actiehouder"
          value={actiehouder}
          onFilterChange={handleFilterChange}
          groupBy={(actiehouder) => actiehouder.group}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={acties}
          label="Actie"
          filterName="actie"
          value={actie}
          onFilterChange={handleFilterChange}
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />
      </FilterGridItem>

      <FilterGridItem minColumns="double">
        <FilterFromToDate
          fromDate={fromDate}
          toDate={toDate}
          onFilterChange={handleFilterChange}
          label="Aangevraagd"
        />
      </FilterGridItem>

      <FilterGridItem minColumns="double">
        <FilterFromToDate
          fromDate={fromGeplandeUitvoeringsdatum}
          toDate={toGeplandeUitvoeringsdatum}
          onFilterChange={handleFilterChange}
          label="Gepland"
          prop="GeplandeUitvoeringsdatum"
        />
      </FilterGridItem>

      {valuelists.labels.length > 0 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={valuelists.labels}
            label="Labels"
            filterName="labels"
            value={labels}
            onFilterChange={handleFilterChange}
            multiple
          />
        </FilterGridItem>
      )}

      <FilterGridItem>
        <FilterAutosuggest
          search={search}
          options={options}
          onFilterChange={handleFilterChange}
          onSearchChange={onSearchChange}
        />
      </FilterGridItem>

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FilterKpi
          onFilterChange={(kpi) => handleFilterChange(kpi, true)}
          kpi={kpi}
          tooltips={kpiTooltips}
        />

        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={handleClearFilters}>
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChangeFilters(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <FilterMyList myList={myList} onFilterChange={handleFilterChange} />

        <Tooltip
          title={
            active
              ? 'Klik om ook inactieve projecten te tonen'
              : 'Klik om alleen actieve projecten te tonen'
          }
        >
          <IconButton
            data-testid="activebutton"
            onClick={() => handleFilterChange({ active: !active }, true)}
          >
            {active ? <AlarmRoundedIcon /> : <AlarmOffRoundedIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip
          title={
            showActions
              ? 'Klik om locatie informatie te tonen'
              : 'Klik om acties te tonen'
          }
        >
          <IconButton data-testid="actiebutton" onClick={onToggleShowActions}>
            {showActions ? <HolidayVillageIcon /> : <AssignmentIndIcon />}
          </IconButton>
        </Tooltip>

        <LocalStorageButtons
          name="Projecten"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
