import React, { FC } from 'react'

import { TableCell, TableRow } from '@mui/material'

import StyledTable from '@components/StyledTable'
import { formatDateTime } from '@util/date-util'

import type { OpdrachtBase } from '../types'

const headerCells = [
  { id: 'status-beschrijving', numeric: false, label: 'Status beschrijving' },
  { id: 'status-oplevering', numeric: false, label: 'Status Oplevering' },
  { id: 'changed', numeric: false, label: 'Gewijzigd' },
]

const defaultSort = (x, y) =>
  Number(new Date(y.changed)) - Number(new Date(x.changed))

const generateStatusText = (row) => {
  const { statusTextAGP, statusTextAGA, statusTextTG } = row
  const textItems: string[] = []

  if (statusTextTG) textItems.push(statusTextTG)
  if (statusTextAGA) textItems.push(statusTextAGA)
  if (statusTextAGP) textItems.push(statusTextAGP)

  return textItems.map((x) => (
    <span key={x}>
      {x}
      <br />
    </span>
  ))
}
const STATUS_OPLEVERING = 500

interface Props {
  history: OpdrachtBase[]
}

export const History: FC<Props> = ({ history }) => {
  return (
    <StyledTable variant="details" headerCells={headerCells} {...({} as any)}>
      {[...history].sort(defaultSort).map((item) => {
        const { changed, beschrijving, status } = item

        return (
          <TableRow key={`${item.opdrachtId}-${item.changed}`}>
            <TableCell>{beschrijving}</TableCell>
            <TableCell>
              {status === STATUS_OPLEVERING && generateStatusText(item)}
            </TableCell>
            <TableCell>{formatDateTime(changed)}</TableCell>
          </TableRow>
        )
      })}
    </StyledTable>
  )
}
