import React, { FC } from 'react'

import { Box } from '@mui/material'

import loading from './loading.svg'

export const Loading: FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
      }}
    >
      <img
        style={{
          left: '50%',
          maxHeight: '200px',
          maxWidth: '200px',
          position: 'absolute',
          top: '40%',
          transform: 'translate(-50%, -50%)',
        }}
        src={loading}
        alt="loading"
      />
    </Box>
  )
}
