import React, { FC, useEffect } from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { error } from '../../alerts/state'
import { parseHash } from '../../gateways/auth0'
import { storeSessionChecked, storeToken } from '../state'

import { Loading } from './Loading'

export const Callback: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleAuthentication = useCallback(
    (err, result) => {
      dispatch(storeSessionChecked(true))

      if (result && result.accessToken && result.idToken) {
        dispatch(storeToken(result))
        navigate(result.state || '/home', { replace: true })
      } else {
        if (err) console.log(err) // eslint-disable-line
        dispatch(error('Inloggen mislukt'))
        navigate('/home', { replace: true })
      }
    },
    [dispatch, navigate],
  )

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      parseHash(handleAuthentication)
    }
  }, [handleAuthentication, location.hash])

  return <Loading />
}
