import React from 'react'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'

import type { SortDirection } from '@models/SortDirection'

import TableLoadingBar from './TableLoadingBar'

const toggleSortDirection = (direction: SortDirection): SortDirection =>
  direction === 'desc' ? 'asc' : 'desc'

export default function StyledTable({
  children,
  headerCells,
  onChangeSort,
  onChangePage,
  sort,
  initialSort = {} as any,
  stats: { count, skip, limit } = {} as any,
  loading,
  variant = 'default',
}) {
  const handleChangeSort = (property) => {
    const { sortBy, sortDirection } = sort

    onChangeSort({
      sortBy: property,
      sortDirection:
        sortBy !== property
          ? sortDirection || initialSort.sortDirection
          : toggleSortDirection(sortDirection),
    })
  }

  const handleChangePage = (page) => {
    onChangePage({ skip: page * limit, limit })
  }

  return (
    <Box sx={{ overflowX: 'auto' }}>
      <Table
        sx={{
          minWidth: '750px',
          ...(variant === 'details' ? { backgroundColor: '#f7f7f7' } : {}),
        }}
        size={'medium'}
      >
        <TableHead>
          <TableRow>
            {headerCells.map((headerCell) => (
              <TableCell
                key={headerCell.id}
                sx={{
                  width: headerCell.fixed,
                }}
              >
                {headerCell.sort ? (
                  <TableSortLabel
                    active={sort.sortBy === headerCell.id}
                    direction={sort.sortDirection}
                    onClick={() => handleChangeSort(headerCell.id)}
                  >
                    {headerCell.label}
                  </TableSortLabel>
                ) : (
                  headerCell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& > tr:nth-of-type(odd)': {
              background: 'rgba(0,0,0,.02)',
            },
          }}
        >
          {children}
          {loading && <TableLoadingBar colSpan={headerCells.length} />}
        </TableBody>
      </Table>
      <div>
        {onChangePage && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count || 0}
            rowsPerPage={limit}
            page={Math.ceil(skip / limit) || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            labelDisplayedRows={function defaultLabelDisplayedRows({
              from,
              to,
              count,
            }) {
              return `${from}-${to} van ${
                count !== -1 ? count : `meer dan ${to}`
              }`
            }}
          />
        )}
      </div>
    </Box>
  )
}
