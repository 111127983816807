import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: {
      werkbak: null,
      gebied: [],
      netbeheerder: null,
      aannemer: null,
      search: '',
      searchOption: null,
      fromDate: null,
      toDate: null,
      kpi: '',
      labels: [],
      open: true,
      myList: false,
    },
    sort: { sortBy: 'realisatiedatum', sortDirection: 'desc' },
    page: { skip: 0, limit: 25 },
  },
  status: 'idle',
  werkbakken: [],
  forProject: [],
}

export const createFilters = ({
  werkbak,
  netbeheerder,
  aannemer,
  gebied,
  search,
  searchOption,
  fromDate,
  toDate,
  kpi,
  labels,
  open,
  myList,
}) => {
  const filters: IFilter[] = []

  if (werkbak)
    filters.push({ name: 'werkbak', value: werkbak.code, operator: 'eq' })
  if (netbeheerder)
    filters.push({ name: 'netbeheerder', value: netbeheerder, operator: 'eq' })
  if (aannemer)
    filters.push({ name: 'aannemer', value: aannemer, operator: 'eq' })
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (search && search !== '') {
    if (searchOption)
      filters.push({ name: searchOption.k, value: search, operator: 'eq' })
    else filters.push({ name: 'text', value: search, operator: 'contains' })
  }
  if (fromDate)
    filters.push({
      name: 'realisatiedatum',
      value: startOfDay(fromDate),
      operator: 'gte',
    })
  if (toDate)
    filters.push({
      name: 'realisatiedatum',
      value: endOfDay(toDate),
      operator: 'lte',
    })
  if (kpi) filters.push({ name: 'kpi.rag', value: kpi, operator: 'eq' })
  if (open) filters.push({ name: 'status', value: 900, operator: 'lt' })
  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchCombiOpdrachten = createAsyncThunk(
  'fetch/combiopdrachten',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/opdracht',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van opdrachten', error))

      throw error
    }
  },
)

export const fetchCombiOpdrachtenForProject = createAsyncThunk(
  'fetch/combiopdrachten/project',
  async (id: any, { dispatch }) => {
    const filters = [{ name: 'clusterId', value: id, operator: 'eq' }]

    try {
      const result = await request({
        url: '/rest/opdracht',
        method: 'POST',
        data: { filters },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van opdrachten', error))

      throw error
    }
  },
)

export const fetchCombiWerkbakken = createAsyncThunk(
  'fetch/combiwerkbakken',
  async (_, { dispatch }) => {
    try {
      const result = await request({
        url: '/rest/opdracht/werkbakken',
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van werkbakken', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'combiOpdrachten',
  initialState,
  reducers: {
    storeQueryCombiOpdrachten: (state, action) => {
      state.query = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCombiOpdrachten.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchCombiOpdrachten.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchCombiOpdrachten.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
      .addCase(fetchCombiWerkbakken.fulfilled, (state, action) => {
        state.werkbakken = action.payload
      })
      .addCase(fetchCombiOpdrachtenForProject.fulfilled, (state, action) => {
        state.forProject = action.payload.items
      })
  },
})

export const getCombiOpdrachten = (state: RootState) => ({
  data: state.combiOpdrachten.data,
  isLoading: state.combiOpdrachten.status === 'loading',
  query: state.combiOpdrachten.query,
})

export const getCombiWerkbakken = (state: RootState) =>
  state.combiOpdrachten.werkbakken
export const getCombiOpdrachtenForProject = (state: RootState) =>
  state.combiOpdrachten.forProject

export const reducer = slice.reducer
export const { storeQueryCombiOpdrachten } = slice.actions
