import React, { FC } from 'react'

import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Zoom } from '@mui/material'

interface Props {
  size?: number
  active?: boolean
}

/* eslint-disable-next-line no-magic-numbers */
export const MyListIcon: FC<Props> = ({ active = false, size = 24 }) => {
  return (
    <>
      <StarBorderIcon sx={{ width: `${size}px`, height: `${size}px` }} />
      <Zoom in={active}>
        <StarIcon
          color="primary"
          sx={{ position: 'absolute', width: `${size}px`, height: `${size}px` }}
        />
      </Zoom>
    </>
  )
}
