import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError, success } from '../alerts/state'
import { request } from '../gateways/api'
import type { RootState } from '../store'

import type { State } from './types'

const initialState: State = {
  projectmelding: undefined,
  createDialog: {
    open: false,
    data: {},
  },
  afwijzenDialog: {
    open: false,
    data: {},
  },
}

export const fetchProjectmelding = createAsyncThunk(
  'fetch/projectmelding',
  async (
    { id, projectdossierId }: { id: string; projectdossierId?: string },
    { dispatch },
  ) => {
    const url = projectdossierId
      ? `/rest/projectdossier/${projectdossierId}/projectmelding`
      : `/rest/projectmelding/${id}`

    try {
      const result = await request({
        url,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van vooraanmelding', error))

      throw error
    }
  },
)

export const patchProjectmelding = createAsyncThunk(
  'patch/projectmelding',
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/projectmelding/${id}`,
        method: 'PATCH',
        data: {
          status: {
            code: 3010,
          },
        },
      })

      dispatch(success('Wijziging succesvol opgeslagen'))
      dispatch(fetchProjectmelding({ id }))
      dispatch(closeAfwijzenDialog())

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij afwijzen van vooraanmelding', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'projectmelding',
  initialState,
  reducers: {
    openCreateDialog: (state, action) => {
      state.createDialog.open = true
      state.createDialog.data = action.payload
    },
    closeCreateDialog: (state) => {
      state.createDialog.open = false
      state.createDialog.data = {}
    },
    openAfwijzenDialog: (state) => {
      state.afwijzenDialog.open = true
    },
    closeAfwijzenDialog: (state) => {
      state.afwijzenDialog.open = false
    },
    clearProjectmelding: (state) => {
      state.projectmelding = initialState.projectmelding
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectmelding.fulfilled, (state, action) => {
      state.projectmelding = action.payload
    })
  },
})

export const getProjectmelding = (state: RootState) =>
  state.projectmelding.projectmelding
export const isCreateDialogOpen = (state: RootState) =>
  state.projectmelding.createDialog.open
export const getCreateDialogData = (state: RootState) =>
  state.projectmelding.createDialog.data
export const isAfwijzenDialogOpen = (state: RootState) =>
  state.projectmelding.afwijzenDialog.open

export const { reducer } = slice
export const {
  openCreateDialog,
  closeCreateDialog,
  openAfwijzenDialog,
  closeAfwijzenDialog,
  clearProjectmelding,
} = slice.actions
