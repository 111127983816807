import React, { useState } from 'react'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'

import SaveIcon from '@mui/icons-material/Save'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material'

import { reviewAssignedBijlageForEntity } from '../../state'
import type { AssignedBijlage } from '../../types'

interface Props {
  data: {
    bijlage: AssignedBijlage
  }
  onClose: () => void
}

export const Review: FC<Props> = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const [reden, setReden] = useState('')
  const [errors, setErrors] = useState({})
  const [approved, setApproved] = useState(true)

  const {
    bijlage: { documentsoort, _id, entity, discipline },
  } = data

  const onClick = () => {
    if ((!approved && !reden) || (approved && reden)) {
      setErrors({
        redenError: true,
      })
      return
    }

    setErrors({})

    dispatch(
      reviewAssignedBijlageForEntity({
        entity,
        id: _id,
        approved,
        reden,
      }),
    )
  }

  return (
    <>
      <DialogTitle>
        Beoordelen {`${documentsoort} ${discipline || ''}`}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                value={approved}
                control={<Switch checked={approved} />}
                label={`${documentsoort} ${discipline || ''}${
                  approved ? ' goedkeuren' : ' afkeuren'
                }`}
                onChange={() => {
                  setReden('')
                  setErrors((errors) => ({ ...errors, redenError: false }))
                  setApproved(!approved)
                }}
              />
            </FormControl>
          </Grid>

          {!approved && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={reden}
                  multiline
                  rows={6}
                  label={`Reden (${reden.length}/500)`}
                  onChange={(e) => setReden(e.target.value)}
                  required
                  error={errors['redenError']}
                  inputProps={{ maxLength: 500 }}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Annuleren</Button>

        <Button onClick={onClick} startIcon={<SaveIcon />} variant="contained">
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
