import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'

import type { Opdracht } from '../types'

interface Props {
  opdracht: Opdracht
}

export const Werkzaamheden: FC<Props> = ({ opdracht }) => {
  const { details = {} } = opdracht
  const { werkzaamheden = {} } = details as any

  const {
    aansluiting,
    binnenwerk,
    fysiekeStatus,
    meter,
    typeAansluiting,
    wijzigenCapaciteit,
  } = werkzaamheden

  const items = [
    { title: 'Aansluiting', text: aansluiting },
    { title: 'Type aansluiting', text: typeAansluiting },
    { title: 'Binnenwerk', text: binnenwerk },
    { title: 'Fysieke status', text: fysiekeStatus },
    { title: 'Meter', text: meter },
    { title: 'Wijzigen capaciteit', text: wijzigenCapaciteit },
  ] as const

  return (
    <Grid container spacing={2} sx={{ m: 'auto', width: '95%', p: 1 }}>
      {items.map(({ title, text }) => (
        <Grid key={`${title}-${text}`} item xs={3}>
          <Typography gutterBottom variant="subtitle1">
            <b>{title}</b>
          </Typography>
          <Typography variant="body2">{text}</Typography>
        </Grid>
      ))}
    </Grid>
  )
}
