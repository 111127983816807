import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  status: 'idle',
  query: {
    filters: {
      fromDate: null,
      toDate: null,
      fromGeplandeUitvoeringsdatum: null,
      toGeplandeUitvoeringsdatum: null,
      search: '',
      searchOption: null,
      gebied: [],
      processtap: null,
      active: true,
      netbeheerder: null,
      uitvoerende: null,
      type: [],
      actiehouder: null,
      actie: null,
      labels: [],
      myList: false,
    },
    sort: { sortBy: 'aanvraagDatum', sortDirection: 'desc' },
    page: { skip: 0, limit: 25 },
  },
  showActions: false,
}

export const createFilters = ({
  fromDate,
  toDate,
  fromGeplandeUitvoeringsdatum,
  toGeplandeUitvoeringsdatum,
  search,
  searchOption,
  gebied,
  processtap,
  active,
  kpi,
  netbeheerder,
  type,
  actiehouder,
  actie,
  uitvoerende,
  labels,
  myList,
}) => {
  const filters: IFilter[] = []

  if (fromDate)
    filters.push({
      name: 'aanvraagDatum',
      value: startOfDay(fromDate),
      operator: 'gte',
    })
  if (toDate)
    filters.push({
      name: 'aanvraagDatum',
      value: endOfDay(toDate),
      operator: 'lte',
    })
  if (fromGeplandeUitvoeringsdatum)
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: startOfDay(fromGeplandeUitvoeringsdatum),
      operator: 'gte',
    })
  if (toGeplandeUitvoeringsdatum)
    filters.push({
      name: 'geplandeUitvoeringsdatum',
      value: endOfDay(toGeplandeUitvoeringsdatum),
      operator: 'lte',
    })
  if (search && search !== '') {
    if (searchOption)
      filters.push({ name: searchOption.k, value: search, operator: 'eq' })
    else filters.push({ name: 'text', value: search, operator: 'contains' })
  }
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'coordinerende.gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (processtap && processtap !== '')
    filters.push({ name: 'statusText', value: processtap, operator: 'eq' })

  if (active) filters.push({ name: 'isActive', value: active, operator: 'eq' })

  if (kpi) filters.push({ name: 'kpi.rag', value: kpi, operator: 'eq' })
  if (netbeheerder)
    filters.push({ name: 'netbeheerders', value: netbeheerder, operator: 'eq' })

  if (type && type.length > 0)
    filters.push({
      name: 'type',
      value: type.map(({ code }) => code),
      operator: 'in',
    })
  if (actiehouder)
    filters.push({
      name: 'actiehouders',
      value: actiehouder.label,
      operator: 'eq',
    })
  if (actie)
    filters.push({
      name: `acties.${actie.code}`,
      value: actiehouder
        ? actiehouder.label
        : { $exists: true, $not: { $size: 0 } },
      operator: 'eq',
    })

  if (uitvoerende)
    filters.push({ name: 'uitvoerende', value: uitvoerende, operator: 'eq' })

  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchProjecten = createAsyncThunk(
  'fetch/project/aansluiting/search',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/project/aansluiting/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van projecten', error))

      throw error
    }
  },
)

export const updateMyList = createAsyncThunk<{ projecten: string[] }, any>(
  'update/project/aansluiting/my-list',
  async (
    { aanvraagID, method }: { aanvraagID: string; method: 'add' | 'remove' },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const result = await request<{ projecten: string[] }>({
        url: `/rest/my-list`,
        method: method === 'remove' ? 'DELETE' : 'POST',
        data: { id: aanvraagID },
      })

      return result
    } catch (error: any) {
      dispatch(
        apiError(
          `Fout bij ${
            method === 'remove' ? 'verwijderen van' : 'toevoegen aan'
          } mijn lijst`,
          error,
        ),
      )

      return rejectWithValue({ error })
    }
  },
)

const slice = createSlice({
  name: 'projecten',
  initialState,
  reducers: {
    storeQueryProjecten: (state, action) => {
      state.query = action.payload
    },
    storeProject: (state, action) => {
      const { aanvraagID } = action.payload

      const items = state.data.items.map((item) => {
        if (item.aanvraagID === aanvraagID) return action.payload

        return item
      })

      state.data.items = items
    },
    storeShowActions: (state, action) => {
      state.showActions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjecten.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchProjecten.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchProjecten.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
      .addCase(updateMyList.fulfilled, (state, action) => {
        const { projecten } = action.payload

        state.data.items = state.data.items.map((item) => ({
          ...item,
          myList: projecten.includes(item.aanvraagID),
        }))
      })
  },
})

export const getProjecten = (state: RootState) => ({
  data: state.projecten.data,
  isLoading: state.projecten.status === 'loading',
  query: state.projecten.query,
})
export const getShowActions = (state: RootState) => state.projecten.showActions

export const { reducer } = slice
export const { storeQueryProjecten, storeProject, storeShowActions } =
  slice.actions
