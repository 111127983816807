import React from 'react'

import { IconButton, Tooltip } from '@mui/material'

interface Props {
  title: string
  onClick?: any
  icon: any
}

export default function StyledIconButton({ title, onClick, icon }: Props) {
  return (
    <Tooltip title={title}>
      <IconButton
        aria-label={title}
        onClick={onClick}
        sx={{ maxHeight: '40px', maxWidth: '40px' }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}
