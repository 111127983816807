import React, { FC, Fragment } from 'react'

import { Box, Tooltip, Typography } from '@mui/material'

import { formatDate } from '@util/date-util'

import type { AutoEventConfig } from '../types'

type Props = {
  configs: AutoEventConfig[]
}

const CommaSpace: FC = () => <span>&#44;&#160;</span>

const TooltipGrid: FC<{ config: AutoEventConfig }> = ({ config }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span>Ingangsdatum: {formatDate(config.ingangsDatum)}</span>

      {config.eindDatum && (
        <span>Einddatum: {formatDate(config.eindDatum)}</span>
      )}

      <span>Doorlooptijd in werkdagen: {config.doorlooptijdWerkdagen}</span>
    </Box>
  )
}

export const AutoEventConfigsList: FC<Props> = ({ configs }) => {
  return (
    <>
      {configs.map((config, index) => (
        <Fragment key={config.netbeheerder}>
          <Tooltip title={<TooltipGrid config={config} />} arrow>
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
              }}
            >
              {config.netbeheerder}
            </Typography>
          </Tooltip>

          {index < configs.length - 1 && <CommaSpace />}
        </Fragment>
      ))}
    </>
  )
}
