import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import {
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

interface Props {
  text: string
  state?: 'loading' | 'done' | 'error'
}

export const UploadIndicatorItem: FC<Props> = ({ state, text }) => {
  return (
    <>
      <ListItem>
        <ListItemText primary={text} />
        {state === 'loading' && (
          <ListItemIcon sx={{ color: 'blue' }}>
            <CircularProgress style={{ width: '20px', height: '20px' }} />
          </ListItemIcon>
        )}

        {state === 'done' && (
          <ListItemIcon sx={{ color: 'green' }}>
            <DoneIcon />
          </ListItemIcon>
        )}

        {state === 'error' && (
          <ListItemIcon sx={{ color: 'red' }}>
            <CloseIcon />
          </ListItemIcon>
        )}
      </ListItem>
      <Divider />
    </>
  )
}
