import React from 'react'

import { Avatar, Chip, Tooltip } from '@mui/material'

import { formatDate, formatDateTime } from '../util/date-util'

interface Props {
  date: string
  endDate?: string
  type?: string
  showTime?: boolean
  wachtTijdDatum?: string
}

const format = (date: string, showTime?: boolean) =>
  date && (showTime ? formatDateTime(date) : formatDate(date))

const TYPE_WENSDATUM_KLANT = 'Wensdatum klant'

export default function Realisatie({
  date,
  endDate,
  type,
  showTime,
  wachtTijdDatum,
}: Props) {
  if (!date) return <></>

  let planType = (type && type.trim()) || 'Onbekend'

  if (planType === TYPE_WENSDATUM_KLANT && wachtTijdDatum) {
    planType = `${planType} rekening houdend met een minimale wachttijd datum van ${format(
      wachtTijdDatum,
    )} `
  }

  const label = endDate
    ? `${format(date, showTime)} · ${format(endDate, showTime)}`
    : `${format(date, showTime)}`

  return (
    <Chip
      variant="outlined"
      avatar={
        <Tooltip title={planType} placement="bottom-start">
          <Avatar
            sx={{
              color: 'black !important',
              cursor: 'default',
              backgroundColor: '#eeeeee',
            }}
          >
            {planType[0]}
          </Avatar>
        </Tooltip>
      }
      label={label}
    />
  )
}
