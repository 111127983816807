import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterAutosuggest from '@components/toolbar/FilterAutosuggest'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'

import { getValuelists } from '../../core/slices/valuelistsSlice'
import type { RootState } from '../../store'
import { getWerkbakken } from '../state'
import type { Filters as StateFilters } from '../types'

interface Props {
  onChange: (filters: StateFilters, removeSort?: boolean) => void
  filters: StateFilters
  initialFilters: StateFilters
  onSearchChange: any
  options: any
}

export const Filters: FC<Props> = ({
  onChange,
  filters,
  initialFilters,
  onSearchChange,
  options,
}) => {
  const [localFilters, setLocalFilters] = useState(filters)
  const {
    gebied,
    netbeheerder,
    aannemer,
    discipline,
    search,
    open,
    versieNummer,
    creatiedatum,
    changed,
    werkbak,
  } = localFilters
  const {
    valuelists: {
      deelnemer: { gebieden, aannemers, netbeheerders, versies, disciplines },
    },
    werkbakken,
  } = useSelector((state: RootState) => ({
    valuelists: getValuelists(state),
    werkbakken: getWerkbakken(state),
  }))

  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

  const handleClearFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  const handleFilterChange = (filter, apply?, removeSort?) => {
    const newFilters = { ...localFilters, ...filter }

    setLocalFilters(newFilters)
    if (apply)
      removeSort ? onChange(newFilters, removeSort) : onChange(newFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterAutocomplete
          items={werkbakken}
          label="Werkbak"
          filterName="werkbak"
          value={werkbak}
          onFilterChange={handleFilterChange}
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={versies}
          label="Versienummer"
          filterName="versieNummer"
          value={versieNummer}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={[{ code: null, label: 'Geen' }, ...gebieden]}
          label="Gebied"
          filterName="gebied"
          value={gebied}
          onFilterChange={handleFilterChange}
          multiple
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          items={disciplines}
          label="Discipline"
          filterName="discipline"
          value={discipline}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <DatePicker
          label="Creatiedatum"
          onChange={(e) => handleFilterChange({ creatiedatum: e })}
          value={creatiedatum}
          displayWeekNumber
          slotProps={{
            field: { clearable: true },
            textField: {
              variant: 'standard',
              fullWidth: true,
            },
          }}
        />
      </FilterGridItem>

      <FilterGridItem>
        <DatePicker
          label="Mutatiedatum"
          onChange={(e) => handleFilterChange({ changed: e })}
          value={changed}
          displayWeekNumber
          slotProps={{
            field: { clearable: true },
            textField: {
              variant: 'standard',
              fullWidth: true,
            },
          }}
        />
      </FilterGridItem>

      {/* If there is only 1 netbeheerder, hide the list. It's unnecessary to display it */}
      {netbeheerders.length > 1 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={netbeheerders}
            label="Netbeheerder"
            filterName="netbeheerder"
            value={netbeheerder}
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>
      )}

      {aannemers.length > 1 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={aannemers}
            label="Aannemer"
            filterName="aannemer"
            value={aannemer}
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>
      )}

      <FilterGridItem>
        <FilterAutosuggest
          search={search}
          options={options}
          onFilterChange={handleFilterChange}
          onSearchChange={onSearchChange}
        />
      </FilterGridItem>

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={handleClearFilters}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => handleFilterChange(localFilters, true)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            open
              ? 'Klik om ook afgesloten opdrachten te tonen'
              : 'Klik om alleen open opdrachten te tonen'
          }
        >
          <IconButton
            data-testid="openbutton"
            onClick={() => handleFilterChange({ open: !open }, true)}
          >
            {open ? <AlarmRoundedIcon /> : <AlarmOffRoundedIcon />}
          </IconButton>
        </Tooltip>
        <LocalStorageButtons
          name="opdrachten"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
