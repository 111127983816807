import React from 'react'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Chip from '@mui/material/Chip'

interface Props {
  expanded: boolean
  label?: string
  onDelete: () => void
  onClick: () => void
  ['data-testid']: string
}

export default function ExpandRow({
  expanded,
  label,
  onDelete,
  onClick,
  ...rest
}: Props) {
  return (
    <Chip
      deleteIcon={
        expanded ? (
          <ExpandLessIcon style={{ color: 'inherit' }} />
        ) : (
          <ExpandMoreIcon />
        )
      }
      variant="outlined"
      onDelete={onDelete}
      onClick={onClick}
      label={label}
      sx={
        expanded
          ? {
              color: 'white',
              backgroundColor: `rgb(61, 108, 180)`,
              border: `1px solid  rgb(61, 108, 180) !important`,
              '&:focus': {
                backgroundColor: `rgb(61, 108, 180) !important`,
              },
              '&:hover': {
                backgroundColor: `rgb(61, 108, 180) !important`,
              },
            }
          : {}
      }
      {...rest}
    />
  )
}
