import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import BusinessIcon from '@mui/icons-material/Business'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'

import { getUser } from '../../auth/state'
import type { RootState } from '../../store'

interface Props {
  onItemClick: () => void
}

export const KetensamenwerkingMenu: FC<Props> = ({ onItemClick }) => {
  const {
    user: { canViewProjectdossiers, canViewProjectmeldingen },
  } = useSelector((state: RootState) => ({
    user: getUser(state),
  }))

  const shouldRender = canViewProjectdossiers || canViewProjectmeldingen

  return shouldRender ? (
    <>
      <List subheader={<ListSubheader>Ketensamenwerking</ListSubheader>}>
        {canViewProjectmeldingen && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/vooraanmeldingen"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <SwapHorizontalCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Vooraanmeldingen" />
          </ListItemButton>
        )}

        {canViewProjectdossiers && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/projectdossiers"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Projectdossiers" />
          </ListItemButton>
        )}
      </List>

      <Divider />
    </>
  ) : null
}
