import type { FC } from 'react'
import React from 'react'

import { Typography } from '@mui/material'

import type { AssignedBijlage } from '../../entity/bijlagen/types'

interface Props {
  assignedBijlage: AssignedBijlage
}

const hasCaption = ({ status, reden, waitFor }: AssignedBijlage): boolean => {
  return (
    status === 'cancelled' ||
    (status === 'waiting' && !!waitFor) ||
    status === 'review' ||
    !!reden
  )
}

const generateCaption = ({
  status,
  reden,
  waitFor,
}: AssignedBijlage): string => {
  if (status === 'review') return 'Te beoordelen'
  if (status === 'waiting') return `Wacht op ${waitFor}`
  if (reden) return 'Opnieuw opleveren na afkeur'

  return 'Geannuleerd'
}

export const AssignedBijlageCaption: FC<Props> = ({ assignedBijlage }) => {
  if (!hasCaption(assignedBijlage)) return <></>
  return (
    <>
      <br />
      <Typography variant="caption">
        <span></span>
        {generateCaption(assignedBijlage)}
      </Typography>
    </>
  )
}
