import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'

import { getUserParam } from '../../auth/state'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import {
  fetchAssignedBijlagen,
  getAssignedBijlagen,
  initialState,
} from './state'

const headerCells = [
  { id: 'expired', numeric: false, label: '', fixed: 25 },
  { id: 'documentsoort', numeric: false, label: 'Documentsoort', fixed: 200 },
  {
    id: 'dueWeek',
    numeric: false,
    label: 'Oplevering',
    fixed: 100,
    sort: true,
  },
  {
    id: 'created.date',
    numeric: false,
    label: 'Aangemaakt',
    fixed: 50,
    sort: true,
  },
  {
    id: 'created.organisatie',
    numeric: false,
    label: 'Aangemaakt door',
    fixed: 100,
    sort: true,
  },
  {
    id: 'assignedTo',
    numeric: false,
    label: 'Toegewezen aan',
    fixed: 150,
    sort: true,
  },
  { id: 'entity', numeric: false, label: 'Horend bij', fixed: 75, sort: true },
  { id: 'adres', numeric: false, label: 'Adres', fixed: 125 },
  { id: 'gebiedscode', numeric: false, label: 'Gebied', fixed: 100 },
  { id: 'actions', numeric: false, label: 'Details', fixed: 75 },
] as const

export const AssignedBijlagen: FC = () => {
  const dispatch = useDispatch()
  const { data, isLoading, query } = useSelector(getAssignedBijlagen)
  const { items: assignedBijlagen, count, skip, limit } = data
  const { sort, page } = query

  const { item: storedFilters } = useLocalStorage(
    'bijlagen',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localSort, setLocalSort] = useState(sort)
  const [localPage, setLocalPage] = useState(page)
  const userParam = useSelector(getUserParam)

  useEffect(() => {
    dispatch(
      fetchAssignedBijlagen({
        filters: localFilters,
        page: localPage,
        sort: localSort,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort, userParam])

  const onChangeFilters = (newFilters) => {
    setLocalSort(initialState.query.sort)
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort) => setLocalSort(sort)
  const onChangePage = (page) => setLocalPage(page)

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">Overzicht van uw bijlagen</Typography>
      <Paper sx={{ mb: 2 }}>
        <Filters
          filters={localFilters}
          initialFilters={initialState.query.filters}
          onChange={onChangeFilters}
        />
        <StyledTable
          headerCells={headerCells}
          loading={isLoading}
          onChangePage={onChangePage}
          onChangeSort={onChangeSort}
          sort={sort}
          stats={{ count, skip, limit }}
        >
          {assignedBijlagen.map((assignedBijlage) => {
            return (
              <Row
                assignedBijlage={assignedBijlage}
                key={assignedBijlage._id}
              />
            )
          })}
        </StyledTable>
      </Paper>
    </Box>
  )
}
