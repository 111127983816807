import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'

import type { Label } from '@models/Label'

import { saveEvent } from '../../state'
import type { Project } from '../../types'

const mapToLabel = (v: string): Label => ({ code: v, label: v })

export interface Props {
  project: Project
  selectableLabels?: string[]
  handleClose: () => void
}

export const Labels: FC<Props> = ({
  handleClose,
  project,
  selectableLabels = [],
}: Props) => {
  const dispatch = useDispatch()

  const { aanvraagID, labels: labelsFromProject = [] } = project
  const options = selectableLabels.map(mapToLabel)
  const selectedOptions = labelsFromProject.map(mapToLabel)

  const [labels, setLabels] = useState<Label[]>(selectedOptions)

  return (
    <>
      <DialogTitle>Labels wijzigen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              Selecteer de labels die u wilt toepassen.
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              filterSelectedOptions
              options={options}
              multiple
              value={labels}
              onChange={(_, labels) => setLabels(labels)}
              renderInput={(params) => (
                <TextField variant="standard" {...params} label="Labels" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Sluiten
        </Button>
        <Button
          onClick={() =>
            dispatch(
              saveEvent({
                id: aanvraagID,
                type: 'update_labels',
                source: 'manual',
                data: {
                  labels: labels.map(({ code }) => code),
                },
              }),
            )
          }
          color="primary"
        >
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
