import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import InfoIcon from '@mui/icons-material/Info'
import LabelIcon from '@mui/icons-material/Label'
import LinkIcon from '@mui/icons-material/Link'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import TransformIcon from '@mui/icons-material/Transform'
import type { SvgIcon } from '@mui/material'
import {
  blue,
  green,
  orange,
  pink,
  purple,
  red,
  yellow,
} from '@mui/material/colors'

import type { Event } from '@models/Event'
import { formatDate, formatDateTime } from '@util/date-util'

import type { QuickscanQuestion } from '../../aansluiting/project/types'

export { extractEventTypes, questionIdMap, getEventConfig, eventTypeTitle }

const SHADE = 100

const questionIdMap: Record<QuickscanQuestion['id'], string> = {
  ['combiuitvoering']: 'Mee in combi',
  ['Extra werkvoorbereiding']: 'Extra werkvoorbereiding',
  ['Hoofdleiding aanleggen']: 'Hoofdleiding aanleggen',
  ['hoofdleiding_aanleggen']: 'Hoofdleiding aanleggen',
}

const eventTypeTitle: Record<Event['type'], string> = {
  combiuitvoering: 'Combi uitvoering',
  quickscan: 'Quickscan',
  actief: 'Actief',
  'product-annulering': 'Product annulering',
  herplanning: 'Herplanning',
  herplanning_klant: 'Herplanning klant',
  hoofdleiding_aanleggen: 'Hoofdleiding aanleggen',
  hoofdleiding_disciplines: 'Hoofdleiding disciplines',
  hoofdleiding_aannemer: 'Hoofdleiding aannemer',
  update_labels: 'Update labels',
  hoofdleiding_processtap: 'Hoofdleiding processtap',
  hoofdleiding_details: 'Hoofdleiding details',
  gebiedswissel: 'Gebiedswissel',
  project_koppelen: 'Project koppelen',
}

type EventConfig = {
  title: string
  label: string
  caption: string
  Icon: typeof SvgIcon
  background: string
  expandable: boolean
}

const extractEventTypes = (events: Event[]) => {
  return Array.from(new Set(events.map((event) => event.type)))
}

const getEventConfig = (event: Event): EventConfig => {
  const { deelnemer, organisatie } = event.data
  const { created, source, email } = event

  const title = eventTypeTitle[event.type]
  let caption = `${formatDateTime(created)} door ${
    organisatie || deelnemer || source
  }`
  if (email) {
    caption = `${caption} ${email}`
  }

  let label = 'Onbekend event'
  let Icon = QuestionMarkIcon
  let background = 'grey'
  let expandable = false

  if (event.type === 'combiuitvoering') {
    const { isCombiUitvoering, disciplines } = event.data

    Icon = AccountTreeIcon
    background = blue[SHADE]
    expandable = true

    if (typeof isCombiUitvoering === 'boolean') {
      label = isCombiUitvoering ? 'In combi' : 'Uit combi'
    } else if (disciplines) {
      label = 'Combi uitvoering per discipline'
    } else {
      label = 'Combi uitvoering'
    }
  }

  if (event.type === 'quickscan') {
    label = 'Quickscan'
    Icon = AccountTreeIcon
    background = orange[SHADE]
    expandable = true
  }

  if (event.type === 'actief') {
    const { isActive } = event.data
    label = isActive ? 'Geactiveerd' : 'Gedeactiveerd'
    Icon = NotificationsIcon
    background = yellow[SHADE]
    expandable = false
  }

  if (event.type === 'product-annulering') {
    label = 'Discipline geannuleerd'
    Icon = DoNotDisturbAltIcon
    background = red[SHADE]
    expandable = false
  }

  if (event.type === 'herplanning') {
    const { startUitvoeringWeek } = event.data

    label = `Herplanning naar week ${startUitvoeringWeek}`
    Icon = EventRepeatIcon
    background = red[SHADE]
    expandable = true
  }

  if (event.type === 'herplanning_klant') {
    const { geplandeUitvoeringsdatum } = event.data

    label = `Herplanning naar ${formatDate(geplandeUitvoeringsdatum)}`
    Icon = PermContactCalendarIcon
    background = red[SHADE]
    expandable = true
  }

  if (event.type === 'hoofdleiding_aanleggen') {
    label = 'Aanleggen hoofdleiding'
    Icon = AddCircleIcon
    background = green[SHADE]
    expandable = true
  }

  if (event.type === 'hoofdleiding_disciplines') {
    label = 'Wijzigen disciplines'
    Icon = ControlPointDuplicateIcon
    background = orange[SHADE]
    expandable = true
  }

  if (event.type === 'hoofdleiding_aannemer') {
    const { aannemer } = event.data

    label = `Wijzigen aannemer naar ${aannemer}`
    Icon = DataSaverOnIcon
    background = blue[SHADE]
    expandable = true
  }

  if (event.type === 'update_labels') {
    label = 'Wijzigen labels'
    Icon = LabelIcon
    background = purple[SHADE]
    expandable = true
  }

  if (event.type === 'hoofdleiding_processtap') {
    const { statusText } = event.data

    label = `Wijzigen processtap naar ${statusText}`
    Icon = MoveDownIcon
    background = purple[SHADE]
    expandable = false
  }

  if (event.type === 'hoofdleiding_details') {
    label = 'Wijzigen project details'
    Icon = InfoIcon
    background = yellow[SHADE]
    expandable = true
  }

  if (event.type === 'gebiedswissel') {
    label = 'Wissel naar ander gebied'
    Icon = TransformIcon
    background = pink[SHADE]
    expandable = true
  }

  if (event.type === 'project_koppelen') {
    label = 'Project koppelen'
    Icon = LinkIcon
    background = blue[SHADE]
    expandable = true
  }

  return {
    title,
    label,
    caption,
    Icon,
    background,
    expandable,
  }
}
