import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import { fetchProjectmeldingValuelists } from '@slices/valuelistsSlice'

import { getUserParam } from '../auth/state'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import {
  fetchProjectmeldingen,
  getProjectmeldingen,
  initialState,
} from './state'

const headerCells = [
  { id: 'projectName', numeric: false, label: 'Projectnaam' },
  { id: 'status', numeric: false, label: 'Status' },
  { id: 'companyName', numeric: false, label: 'Bedrijf' },
  { id: 'numberOfObjects', numeric: false, label: 'Aantal objecten' },
  { id: 'created', numeric: false, label: 'Datum aangeboden' },
  { id: 'projectCity', numeric: false, label: 'Projectplaats' },
  { id: 'details', numeric: false, label: 'Details', fixed: 75 },
]

export const Projectmeldingen = () => {
  const dispatch = useDispatch()
  const { data, isLoading, query } = useSelector(getProjectmeldingen)
  const { items: projectmeldingen, count, skip, limit } = data
  const userParam = useSelector(getUserParam)
  const { filters, page } = query

  const [localFilters, setLocalFilters] = useState(filters)
  const [localPage, setLocalPage] = useState(page)

  useEffect(() => {
    dispatch(fetchProjectmeldingen({ filters: localFilters, page: localPage }))
  }, [dispatch, userParam, localFilters, localPage])

  useEffect(() => {
    dispatch(fetchProjectmeldingValuelists())
  }, [dispatch])

  const onChangeFilters = (newFilters) => {
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangePage = (page) => setLocalPage(page)

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">Overzicht van uw vooraanmeldingen</Typography>
      <Paper sx={{ mb: 2 }}>
        <Filters
          onChange={onChangeFilters}
          filters={filters}
          initialFilters={initialState.query.filters}
        />
        <StyledTable
          headerCells={headerCells}
          onChangePage={onChangePage}
          stats={{ count, skip, limit }}
          loading={isLoading}
          {...({} as any)}
        >
          {projectmeldingen.map((projectmelding) => (
            <Row key={projectmelding.id} projectmelding={projectmelding} />
          ))}
        </StyledTable>
      </Paper>
    </Box>
  )
}
