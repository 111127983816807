import React, { FC, useEffect, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from '@mui/material'

import type { DisciplineVerzameling } from '@models/DisciplineVerzameling'

import { BooleanToggleGroup } from './BooleanToggleGroup'

const uitCombiRedenen: DisciplineVerzameling['isCombiUitvoeringReden'][] = [
  'Geen akkoord klant',
  'Geannuleerd door klant',
  'Technisch niet uitvoerbaar',
  'Verkeerd aangevraagd',
  'Uitvoering door netbeheerder zelf',
  'Buiten verzorgingsgebied netbeheerder',
  'Valt buiten scope',
]

const getKey = (dv: DisciplineVerzameling) =>
  `${dv.disciplineCode}-${dv.netbeheerderCode}`

interface Props {
  disciplineVerzamelingen: DisciplineVerzameling[]
  onChange: (disciplineVerzamelingen: DisciplineVerzameling[]) => void
}

export const EditCombiUitvoeringPerDiscipline: FC<Props> = ({
  disciplineVerzamelingen,
  onChange,
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({})

  const setError = (dv: DisciplineVerzameling, error: string) => {
    const key = getKey(dv)

    if (errors[key] && errors[key] === error) {
      return
    }

    setErrors({
      ...errors,
      [key]: error,
    })
  }

  const getError = (dv: DisciplineVerzameling) => {
    const key = getKey(dv)

    return errors[key]
  }

  const clearError = (dv: DisciplineVerzameling) => {
    setError(dv, '')
  }

  const update = (
    dv: DisciplineVerzameling,
    updates: Partial<DisciplineVerzameling>,
  ) => {
    const updatedData = [...disciplineVerzamelingen]
    const index = disciplineVerzamelingen.findIndex(
      (d) =>
        d.disciplineCode === dv.disciplineCode &&
        d.netbeheerderCode === dv.netbeheerderCode,
    )

    if (index !== -1) {
      clearError(dv)

      updatedData[index] = {
        ...updatedData[index],
        ...updates,
      }

      onChange(updatedData)
    }
  }

  useEffect(() => {
    if (
      disciplineVerzamelingen.some(
        (dv) => typeof dv.isCombiUitvoering === 'undefined',
      )
    ) {
      const definedDiscVer = [...disciplineVerzamelingen].map((dv) => ({
        ...dv,
        isCombiUitvoering:
          typeof dv.isCombiUitvoering === 'undefined'
            ? true
            : dv.isCombiUitvoering,
      }))

      onChange(definedDiscVer)
    }
  }, [disciplineVerzamelingen, onChange])

  return (
    <>
      {disciplineVerzamelingen.map((dv) => (
        <Accordion
          key={`${dv.disciplineCode}-${dv.netbeheerderCode}`}
          expanded={dv.isCombiUitvoering === false}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary>
            <Typography style={{ flex: 1 }}>
              {dv.disciplineLabel} mee in combi
            </Typography>

            <BooleanToggleGroup
              value={dv.isCombiUitvoering}
              onChange={(bool) => update(dv, { isCombiUitvoering: bool })}
            />
          </AccordionSummary>

          <AccordionDetails>
            <FormControl fullWidth>
              <Autocomplete
                options={uitCombiRedenen}
                onChange={(_, reden) => {
                  clearError(dv)
                  update(dv, {
                    isCombiUitvoeringReden: reden || undefined,
                  })
                }}
                value={dv.isCombiUitvoeringReden || null}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    required={!dv.isCombiUitvoering}
                    onInvalid={(e) => {
                      e.preventDefault()
                      setError(dv, 'Verplicht bij uit combi melden')
                    }}
                    error={!!getError(dv)}
                    helperText={getError(dv)}
                    label="Reden"
                    {...params}
                  />
                )}
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
