import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Activiteiten } from './aansluiting/activiteiten'
import { AssignedBijlagen } from './aansluiting/bijlagen'
import { Dashboard } from './aansluiting/dashboard'
import { Project } from './aansluiting/project'
import { Projecten } from './aansluiting/projecten'
import { Callback } from './auth/components/Callback'
import { Login } from './auth/components/Login'
import { Logout } from './auth/components/Logout'
import { ProtectedComponent } from './auth/components/ProtectedComponent'
import { getUser, isAuthenticated, isSessionChecked } from './auth/state'
import { Beheer } from './beheer'
import { Contracten } from './contracten'
import { Activiteiten as HoofdleidingActiviteiten } from './hoofdleiding/activiteiten'
import { AssignedBijlagen as HoofdleidingAssignedBijlagen } from './hoofdleiding/bijlagen'
import { Dashboard as HoofdleidingDashboard } from './hoofdleiding/dashboard'
import { Project as HoofdleidingProject } from './hoofdleiding/project'
import { Projecten as HoofdleidingProjecten } from './hoofdleiding/projecten'
import { Navigation } from './nav'
import { LoginNavigation } from './nav/components/LoginNavigation'
import { NotFound } from './nav/components/NotFound'
import { Opdracht } from './opdracht'
import { CombiOpdracht } from './opdracht/combi'
import { Opdrachten } from './opdrachten'
import { CombiOpdrachten } from './opdrachten/combi'
import { Projectdossier } from './projectdossier'
import { Projectdossiers } from './projectdossiers'
import { Projectmelding } from './projectmelding'
import { Projectmeldingen } from './projectmeldingen'

export default function MainRoutes() {
  const authenticated = useSelector(isAuthenticated)
  const sessionChecked = useSelector(isSessionChecked)
  const {
    canViewDashboard,
    canViewProjecten,
    canViewCombiOpdrachten,
    canViewOpdrachten,
    canViewActiviteiten,
    canViewProjectmeldingen,
    canViewProjectdossiers,
    canViewContracten,
    canViewBeheer,
    canViewAssignedBijlagen,
  } = useSelector(getUser)

  return (
    <>
      {authenticated ? <Navigation /> : sessionChecked && <LoginNavigation />}

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />

      <Routes>
        <Route path="/" element={<Navigate to={'/home'} replace />} />
        <Route
          path="/home"
          element={
            authenticated ? (
              <Navigate to={'/aansluiting/dashboard'} replace />
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/aansluiting/dashboard"
          element={
            <ProtectedComponent allowed={canViewDashboard}>
              <Dashboard />
            </ProtectedComponent>
          }
        />
        <Route
          path="/hoofdleiding/dashboard"
          element={
            <ProtectedComponent allowed={canViewDashboard}>
              <HoofdleidingDashboard />
            </ProtectedComponent>
          }
        />
        <Route
          path="/hoofdleiding/projecten"
          element={
            <ProtectedComponent allowed={canViewProjecten}>
              <HoofdleidingProjecten />
            </ProtectedComponent>
          }
        />

        <Route
          path="/hoofdleiding/activiteiten"
          element={
            <ProtectedComponent allowed={canViewProjecten}>
              <HoofdleidingActiviteiten />
            </ProtectedComponent>
          }
        />

        <Route
          path="/hoofdleiding/bijlagen"
          element={
            <ProtectedComponent allowed={canViewProjecten}>
              <HoofdleidingAssignedBijlagen />
            </ProtectedComponent>
          }
        />

        <Route
          path="/hoofdleiding/project/:id"
          element={
            <ProtectedComponent allowed={canViewProjecten}>
              <HoofdleidingProject />
            </ProtectedComponent>
          }
        />

        <Route
          path="/aansluiting/projecten"
          element={
            <ProtectedComponent allowed={canViewProjecten}>
              <Projecten />
            </ProtectedComponent>
          }
        />
        <Route
          path="/aansluiting/project/:id"
          element={
            <ProtectedComponent allowed={canViewProjecten}>
              <Project />
            </ProtectedComponent>
          }
        />
        <Route
          path="/opdrachten"
          element={
            <ProtectedComponent allowed={canViewOpdrachten}>
              <Opdrachten />
            </ProtectedComponent>
          }
        />
        <Route
          path="/opdracht/:id"
          element={
            <ProtectedComponent allowed={canViewOpdrachten}>
              <Opdracht />
            </ProtectedComponent>
          }
        />
        <Route
          path="/combiopdrachten"
          element={
            <ProtectedComponent allowed={canViewCombiOpdrachten}>
              <CombiOpdrachten />
            </ProtectedComponent>
          }
        />
        <Route
          path="/combiopdracht/:id"
          element={
            <ProtectedComponent allowed={canViewCombiOpdrachten}>
              <CombiOpdracht />
            </ProtectedComponent>
          }
        />
        <Route
          path="/aansluiting/activiteiten"
          element={
            <ProtectedComponent allowed={canViewActiviteiten}>
              <Activiteiten />
            </ProtectedComponent>
          }
        />
        <Route
          path="/aansluiting/bijlagen"
          element={
            <ProtectedComponent allowed={canViewAssignedBijlagen}>
              <AssignedBijlagen />
            </ProtectedComponent>
          }
        />
        <Route
          path="/vooraanmeldingen"
          element={
            <ProtectedComponent allowed={canViewProjectmeldingen}>
              <Projectmeldingen />
            </ProtectedComponent>
          }
        />
        <Route
          path="/vooraanmelding/:id"
          element={
            <ProtectedComponent allowed={canViewProjectmeldingen}>
              <Projectmelding />
            </ProtectedComponent>
          }
        />
        <Route
          path="/projectdossiers"
          element={
            <ProtectedComponent allowed={canViewProjectdossiers}>
              <Projectdossiers />
            </ProtectedComponent>
          }
        />
        <Route
          path="/projectdossier/:id"
          element={
            <ProtectedComponent allowed={canViewProjectdossiers}>
              <Projectdossier />
            </ProtectedComponent>
          }
        />
        <Route
          path="/contracten"
          element={
            <ProtectedComponent allowed={canViewContracten}>
              <Contracten />
            </ProtectedComponent>
          }
        />
        <Route
          path="/beheer"
          element={
            <ProtectedComponent allowed={canViewBeheer}>
              <Beheer />
            </ProtectedComponent>
          }
        />

        <Route path="/logout" element={<Logout />} />

        <Route path="/callback" element={<Callback />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}
