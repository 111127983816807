import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Chip } from '@mui/material'

/** @todo: Deze lijsten/checks kunnen versimpeld worden wanneer alle pagina's
 * zijn onderverdeeld in `/aansluiting/*` en `/hoofdleiding/*` pagina's. */
const hoofdleidingPaths = ['/hoofdleiding/']
const aansluitingPaths = [
  '/aansluiting/',
  '/dashboard',
  '/opdrachten',
  '/combiopdrachten',
  '/planning',
]

export const ContextChip: FC = () => {
  const { pathname } = useLocation()
  const [label, setLabel] = useState<string>('')

  /** Deze if check volgorde doet ertoe, omdat `/dashboard` eigenlijk in zowel
   * `hoofdleidingPaths` als in `aansluitingPaths` voorkomt. Echter, omdat we
   * eerst checken op `/hoofdleiding/` via de else/if toont hij nog het juiste
   * label. */
  useEffect(() => {
    if (hoofdleidingPaths.some((path) => pathname.includes(path))) {
      setLabel('Hoofdleiding')
    } else if (aansluitingPaths.some((path) => pathname.includes(path))) {
      setLabel('Aansluiting')
    } else {
      setLabel('')
    }
  }, [pathname])

  return label ? (
    <Chip label={label} sx={{ backgroundColor: 'rgba(0,0,0,.05)' }} />
  ) : null
}
