import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { apiError } from '../../alerts/state'
import { request, requestS3 } from '../../gateways/api'

export const useZipper = (fileName: string) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  /** Download urls and save them to a .zip file. */
  const save = async (urls: string[]) => {
    const zip = new JSZip()

    try {
      setLoading(true)

      for (const url of urls) {
        const result = await request({ url })
        const file = await requestS3({
          url: result.downloadData.url,
          responseType: 'blob',
        })

        zip.file(result.databaseData.naam, file.arrayBuffer())
      }

      const content = await zip.generateAsync({ type: 'blob' })

      saveAs(content, `${fileName}.zip`)
    } catch (error: any) {
      dispatch(apiError('Fout bij openen bestanden', error))
    } finally {
      setLoading(false)
    }
  }

  return { loading, save }
}
