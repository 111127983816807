import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Drawer,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import StyledTable from '@components/StyledTable'
import type {
  Contactpersoon,
  ContactpersoonStatus,
} from '@models/Contactpersoon'
import { formatDateTime } from '@util/date-util'

const headerCells = [
  { id: 'date', numeric: false, label: 'Datum', fixed: 80 },
  { id: 'status', numeric: false, label: 'Actie' },
  { id: 'naam', numeric: false, label: 'Naam' },
  { id: 'rol', numeric: false, label: 'Rol' },
  { id: 'telefoonnummer', numeric: false, label: 'Telefoonnummer' },
  { id: 'emailadres', numeric: false, label: 'Emailadres' },
  { id: 'email', numeric: false, label: 'Door' },
]

function translateStatus(status: ContactpersoonStatus, index: number) {
  if (status === 'created' && index === 0) return 'Aangemaakt'
  if (status === 'created' && index !== 0) return 'Bijgewerkt'
  if (status === 'cancelled') return 'Verwijderd'
}

export const ContactpersoonLog = ({
  contactpersoon,
  onClose,
  open,
}: {
  contactpersoon?: Contactpersoon
  onClose: () => void
  open: boolean
}) => {
  const { log = [] } = contactpersoon || {}

  return contactpersoon ? (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: '70%' },
      }}
    >
      <Box role="presentation" sx={{ p: '20px' }}>
        <IconButton
          data-testid="close-button"
          onClick={onClose}
          sx={{ color: 'black' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            pt: '1em',
            fontWeight: 550,
            pb: '1em',
            fontSize: '1.5rem',
            color: 'black',
          }}
          variant="h4"
        >{`Log van contactpersoon ${contactpersoon.naam}`}</Typography>
        <StyledTable headerCells={headerCells} {...({} as any)}>
          {log.map((logEntry, index) => {
            return (
              <TableRow key={`${logEntry.date}-${logEntry.organisatie}`}>
                <TableCell>{formatDateTime(logEntry.date)}</TableCell>
                <TableCell>{translateStatus(logEntry.status, index)}</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                  {logEntry.naam}
                </TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                  {logEntry.rol}
                </TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                  {logEntry.telefoonnummer}
                </TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                  {logEntry.emailadres}
                </TableCell>
                <TableCell>
                  {logEntry.organisatie}

                  {logEntry.email ? (
                    <Typography variant="caption">
                      <br />
                      {logEntry.email}
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </StyledTable>
      </Box>
    </Drawer>
  ) : (
    <></>
  )
}
