import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError, success } from '../alerts/state'
import { request } from '../gateways/api'
import type { RootState } from '../store'

import type { State } from './types'

const initialState: State = {
  projectdossier: undefined,
  linkDialog: {
    open: false,
    data: {},
  },
}

export const fetchProjectdossier = createAsyncThunk(
  'fetch/projectdossier',
  async (id: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/projectdossier/${id}`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van projectdossier', error))

      throw error
    }
  },
)

export const updateProjectdossier = createAsyncThunk(
  'update/projectdossier',
  async (payload: any, { dispatch }) => {
    const { id, projectdossierInfo } = payload

    try {
      const result = await request({
        url: `/rest/projectdossier/${id}`,
        method: 'PATCH',
        data: projectdossierInfo,
      })

      dispatch(success('Projectdossier successvol bijgewerkt'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij bijwerken projectdossier', error))

      throw error
    }
  },
)

export const linkProjectToProjectdossier = createAsyncThunk(
  'link/projectdossier/project',
  async (payload: any, { dispatch }) => {
    const { id, projectId } = payload

    try {
      const result = await request({
        url: `/rest/projectdossier/${id}/project/${projectId}`,
        method: 'PUT',
      })

      dispatch(success('Project succesvol gekoppeld'))
      dispatch(fetchProjectdossier(id))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij het koppelen van het projectdossier', error))

      throw error
    }
  },
)

export const deleteLinkProjectToProjectdossier = createAsyncThunk(
  'delete/link/projectdossier/project',
  async (payload: any, { dispatch }) => {
    const { id, projectId } = payload

    try {
      const result = await request({
        url: `/rest/projectdossier/${id}/project/${projectId}`,
        method: 'DELETE',
      })

      dispatch(success('Koppeling met project verwijderd'))
      dispatch(fetchProjectdossier(id))

      return result
    } catch (error: any) {
      dispatch(
        apiError('Fout bij het verwijderen van koppeling met project', error),
      )

      throw error
    }
  },
)

const slice = createSlice({
  name: 'projectdossier',
  initialState,
  reducers: {
    openLinkDialog: (state, action) => {
      state.linkDialog.open = true
      state.linkDialog.data = action.payload
    },
    closeLinkDialog: (state) => {
      state.linkDialog.open = false
      state.linkDialog.data = {}
    },
    clearProjectdossier: (state) => {
      state.projectdossier = initialState.projectdossier
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectdossier.fulfilled, (state, action) => {
        state.projectdossier = action.payload
      })
      .addCase(fetchProjectdossier.rejected, (state) => {
        state.projectdossier = undefined
      })
      .addCase(updateProjectdossier.fulfilled, (state, action) => {
        state.projectdossier = action.payload
      })
      .addCase(linkProjectToProjectdossier.fulfilled, (state) => {
        state.linkDialog.open = false
        state.linkDialog.data = {}
      })
  },
})

export const getProjectdossier = (state: RootState) =>
  state.projectdossier.projectdossier
export const isLinkDialogOpen = (state: RootState) =>
  state.projectdossier.linkDialog.open
export const getLinkDialogData = (state: RootState) =>
  state.projectdossier.linkDialog.data

export const { reducer } = slice
export const { openLinkDialog, closeLinkDialog, clearProjectdossier } =
  slice.actions
