import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'

import type { Opdracht } from '../types'

interface Props {
  opdracht: Opdracht
}

export const AanvraagDetail: FC<Props> = ({ opdracht }) => {
  const {
    dienstCodeLabel = 'Dienst onbekend',
    subdienstCodeLabel = 'Subdienst onbekend',
    productCode = 'Productcode onbekend',
    specificatie = 'Specificatie onbekend',
  } = opdracht

  const items = [
    { title: 'Dienst', text: dienstCodeLabel },
    { title: 'Subdienst', text: subdienstCodeLabel },
    { title: 'Productcode', text: productCode },
    { title: 'Specificatie', text: specificatie },
  ]

  return (
    <>
      <Grid container spacing={2} sx={{ m: 'auto', width: '95%', p: 1 }}>
        {generateTextItems(items)}
      </Grid>
    </>
  )
}

/** Generard a `Grid` > `Typography` or `Grid` > `DisciplineIcon` based on an array. Used for less code duplication */
function generateTextItems(items) {
  return (
    <>
      {items.map((item) => {
        return (
          <Grid key={`${item.title}-${item.text}`} item xs={3}>
            <Typography gutterBottom variant="subtitle1">
              <b>{item.title}</b>
            </Typography>
            <Typography variant="body2">{item.text}</Typography>
          </Grid>
        )
      })}
    </>
  )
}
