import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { saveEvent } from '../../state'
import type { Project } from '../../types'

const HERPLAN_REDENEN = [
  'Uitvoeringsmap niet tijdig aangeleverd',
  'Werkzaamheden klant niet gereed',
  'Schouw afgekeurd',
  'Vervuilde grond',
  'Overmacht (bijv. weersomstandigheden)',
  'Onvoldoende capaciteit combi-aannemer',
  'Op klantaanvraag',
  'Hoofdleiding (nog) niet gereed',
  'Eerder starten mogelijk',
  'Geen reactie klant',
  'Werkvoorbereiding niet juist',
]

interface Props {
  project: Project
  handleClose: () => void
}

export const Herplan: FC<Props> = ({ project, handleClose }: Props) => {
  const dispatch = useDispatch()
  const { aanvraagID, geplandeUitvoeringsdatum } = project

  const [date, setDate] = useState<Date | null>(
    geplandeUitvoeringsdatum ? new Date(geplandeUitvoeringsdatum) : null,
  )
  const [reden, setReden] = useState('')
  const [errors, setErrors] = useState({})
  const redenen = [...HERPLAN_REDENEN]

  const validate = () => {
    const dateError = !date
    const redenError = !reden

    setErrors({
      redenError,
      dateError,
    })

    return !(dateError || redenError)
  }

  const handleSubmitClick = () => {
    if (validate()) {
      dispatch(
        // @ts-ignore-next-line checked in validate()
        saveEvent({
          id: aanvraagID,
          data: {
            geplandeUitvoeringsdatum: date,
            reden,
          },
          type: 'herplanning_klant',
          source: 'manual',
        }),
      )
    }
  }

  return (
    <>
      <DialogTitle>Herplannen project {aanvraagID}</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <DatePicker
            disablePast
            label="Geplande uitvoeringsdatum"
            value={date}
            onChange={(date) => setDate(date || null)}
            displayWeekNumber
            slotProps={{
              field: { clearable: true },
              textField: {
                error: Boolean(errors['dateError']),
                variant: 'standard',
                sx: { width: '33%', pr: 1 },
              },
            }}
          />
        </Box>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="reden-label">Reden</InputLabel>
          <Select
            labelId="reden-label"
            label="Reden"
            variant="standard"
            value={reden}
            onChange={(e) => setReden(e.target.value)}
            error={errors['redenError']}
          >
            {redenen.map((reden) => (
              <MenuItem value={reden} key={reden}>
                {reden}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={handleSubmitClick}>
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
