import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import DashboardIcon from '@mui/icons-material/Dashboard'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'

import { getUser } from '../../auth/state'
import type { RootState } from '../../store'

interface Props {
  onItemClick: () => void
}

export const HoofdleidingMenu: FC<Props> = ({ onItemClick }) => {
  const {
    user: {
      canViewDashboard,
      canViewProjecten,
      canViewActiviteiten,
      canViewAssignedBijlagen,
    },
  } = useSelector((state: RootState) => ({
    user: getUser(state),
  }))

  const shouldRender =
    canViewDashboard ||
    canViewProjecten ||
    canViewActiviteiten ||
    canViewAssignedBijlagen

  return shouldRender ? (
    <>
      <List subheader={<ListSubheader>Hoofdleiding</ListSubheader>}>
        {canViewDashboard && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/hoofdleiding/dashboard"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}

        {canViewProjecten && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/hoofdleiding/projecten"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <SettingsInputSvideoIcon />
            </ListItemIcon>
            <ListItemText primary="Projecten" />
          </ListItemButton>
        )}

        {canViewActiviteiten && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/hoofdleiding/activiteiten"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Activiteiten" />
          </ListItemButton>
        )}

        {canViewAssignedBijlagen && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/hoofdleiding/bijlagen"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <AttachFileIcon />
            </ListItemIcon>
            <ListItemText primary="Bijlagen" />
          </ListItemButton>
        )}
      </List>

      <Divider />
    </>
  ) : null
}
