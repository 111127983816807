import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, AlertTitle, Box, Snackbar } from '@mui/material'

import { getAlert, removeAlert } from './state'

const MUI_ALERT_ANIMATION_TIME = 150

export const AlertProvider: FC = () => {
  const dispatch = useDispatch()
  const alert = useSelector(getAlert)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (alert) {
      setOpen(true)
    }
  }, [alert])

  if (!alert) {
    return null
  }

  const { duration, message, messageDetail, severity, title } = alert

  const handleClose = () => {
    setOpen(false)

    setTimeout(() => {
      dispatch(removeAlert())
    }, MUI_ALERT_ANIMATION_TIME)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {title && <AlertTitle>{title}</AlertTitle>}

        {messageDetail ? (
          <Box sx={{ pt: 1, pb: 1 }}>
            <div>{message}</div>
            <em>{messageDetail}</em>
          </Box>
        ) : (
          <div>{message}</div>
        )}
      </Alert>
    </Snackbar>
  )
}
