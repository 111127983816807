import type { FC, PropsWithChildren } from 'react'
import React, { useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

interface Props {
  title: string
}

export const StyledExpandCard: FC<PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion
      sx={{ mb: 2 }}
      onChange={() => {
        setExpanded(!expanded)
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ width: '95%', mx: 'auto', pb: 7 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
