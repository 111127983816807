import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutoComplete from '@components/toolbar/FilterAutocomplete'
import FilterFromToDate from '@components/toolbar/FilterFromToDate'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import { FilterMyList } from '@components/toolbar/FilterMyList'

import { getUser } from '../../../../auth/state'
import type { FiltersWerklijsten } from '../../types'

export interface Props {
  filters: FiltersWerklijsten
  initialFilters: FiltersWerklijsten
  onChange: (filters: FiltersWerklijsten) => void
  valuelists: any
}

export const Filters: FC<Props> = ({
  filters,
  initialFilters,
  onChange,
  valuelists,
}: Props) => {
  const [localFilters, setLocalFilters] = useState(filters)
  const { combi } = useSelector(getUser)
  const { gebieden, actiehouders } = valuelists

  const handleFilterChange = (filters, submit?: boolean) => {
    setLocalFilters({ ...localFilters, ...filters })

    if (submit) {
      onChange({ ...localFilters, ...filters })
    }
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={localFilters.gebied}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      {combi && (
        <FilterGridItem>
          <FilterAutoComplete
            items={actiehouders}
            label="Organisatie"
            filterName="organisatie"
            value={localFilters.organisatie}
            onFilterChange={handleFilterChange}
            groupBy={(actiehouder) => actiehouder.group}
          />
        </FilterGridItem>
      )}

      <FilterGridItem minColumns="double">
        <FilterFromToDate
          fromDate={localFilters.fromGeplandeUitvoeringsdatum}
          toDate={localFilters.toGeplandeUitvoeringsdatum}
          onFilterChange={handleFilterChange}
          label="Project gepland"
          prop="GeplandeUitvoeringsdatum"
        />
      </FilterGridItem>

      {valuelists.labels.length > 0 && (
        <FilterGridItem>
          <FilterAutoComplete
            items={valuelists.labels}
            label="Labels"
            filterName="labels"
            value={localFilters.labels}
            onFilterChange={handleFilterChange}
            multiple
          />
        </FilterGridItem>
      )}

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={resetFilters}>
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <FilterMyList
          myList={localFilters.myList}
          onFilterChange={handleFilterChange}
        />

        <LocalStorageButtons
          name="werklijsten"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
