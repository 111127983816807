import React, { FC } from 'react'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import { useActiviteitenSearch } from './hooks'

const headerCells = [
  { id: 'expired', numeric: false, label: '', fixed: 25 },
  { id: 'type', numeric: false, label: 'Type activiteit', fixed: 125 },
  { id: 'text', numeric: false, label: 'Opmerking' },
  {
    id: 'dueDate',
    numeric: false,
    label: 'Opleverdatum',
    fixed: 100,
    sort: true,
  },
  {
    id: 'created.date',
    numeric: false,
    label: 'Aangemaakt',
    fixed: 100,
    sort: true,
  },
  {
    id: 'created.organisatie',
    numeric: false,
    label: 'Aangemaakt door',
    fixed: 150,
    sort: true,
  },
  {
    id: 'assignedTo',
    numeric: false,
    label: 'Toegewezen aan',
    fixed: 150,
    sort: true,
  },
  { id: 'changed', numeric: false, label: 'Gewijzigd', fixed: 100, sort: true },
  { id: 'entity', numeric: false, label: 'Horend bij', fixed: 75, sort: true },
  { id: 'adres', numeric: false, label: 'Adres' },
  { id: 'gebiedscode', numeric: false, label: 'Gebied', fixed: 100 },
  { id: 'actions', numeric: false, label: 'Details', fixed: 100 },
] as const

export const Activiteiten: FC = () => {
  const {
    data: { items: activiteiten, count, skip, limit },
    isLoading,
    initialFilters,
    onChangeFilters,
    onChangeSort,
    onChangePage,
    filters,
    sort,
  } = useActiviteitenSearch()

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">Overzicht van uw activiteiten</Typography>
      <Paper sx={{ mb: 2 }}>
        <Filters
          onChange={onChangeFilters}
          filters={filters}
          initialFilters={initialFilters}
        />
        <StyledTable
          onChangeSort={onChangeSort}
          onChangePage={onChangePage}
          headerCells={headerCells}
          sort={sort}
          stats={{ count, skip, limit }}
          loading={isLoading}
        >
          {activiteiten.map((activiteit) => {
            return <Row key={activiteit._id} activiteit={activiteit} />
          })}
        </StyledTable>
      </Paper>
    </Box>
  )
}
