import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import Providers from '@components/Providers'

import './index.css'
import MainRoutes from './routes'
import store from './store'

ReactDOM.render(
  <Providers store={store}>
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
  </Providers>,
  document.getElementById('root'),
)
