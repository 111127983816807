import React from 'react'

import { Chip, SxProps } from '@mui/material'

/* eslint-disable no-bitwise, no-magic-numbers */
const stringToColor = (str: string) => {
  let hash = 0
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += value.toString(16).padStart(2, '0')
  }
  return color
}
/* eslint-enable no-bitwise, no-magic-numbers */

interface Props {
  text: string
  small?: boolean
  sx?: SxProps
}

export default function Label({ text, small = false, sx }: Props) {
  return (
    <Chip
      label={text}
      variant="outlined"
      sx={{
        borderColor: stringToColor(text),
        mr: 1,
        mt: 1,
        ...(small && {
          borderRadius: '11px',
          height: '22px',

          '& .MuiChip-label': {
            paddingTop: '2px',
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        }),
        ...sx,
      }}
    />
  )
}
