import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { IconButton, Tooltip } from '@mui/material'

interface Props {
  href: string
  icon: ReactNode
  title: string
}

const StyledIconLink = ({ href, icon, title }: Props) => (
  <Tooltip title={title}>
    <Link to={href} style={{ display: 'inline-block' }}>
      <IconButton>{icon}</IconButton>
    </Link>
  </Tooltip>
)

export default StyledIconLink
