import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

import { saveEvent } from '../../state'
import type { OptionsDialogData } from '../../types'

interface Props {
  data: OptionsDialogData
  handleClose: () => void
}

export const LinkProject: FC<Props> = ({ data, handleClose }) => {
  const dispatch = useDispatch()
  const {
    project: { aanvraagID },
  } = data

  const [projectId, setProjectId] = useState('')

  const [errors, setErrors] = useState<Record<string, boolean>>({})

  const validate = () => {
    setErrors({
      projectIdError: !projectId,
    })

    return !!projectId
  }

  const handleSubmitClick = () => {
    if (validate()) {
      dispatch(
        saveEvent({
          id: aanvraagID,
          data: {
            addProject: projectId,
          },
          type: 'project_koppelen',
          source: 'manual',
        }),
      )
    }
  }

  return (
    <>
      <DialogTitle>Koppel een project aan {aanvraagID}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ width: '100%', mt: 2 }}
          variant="standard"
          value={projectId}
          onChange={(e) => setProjectId(e.target.value.trim())}
          error={errors.projectIdError}
          label="Project ID"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={handleSubmitClick}>
          Koppelen
        </Button>
      </DialogActions>
    </>
  )
}
