import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'

import type { RootState } from '../../src/store'
import { apiError } from '../alerts/state'
import { request } from '../gateways/api'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: {
      statuscode: [{ code: 1010, label: 'Gemeld' }],
      gebied: [],
    },
    page: { skip: 0, limit: 25 },
  },
  status: 'idle',
}

export const createFilters = ({ gebied, statuscode }) => {
  const filters: IFilter[] = []

  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })

  filters.push({
    name: 'statuscode',
    value: statuscode.map(({ code }) => parseInt(code)),
    operator: 'in',
  })

  return filters
}

export const fetchProjectmeldingen = createAsyncThunk(
  'fetch/projectmeldingen',
  async (payload: any, { dispatch }) => {
    const { filters, page } = payload

    try {
      const result = await request({
        url: '/rest/projectmelding/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van vooraanmeldingen', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'projectmeldingen',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectmeldingen.pending, (state, action) => {
        state.data = initialState.data
        state.query = action.meta.arg
        state.status = 'loading'
      })
      .addCase(fetchProjectmeldingen.fulfilled, (state, action) => {
        const { skip = 0, limit = 0, items = [], count = 0 } = action.payload

        state.data = { items, skip, limit, count }
        state.status = 'idle'
      })
      .addCase(fetchProjectmeldingen.rejected, (state) => {
        state.data = initialState.data
        state.status = 'error'
      })
  },
})

export const getProjectmeldingen = (state: RootState) => ({
  data: state.projectmeldingen.data,
  query: state.projectmeldingen.query,
  isLoading: state.projectmeldingen.status === 'loading',
})

export const { reducer } = slice
