import React, { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'

interface Props {
  filters: any
  initialFilters: any
  onChangeFilters: (filters: any) => void
  valuelists: any
}

export default function WerkbakFilters({
  filters,
  initialFilters,
  onChangeFilters,
  valuelists,
}: Props) {
  const [localFilters, setLocalFilters] = useState(filters)
  const { netbeheerders, aannemers, gebieden } = valuelists

  const handleFilterChange = (filters) => {
    setLocalFilters({ ...localFilters, ...filters })
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)
    onChangeFilters(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterAutocomplete
          items={gebieden}
          label="Gebied"
          filterName="gebied"
          value={localFilters.gebied}
          onFilterChange={handleFilterChange}
          multiple
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />
      </FilterGridItem>

      {/* If there is only 1 netbeheerder, hide the list. It's unnecessary to display it */}
      {netbeheerders.length > 1 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={netbeheerders}
            label="Netbeheerder"
            filterName="netbeheerder"
            value={localFilters.netbeheerder}
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>
      )}

      {aannemers.length > 1 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={aannemers}
            label="Aannemer"
            filterName="aannemer"
            value={localFilters.aannemer}
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>
      )}

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={resetFilters}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChangeFilters(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <LocalStorageButtons
          name="werkbakken"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
