import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'

import SearchIcon from '@mui/icons-material/Search'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Link as MuiLink,
  Typography,
} from '@mui/material'

const oldRoutesMap: Record<string, { newUrl: string; title: string }> = {
  ['/dashboard']: { newUrl: '/aansluiting/dashboard', title: 'Dashboard' },
  ['/projecten']: { newUrl: '/aansluiting/projecten', title: 'Projecten' },
  ['/project/']: { newUrl: '/aansluiting/project/', title: 'Project' },
  ['/activiteiten']: {
    newUrl: '/aansluiting/activiteiten',
    title: 'Activiteiten',
  },
  ['/bijlagen']: { newUrl: '/aansluiting/bijlagen', title: 'Bijlagen' },
  ['/hoofdleidingen']: {
    newUrl: '/hoofdleiding/projecten',
    title: 'Projecten',
  },
  ['/projectmeldingen']: {
    newUrl: '/vooraanmeldingen',
    title: 'Vooraanmeldingen',
  },
  ['/projectmelding/']: {
    newUrl: '/vooraanmelding/',
    title: 'Vooraanmelding',
  },
}

export const NotFound: FC = () => {
  const { pathname } = useLocation()

  const projectPath = pathname.indexOf('/project/') !== -1
  const projectId = projectPath ? pathname.split('/project/')[1] : ''

  const projectmeldingPath = pathname.indexOf('/projectmelding/') !== -1
  const projectmeldingId = projectmeldingPath
    ? pathname.split('/projectmelding/')[1]
    : ''

  return (
    <Container maxWidth="sm" sx={{ pt: '50px' }}>
      <Card sx={{ p: '20px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Typography variant="h2" sx={{ ml: '10px' }}>
                Pagina niet gevonden
              </Typography>
              <SearchIcon
                sx={{
                  ml: 2,
                  display: 'inline-block',
                  width: 'auto',
                  fontSize: '32px',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography paragraph sx={{ m: 0 }}>
                Oeps, deze pagina bestaat niet (meer). Controleer de URL of ga
                naar de{' '}
                <MuiLink component={Link} to="/">
                  home pagina
                </MuiLink>
                .
              </Typography>
            </Grid>

            {pathname in oldRoutesMap && (
              <Grid item xs={12}>
                <Typography paragraph sx={{ m: 0 }}>
                  Het lijkt erop dat je probeert naar een oude pagina te gaan.
                  Deze is verplaatst in november &apos;23 met CAPO versie 1.64.
                  Probeer het eens met de nieuwe pagina:{' '}
                  <MuiLink component={Link} to={oldRoutesMap[pathname].newUrl}>
                    {oldRoutesMap[pathname].title}
                  </MuiLink>{' '}
                  en vergeet niet je bookmarks aan te passen.
                </Typography>
              </Grid>
            )}

            {/** Could be DRYer but this is temporary and more readable. */}
            {projectPath && projectId && (
              <Grid item xs={12}>
                <Typography paragraph sx={{ m: 0 }}>
                  Het lijkt erop dat je probeert naar een oude pagina te gaan.
                  Deze is verplaatst in november &apos;23 met CAPO versie 1.64.
                  Probeer het eens met de nieuwe pagina:{' '}
                  <MuiLink
                    component={Link}
                    to={`${oldRoutesMap['/project/'].newUrl}${projectId}`}
                  >
                    {oldRoutesMap['/project/'].title}
                  </MuiLink>{' '}
                  en vergeet niet je bookmarks aan te passen.
                </Typography>
              </Grid>
            )}

            {projectmeldingPath && projectmeldingId && (
              <Grid item xs={12}>
                <Typography paragraph sx={{ m: 0 }}>
                  Het lijkt erop dat je probeert naar een oude pagina te gaan.
                  Deze is verplaatst in december &apos;23 met CAPO versie 1.65.
                  Probeer het eens met de nieuwe pagina:{' '}
                  <MuiLink
                    component={Link}
                    to={`${oldRoutesMap['/projectmelding/'].newUrl}${projectmeldingId}`}
                  >
                    {oldRoutesMap['/projectmelding/'].title}
                  </MuiLink>{' '}
                  en vergeet niet je bookmarks aan te passen.
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}
