import React, { FC } from 'react'

import type { Act } from '@models/Act'
import { formatDateTime } from '@util/date-util'

const getVergunningText = (data) => {
  return !!data?.vergunningId && !!data?.request?.vestiging?.name
    ? `Vergunning ${data.vergunningId} is aangelegd voor vestiging ${data.request.vestiging.name}`
    : 'Vergunning data niet aanwezig'
}

interface Props {
  isVergunning?: boolean
  text?: string
  status?: string
  completed?: Act
  data?: any
}

export const ActiviteitOpmerking: FC<Props> = ({
  isVergunning,
  text,
  status,
  completed,
  data,
}) => {
  const {
    text: completedText,
    organisatie: completedBy,
    date: completedAt,
  } = completed || {}

  return (
    <>
      {isVergunning && (
        <div>
          <div>{getVergunningText(data)}</div>
          <br />
        </div>
      )}
      <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
      {completedAt && (
        <div>
          <br />
          <div>{`${
            status === 'cancelled' ? 'Geannuleerd' : 'Afgerond'
          } door ${completedBy} op ${formatDateTime(completedAt)}`}</div>
          {completedText && (
            <div>
              <br />
              <div>{`${completedText}`}</div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
