import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AssignmentIcon from '@mui/icons-material/Assignment'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DashboardIcon from '@mui/icons-material/Dashboard'
import InboxIcon from '@mui/icons-material/Inbox'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'

import { getUser } from '../../auth/state'
import type { RootState } from '../../store'

interface Props {
  onItemClick: () => void
}

export const AansluitingMenu: FC<Props> = ({ onItemClick }) => {
  const {
    user: {
      canViewDashboard,
      canViewProjecten,
      canViewCombiOpdrachten,
      canViewOpdrachten,
      canViewAssignedBijlagen,
      canViewActiviteiten,
    },
  } = useSelector((state: RootState) => ({
    user: getUser(state),
  }))

  const shouldRender =
    canViewDashboard ||
    canViewProjecten ||
    canViewCombiOpdrachten ||
    canViewOpdrachten ||
    canViewAssignedBijlagen ||
    canViewActiviteiten

  return shouldRender ? (
    <>
      <List subheader={<ListSubheader>Aansluiting</ListSubheader>}>
        {canViewDashboard && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/aansluiting/dashboard"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}

        {canViewProjecten && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/aansluiting/projecten"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Projecten" />
          </ListItemButton>
        )}

        {canViewOpdrachten && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/opdrachten"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Opdrachten" />
          </ListItemButton>
        )}

        {canViewCombiOpdrachten && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/combiopdrachten"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Combi opdrachten" />
          </ListItemButton>
        )}

        {canViewActiviteiten && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/aansluiting/activiteiten"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Activiteiten" />
          </ListItemButton>
        )}

        {canViewAssignedBijlagen && (
          <ListItemButton
            component={Link}
            onClick={onItemClick}
            to="/aansluiting/bijlagen"
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <AttachFileIcon />
            </ListItemIcon>
            <ListItemText primary="Bijlagen" />
          </ListItemButton>
        )}
      </List>

      <Divider />
    </>
  ) : null
}
