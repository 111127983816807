import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog } from '@mui/material'

import { closeContactpersoonDialog } from '../state'

import { CreateEdit } from './dialogs/CreateEdit'
import { Delete } from './dialogs/Delete'

interface Props {
  open: boolean
  data: any
}

export const ContactpersoonDialog: FC<Props> = ({ open, data }) => {
  const dispatch = useDispatch()
  const onClose = () => dispatch(closeContactpersoonDialog())

  const { type } = data

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      {type === 'create' && <CreateEdit data={data} onClose={onClose} />}
      {type === 'edit' && <CreateEdit data={data} onClose={onClose} isEdit />}
      {type === 'delete' && <Delete data={data} onClose={onClose} />}
    </Dialog>
  )
}
