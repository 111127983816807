import { useReducer } from 'react'

interface Progression {
  total: number
  current: number
  error: boolean
}

const initialProgression: Progression = {
  total: 0,
  current: 0,
  error: false,
}

type Action =
  | { type: 'set'; payload: Progression }
  | { type: 'bump' | 'clear' | 'fail' }

const reducer = (progression: Progression, action: Action) => {
  switch (action.type) {
    case 'set':
      return {
        ...progression,
        current: action.payload.current,
        total: action.payload.total,
        error: action.payload.error,
      }

    case 'bump':
      return {
        ...progression,
        current: progression.current + 1,
      }

    case 'clear':
      return {
        ...initialProgression,
      }

    case 'fail':
      return {
        ...progression,
        error: true,
      }
  }
}

export const useProgress = () => {
  const [progression, dispatch] = useReducer(reducer, initialProgression)

  const set = (progression: Progression) => {
    dispatch({ type: 'set', payload: progression })
  }

  const bump = () => {
    dispatch({ type: 'bump' })
  }

  const clear = () => {
    dispatch({ type: 'clear' })
  }

  const fail = () => {
    dispatch({ type: 'fail' })
  }

  return {
    ...progression,
    set,
    bump,
    clear,
    fail,
  }
}
