import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material'

import useLocalStorage from '@hooks/useLocalStorage'
import { getValuelists } from '@slices/valuelistsSlice'

import { getUser } from '../../../../auth/state'
import {
  fetchWerkbakken,
  getWerkbakken,
  getWerkbakkenFilters,
} from '../../../../opdrachten/state'
import { initialState } from '../../../../opdrachten/state'
import { storeSelectedTabDashboard } from '../../state'

import WerkbakFilters from './Filters'
import WerkbakTile from './Tile'

export interface Props {
  onClickTile: (query: any, url: any) => void
}

export const Werkbakken: FC<Props> = ({ onClickTile }) => {
  const dispatch = useDispatch()
  const werkbakken = useSelector(getWerkbakken)
  const werkbakkenFilters = useSelector(getWerkbakkenFilters)
  const valuelists = useSelector(getValuelists)
  const { canViewOpdrachten, canViewCombiOpdrachten } = useSelector(getUser)

  const { item: storedFilters } = useLocalStorage(
    'werkbakken',
    werkbakkenFilters,
    initialState.werkbakkenFilters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)

  useEffect(() => {
    if (canViewOpdrachten) {
      dispatch(fetchWerkbakken({ filters: localFilters }))

      if (!canViewCombiOpdrachten) {
        dispatch(storeSelectedTabDashboard('werkbakken'))
      }
    }
  }, [canViewCombiOpdrachten, canViewOpdrachten, dispatch, localFilters])

  return canViewOpdrachten ? (
    <>
      <WerkbakFilters
        onChangeFilters={(newFilters) => setLocalFilters(newFilters)}
        filters={localFilters}
        initialFilters={initialState.werkbakkenFilters}
        valuelists={valuelists}
      />

      <Box sx={{ p: '50px', ml: '20px' }}>
        {['Netbeheerder', 'Aannemer', 'Uitval'].map((group) => (
          <Accordion sx={{ width: '100%' }} defaultExpanded key={group}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontSize: '24px', pl: '24px' }}>
                {group}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {werkbakken
                  .filter((werkbak) => werkbak.deelnemer === group)
                  .map((wb) => (
                    <Grid item key={wb.code}>
                      <WerkbakTile werkbak={wb} onClickTile={onClickTile} />
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  ) : null
}
