import React, { useState } from 'react'

import { Drawer, Grid, TableCell, TableRow, Typography } from '@mui/material'

import { ProductIcon } from '@components/DisciplineIcon'
import type { DisciplineVerzameling as DisciplineVerzamelingType } from '@models/DisciplineVerzameling'
import { createBadge } from '@util/kpi-util'

import type { Project } from '../../project/types'

import { CombiUitvoering } from './CombiUitvoering'
import { Disciplines } from './Disciplines'

export interface Props {
  project: Project
  product: DisciplineVerzamelingType
}

export const DisciplineVerzameling = ({ project, product }: Props) => {
  const [drawer, setDrawer] = useState(false)

  const toggleDrawer = (open) => () => {
    setDrawer(open)
  }

  const handleClickProduct = (event) => {
    event.stopPropagation()
    toggleDrawer(true)()
  }

  return (
    <TableRow>
      <TableCell>
        <Grid container direction="row" alignItems="center">
          <ProductIcon
            product={product}
            badge={createBadge(product.kpi)}
            onClick={handleClickProduct}
          />
          <Typography style={{ fontSize: 'inherit' }}>
            {product.disciplineLabel}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell>
        {product.isCombiUitvoering === undefined ? (
          product.netbeheerderLabel
        ) : (
          <CombiUitvoering {...product} />
        )}
      </TableCell>
      <TableCell>{product.aantalObjecten}</TableCell>
      <TableCell>{product.statusText}</TableCell>
      <TableCell />
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
        <Disciplines
          project={project}
          product={product}
          onClose={toggleDrawer(false)}
        />
      </Drawer>
    </TableRow>
  )
}
