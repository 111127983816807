import React from 'react'

import { Badge, Box, Tooltip } from '@mui/material'

const backgroundDict = {
  E: {
    // Elektriciteit
    backgroundColor: 'rgb(79, 186, 219)',
    backgroundImage: 'url(/images/elektriciteit-icon-white.svg)',
  },
  G: {
    // Gas
    backgroundColor: 'rgb(188, 53, 141)',
    backgroundImage: 'url(/images/gas-icon-white.svg)',
  },
  W: {
    // Water
    backgroundColor: 'rgb(61, 108, 180)',
    backgroundImage: 'url(/images/water-icon-white.svg)',
  },
  M: {
    // Media
    backgroundColor: 'rgb(40, 162, 124)',
    backgroundImage: 'url(/images/media-icon-white.svg)',
  },
  C: {
    // CAI
    backgroundColor: 'rgb(231, 0, 0)',
    backgroundImage: 'url(/images/media-icon-white.svg)',
  },
  K: {
    // Koper
    backgroundColor: 'rgb(228, 193, 24)',
    backgroundImage: 'url(/images/media-icon-white.svg)',
  },
  H: {
    // Heat (warmte, W was bezet voor water)
    backgroundColor: 'rgb(221, 128, 37)',
    backgroundImage: 'url(/images/heat-icon-white.svg)',
  },
  R: {
    // Riool
    backgroundColor: 'rgb(124, 91, 174)',
    backgroundImage: 'url(/images/riool-icon-white.svg)',
  },
}

interface Props {
  toolTip?: string
  disciplineCode: string
  onClick?: any
  active?: boolean
  badge?: any
}

export default function DisciplineIcon({
  toolTip = '',
  disciplineCode,
  onClick,
  active = true,
  badge = {},
}: Props) {
  const disabled = !active
  const clickable = onClick && active
  const badgeColor =
    badge.color && badge.color === 'red' ? badge.color : 'darkorange'
  const title = badge.msg ? badge.msg : toolTip

  return (
    <Tooltip title={title}>
      <Badge
        sx={{
          '& .MuiBadge-badge': { backgroundColor: badgeColor, color: 'white' },
        }}
        badgeContent={badge.count}
        overlap="circular"
        onClick={clickable ? onClick : undefined}
      >
        <Box
          sx={{
            width: 30,
            height: 30,
            margin: '5px',
            borderRadius: '100%',
            backgroundPosition: 'center 5px',
            backgroundSize: 20,
            backgroundRepeat: 'no-repeat',
            backgroundImage: backgroundDict[disciplineCode]?.backgroundImage,
            backgroundColor: backgroundDict[disciplineCode]?.backgroundColor,
            cursor: clickable ? 'pointer' : 'help',
            ...(disabled && {
              filter: 'grayscale(100%)',
              opacity: 0.3,
            }),
          }}
        />
      </Badge>
    </Tooltip>
  )
}
// TODO add possibility for expanding tooltip with message (new line in tooltip)

interface ProductIconProps {
  product: {
    disciplineCode: string
    disciplineLabel: string
    netbeheerderCode: string
  }
  onClick?: any
  badge?: any
}

export const ProductIcon = ({
  product: { disciplineCode, disciplineLabel, netbeheerderCode },
  onClick,
  badge,
}: ProductIconProps) => (
  <DisciplineIcon
    onClick={onClick}
    disciplineCode={disciplineCode}
    toolTip={disciplineLabel}
    badge={badge}
    active={Boolean(netbeheerderCode)}
  />
)
